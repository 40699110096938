import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const submitButtonStyles = StyleSheet.create({
    button: {
      maxWidth: 224,
      width: '100%',
    },
    buttonGradient: {
      //height: 56,
      paddingVertical: 10,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 5,
    },
    buttonText: {
      ...theme.fonts.black,
      fontSize: 16,
      color: theme.colors.bgColor,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  });