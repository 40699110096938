import { Formik } from "formik";
import React, { useMemo, useState } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../assets/strings";
import { Screens } from "../../common/Constant";
import BgImageContainer from "../../components/BgImageContainer";
import FormInput from "../../components/FormInput";
import LoadingButton from "../../components/LoadingButton";
import { useRootContext } from "../../context/rootContext/RootContext";
import validationSchema from "../../data/validationSchema";
import { replace } from "../../navigation/RouteNavigation";
import ResetPasswordConfirmController from "./controller/ResetPasswordConfirmController";
import { resetPasswordConfirmStyles } from "./ResetPasswordConfirm.Styles";

type props = {};

const ResetPasswordConfirm: React.FC<props> = () => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoadingStart, setIsLoadingStarted] = useState(false);

  const url = useMemo(() => window.location.href, [window.location.href]);

  const isOrg = useMemo(
    () => new URL(url).searchParams.get("type") === "organization",
    [url]
  );

  console.log(isOrg);

  const {
    sendPasswordRequestConfirmation,
    sendPasswordRequestConfirmationForOrg,
  } = ResetPasswordConfirmController();

  const {
    dimensions: { width: width },
  } = useRootContext();

  const savePassword = async (password: string, confirmPassword: string) => {
    setIsLoadingStarted(true);
    const token = new URL(url).searchParams.get("token");
    await sendPasswordRequestConfirmation(
      token,
      password,
      confirmPassword
    ).then((res) => {
      if (!res.hasError) {
        setHasError(false);
        setErrorMessage("");
        setIsSuccess(true);
      } else {
        setHasError(true);
        setErrorMessage(res.message);
        setIsSuccess(false);
      }
    });
    setIsLoadingStarted(false);
  };

  const savePasswordForOrg = async (
    password: string,
    confirmPassword: string
  ) => {
    setIsLoadingStarted(true);
    const token = new URL(url).searchParams.get("token");
    await sendPasswordRequestConfirmationForOrg(
      token,
      password,
      confirmPassword
    ).then((res) => {
      if (!res.hasError) {
        setHasError(false);
        setErrorMessage("");
        setIsSuccess(true);
      } else {
        setHasError(true);
        setErrorMessage(res.message);
        setIsSuccess(false);
      }
    });
    setIsLoadingStarted(false);
  };

  return (
    <BgImageContainer>
      <View style={resetPasswordConfirmStyles.formContainer}>
        <View style={{ flexDirection: "row" }}>
          <Text style={resetPasswordConfirmStyles.heading}>
            {Strings.ENTER_PASSWORD}
          </Text>
          {isSuccess && (
            <LoadingButton
              style={[resetPasswordConfirmStyles.btnStyle, { width: 100 }]}
              name="Login"
              onPress={() =>
                isOrg
                  ? replace(Screens.ORGANIZATION_USER_LOGIN, null)
                  : replace(Screens.LOGIN, null)
              }
              isLoadingStart={false}
              isSuccess={false}
            />
          )}
        </View>
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validationSchema={validationSchema.resetPassword}
          onSubmit={(values) => {
            isOrg
              ? savePasswordForOrg(values.password, values.confirmPassword)
              : savePassword(values.password, values.confirmPassword);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (
            <>
              <View
                style={[
                  resetPasswordConfirmStyles.inputsContainer,
                  width < 540 && {
                    flexDirection: "column",
                    marginHorizontal: 10,
                  },
                ]}
              >
                <View
                  style={[
                    resetPasswordConfirmStyles.inputContainer,
                    { marginRight: 5 },
                  ]}
                >
                  <FormInput
                    label="Password"
                    inputPlaceholder="Password"
                    inputValue={values.password}
                    setInputValue={handleChange("password")}
                    mode="outlined"
                    error={touched.password && errors.password}
                    onBlur={handleBlur("password")}
                    secureTextEntry
                    labelStyle={resetPasswordConfirmStyles.label}
                    style={resetPasswordConfirmStyles.input}
                    activeOutlineColor="#9797FF"
                    outlineColor="transparent"
                    dense
                  />
                  {(touched.password && errors.password) === undefined && (
                    <View
                      style={resetPasswordConfirmStyles.errorContainer}
                    ></View>
                  )}
                </View>
                <View style={resetPasswordConfirmStyles.inputContainer}>
                  <FormInput
                    label="Confirm Password"
                    inputPlaceholder="Confirm Password"
                    inputValue={values.confirmPassword}
                    setInputValue={handleChange("confirmPassword")}
                    mode="outlined"
                    error={touched.confirmPassword && errors.confirmPassword}
                    onBlur={handleBlur("confirmPassword")}
                    secureTextEntry
                    labelStyle={resetPasswordConfirmStyles.label}
                    style={resetPasswordConfirmStyles.input}
                    activeOutlineColor="#9797FF"
                    outlineColor="transparent"
                    dense
                  />
                  {(touched.confirmPassword && errors.confirmPassword) ===
                    undefined && (
                    <View
                      style={resetPasswordConfirmStyles.errorContainer}
                    ></View>
                  )}
                </View>
              </View>
              <View style={resetPasswordConfirmStyles.btnContainer}>
                <LoadingButton
                  style={resetPasswordConfirmStyles.btnStyle}
                  name={Strings.SAVE_PASSWORD}
                  onPress={handleSubmit}
                  isLoadingStart={isLoadingStart}
                  isSuccess={isSuccess}
                />
                {hasError ? (
                  <Text style={[resetPasswordConfirmStyles.errorText]}>
                    {errorMessage}
                  </Text>
                ) : isSuccess ? (
                  <Text style={resetPasswordConfirmStyles.text}>
                    {Strings.RESET_PASS_SUCCESS_TXT}
                  </Text>
                ) : null}
              </View>
            </>
          )}
        </Formik>
      </View>
    </BgImageContainer>
  );
};

export default React.memo(ResetPasswordConfirm);
