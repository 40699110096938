import { useRoute } from "@react-navigation/native";
import { Formik } from "formik";
import React, { useMemo, useState } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../assets/strings";
import { Screens } from "../../../common/Constant";
import { decryptedData } from "../../../common/Utils";
import BgImageContainer from "../../../components/BgImageContainer";
import CustomButton from "../../../components/Buttons/CustomButton";
import ErrorText from "../../../components/ErrorText";
import FormInput from "../../../components/FormInput";
import { useRootContext } from "../../../context/rootContext/RootContext";
import validationSchema from "../../../data/validationSchema";
import { replace } from "../../../navigation/RouteNavigation";
import { theme } from "../../../theme";
import { OrganizationUser } from "../types";
import EditAdminScreenController from "./controller/EditAdminScreenController";
import { editAdminScreenStyles } from "./EditAdminScreen.Styles";
import { OrganizationNavigatorScreenProps } from "../../../navigation/types";

type props = {};

const EditAdminScreen: React.FC<props> = () => {
  const [updateMsg, setUpdateMsg] = useState("");
  const {
    dimensions: { width: width },
  } = useRootContext();
  const { params } = useRoute<OrganizationNavigatorScreenProps<"EditAdminscreen">["route"]>();

  const { editAdmin } = EditAdminScreenController();
  const data: OrganizationUser = useMemo(() => {
    return decryptedData(params.data);
  }, [params.data]);
  const updateAdmin = async (first_name: string, last_name: string) => {
    setUpdateMsg(Strings.LOADING_STARTED);
    await editAdmin(first_name, last_name, data?.id).then((res) => {
      if (!res.hasError) {
        setUpdateMsg("");
        replace(Screens.ADMIN_SCREEN, null);
      } else {
        setUpdateMsg(res.message);
      }
    });
  };

  return (
    <BgImageContainer isOrganization={true}>
      <View
        style={[
          editAdminScreenStyles.innerContainer,
          width < 540 && { width: "95%" },
        ]}
      >
        <Text style={editAdminScreenStyles.heading}>{Strings.EDIT_USER}</Text>
        <Formik
          initialValues={{
            givenName: data?.first_name,
            familyName: data?.last_name,
            email: data?.email,
          }}
          validationSchema={validationSchema.editAdmin}
          onSubmit={(values) => {
            updateAdmin(values.familyName, values.givenName);
          }}
        >
          {({ values, errors, touched, handleSubmit, handleChange }) => (
            <View style={{ marginTop: 30, flex: 1 }}>
              <View style={[editAdminScreenStyles.inputsContainer]}>
                <View style={editAdminScreenStyles.inputContainer}>
                  <FormInput
                    label={Strings.ConfirmInfo.GIVEN_NAME}
                    inputPlaceholder={Strings.ConfirmInfo.GIVEN_NAME}
                    inputValue={values.givenName}
                    setInputValue={handleChange("givenName")}
                    mode="outlined"
                    error={touched.givenName && errors.givenName}
                    labelStyle={editAdminScreenStyles.label}
                    style={editAdminScreenStyles.input}
                    activeOutlineColor="#9797FF"
                    outlineColor={theme.colors.btn}
                    dense
                  />
                  {(touched.givenName && errors.givenName) === undefined && (
                    <View style={editAdminScreenStyles.errorContainer}></View>
                  )}
                </View>
                <View style={editAdminScreenStyles.inputContainer}>
                  <FormInput
                    label={Strings.ConfirmInfo.FAMILY_NAME}
                    inputPlaceholder={Strings.ConfirmInfo.FAMILY_NAME}
                    inputValue={values.familyName}
                    setInputValue={handleChange("familyName")}
                    mode="outlined"
                    error={touched.familyName && errors.familyName}
                    labelStyle={editAdminScreenStyles.label}
                    style={editAdminScreenStyles.input}
                    activeOutlineColor="#9797FF"
                    outlineColor={theme.colors.btn}
                    dense
                  />
                  {(touched.familyName && errors.familyName) === undefined && (
                    <View style={editAdminScreenStyles.errorContainer}></View>
                  )}
                </View>
              </View>
              <View style={[editAdminScreenStyles.inputsContainer]}>
                <View style={editAdminScreenStyles.inputContainer}>
                  <FormInput
                    label={Strings.EMAIL}
                    inputPlaceholder={Strings.EMAIL}
                    inputValue={values.email}
                    editable={false}
                    setInputValue={handleChange("email")}
                    mode="outlined"
                    error={touched.email && errors.email}
                    labelStyle={editAdminScreenStyles.label}
                    style={editAdminScreenStyles.input}
                    activeOutlineColor="#9797FF"
                    outlineColor={theme.colors.btn}
                    dense
                  />
                  {(touched.email && errors.email) === undefined && (
                    <View style={editAdminScreenStyles.errorContainer}></View>
                  )}
                </View>
                <View style={{ flex: 1 }} />
              </View>
              <CustomButton
                onPress={handleSubmit}
                name={Strings.Profile.SAVE}
                isLoading={updateMsg === Strings.LOADING_STARTED}
              />
            </View>
          )}
        </Formik>
        {updateMsg && updateMsg !== Strings.LOADING_STARTED ? (
          <ErrorText
            txtStyle={editAdminScreenStyles.errorText}
            error={updateMsg}
          />
        ) : null}
      </View>
    </BgImageContainer>
  );
};

export default React.memo(EditAdminScreen);
