import AsyncStorage from "@react-native-async-storage/async-storage";
export const setStorageValue = async (key: string, value: string) => {
  try {
    return await AsyncStorage.setItem(key, value);
  } catch (error) {
    console.log("Error when setting value: ", error);
  }
};
export const getStorageValue = async (key: string) => {
  const value = await AsyncStorage.getItem(key);
  try {
    return JSON.parse(value);
  } catch (error) {
    // console.log("Error when getting value: ", error);
  }
  return value;
};
export const getLocalStorageValue = async (key: string) => {
  try {
    const value = await AsyncStorage.getItem(key);
    return value;
  } catch (error) {
    console.log("Error when getting value: ", error);
  }
};
const createPromiseAll = (
  promises: Promise<any>[],
  callback: Function | undefined,
  processResult?: (result: (string | null)[]) => any
): Promise<[string, string | null][]> => {
  return Promise.all(promises).then(
    (result) => {
      const value = processResult ? processResult(result) : null;
      callback && callback(null, value);
      return Promise.resolve(value);
    },
    (errors) => {
      callback && callback(errors);
      return Promise.reject(errors);
    }
  );
};
export function multiGet(
  keys: Array<string>,
  callback?: Function
): Promise<[string, string | null][]> {
  const promises = keys.map((key) => getStorageValue(key));
  const processResult = (result: (string | null)[]) =>
    result.map((value, i) => [keys[i], value]);
  return createPromiseAll(promises, callback, processResult);
}

export function multiSet(
  values: string[][],
  callback?: Function
): Promise<[string, string | null][]> {
  const promises = values.map((value) => setStorageValue(value[0], value[1]));
  return createPromiseAll(promises, callback);
}

export const removeStorageKeys = (keys: string[]) => {
  AsyncStorage.multiRemove(keys);
};
export const removeStorageKey = (key: string) => {
  return AsyncStorage.removeItem(key);
};
export const getAllKeys = async () => await AsyncStorage.getAllKeys();
