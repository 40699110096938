import { initialLoginType } from "../../context/AuthContext";
import { Apis } from "../axiosInstances/Apis";
import { autoVisasPrivateApi } from "../axiosInstances/autovisasPrivateApi";
import { getError, getSuccess } from "../axiosInstances/handleResponse";

export const deleteDependentDoc = async (
  loginState: initialLoginType,
  dId: string,
  docId: string
) => {
  try {
    const options = {
      method: "DELETE",
      url: `${Apis.GET_DOCUMENTS}/${loginState.userId}/dependent/${dId}/${docId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginState.userToken}`,
      },
    };
    const getDocumentResponse = await autoVisasPrivateApi.request(options);
    return getSuccess(getDocumentResponse);
  } catch (error) {
    console.error(error.response);
    return getError(error);
  }
};
