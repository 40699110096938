import { useContext } from "react";
import { useOrgAuthContext } from "../../../context/OrganizationAuthContext"
import { Screens } from "../../../common/Constant";
import { AuthContext } from "../../../context/AuthContext";
import { replace } from "../../../navigation/RouteNavigation";
import { sendOTP } from "../../../util/2FactorAuth/sendOTP";
import { login } from "../../../util/login";
import { Strings } from "../../../../assets/strings";

const LoginController = () => {
    const { authSignInCompleted } = useContext(AuthContext);
    const { orgSignInCompleted } = useOrgAuthContext();

    const handleLogin = async ( email: string, password: string ) => {
        const userLoginRes = await login(email, password);
        
        if (userLoginRes.status === 200) {
            const sendOTPRes = await sendOTP(userLoginRes.data.session_id);

            if (sendOTPRes.status === 200) {
                if (userLoginRes.data.is_org) {

                    orgSignInCompleted(email, userLoginRes.data.session_id, Strings.USER_TYPE.ORGANIZATION)
                        .then(() => {
                            //navigation.dispatch(StackActions.replace(Screens.ORGANIZATION,  {params: { screen: Screens.ORGANIZATION_SIGNUP_VERIFICATION} }));
                            replace(Screens.ORGANIZATION_SIGNUP_VERIFICATION, null);
                        })
                        .catch((e) => console.error(e));

                } else {
                    
                    authSignInCompleted(email, userLoginRes.data.session_id, Strings.USER_TYPE.INDIVIDUAL)
                        .then(() => {
                            //navigation.dispatch(StackActions.replace(Screens.AUTOVISAS, { screen: Screens.TWO_FACTOR_AUTH }));
                            replace(Screens.TWO_FACTOR_AUTH, null);
                        })
                        .catch((e) => console.error(e));
                }
            } else {
                return userLoginRes;
            }
        } else {
            return userLoginRes;
        }
        
    };

    return { handleLogin }
};

export default LoginController;