import { Strings } from "../../../../../assets/strings";
import { createOrgCheckoutLink } from "../../../../util/organization/createOrgCheckoutLink";
import { getOrgPricingTable } from "../../../../util/organization/getOrgPricingTable";

const OrgPaymentController = () => {
  const getOrgPlans = async () => {
    const response = await getOrgPricingTable();
    if (response.status === 200) {
      return {
        hasError: false,
        data: response.data,
      };
    } else {
      return {
        hasError: true,
        data: Strings.SOMETHING_WENT_WRONG,
      };
    }
  };

  const getOrgCheckoutLink = async (
    userId: string,
    productId: string,
    priceId: string
  ) => {
    const response = await createOrgCheckoutLink(userId, productId, priceId);
    if (response.status === 200) {
      return {
        hasError: false,
        data: response.data,
      };
    } else {
      return {
        hasError: true,
        data: Strings.SOMETHING_WENT_WRONG,
      };
    }
  };

  return { getOrgPlans, getOrgCheckoutLink };
};

export default OrgPaymentController;
