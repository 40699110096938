import { useState } from "react";
import { MenuType } from "../types/DeshboardTypes";

const useMenuStateHandler = (initialMenu: MenuType) => {
    const [activeMenu, setActiveMenu] = useState(initialMenu);
  
    const changeActiveMenu = (menu: MenuType) => {
      setActiveMenu(menu);
    };
  
    const isActiveMenu = (menu: MenuType): boolean => {
      return activeMenu === menu;
    };
  
    return {
      activeMenu,
      changeActiveMenu,
      isActiveMenu,
    };
  };

export default useMenuStateHandler;

  
  