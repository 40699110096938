import { useNavigation } from "@react-navigation/native";
import { View } from "react-native";
import { Text, Divider } from "react-native-paper";
import { styles } from "../../../screen/Home/HomeStyles";
import SubmitButton from "../../Buttons/Submit";

const Pricing = ({ title, subTitle, content, btnName, navigateTo }) => {
    const navigation = useNavigation();

    return (
        <View style={styles.sectionFive_container}>
            <Text style={styles.sectionFive_title}>{title}</Text>
            <Divider style={styles.sectionFive_divider} />
            <View style={styles.sectionFive_subContainer}>
                <View>
                    <Text style={styles.sectionFive_subTitle}>{subTitle}</Text>
                    {
                        content.map((text) => (
                            <View key={text.id} style={{ flexDirection: "row" }}>
                                <View style={[styles.point, { marginTop: 9, marginRight: 9, backgroundColor: "#9797FF", }]}></View>
                                <Text style={styles.sectionFive_description}>
                                    { text.description }
                                </Text>
                            </View> 
                        ))
                    }
                </View>
                {
                    navigateTo 
                        ? (
                            <SubmitButton 
                                    name={btnName} 
                                    onPress={() => navigation.navigate(navigateTo)} 
                                    style={{ maxWidth: "100%" }} 
                                    letterStyle={styles.sectionFive_letterBtn} 
                            />
                        )
                        : (
                            <View style={{ width: "100%", height: 48.8, justifyContent: "center",}}>
                                <Text style={{ fontSize: 16, textAlign: "center" }}>Contact <Text style={{ textDecorationLine: "underline" }}>hello@autovisas.com</Text> for pricing.</Text>
                            </View>
                        )
                }
            </View>
        </View>
    );
};

export default Pricing;