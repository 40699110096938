import React from "react";
import { View } from "react-native";
import { Feather } from "@expo/vector-icons";
import { theme } from "../../../../theme";
import { actionStyles } from "./Actions.Styles";

type props = {
  onEdit: () => void;
  style?: {};
};

const Actions: React.FC<props> = ({ onEdit, style }) => {
  return (
    <View style={[actionStyles.container, style]}>
      <Feather
        style={actionStyles.imageStyle}
        name="edit"
        size={18}
        color={theme.colors.btn}
        onPress={onEdit}
      />
    </View>
  );
};

export default React.memo(Actions);
