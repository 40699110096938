import * as React from "react";
import { NavigationContainer } from "@react-navigation/native";
import RootStackNavigator from "./RootStackNavigator";
import { theme } from "../theme";
import { linking } from "./linking";
import Loading from "../components/Loading";
import { navigationRef } from "./RouteNavigation";
import { StoreContextProvider } from "../context/storeContext/StoreProvider";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import OrganizationStackNavigator from "./OrganizationStackNavigator";
import { useOrgAuthContext } from "../context/OrganizationAuthContext";
import { AuthContext } from "../context/AuthContext";
import { OrgStoreContextProvider } from "../context/orgStoreContext/StoreProvider";
import { APP_TITLES, Screens } from "../common/Constant";
import { Strings } from "../../assets/strings";
import { RootNavigatorParamList } from "./types";
import { AsyncStorageKeys } from "../common/Constant";
import Home from "../screen/Home";
import Login from "../screen/Login";
import ResetPasswordRequest from "../screen/RequestPasswordRequest";
import ResetPasswordConfirm from "../screen/ResetPasswordConfirm";
import OrgSignUpVerification from "../screen/Organization/OrgSignUpVerification";
import TwoFactorAuth from "../screen/TwoFactorAuth";
import CreateAccountForm from "../screen/CreateAccount";
import OrganizationInviteScreen from "../screen/Organization/OrganizationInviteScreen";
import OrganizationSignUp from "../screen/Organization/OrgUserSignUp";
import { getLocalStorageValue } from "../common/AsyncStorage";
import { getIndividualToken } from "../util/getIndividualToken";
import { getOrganizationToken } from "../util/getOrganizationToken";

const RootNavigator = () => {
  const [isLoading, setLoading] = React.useState(true);
  const StackRoot = createNativeStackNavigator<RootNavigatorParamList>();
  const { loginState, loginDispatch } = React.useContext(AuthContext);
  const { orgAuthState, orgDispatch } = useOrgAuthContext();

  const { USER_TYPE } = AsyncStorageKeys;

  const getToken = async () => {
    const userType = await getLocalStorageValue(USER_TYPE);

    if (userType ===  Strings.USER_TYPE.INDIVIDUAL) {
      await getIndividualToken(userType, loginDispatch);
    } else if (userType === Strings.USER_TYPE.ORGANIZATION) {
      await getOrganizationToken(userType, orgDispatch);
    }

    setLoading(false);
  }

  React.useEffect(() => {
    getToken();
  }, [])

  if (isLoading) {
    return <Loading />;
  }

  return (
    <NavigationContainer
      ref={navigationRef}
      theme={theme}
      //@ts-ignore
      linking={linking}
      fallback={<Loading />}
    >
      <StackRoot.Navigator screenOptions={{ headerShown: false }}>
        {loginState.userToken && loginState.userType === Strings.USER_TYPE.INDIVIDUAL 
          ? (
              <StackRoot.Screen name={Screens.AUTOVISAS}>
                {() => (
                  <StoreContextProvider>
                    <RootStackNavigator />
                  </StoreContextProvider>
                )}
              </StackRoot.Screen>
          ) : orgAuthState.orgUserToken && orgAuthState.userType === Strings.USER_TYPE.ORGANIZATION
            ? (
                <StackRoot.Screen name={Screens.ORGANIZATION}>
                  {() => (
                    <OrgStoreContextProvider>
                      <OrganizationStackNavigator />
                    </OrgStoreContextProvider>
                  )}
                </StackRoot.Screen>
            ) : (
            <StackRoot.Group>
              <StackRoot.Screen 
                name={Screens.HOME}
                component={Home}
                options={{
                  title: APP_TITLES.HOME_TITLE
                }}
              />
              <StackRoot.Screen 
                name={Screens.LOGIN}
                component={Login}
                options={{
                  title: APP_TITLES.LOGIN_TITLE
                }}
              />
              <StackRoot.Screen 
                name={Screens.PASSWORD_RESET_REQUEST}
                options={{ 
                  title: APP_TITLES.PASSWORD_RESET_TITLE
                 }}
              >
                {() => (
                  <ResetPasswordRequest />
                )}
              </StackRoot.Screen>
              <StackRoot.Screen 
                name={Screens.PASSWORD_RESET_CONFIRM}
                options={{
                  title: APP_TITLES.PASSWORD_CONFIRM_TITLE
                }}
              >
                {() => (
                  <ResetPasswordConfirm />
                )}
              </StackRoot.Screen>
              <StackRoot.Screen
                name={Screens.CREATE_ACCOUNT}
                component={CreateAccountForm}
                options={{
                  title: APP_TITLES.CREATE_INDIVIDUAL_TITLE
                }}
              />
              <StackRoot.Screen
                name={Screens.ORGANIZATION_USER_SIGNUP}
                component={OrganizationSignUp}
                options={{
                  title: APP_TITLES.CREATE_ORGANIZATION_TITLE
                }}
              />
              <StackRoot.Screen
                name={Screens.ORGANIZATION_INVITE_SCREEN}
                component={OrganizationInviteScreen}
              />
              {orgAuthState.isOrgSignInCompleted && orgAuthState.userType === Strings.USER_TYPE.ORGANIZATION
                && (
                  <StackRoot.Screen
                    name={Screens.ORGANIZATION_SIGNUP_VERIFICATION}
                    component={OrgSignUpVerification}
                    options={{
                      title: APP_TITLES.VERIFY_OTP_TITLE
                    }}
                  />
              )}
              {loginState.isSignInCompleted && loginState.userType === Strings.USER_TYPE.INDIVIDUAL
                && (
                  <StackRoot.Screen
                    name={Screens.TWO_FACTOR_AUTH}
                    component={TwoFactorAuth}
                    options={{
                      title: APP_TITLES.VERIFY_OTP_TITLE
                    }}
                  />
              )}
            </StackRoot.Group> 
        )}
      </StackRoot.Navigator>
    </NavigationContainer>
  );
};

export default RootNavigator;
