import { StyleSheet } from "react-native";
import { theme } from "../../theme";

export const profileStyles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
  innerContainer: { maxWidth: 720, width: "100%", paddingHorizontal: 5 },
  btn: {
    height: 40,
    minWidth: 150,
    justifyContent: "center",
    alignItems: "center",
  },
  btnEdit: {
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 3,
    borderColor: theme.colors.bottom2,
  },
  btnText: {
    fontFamily: "FilsonSoftBook",
    color: "white",
    textTransform: "uppercase",
  },
  btnSave: {
    height: 40,
    width: 150,
    alignSelf: "flex-end",
    marginBottom: 8,
    marginStart: 20,
    borderRadius: 6,
  },
  profile: {
    fontFamily: "Brother1816Black",
    fontSize: 33,
    color: theme.colors.btn,
    textTransform: "uppercase",
  },
  editYour: {
    fontFamily: "FilsonSoftBook",
    fontSize: 28,
    color: theme.colors.btn,
    textTransform: "uppercase",
  },
  contain: {
    flex: 1,
    paddingHorizontal: 5,
  },
  item: {
    flexDirection: "row",
    marginBottom: 20,
  },
  input: {
    height: 24,
    paddingBottom: 5,
    paddingHorizontal: 0,
  },
  btnDelete: {
    height: 40,
    marginTop: 30,
    marginBottom: 15,
    borderRadius: 6,
    marginHorizontal: 3,
    paddingHorizontal: 20,
  },
  btnConatiner: { flexDirection: "row", justifyContent: "space-between" },
  errorText: {
    color: "red",
    marginVertical: 10,
    fontWeight: "bold",
    textAlign: "center",
  },
  label: {
    ...theme.fonts.black,
    marginBottom: 1,
    fontSize: 22,
    color: theme.colors.btn,
    textTransform: "uppercase",
  },
});
