import { Dimensions, StyleSheet } from "react-native";
import { theme } from "../../theme";
export const headerStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  logo: {
    height: 35,
    width: 300,
    position: "relative",
  },
  textStyle: {
    fontFamily: "Inter",
    fontSize: 16,
    color: theme.colors.btn,
  },
  imageStyle: {
    marginStart: 5,
    width: 32,
    height: 32,
    tintColor: theme.colors.btn,
  },
});
