export enum PlanType {
  MONTHLY = "Monthly",
  YEARLY = "Yearly",
  ONCE = "One Time",
}

export type SubscriptionPlan = {
  product_id: string;
  price_id: string;
  mode: string;
  name: string;
  description: string;
  currency: string;
  unit_amount: number;
  recurring_interval?: string;
  recurring_interval_count?: number;
  validity: {
    year: number;
  };
};

export type CheckoutLinkResponse = {
  url: string;
  expire_at: string;
  checkout_id: string;
};
