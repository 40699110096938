import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { View, Image, ScrollView } from "react-native";
import { Text } from "react-native-paper";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { theme } from "../../theme";
import Status from "../../components/Status";
import Documents from "../../components/Documents";
import Menu from "../../components/Menu";
import { Images } from "../../../assets/images";
import {
  DocumentBody,
  ImmigrantStatus,
  MenuType,
  Tags,
} from "./types/DeshboardTypes";
import StatusProtectorController from "./controller/StatusProtectorController";
import dashboardStyles from "./StatusProtector.Styles";
import { Strings } from "../../../assets/strings";
import { ProgressBar } from "../../components/progressBar/ProgressBar";
import uploadDocumentStyles from "../UploadDocuments/UploadDocumentsForm.Styles";
import UploadDocumentsController from "../UploadDocuments/controller/UploadDocumentsController";
import { useStoreContext } from "../../context/storeContext/StoreProvider";
import DeleteDocumentModal from "../../components/StatusProtector/DeleteDocumentModal";
import BgImageContainer from "../../components/BgImageContainer";
import { formatDate } from "../../common/DateFormat";
import FooterButtons from "../../components/StatusProtector/FooterButtons";
import { useRootContext } from "../../context/rootContext/RootContext";
import DependentModals from "../../components/StatusProtector/DependentModals";
import Dependents from "../../components/Dependents";
import TravelHistory from "../../components/TravelHistory";
import Warnings from "../../components/Warnings";
import { ProfileType } from "../../context/storeContext/type/StoreContextType";
import { useIsFocused } from "@react-navigation/native";
import { DependentInfo, DependentList } from "./types/Dependentypes";
import { AuthContext } from "../../context/AuthContext";
import { deleteDependent } from "../../util/dependent/deleteDependent";
import DependentTagsModal from "../../components/Dependent/DependentTagsModal";
import ErrorText from "../../components/ErrorText";
import DateAdmitUntil from "../../components/StatusProtector/DateAdmitUntil";
import NonImmigrantStatus from "../../components/StatusProtector/NonImmigrantStatus";
import StatusProtectorBody from "../../components/StatusProtector/StatusProtectorBody";
import Logo from "../../components/StatusProtector/Logo";
import useMenuStateHandler from "./hook/useMenuStateHandler";
import { AntDesign } from '@expo/vector-icons';

const StatusProtector = () => {
  const { activeMenu, changeActiveMenu, isActiveMenu } = useMenuStateHandler(MenuType.STATUS);
  const [isLoading, setIsLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [dependentList, setDependentList] = useState<DependentList[]>([]);
  const [travelHistory, setTravelHistory] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [dependentModal, setDependentModal] = useState<DependentInfo>({
    visible: false,
  });
  const [typeMessage, setTypeMessage] = useState(Strings.TYPE_MESSAGE_ERROR);
  const [docToDelete, setDocToDelete] = useState<DocumentBody>();
  const [nonImmigrantStatus, setNonImmigrantStatus] =
    useState<ImmigrantStatus>();
  const isFocused = useIsFocused();
  const { setProfileData, profileData } = useStoreContext();
  const {
    dimensions: { width },
  } = useRootContext();
  const { loginState } = useContext(AuthContext);
  const {
    getNonImmigrantStatusApi,
    getUserInfoApi,
    getDocumentsApi,
    deleteDoc,
    getTravelHistoryOfUser,
    getDependentListApi,
  } = StatusProtectorController();
  const { uploadDocumentsFile, getSingleResponse } =
    UploadDocumentsController();

  const fetchProfileData = async (
    visa_type: string,
    profileData?: ProfileType
  ) => {
    const admission_class = visa_type ? visa_type : "N/A";
    if (profileData) {
      setProfileData({
        ...profileData,
        admission_class,
      });
    } else {
      setIsLoading(true);
      const userInfo = await getUserInfoApi();
      if (!userInfo.hasError) {
        setProfileData({
          ...userInfo.data,
          admission_class,
        });
      }
    }
    setIsLoading(false);
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      const nonImmigrantStatus = await getNonImmigrantStatusApi();
      if (!nonImmigrantStatus.hasError) {
        setNonImmigrantStatus({
          ...nonImmigrantStatus?.data,
          dateEntry: formatDate(nonImmigrantStatus?.data.dateEntry, "MM/DD/YY"),
          admitUntil: formatDate(
            nonImmigrantStatus?.data.admitUntil,
            "MMM. DD, YYYY"
          ),
        });
        fetchProfileData(nonImmigrantStatus.data.visa_type, profileData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  const onDismiss = () => {
    setDependentModal({ visible: false });
  };

  useEffect(() => {
    if (isFocused) {
      if (nonImmigrantStatus && isActiveMenu(MenuType.STATUS))
        fetchProfileData(nonImmigrantStatus.visa_type);
      if (isActiveMenu(MenuType.DEPENDENTS)) getDependentList();
    }
  }, [isFocused, activeMenu]);

  useEffect(() => {
    if (isActiveMenu(MenuType.DOCUMENTS)) {
      getDocumentsList();
    }
    if (isActiveMenu(MenuType.TRAVEL)) {
      getTravelHistory();
    }
  }, [activeMenu]);

  const getDependentList = useCallback(async () => {
    setIsLoading(true);
    await getDependentListApi().then((list) => {
      setDependentList(list.data);
    });
    setIsLoading(false);
  }, []);

  const getDocumentsList = useCallback(async () => {
    setIsLoading(true);
    await getDocumentsApi().then((data) => {
      if (data.data) {
        setDocuments(data.data);
        setMessage("");
      } else {
        setMessage(data.error);
      }
    });
    setIsLoading(false);
  }, [setIsLoading]);

  const getTravelHistory = useCallback(async () => {
    setIsLoading(true);
    const travelDataResponse = await getTravelHistoryOfUser();
    if (!travelDataResponse.hasError) {
      setTravelHistory(travelDataResponse.data);
    }
    setIsLoading(false);
  }, [setIsLoading]);

  const deleteDependentHandler = useCallback(async (dId: string) => {
    setIsLoading(true);
    await deleteDependent(loginState, dId).then((res) => {
      if (res.status === 200) {
        getDependentList();
      } else {
        setTypeMessage(Strings.TYPE_MESSAGE_ERROR);
        setMessage(Strings.SOMETHING_WENT_WRONG);
      }
    });
    setIsLoading(false);
  }, []);

  const uploadFiles = useCallback(async (files: File[]) => {
    setIsLoading(true);
    const documentsApiResponse = await uploadDocumentsFile(files);
    const response = getSingleResponse(documentsApiResponse);
    if (!response.hasError) {
      getDocumentsList();
      getData();
    } else {
      setTypeMessage(Strings.TYPE_MESSAGE_ERROR);
      setMessage(response.message);
    }
    setIsLoading(false);
  }, []);

  const showDeleteDocModal = (documentBody: DocumentBody) => {
    setDocToDelete(documentBody);
    setModalVisible(!modalVisible);
  };

  const deleteDocument = async () => {
    setLoading(true);
    await deleteDoc(docToDelete.uid, docToDelete.id).then((res) => {
      setModalVisible(!modalVisible);
      if (!res.hasError) {
        getDocumentsList();
        getData();
      } else {
        setMessage(res.data);
      }
    });
    setLoading(false);
  };
  const hasPendingTags = useMemo(() => {
    const tags: string[] = nonImmigrantStatus?.tags;
    return {
      hasPendingAOS: tags?.find((tag) => tag === Tags.HAS_PENDING_AOS),
      hasPendingNIV: tags?.find((tag) => tag === Tags.HAS_PENDING_NIV),
    };
  }, [nonImmigrantStatus?.tags]);

  return (
    <BgImageContainer footer={Strings.STATUS_PROTECTOR_FOOTER}>
      <View style={{ flex: 1 }}>
        <ScrollView contentContainerStyle={dashboardStyles.container}>
          <ProgressBar loading={isLoading}>
            {!profileData.subscription_active &&
            !profileData.organization_id ? (
              <ErrorText
                error={Strings.PLAN_EXPIRED}
                txtStyle={{ textAlign: "center" }}
              />
            ) : null}
            <View style={dashboardStyles.body}>
              <Logo />
              <DateAdmitUntil
                admitUntil={nonImmigrantStatus?.admitUntil} 
                hasPendingAOS={hasPendingTags.hasPendingAOS}
                hasPendingNIV={hasPendingTags.hasPendingNIV}
              />
              <NonImmigrantStatus status={nonImmigrantStatus?.status} />
              <StatusProtectorBody>
                {isActiveMenu(MenuType.STATUS) ? (
                  <Status
                    name={`${profileData?.first_name} ${profileData?.last_name}`}
                    passportNumber={profileData?.passport_number}
                    dateEntry={nonImmigrantStatus?.dateEntry}
                    admisionClass={profileData?.admission_class}
                    citizenship={profileData?.citizenship}
                  />
                ) : isActiveMenu(MenuType.DOCUMENTS) ? (
                  <Documents
                    documents={documents}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    deleteDocument={showDeleteDocModal}
                  />
                ) : isActiveMenu(MenuType.WARNINGS) ? (
                  <Warnings tags={nonImmigrantStatus?.tags} />
                ) : isActiveMenu(MenuType.TRAVEL) ? (
                  <TravelHistory
                    isLoading={isLoading}
                    travelHistoryList={travelHistory}
                  />
                ) : (
                  <Dependents
                    dependentList={dependentList}
                    actionHandler={setDependentModal}
                    deleteDependentHandler={deleteDependentHandler}
                  />
                )}
                <Menu isActiveMenu={isActiveMenu} changeActiveMenu={changeActiveMenu} />
              </StatusProtectorBody>
              {message ? (
                <Text
                  style={[
                    typeMessage === Strings.TYPE_MESSAGE_ERROR
                      ? uploadDocumentStyles.errorText
                      : typeMessage === Strings.TYPE_MESSAGE_CBP
                        ? uploadDocumentStyles.successfulCbpText
                        : uploadDocumentStyles.successfulText,
                    { marginLeft: 45, marginTop: 15 },
                  ]}
                > 
                  {typeMessage === Strings.TYPE_MESSAGE_CBP && (
                    <AntDesign name="infocirlce" size={18} color="#004C86" style={{ marginRight: 5 }}/>
                  )}
                   {message}
                </Text>
              ) : null}
              <FooterButtons
                isStatusSet={Boolean(nonImmigrantStatus)}
                activeMenu={activeMenu}
                setMessage={(type: string, message: string) => {
                  setTypeMessage(type);
                  setMessage(message);
                }}
                reloadNonImmigrantStatusCallback={getData}
                disabled={profileData.account_paused}
                isActiveMenu={isActiveMenu}
                uploadedSuccessFully={() => {
                  getDocumentsList();
                  getData();
                }}
                addDependent={() =>
                  setDependentModal({
                    visible: true,
                  })
                }
              />
              <DependentModals
                dependentInfo={dependentModal}
                onDismiss={(profileCreated: boolean) => {
                  profileCreated === true && getDependentList();
                  onDismiss();
                }}
                modalStyle={[
                  dashboardStyles.modalStyle,
                  { width: width > 700 ? 700 : "95%" },
                ]}
              />
            </View>
          </ProgressBar>
        </ScrollView>
        <DeleteDocumentModal
          visible={modalVisible}
          onDismiss={() => setModalVisible(!modalVisible)}
          isLoading={loading}
          docName={docToDelete ? docToDelete.file_name : ""}
          onDelete={deleteDocument}
        />
      </View>
    </BgImageContainer>
  );
};

export default StatusProtector;
