import { StyleSheet } from "react-native"

export const actionStyles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
    },

    imageStyle: {
        flex: 1,
        marginHorizontal: 2
  }
})