import AsyncStorage from "@react-native-async-storage/async-storage";
import { AsyncStorageKeys } from "../common/Constant";
import { autoVisasPublicApi } from "./axiosInstances/autovisasPublicApi";

export const refreshToken = async (refresh_token) => {
  try {
    const options = {
      method: "POST",
      url: "/users/refresh",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${refresh_token}`,
      },
    };
    const response = await autoVisasPublicApi.request(options);
    return {
      userId: response.data.uid,
      accessToken: response.data.access_token.token,
      refreshToken: response.data.refresh_token.token,
      expiresAt: response.data.access_token.expires_at,
      status: response.status,
    };
  } catch (error) {
    const keys = [
      AsyncStorageKeys.USER_SESSION,
      AsyncStorageKeys.USER_EMAIL,
      AsyncStorageKeys.USER_ID,
      AsyncStorageKeys.USER_SESSION_TOKEN,
      AsyncStorageKeys.USER_SESSION_REFRESH_TOKEN,
      AsyncStorageKeys.EXPIRES_AT,
    ];
    await AsyncStorage.multiRemove(keys);
  }
};
