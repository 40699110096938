export const warningsText = {
  unexpectedType: `Your new I-94 lists an admission class that does not match your previous I-94. Please login to your AutoVisas account [hyperlinked], check your new I-94 under "Your Documents" tab, and confirm that the new I-94 lists your correct admission class.\n
If your I-94 lists an incorrect admission class, click here [hyperlinked to FAQs] for information on how to request an I-94 correction from U.S. Customs and Border Protection.\n
If your I-94 lists the correct admission class, please upload a copy of your corresponding visa and click here [hyperlinked to "dismiss alert" function] to confirm that your I-94 is correct. Clicking the link will dismiss alerts for this issue.`,
  //"gapInDates": "",
  //"majorGapInDates": "",
  cutShort: `Your I-94 expiration date was cut short upon your most recent arrival in the United States, meaning the expiration date on your new I-94 is earlier than the expiration date on your previous I-94 (which was likely issued by USCIS on form I-797A, approval notice).\n
The most common reason for a difference in I-94 end dates is an expiring passport. When your passport is expiring before the I-797 end date, CBP will cut short the I-94 end date and only grant entry until the date the passport is expiring.\n
There are generally 4 ways to “correct” a shortened I-94 before it expires after the passport has been renewed:\n
1. Travel again if your visa stamp remains unexpired. When you return, you will present your renewed passport, visa stamp, and the same I-797 approval notice. This time, CBP should electronically issue another I-94 with the end date matching your I-797 approval notice.\n 
2. If your visa stamp has expired, utilize automatic revalidation to travel to Canada or Mexico and return within 30 days. If traveling by air, CBP should electronically issue another I-94 with the end date matching your I-797 approval notice.\n 
3. File an extension with USCIS prior to the end date of the shortened I-94.\n
4. Request correction from a CBP Deferred Inspection Site.`,
  willBeCutShort: `Your current passport expires before your current I-94. If you travel internationally with your current passport, your I-94 will be cut short to your passport expiration date upon return to the U.S.\n 
    In order to avoid this issue, you should renew your passport before any international travel. If you have any questions, please contact your immigration lawy\n `,
  hasPendingNIVApplication: `Your current I-94 is expiring soon or has already expired, but you remain lawfully present in the United States based on your pending application or petition for an extension of your nonimmigrant status. You can remain in the United States legally as long as the extension is pending.\n
If USCIS approves the extension, you can remain in the United States legally through the expiration date of your new I-94 (attached to the I-797A approval notice). You may be able to stay beyond the expiration date of your new I-94 under certain circumstances.\n
If USCIS denies the extension, you may need to depart the United States within a short period of time.\n
Please contact your immigration lawyer for further information.`,
  expiringSoon: `Your current I-94 is expiring soon. You may be eligible to file for an extension or change of your nonimmigrant status. Please contact your immigration lawyer for further information.`,
  missingI94: `Upon your most recent entry to the United States, U.S. Customs and Border Protection (CBP) should have issued a new I-94 to you. However, CBP's I-94 website does not contain a new I-94.`,
  //"noAlert": "",
  expiredI94: `Your I-94 is expired. This can have serious immigration consequences.\n
If you remained in the United States past the expiration date, you are not eligible to apply for U.S. visas in third countries; you must apply in your hope country.\n
If you remain in the United States six months past the expiration date, you will be subject to a three-year ban from returning to the United States.\n 
If you remain in the United States one year past the expiration date, you will be subject to a 10-year ban from returning to the United States.`,
  hasPendingAOSApplication: `Your current I-94 is expiring soon or has already expired, but you remain lawfully present in the United States based on your pending application for adjustment of status (AOS). You can remain in the United States legally as long as the application is pending.\n
If USCIS approves the application, you will be a permanent resident with the rights to live and work in the United States indefinitely.\n
If USCIS denies the application, you may need to depart the United States within a short period of time.\n
Please contact your immigration lawyer for further information.`,

  hasPendingAOS: `Your current I-94 may be expiring soon or has already expired, but you remain lawfully present in the United States based on your pending application for adjustment of status (AOS). You can remain in the United States legally as long as the application is pending.\n
If USCIS approves the application, you will be a permanent resident with the rights to live and work in the United States indefinitely.\n
If USCIS denies the application, you may need to depart the United States within a short period of time.\n
Please contact your immigration lawyer for further information.`,
  hasPendingNIV: `Your current I-94 may be expiring soon or has already expired, but you remain lawfully present in the United States based on your pending application or petition for an extension of your nonimmigrant status. You can remain in the United States legally as long as the extension is pending.\n
If USCIS approves the extension, you can remain in the United States legally through the expiration date of your new I-94 (attached to the I-797A approval notice). You may be able to stay beyond the expiration date of your new I-94 under certain circumstances.\n
If USCIS denies the extension, you may need to depart the United States within a short period of time.\n
Please contact your immigration lawyer for further information.`,
  noDocuments: `The profile details you entered do not match any record at CBP, please double check and try again`,
  errorInRun: `We were unable to collect your latest I94, please confirm your account details and try again`,
};
