import React, { useRef } from "react";
import { Pressable, View } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Text } from "react-native-paper";
import Loading from "../../Loading";
import { customButtonStyles } from "./CustomButton.Styles";

type Props = {
  style?: {};
  btnStyle?: {};
  onPress: () => void;
  name: string;
  isDisable?: boolean;
  isLoading?: boolean;
};

const CustomButton: React.FC<Props> = ({
  style,
  btnStyle,
  onPress,
  name,
  isLoading = false,
  isDisable = false,
}) => {
  const ref = useRef(null);
  return (
    <View style={[customButtonStyles.container, style]}>
      <View style={[customButtonStyles.buttonContainer]}>
        <Pressable disabled={isDisable} style={btnStyle} onPress={onPress}>
          <LinearGradient
            ref={ref}
            colors={isDisable ? ["#AEAEAE", "#C5C5C5"] : ["#9797FF", "#BE8DFA"]}
            locations={[0.2, 0.7]}
            start={{ x: 0.1, y: 0.5 }}
            end={{ x: 0.5, y: 0.5 }}
            style={[
              customButtonStyles.buttonGradient,
              {
                opacity: isLoading ? 0.6 : 1,
              },
            ]}
          >
            {isLoading ? (
              <Loading
                loadingStyle={customButtonStyles.loadStyle}
                size="small"
                color="#F0FFFF"
              />
            ) : (
              <Text style={customButtonStyles.buttonText}>{name}</Text>
            )}
          </LinearGradient>
        </Pressable>
      </View>
    </View>
  );
};

export default CustomButton;
