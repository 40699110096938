import { StyleSheet } from "react-native";
const registerStepStyles = StyleSheet.create({
  textColor: {
    color: "#9797FF",
    textTransform: "uppercase",
  },
  numberStep: {
    fontSize: 26,
    fontFamily: "FilsonSoftBook",
  },
  title: {
    fontFamily: "Brother1816Black",
    fontSize: 39,
    marginBottom: 10,
  },
  textContainer: { maxWidth: 600, width: "100%", marginTop: 4 },
  description: {
    fontFamily: "FilsonSoftBook",
    fontSize: 18,
    lineHeight: 24,
    color: "#5B456B",
    textTransform: "uppercase",
  },
});
export default registerStepStyles;
