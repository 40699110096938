import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";

/* Logic */
import { AUTOVISAS_BASE_URL } from "@env";
import { AsyncStorageKeys } from "../../common/Constant";
import { getUserSessionFromStorage } from "../getUserSessionFromStorage";
import { refreshToken } from "../refreshToken";
import { multiSet } from "../../common/AsyncStorage";

export const autoVisasPrivateApi = axios.create({
  baseURL: AUTOVISAS_BASE_URL,
});

autoVisasPrivateApi.interceptors.request.use(
  async (config) => {
    const isTokenInStorage = await AsyncStorage.getItem(
      AsyncStorageKeys.USER_SESSION_TOKEN
    );

    if (isTokenInStorage !== null) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${isTokenInStorage}`,
      };
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

autoVisasPrivateApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const isUserSessionStorage = await getUserSessionFromStorage();
        if (isUserSessionStorage !== null) {
          const refreshTokenResponse = await refreshToken(
            isUserSessionStorage.refreshToken
          );
          await multiSet([
            [AsyncStorageKeys.USER_ID, isUserSessionStorage.userId],
            [AsyncStorageKeys.USER_EMAIL, isUserSessionStorage.email],
            [
              AsyncStorageKeys.USER_SESSION_TOKEN,
              refreshTokenResponse.accessToken,
            ],
            [AsyncStorageKeys.EXPIRES_AT, refreshTokenResponse.expiresAt],
            [
              AsyncStorageKeys.USER_SESSION_REFRESH_TOKEN,
              refreshTokenResponse.refreshToken,
            ],
          ]);

          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${refreshTokenResponse.accessToken.token}`;
          return autoVisasPrivateApi(originalRequest);
        }
      } catch (error) {
        window.location.reload();
        return Promise.reject(error);
      }
    } else {
      console.log(error.response.status);
    }

    return Promise.reject(error);
  }
);
