import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Formik } from "formik";
/* Logic */
import { theme } from "../../theme";
import { multiGet, removeStorageKeys } from "../../common/AsyncStorage";
import { AsyncStorageKeys } from "../../common/Constant";

/* Components */
import FormInput from "../../components/FormInput";
import validationSchema from "../../data/validationSchema";
import SubmitButton from "../../components/Buttons/Submit";
import TwoFactorAuthController from "./controller/TwoFactorAuthController";
import SignUpHOC from "../../hoc/SignUpHOC";
import ProgressButton from "../../components/Buttons/Progress";
import Cookies from "js-cookie";
import { Strings } from "../../../assets/strings";

const TwoFactorAuth = () => {
  const [email, setEmail] = React.useState(null);
  const [session, setSession] = React.useState(null);
  const [codeSent, setCodeSent] = React.useState(null);
  const [otpErrorMessage, setOtpErrorMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const { handleOTPVerification, handleSendOTP } = TwoFactorAuthController();

  React.useEffect(() => {
    multiGet([AsyncStorageKeys.USER_EMAIL])
      .then((response) => {
        const [userEmail] = response;
        const session = Cookies.get(AsyncStorageKeys.USER_SESSION);
        if (session !== undefined && userEmail[1] !== null) {
          setEmail(userEmail[1]);
          setSession(session);
        }
      })
      .catch((e) => console.error(e));
  }, []);

  const handleOTP = async (code: string) => {
    //Reset the OTP error message
    setOtpErrorMessage("");
    if (email !== null && session !== null) {
      const data = await handleOTPVerification(session, email, code);
      data.hasError && setOtpErrorMessage(data.message);
    } else {
      setOtpErrorMessage(Strings.SEND_OTP_USER_SESSION_EXPIRED);
      removeStorageKeys([
        AsyncStorageKeys.USER_EMAIL,
        AsyncStorageKeys.SignIn_COMPLETED,
      ]);
    }
  };

  const handleResendCode = async () => {
    setOtpErrorMessage("");
    setIsLoading(true);

    const response = await handleSendOTP(session);
    if (!response.hasError) {
      setCodeSent(true);
    } else {
      setOtpErrorMessage(response.message);
    }

    setIsLoading(false);
  };

  return (
    <SignUpHOC pageNumber={1}>
      <View style={twoFactorAuthStyles.body}>
        <Formik
          initialValues={{
            otp: "",
          }}
          validationSchema={validationSchema.verifyOTP}
          onSubmit={async (values) => {
            await handleOTP(values.otp);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (
            <View style={twoFactorAuthStyles.formContainer}>
              {otpErrorMessage !== "" && (
                <Text style={twoFactorAuthStyles.otpError}>
                  {otpErrorMessage}
                </Text>
              )}
              <View style={twoFactorAuthStyles.inputContainer}>
                <FormInput
                  label="Code"
                  inputPlaceholder="Code"
                  inputValue={values.otp}
                  setInputValue={handleChange("otp")}
                  mode="outlined"
                  error={touched.otp && errors.otp}
                  onBlur={handleBlur("otp")}
                  labelStyle={twoFactorAuthStyles.label}
                  style={twoFactorAuthStyles.input}
                  activeOutlineColor="#9797FF"
                  outlineColor="transparent"
                  dense
                />
                {(touched.otp && errors.otp) === undefined && (
                  <View style={twoFactorAuthStyles.errorContainer}></View>
                )}
              </View>
              <SubmitButton onPress={handleSubmit} name="Continue" />
              {codeSent !== null ? (
                <View style={twoFactorAuthStyles.containerResendCodeText}>
                  <Text style={twoFactorAuthStyles.successfulResendCodeText}>
                    The code has been sent!
                  </Text>
                </View>
              ) : (
                <ProgressButton
                  name="Resend code"
                  onPress={handleResendCode}
                  isLoading={isLoading}
                />
              )}
            </View>
          )}
        </Formik>
      </View>
    </SignUpHOC>
  );
};

const twoFactorAuthStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  body: {
    maxWidth: 450,
    width: 450,
    justifyContent: "center",
  },
  title: {
    ...theme.fonts.black,
    fontSize: 38,
    color: "#9797FF",
    textAlign: "center",
    textTransform: "uppercase",
    marginBottom: 10,
  },
  description: {
    fontFamily: "FilsonSoftBook",
    fontSize: 16,
    lineHeight: 24,
    color: "#5B456B",
    textAlign: "center",
    textTransform: "uppercase",
    marginBottom: 18,
  },
  emailText: {
    textTransform: "lowercase",
    color: "#4F3C5D",
  },
  otpError: {
    fontSize: 16,
    color: theme.colors.red,
    marginBottom: 18,
  },
  formContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  inputContainer: {
    maxWidth: 224,
    width: "100%",
  },
  errorContainer: {
    height: 22.5,
  },
  label: {
    fontSize: 16,
    color: "#9797FF",
    textTransform: "uppercase",
  },
  input: { backgroundColor: "#F0FFFF" },
  containerResendCodeText: {
    marginTop: 32,
  },
  successfulResendCodeText: {
    fontFamily: "FilsonSoftBook",
    fontSize: 14,
    color: "#5B456B",
  },
});

export default TwoFactorAuth;
