import React from "react";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Text } from "react-native-paper";
import { Strings } from "../../../assets/strings";
import { TravelHistoryData } from "../../screen/StatusProtector/types/DeshboardTypes";
import { travelHistoryStyles } from "./TravelHistory.Styles";
import TravelHistoryHeader from "./TravelHistoryHeader";
import TravelRowData from "./TravelRowData";

type props = {
  travelHistoryList: TravelHistoryData[];
  isLoading: boolean;
};

const TravelHistory: React.FC<props> = ({ travelHistoryList, isLoading }) => {
  return (
    <View style={travelHistoryStyles.container}>
      <View style={travelHistoryStyles.tableContainer}>
        <TravelHistoryHeader />
        {!isLoading && (
          <ScrollView contentContainerStyle={{ height: 145 }}>
            {travelHistoryList.length ? (
              travelHistoryList.map((data) => (
                <TravelRowData key={data.id} travelData={data} />
              ))
            ) : (
              <Text style={travelHistoryStyles.text}>
                {Strings.NO_DATA_FOUND}
              </Text>
            )}
          </ScrollView>
        )}
      </View>
    </View>
  );
};

export default React.memo(TravelHistory);
