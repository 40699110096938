import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const dependentDocumentStyles = StyleSheet.create({
  documentContainer: {
    maxHeight: 500,
    marginTop: 20,
    paddingBottom: 50,
  },
  docText: {
    color: theme.colors.btn,
    fontSize: 30,
    marginBottom: 20,
    fontWeight: "bold",
  },
  submitBtnContainer: {
    marginTop: 20,
    alignItems: "flex-end",
  },
  errorText: {
    fontSize: 16,
    color: theme.colors.red,
  },
});
