import { StyleSheet } from "react-native";

export const stripeSubmitButtonStyles = StyleSheet.create({
  container: {
    backgroundColor: "#4169E1",
    paddingVertical: 10,
    borderRadius: 5,
  },
  textStyle: { color: "white", textAlign: "center", fontWeight: "bold" },
});
