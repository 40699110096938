import React, { useMemo } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../assets/strings";
import {
  PlanType,
  SubscriptionPlan,
} from "../../../screen/StripePayment/types";
import StripeSubmitButton from "../../Buttons/StripeSubmitButton";
import { planDescriptionStyles } from "./PlanDescription.Styles";

type props = {
  onSubscribe: () => void;
  isLoading: boolean;
  selectedPlan: SubscriptionPlan;
};

const PlanDescription: React.FC<props> = ({
  onSubscribe,
  isLoading,
  selectedPlan,
}) => {
  const getPlan = () => {
    if (selectedPlan.mode === Strings.SUBSCRIPTION) {
      return `${selectedPlan.recurring_interval_count} ${selectedPlan.recurring_interval}`;
    } else if (selectedPlan.mode === Strings.PAYMENT) {
      return selectedPlan.validity
        ? `${selectedPlan.validity.year} ${Strings.YEAR}`
        : `${selectedPlan.recurring_interval_count} ${Strings.YEAR}`;
    }
  };

  const plan = useMemo(() => {
    return selectedPlan && getPlan();
  }, [selectedPlan]);
  const isNotSubscriptionPlan =
    selectedPlan && selectedPlan.mode === Strings.PAYMENT;
  return (
    <View style={planDescriptionStyles.container}>
      <Text style={planDescriptionStyles.txtStyle}>
        {selectedPlan && selectedPlan.description}
      </Text>
      <View style={planDescriptionStyles.paymentView}>
        <Text style={planDescriptionStyles.paymentTxt}>
          ${selectedPlan && selectedPlan.unit_amount / 100}
        </Text>
        {!isNotSubscriptionPlan && (
          <View>
            <Text style={{ fontSize: 10 }}>{Strings.PER}</Text>
            <Text style={{ fontSize: 10 }}>{plan}</Text>
          </View>
        )}
      </View>
      <View style={planDescriptionStyles.btnContainer}>
        <StripeSubmitButton
          title={isNotSubscriptionPlan ? Strings.PAY : Strings.SUBSCRIBE}
          isLoading={isLoading}
          onSubscribe={onSubscribe}
        />
      </View>
    </View>
  );
};

export default React.memo(PlanDescription);
