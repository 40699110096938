import { AsyncStorageKeys } from "./../common/Constant";
import { multiGet } from "../common/AsyncStorage";
import { userInfo } from "../data/userInfo";

export const getUserSessionFromStorage = async () => {
  try {
    const values = await multiGet([
      AsyncStorageKeys.USER_ID,
      AsyncStorageKeys.USER_EMAIL,
      AsyncStorageKeys.USER_SESSION_TOKEN,
      AsyncStorageKeys.EXPIRES_AT,
      AsyncStorageKeys.USER_SESSION_REFRESH_TOKEN,
    ]);

    return {
      userId: values[userInfo.id.position][userInfo.id.value],
      accessToken: values[userInfo.token.position][userInfo.token.value],
      expiresAt: values[userInfo.expiresAt.position][userInfo.expiresAt.value],
      refreshToken:
        values[userInfo.refreshToken.position][userInfo.refreshToken.value],
      email: values[userInfo.email.position][userInfo.email.value],
    };
  } catch (error) {
    console.error(error);
  }
};
