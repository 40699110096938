import { useState } from "react";
import { Screens } from "../../../common/Constant";
import { replace } from "../../../navigation/RouteNavigation";
import React from "react";
import CreateOrganizationController from "./controller/CreateOrganizationController";
import OrganizationDetails from "../../../components/Organization/OrganizationDetails";
import { useOrgStoreContext } from "../../../context/orgStoreContext/StoreProvider";

type props = {};

const CreateOrganization: React.FC<props> = () => {
  const { setOrganizationData } = useOrgStoreContext();

  return (
    <OrganizationDetails
      isEdit={false}
      redirect={async (data) => {
        await setOrganizationData(data);
        replace(Screens.ORGANIZATION_PAYMENT, null);
      }}
    />
  );
};

export default React.memo(CreateOrganization);
