import * as Yup from "yup";
import { Strings } from "../../assets/strings";
import Constants from "./constants";
const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
const passwordRegExp =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

const numberRegex = /^\d+$/;

export default {
  userPreferences: Yup.object({
    firstName: Yup.string()
      .max(15, "Must be 15 characters or less")
      .required("Required"),
    lastName: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Please enter email."),
    emailSecondary: Yup.string()
      .notRequired()
      .email("Invalid secondary email address"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid.")
      .required("Please enter phone number."),
    secondaryPhone: Yup.string()
      .notRequired()
      .matches(phoneRegExp, "Secondary Phone number is not valid"),
    password: Yup.string().min(6, "Password should have at least 6 characters"),
    newPassword: Yup.string()
      .ensure()
      .when("password", (password) => {
        if (password) {
          return Yup.string()
            .min(6, "Password should have at least 6 characters")
            .required("Please enter new password");
        }
      }),
    confirmPassword: Yup.string()
      .ensure()
      .when("password", (password) => {
        if (password)
          return Yup.string()
            .min(6, "Password should have at least 6 characters")
            .oneOf([Yup.ref("newPassword")], "Passwords do not match")
            .required("Please enter confirm password");
      }),
  }),
  login: Yup.object({
    email: Yup.string()
      .email(Strings.VALID_MAIL_MESSAGE)
      .required(Strings.ENTER_MAIL),
    password: Yup.string().required("Please enter password"),
  }),
  addDependent: Yup.object({
    email: Yup.string()
      .email(Strings.VALID_MAIL_MESSAGE)
      .required(Strings.ENTER_MAIL),
    relationShip: Yup.string().required("Please enter relationship"),
  }),

  new194: Yup.object({
    firstName: Yup.string()
      .max(15, "Must be 15 characters or less")
      .required(Strings.REQUIRED),
    givenName: Yup.string()
      .max(20, "Must be 15 characters or less")
      .required(Strings.REQUIRED),
    birthDate: Yup.string()
      .required(Strings.REQUIRED)
      .matches(Constants.DateRegex, "Please enter valid date."),
    passportNumber: Yup.string().required(Strings.REQUIRED),
    dateOfEntry: Yup.string()
      .required(Strings.REQUIRED)
      .matches(Constants.DateRegex, "Please enter valid date."),
    admissionClass: Yup.string().required(Strings.REQUIRED),
    expirationDate: Yup.string()
      .required(Strings.REQUIRED)
      .matches(Constants.DateRegex, "Please enter valid date."),
  }),
  register: Yup.object({
    email: Yup.string()
      .email(Strings.VALID_MAIL_MESSAGE)
      .required(Strings.ENTER_MAIL),
    password: Yup.string()
      .min(8, "Password should have at least 8 characters")
      .required("Please enter passsword")
      .matches(
        passwordRegExp,
        "Passwords must be at least 8 characters long, with at least one uppercase and one lowercase letter, one number and one special character."
      ),
    confirmPassword: Yup.string()
      .ensure()
      .when("password", (password) => {
        if (password)
          return Yup.string()
            .min(8, "Password should have at least 8 characters")
            .oneOf([Yup.ref("password")], "Passwords do not match")
            .required("Please enter confirm password");
      }),
  }),
  orgSignUp: Yup.object({
    firstName: Yup.string()
      .max(15, "Must be 15 characters or less")
      .required("Please enter your given name"),
    lastName: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Please enter your family name"),
    email: Yup.string()
      .email(Strings.VALID_MAIL_MESSAGE)
      .required(Strings.ENTER_MAIL),
    password: Yup.string()
      .min(8, "Password should have at least 8 characters")
      .required("Please enter passsword")
      .matches(
        passwordRegExp,
        "Passwords must be at least 8 characters long, with at least one uppercase and one lowercase letter, one number and one special character."
      ),
    confirmPassword: Yup.string()
      .ensure()
      .when("password", (password) => {
        if (password)
          return Yup.string()
            .min(8, "Password should have at least 8 characters")
            .oneOf([Yup.ref("password")], "Passwords do not match")
            .required("Please enter confirm password");
      }),
  }),
  resetPassword: Yup.object({
    password: Yup.string()
      .min(8, "Password should have at least 8 characters")
      .required("Please enter passsword")
      .matches(
        passwordRegExp,
        "Passwords must be at least 8 characters long, with at least one uppercase and one lowercase letter, one number and one special character."
      ),
    confirmPassword: Yup.string()
      .ensure()
      .when("password", (password) => {
        if (password)
          return Yup.string()
            .min(8, "Password should have at least 8 characters")
            .oneOf([Yup.ref("password")], "Passwords do not match")
            .required("Please enter confirm password");
      }),
  }),
  confirmInfo: Yup.object({
    first_name: Yup.string().required("Please enter your given name"),
    family_name: Yup.string().required("Please enter your family name"),
    birth_date: Yup.string().required("Please enter your date of the birth"),
    passport_number: Yup.string().required("Please enter your passport id"),
    passport_country: Yup.string().required("Please enter your citizenship"),
  }),
  confirmDependentInfo: Yup.object({
    first_name: Yup.string().required("Please enter your given name"),
    family_name: Yup.string().required("Please enter your family name"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Please enter your email"),
    birth_date: Yup.string().required("Please enter your date of the birth"),
    passport_number: Yup.string().required("Please enter your passport id"),
    passport_country: Yup.string().required("Please enter your citizenship"),
  }),
  confirmClientInfo: Yup.object({
    first_name: Yup.string().required("Please enter your given name"),
    family_name: Yup.string().required("Please enter your family name"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Please enter your email"),
    birth_date: Yup.string().required("Please enter your date of the birth"),
    passport_number: Yup.string().required("Please enter your passport id"),
    passport_country: Yup.string().required("Please enter your citizenship"),
    employer: Yup.string().optional(),
  }),
  verifyOTP: Yup.object({
    otp: Yup.string().required("Pleaser enter the code"),
  }),
  checkEmail: Yup.object({
    email: Yup.string()
      .email(Strings.VALID_MAIL_MESSAGE)
      .required(Strings.ENTER_MAIL),
  }),
  createClient: Yup.object({
    email: Yup.string()
      .email(Strings.VALID_MAIL_MESSAGE)
      .required(Strings.ENTER_MAIL),
    employer: Yup.string().optional(),
  }),
  editAdmin: Yup.object({
    givenName: Yup.string().required("Please enter your given name"),
    familyName: Yup.string().required("Please enter your family name"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Please enter your email"),
  }),
  emailVerification: Yup.object({
    otp: Yup.string()
      .required("Pleaser enter the code")
      .min(6, "Passcode must be 6 digits long")
      .max(6, "Passcode must be 6 digits long"),
    email: Yup.string()
      .email(Strings.VALID_MAIL_MESSAGE)
      .required(Strings.ENTER_MAIL),
  }),
  acceptInvitation: Yup.object({
    firstName: Yup.string().required("Please enter your given name"),
    lastName: Yup.string().required("Please enter your family name"),
    email: Yup.string()
      .email(Strings.VALID_MAIL_MESSAGE)
      .required(Strings.ENTER_MAIL),
    password: Yup.string()
      .min(8, "Password should have at least 8 characters")
      .required("Please enter passsword")
      .matches(
        passwordRegExp,
        "Passwords must be at least 8 characters long, with at least one uppercase and one lowercase letter, one number and one special character."
      ),
    confirmPassword: Yup.string()
      .ensure()
      .when("password", (password) => {
        if (password)
          return Yup.string()
            .min(8, "Password should have at least 8 characters")
            .oneOf([Yup.ref("password")], "Passwords do not match")
            .required("Please enter confirm password");
      }),
  }),
  organizationCreation: Yup.object({
    name: Yup.string().required("Please enter your name"),
    email: Yup.string()
      .email(Strings.VALID_MAIL_MESSAGE)
      .required(Strings.ENTER_MAIL),
    addressLine1: Yup.string()
      .max(100, "Must be 100 characters or less")
      .required(Strings.REQUIRED),
    addressLine2: Yup.string()
      .max(50, "Must be 50 characters or less")
      .notRequired(),
    city: Yup.string()
      .max(10, "Must be 10 characters or less")
      .required(Strings.REQUIRED),
    state: Yup.string()
      .max(10, "Must be 10 characters or less")
      .required(Strings.REQUIRED),
    zipCode: Yup.string()
      .min(2, "ZIP code must have minimum 2 characters")
      .max(16, "ZIP code must be less than 16 characters")
      .matches(numberRegex, "Please enter valid zip code")
      .required(Strings.REQUIRED),
    phone: Yup.string()
      .min(2, "Phone Number must have minimum 2 characters")
      .max(16, "Phone Number must be less than 16 characters")
      .matches(numberRegex, "Please enter valid phone number")
      .required(Strings.REQUIRED),
  }),
  addUser: Yup.object({
    email: Yup.string()
      .email(Strings.VALID_MAIL_MESSAGE)
      .required(Strings.ENTER_MAIL),
    role: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required(Strings.REQUIRED),
  }),
  updateClient: Yup.object({
    firstName: Yup.string().required("Please enter your given name"),
    lastName: Yup.string().required("Please enter your family name"),
    DOB: Yup.string().required("Please enter your date of the birth"),
    passportNumber: Yup.string().required("Please enter your passport id"),
    citizenship: Yup.string().required("Please enter your citizenship"),
  }),
  updateClientDetails: Yup.object({
    first_name: Yup.string().required("Please enter your given name"),
    last_name: Yup.string().required("Please enter your family name"),
    email: Yup.string()
      .email(Strings.VALID_MAIL_MESSAGE)
      .required(Strings.ENTER_MAIL),
    passport: Yup.string().required("Please enter your passport id"),
    citizenship: Yup.string().required("Please enter your citizenship"),
  }),
  updateOwner: Yup.object({
    firstName: Yup.string().required("Please enter your given name"),
    lastName: Yup.string().required("Please enter your family name"),
    email: Yup.string()
      .email(Strings.VALID_MAIL_MESSAGE)
      .required(Strings.ENTER_MAIL),
  }),
  changePassword: Yup.object({
    password: Yup.string().required("Please enter your current password"),
    confirmPassword: Yup.string().required("Please enter your new password"),
  }),
};
