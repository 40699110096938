import { StyleSheet } from "react-native";
import { theme } from "../../theme";

export const resetPasswordConfirmStyles = StyleSheet.create({
    formContainer: {
        maxWidth: 720,
        width: "100%",
        marginTop: 24,
        alignSelf: "center"
      },
      inputsContainer: {
        flexDirection: "row",
        marginVertical: 10,
        justifyContent: "center"
      },
      inputContainer: {
        width: "100%",
        flex: 1
      },
      errorContainer: {
        height: 22.5,
      },
      label: {
        fontSize: 16,
        color: "#9797FF",
        textTransform: "uppercase",
      },
      input: { backgroundColor: "#F0FFFF" },
      btnContainer: {
        justifyContent: "center",
        alignItems: "center"
      },
      btnStyle: {
        maxWidth: 300,
        height: 35,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor:theme.colors.btn,
        textAlign: 'center',
      },
      errorText: {
        color: "red",
        textAlign: "center",
        marginVertical: 10
      },
      text: {
        fontSize: 15,
        color: "green",
        textAlign: "center",
        marginTop: 10,
      },
      heading: {
        flex: 1,
        fontSize: 25,
        marginBottom: 20,
        color: theme.colors.btn,
        fontWeight: "bold"      
      }
})