import { Card, Text } from "react-native-paper";
import { Modal, Pressable, View } from "react-native";
import React, { useRef } from "react";
import { customModalStyles } from "./CustomModal.Styles";
import { useRootContext } from "../../../context/rootContext/RootContext";

type Props = {
  visible: boolean;
  onDismiss?: () => void;
  children: {};
  modalStyle?: {};
};

const CustomModal: React.FC<Props> = ({
  visible,
  onDismiss,
  children,
  modalStyle,
}) => {
  const {
    dimensions: { width },
  } = useRootContext();
  return visible ? (
    <Modal visible={visible} onDismiss={onDismiss} transparent>
      <View style={customModalStyles.centeredView}>
        <Card
          style={[
            customModalStyles.modalView,
            { width: width > 600 ? 530 : "95%" },
            modalStyle,
          ]}
        >
          {children}
        </Card>
      </View>
    </Modal>
  ) : null;
};

export default CustomModal;
