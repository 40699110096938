import { StyleSheet } from "react-native";
import { theme } from "../../theme";
const createAccountStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  body: {
    flex: 1,
    marginTop: 64,
  },
  formContainer: {
    maxWidth: 720,
    width: "100%",
    marginTop: 24,
  },
  inputsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 10,
  },
  inputContainer: {
    maxWidth: 224,
    width: "100%",
  },
  errorContainer: {
    height: 22.5,
  },
  label: {
    fontSize: 16,
    color: "#9797FF",
    textTransform: "uppercase",
  },
  input: { backgroundColor: "#F0FFFF" },
  text: {
    fontSize: 16,
    color: "#5B3396",
    marginLeft: 10,
  },
  link: {
    textDecorationLine: "underline",
  },
  policyContainer: {
    flexDirection: "row",
    marginVertical: 15,
  },
  errorText: {
    marginTop: 24,
    fontSize: 16,
    color: theme.colors.red,
  },
});
export default createAccountStyles;
