import { StyleSheet } from "react-native";
const signUpStyles = StyleSheet.create({
  container: {
    width: "100%",
    alignItems: "center",
  },
  body: {
    maxWidth: 720,
    width: "100%",
    justifyContent: "center",
    marginTop: 82,
    paddingHorizontal: 10,
  },

  formContainer: {
    marginTop: 24,
  },
});
export default signUpStyles;
