import React, { useState } from "react";
import { View } from "react-native";
import Header from "../Dependent/Header";
import { dependentStyles } from "./Dependents.Styles";
import { ScrollView } from "react-native-gesture-handler";
import DependentRowData from "../Dependent/DependentRowData";
import {
  ActionType,
  DependentInfo,
  DependentList,
} from "../../screen/StatusProtector/types/Dependentypes";
import DeleteAccount from "../Dialog/DeleteAccount";
import { Strings } from "../../../assets/strings";
import DependentTagsModal from "../Dependent/DependentTagsModal";

type props = {
  dependentList: DependentList[];
  actionHandler?: (dependentInfo: DependentInfo) => void;
  deleteDependentHandler?: (dId: string) => void;
  isOrg?: boolean;
};

const Dependents: React.FC<props> = ({
  dependentList,
  actionHandler,
  deleteDependentHandler,
  isOrg = false,
}) => {
  const [dialogData, setDialogData] = useState<{
    visible: boolean;
    dId?: string;
    tags?: [];
    type: ActionType;
  }>();
  return (
    <View style={dependentStyles.container}>
      <View style={dependentStyles.tableContainer}>
        <Header isOrg={isOrg} />
        <ScrollView
          style={{ height: 145 }}
          showsVerticalScrollIndicator={false}
        >
          {dependentList.map((data) => {
            return (
              <DependentRowData
                actionHandler={(data, type) => {
                  if (type === ActionType.EDIT) actionHandler(data);
                  if (type === ActionType.DELETE) {
                    setDialogData({ ...data, type: ActionType.DELETE });
                  }
                  if (type === ActionType.VIEW_TAG) {
                    setDialogData({ ...data, type: ActionType.VIEW_TAG });
                  }
                }}
                data={data}
                isOrg={isOrg}
                key={isOrg ? data.uid : data.did}
              />
            );
          })}
        </ScrollView>
      </View>
      <DeleteAccount
        isDialogVisible={
          dialogData?.visible && dialogData?.type === ActionType.DELETE
        }
        setIsDialogVisible={() => {
          setDialogData(null);
        }}
        deleteHandler={() => {
          setDialogData(null);
          deleteDependentHandler(dialogData?.dId);
        }}
        message={Strings.DELETE_DEPENDENT}
      />
      <DependentTagsModal
        visible={
          dialogData?.type === ActionType.VIEW_TAG && dialogData?.visible
        }
        tags={dialogData?.tags}
        onDismiss={() => {
          setDialogData(null);
        }}
      />
    </View>
  );
};

export default React.memo(Dependents);
