import { StyleSheet } from "react-native";
import { theme } from "../../theme";

export const resetPasswordRequestStyles = StyleSheet.create({
    formContainer: { justifyContent: 'center', alignItems: 'center' },
    errorText: {
        color: "red",
        textAlign: "center",
        marginVertical: 5
      },
    label: {
        fontSize: 16,
        color: '#9797FF',
      },
      input: { backgroundColor: '#F0FFFF' },
      errorContainer: {
        height: 22.5
      },
      btnContainer: {
        marginVertical: 15,
        justifyContent: "center",
        alignItems: "center"
      },
      btnStyle: {
        maxWidth: 300,
        height: 35,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor:theme.colors.btn,
        textAlign: 'center',
      },
      text: {
        fontSize: 15,
        color: "green",
        textAlign: "center",
      }
})