import { initialLoginType } from "./../../../context/AuthContext/index";
import { Strings } from "../../../../assets/strings";
import { uploadDependentDocument } from "../../../util/dependent/uploadDependentDoc";
import { uploadDocument } from "../../../util/uploadDocument";
import { documentsApiResponse } from "../types";
import { uploadClientDoc } from "../../../util/organization/uploadClientDoc";
import {
  initialOrganizationType,
  useOrgAuthContext,
} from "../../../context/OrganizationAuthContext";

const UploadDocumentsController = () => {
  const uploadDocumentFile = async (file: File) => {
    const uploadDocumentResponse = await uploadDocument(file);
    if (uploadDocumentResponse?.status === 200) {
      return { hasError: false, data: uploadDocumentResponse.userInfoData };
    } else {
      return { hasError: true, message: Strings.UPLOAD_ERROR };
    }
  };
  const uploadDependentFile = async (
    file: File,
    dId: string,
    userData: initialLoginType
  ) => {
    const uploadDocumentResponse = await uploadDependentDocument(
      dId,
      file,
      userData
    );
    if (uploadDocumentResponse?.status === 200) {
      return { hasError: false, data: uploadDocumentResponse.userInfoData };
    } else {
      return { hasError: true, message: Strings.UPLOAD_ERROR };
    }
  };
  const uploadClientFile = async (
    file: File,
    clientId: string,
    orgId: string,
    token: string
  ) => {
    const uploadDocumentResponse = await uploadClientDoc(
      clientId,
      orgId,
      token,
      file
    );
    if (uploadDocumentResponse?.status === 200) {
      return { hasError: false, data: uploadDocumentResponse.userInfoData };
    } else {
      return { hasError: true, message: Strings.UPLOAD_ERROR };
    }
  };

  const validateFileType = (files: File[]) => {
    var filePath = files[0]?.name;
    // Allowing file type
    var allowedExtensions = /(\.jpg|\.pdf|\.png|\.tiff)$/i;
    if (filePath) {
      if (!allowedExtensions.exec(filePath)) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  const uploadDocumentsFile = async (
    files: File[],
    dId?: string,
    userData?: initialLoginType,
    clientId?: string,
    orgData?: initialOrganizationType
  ) => {
    console.log(dId + " " + clientId);

    const documentsApiResponse = [];
    const requests = [];
    for (let i = 0; i < files.length; i++) {
      requests.push(
        dId
          ? uploadDependentFile(files[i], dId, userData)
          : clientId
          ? uploadClientFile(
              files[i],
              clientId,
              orgData.orgId,
              orgData.orgUserToken
            )
          : uploadDocumentFile(files[i])
      );
    }
    return Promise.all(requests).then((values) => {
      documentsApiResponse.push(...values);
      return documentsApiResponse;
    });
  };

  const getSingleResponse = (responses: documentsApiResponse[]) => {
    const successfulResponses = responses.filter(
      (response) => !response.hasError
    );
    if (!successfulResponses.length) {
      return responses[0];
    } else {
      return successfulResponses[successfulResponses.length - 1];
    }
  };

  return {
    validateFileType,
    uploadDocumentFile,
    uploadDocumentsFile,
    getSingleResponse,
  };
};
export default UploadDocumentsController;
