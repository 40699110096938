import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const organizationInviteScreenStyles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
        alignSelf: "center",
      },
      msgContainer: {
        flex: 1,
        justifyContent: "center",
      },
      getTxt: {
        fontSize: 20,
        color: theme.colors.btn,
        fontWeight: "bold",
        marginTop: -120
      },
      inputsContainer: {
        flexDirection: "row",
        width: "100%",
      },
      inputContainer: {
        flex: 1,
        marginEnd: 5,
      },
      label: {
        fontSize: 16,
        color: "#9797FF",
        textTransform: "uppercase",
      },
    
      input: { backgroundColor: "#F0FFFF" },
      heading: {
        color: theme.colors.btn,
        fontWeight: "bold",
        fontSize: 20,
        marginVertical: 20,
      },
      errorText: {
        color: "red",
        textAlign: "center",
        fontSize: 15,
        marginTop: 15
      },
      successTxt: {
        color: "green",
        textAlign: "center",
        fontSize: 15,
        marginTop: 15
      },
      errorContainer: {
        height: 22.5,
      },
})