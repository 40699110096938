import { autoVisasPrivateApi } from "./axiosInstances/autovisasPrivateApi";

export const register = async (
  email,
  password,
  confirm_password,
) => {
  try {
    const options = {
      method: "POST",
      url: "/users",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email: email,
        password: password,
        confirm_password: confirm_password,
      },
    };
    const registerResponse = await autoVisasPrivateApi.request(options);
    return {
      data: registerResponse.data,
      status: registerResponse.status,
    };
  } catch (error) {
    return {
      data: error.response.data,
      status: error.response.status,
    };
  }
};
