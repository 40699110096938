import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const deleteDocumentModalStyles = StyleSheet.create({
    warningTxt: {
        fontSize: 20,
        color: 'red',
        fontWeight: 'bold',
        textAlign: 'center',
      },
      text: {
        fontSize: 20,
        marginVertical: 10,
        textAlign: "center",
        color: theme.colors.btn,
      },
      fileName: {
        fontSize: 15,
        marginVertical: 5,
        color: theme.colors.btn,
        fontWeight: 'bold',
        textAlign: 'center',
      },
      btnContainer: { flex: 1, flexDirection: 'row', alignItems: 'flex-end' }
})