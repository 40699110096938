/* eslint-disable react-native/no-inline-styles */
import React from "react";
import { ActivityIndicator, View, StyleSheet } from "react-native";
import { theme } from "../../theme";
type Props = {
  loading: boolean;
};
export const ProgressBar: React.FC<Props> = ({ children, loading }) => {
  return (
    <View style={{ flex: 1 }}>
      {children}
      {loading && (
        <ActivityIndicator
          color={theme.colors.btn}
          style={[styles.loadingBar]}
          size="large"
          animating={true}
        />
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  loadingBar: {
    width: "100%",
    height: "100%",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 100,
  },
});
