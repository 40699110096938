import React, { useContext, useState } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../assets/strings";
import { DocumentBody } from "../../../screen/StatusProtector/types/DeshboardTypes";
import UploadDocumentsButton from "../../Buttons/UploadDocument";
import Documents from "../../Documents";
import { dependentDocumentStyles } from "./DependentDocuments.Styles";

type props = {
  documents: DocumentBody[];
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  deleteDocument: (data: DocumentBody) => void;
  dId: string;
  uploadedSuccessFully: () => void;
};

const DependentDocuments: React.FC<props> = ({
  documents,
  isLoading,
  deleteDocument,
  dId,
  setIsLoading,
  uploadedSuccessFully,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  return (
    <>
      <View style={dependentDocumentStyles.documentContainer}>
        <Text style={dependentDocumentStyles.docText}>
          {Strings.YOUR_DOCUMENTS}
        </Text>
        <Documents
          documents={documents}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          deleteDocument={(data: DocumentBody) => {
            setErrorMessage("");
            deleteDocument(data);
          }}
        />
      </View>
      {errorMessage !== "" && (
        <Text style={dependentDocumentStyles.errorText}>{errorMessage}</Text>
      )}
      <UploadDocumentsButton
        dId={dId}
        setMessage={(type: string, message: string) => {
          if (type === Strings.TYPE_MESSAGE_SUCCESSFUL) {
            uploadedSuccessFully();
          } else {
            setErrorMessage(message);
          }
        }}
      />
    </>
  );
};

export default React.memo(DependentDocuments);
