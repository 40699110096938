import React, { useRef } from "react";
import { StyleSheet, Pressable, View, ViewStyle } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Text } from "react-native-paper";
/* Logic */
import { theme } from "../../../theme";

/* Components */
import Loading from "../../Loading";

type Props = {
  buttonContainerStyle?: ViewStyle;
  containerStyle?: ViewStyle;
  buttonStyle?: ViewStyle;
  txtStyle?: {};
  onPress: () => void;
  name: string;
  isDisable?: boolean;
  isLoading?: boolean;
};

const ContinueButton: React.FC<Props> = ({
  buttonContainerStyle,
  containerStyle,
  buttonStyle,
  txtStyle,
  onPress,
  name,
  isLoading = false,
  isDisable = false,
}) => {
  const ref = useRef(null);
  return (
    <View style={[continueButtonStyles.container, containerStyle]}>
      <View
        style={[continueButtonStyles.buttonContainer, buttonContainerStyle]}
      >
        <Pressable
          disabled={isDisable}
          style={[continueButtonStyles.button, buttonStyle]}
          onPress={onPress}
        >
          <LinearGradient
            ref={ref}
            colors={["#9797FF", "#BE8DFA"]}
            locations={[0.2, 0.7]}
            start={{ x: 0.1, y: 0.5 }}
            end={{ x: 0.5, y: 0.5 }}
            style={[
              continueButtonStyles.buttonGradient,
              {
                opacity: isLoading ? 0.6 : 1,
              },
            ]}
          >
            {isLoading ? (
              <Loading size="small" color="#F0FFFF" />
            ) : (
              <Text style={[continueButtonStyles.buttonText, txtStyle]}>
                {name}
              </Text>
            )}
          </LinearGradient>
        </Pressable>
      </View>
    </View>
  );
};

const continueButtonStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "flex-end",
  },
  buttonContainer: {
    minWidth: 230,
  },
  button: {
    height: 47,
  },
  buttonGradient: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
    paddingHorizontal: 10,
  },
  buttonText: {
    fontFamily: "Brother1816Black",
    fontSize: 16,
    color: theme.colors.bgColor,
    textAlign: "center",
    textTransform: "uppercase",
  },
});

export default ContinueButton;
