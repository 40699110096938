import { StyleSheet } from "react-native";
import { theme } from "../../theme";

export const travelHistoryStyles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#F0FFFF",
        minHeight: 204
    },
    tableContainer: {
        flex: 1,
        marginBottom: 20,
      },
    text: {
        color: theme.colors.btn,
        flex: 1,
        textAlign: "center",
        fontSize: 20,
        fontWeight: "bold",
        marginVertical: 30
    }
})