import React, { ReactElement } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "./react-datepicker.css";
import { View } from "react-native";

type Props = {
  selectedDate: any;
  setSelectedDate: (data: any) => void;
  customInput: JSX.Element;
  disable?: boolean;
};
export const DatePickerInput = ({
  selectedDate,
  setSelectedDate,
  disable,
  customInput,
}: Props) => {
  return (
    <DatePicker
      disabled={disable}
      locale={"en"}
      selected={selectedDate}
      onChange={setSelectedDate}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      customInput={<View>{customInput}</View>}
      maxDate={moment().toDate()}
      onFocus={(e) => (e.target.readOnly = true)}
    />
  );
};
