import { Formik } from "formik";
import React, { useState } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../../assets/strings";
import validationSchema from "../../../../data/validationSchema";
import CustomButton from "../../../Buttons/CustomButton";
import ClickableText from "../../../ClickableText";
import FormInput from "../../../FormInput";
import { verifyOtpStyles } from "./VerifyOtp.Styles";

type props = {
  email: string;
  onDismiss: () => void;
  hasError: boolean;
  isLoading: boolean;
  errorMessage: string;
  isSendPasscodeAgain: boolean;
  onChangeEmail: (email: string, passcode: string) => void;
  onResendPasscodeAgain: (email: string, isSendPasscodeAgain: boolean) => void;
};

const VerifyOtp: React.FC<props> = ({
  email,
  onChangeEmail,
  onDismiss,
  isLoading,
  hasError,
  isSendPasscodeAgain,
  errorMessage,
  onResendPasscodeAgain,
}) => {
  return (
    <>
      <Text style={verifyOtpStyles.heading}>
        {Strings.EMAIL_VERIFICATION_TXT}
      </Text>
      <Formik
        initialValues={{
          email: email,
          otp: "",
        }}
        validationSchema={validationSchema.emailVerification}
        onSubmit={(values) => {
          onChangeEmail(values.email, values.otp);
        }}
      >
        {({ values, errors, touched, handleSubmit, handleChange }) => (
          <View style={{ marginTop: 30 }}>
            <View style={{ flexDirection: "row" }}>
              <View style={verifyOtpStyles.formContainer}>
                <FormInput
                  label={Strings.EMAIL}
                  inputPlaceholder={Strings.EMAIL}
                  inputValue={values.email}
                  setInputValue={handleChange("email")}
                  mode="outlined"
                  editable={false}
                  error={touched.email && errors.email}
                  labelStyle={verifyOtpStyles.label}
                  style={verifyOtpStyles.input}
                  activeOutlineColor="#9797FF"
                  outlineColor="#9797FF"
                  dense
                />
                {(touched.email && errors.email) === undefined && (
                  <View style={verifyOtpStyles.errorContainer}></View>
                )}
              </View>
              <View style={verifyOtpStyles.formContainer}>
                <FormInput
                  label={Strings.ENTER_OTP}
                  inputPlaceholder={Strings.ENTER_OTP}
                  inputValue={values.otp}
                  setInputValue={handleChange("otp")}
                  mode="outlined"
                  error={touched.otp && errors.otp}
                  labelStyle={verifyOtpStyles.label}
                  style={verifyOtpStyles.input}
                  activeOutlineColor="#9797FF"
                  outlineColor="#9797FF"
                  dense
                />
                {(touched.otp && errors.otp) === undefined && (
                  <View style={verifyOtpStyles.errorContainer}></View>
                )}
              </View>
            </View>
            <View style={verifyOtpStyles.btnContainer}>
              <CustomButton
                style={{ marginHorizontal: 10 }}
                onPress={onDismiss}
                name={Strings.CANCEL}
              />
              <CustomButton
                style={{ marginHorizontal: 10 }}
                onPress={handleSubmit}
                isLoading={isLoading}
                name={Strings.SUBMIT}
              />
            </View>
            <View style={{ flexDirection: "row" }}>
              {isSendPasscodeAgain && (
                <Text style={verifyOtpStyles.resendOtpText}>
                  {Strings.PASSCODE_SENT}
                </Text>
              )}
              <ClickableText
                containerStyle={verifyOtpStyles.resendOtpStyle}
                style={{ textAlign: "right" }}
                text={Strings.RESEND_PASSCODE}
                onPress={() => {
                  onResendPasscodeAgain(values.email, true);
                }}
              />
            </View>
            {hasError ? (
              <Text style={[verifyOtpStyles.errorText]}>{errorMessage}</Text>
            ) : null}
          </View>
        )}
      </Formik>
    </>
  );
};

export default React.memo(VerifyOtp);
