import { StyleSheet } from "react-native";

export const dependentStyles = StyleSheet.create({
    scrollContainer: {
        flex: 1,
        height: "100%",
        width: "100%",
        marginBottom: "4%"
    },
    container: {
        flex: 1,
        paddingTop: 20,
        alignSelf: "center"
    },
})