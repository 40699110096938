import { Strings } from "../../../../assets/strings"
import { passwordResetRequest, passwordResetRequestForOrganization } from "../../../util/passwordReset"

const ResetPasswordRequestController = () => {
    const raisePasswordResetRequest = async (email: string) => {
        try {
            const response = await passwordResetRequest(email)            
            if (response.status === 200) {
                return {
                    hasError: false,
                    data: response.data
                }
            } else {
                return {
                    hasError: true,
                    message: response.data.message
                }
            }
        } catch(error) {
            return {
                hasError: true,
                message: Strings.SOMETHING_WENT_WRONG
            }
        }
    }

    const raisePasswordResetRequestForOrganization = async (email: string) => {
        try {
            const response = await passwordResetRequestForOrganization(email)            
            if (response.status === 200) {
                return {
                    hasError: false,
                    data: response.data
                }
            } else {
                return {
                    hasError: true,
                    message: response.data.message
                }
            }
        } catch(error) {
            return {
                hasError: true,
                message: Strings.SOMETHING_WENT_WRONG
            }
        }
    }

    return { raisePasswordResetRequest, raisePasswordResetRequestForOrganization }
}

export default ResetPasswordRequestController