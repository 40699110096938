import { autoVisasOrgPublicApi } from "./../../axiosInstances/autoVisasOrgPublicApi";
export const orgVerifyOTP = async (session_id: string, otpCode: string) => {
  try {
    const options = {
      method: "POST",
      url: `/otp/${session_id}/verify?type=organization`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        otp: otpCode,
      },
    };

    const verifyOTPResponse = await autoVisasOrgPublicApi(options);
    return {
      data: verifyOTPResponse.data,
      status: verifyOTPResponse.status,
    };
  } catch (error) {
    return {
      data: error.response.data,
      status: error.response.status,
    };
  }
};
