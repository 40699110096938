import { StyleSheet } from "react-native";

export const clientScreenStyles = StyleSheet.create({
    scrollContainer: {
        flex: 1,
        alignItems: 'center',
        alignSelf: "center",
       
    minWidth: 467

    }
})