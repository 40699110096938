import { Link } from "@react-navigation/native";
import React from "react";
import { Image, View, Text, Pressable } from "react-native";
import { Images } from "../../../assets/images";
import { Screens } from "../../common/Constant";
import { theme } from "../../theme";
import { useRootContext } from "../../context/rootContext/RootContext";
import { headerStyles } from "./Header.Styles";

type props = {
  name?: string;
  isOrganization?: boolean;
  isHome?: boolean;
};

const Header: React.FC<props> = ({ name, isOrganization, isHome }) => {
  const {
    dimensions: { width: width },
  } = useRootContext();

  return (
    <View style={headerStyles.container}>
      <Image
        source={Images.AUTOVISA_LOGO}
        style={[
          headerStyles.logo,
          isOrganization || isHome || width < 423
            ? { flex: 1 }
            : { marginRight: -50 },
        ]}
        resizeMode={"contain"}
      />
      {isHome && (
        <View style={{ alignSelf: "center" }}>
          <Link
            style={{
              backgroundColor: theme.colors.accent,
              paddingVertical: 7,
              paddingHorizontal: 15,
              borderRadius: 5,
            }}
            to={{ screen: Screens.LOGIN }}
          >
            <Text
              style={{
                fontSize: 15,
                fontWeight: "bold",
                lineHeight: 18.15,
                textTransform: "uppercase",
                color: theme.colors.bgColor,
              }}
            >
              Sign in
            </Text>
          </Link>
        </View>
      )}
      {/* {isOrganization ? (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginLeft: 10,
          }}
        >
          <Text style={headerStyles.textStyle}>{name}</Text>
          <Image source={Images.SPHERE} style={headerStyles.imageStyle} />
        </View>
      ) : null} */}
    </View>
  );
};

export default React.memo(Header);
