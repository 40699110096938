import { AuthProvider } from "../../context/AuthContext";
import { OrgAuthProvider } from "../../context/OrganizationAuthContext";
import RootNavigator from "../../navigation/RootNavigator";

const AppUI = () => {
  return (
    <OrgAuthProvider>
      <AuthProvider>
        <RootNavigator />
      </AuthProvider>
    </OrgAuthProvider>
  );
};

export default AppUI;
