import React, { useEffect, useState } from "react";
import { Image, View } from "react-native";
import { stripePaymentStyles } from "./StripePayment.Styles";
import { PlanType, SubscriptionPlan } from "./types";
import { Images } from "../../../assets/images";
import PlanHeader from "../../components/StripePayment/PlanHeader";
import PlanDescription from "../../components/StripePayment/PlanDescription";
import StripePaymentController from "./controller/StripePaymentController";
import { ProgressBar } from "../../components/progressBar/ProgressBar";
import { useStoreContext } from "../../context/storeContext/StoreProvider";
import { Text } from "react-native-paper";
import { Strings } from "../../../assets/strings";

type props = {};

const StripePayment: React.FC<props> = () => {
  const [activeBtn, setActiveBtn] = useState<string>();
  const [payStart, setPayStart] = useState(false);
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>(plans[0]);

  const { getPlans, getCheckoutLink } = StripePaymentController();
  const { profileData } = useStoreContext();

  const getActivePlans = async () => {
    setLoading(true);
    await getPlans().then((res) => {
      if (!res.hasError) {
        setPlans(res.data.plans);
        setHasError(false);
        setActiveBtn(PlanType.MONTHLY);
      } else {
        setHasError(true);
        setErrorMessage(res.data);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getActivePlans();
  }, []);
  useEffect(() => {
    plans
      .sort((a, b) => a.unit_amount - b.unit_amount)
      .map((plan: SubscriptionPlan) => {
        plan.name === activeBtn && setSelectedPlan(plan);
      });
  }, [activeBtn]);

  const onSubscribe = async () => {
    setPayStart(true);
    await getCheckoutLink(
      profileData.uid,
      selectedPlan.product_id,
      selectedPlan.price_id
    ).then((res) => {
      if (!res.hasError) {
        window.open(res.data.url, "_self");
      }
      setPayStart(false);
    });
  };

  return (
    <ProgressBar loading={loading}>
      {!loading && (
        <View style={stripePaymentStyles.container}>
          <Image
            style={stripePaymentStyles.imageStyle}
            source={Images.MAIN_LOGO}
            resizeMode="contain"
          />
          {plans.length ? (
            <View style={stripePaymentStyles.stripePlanContainer}>
              <View style={stripePaymentStyles.buttonOuterContainer}>
                <PlanHeader
                  activeBtn={activeBtn}
                  setActiveBtn={setActiveBtn}
                  plans={plans}
                />
              </View>
              <PlanDescription
                isLoading={payStart}
                onSubscribe={onSubscribe}
                selectedPlan={selectedPlan}
              />
            </View>
          ) : !hasError ? (
            <Text style={stripePaymentStyles.noPlanText}>
              {Strings.NO_ACTIVE_PLANS}
            </Text>
          ) : (
            <Text style={[stripePaymentStyles.noPlanText, { color: "red" }]}>
              {errorMessage}
            </Text>
          )}
        </View>
      )}
    </ProgressBar>
  );
};

export default React.memo(StripePayment);
