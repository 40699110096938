import { login } from "../../../util/login";
import { register } from "../../../util/register";
import { sendOTP } from "../../../util/2FactorAuth/sendOTP";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { addToWaitList, slotAvailability } from "../../../util/slotAvailability";
import { checkEmailAvailability } from "../../../util/checkEmailAvailability";
import { Strings } from "../../../../assets/strings";

const CreateAccountFormController = () => {
  const messageServer = "Opps something went wrong, please try again";
  const { authSignInCompleted } = useContext(AuthContext);

  const emailAvailability = async (email: string) => {
    const isSlotAvailable = await slotAvailability();

    if (!isSlotAvailable) {
      const addedToWaitList = await addToWaitList(email);
      if (addedToWaitList.status === 200) {
        return true;
      } 
    }

    return false;
  };

  const checkEmailAvail = async (email: string) => {
    try {
      const response = await checkEmailAvailability(email);
      if (response.status === 200) {
        return {
          hasError: false,
          data: response.data,
        }
      } else {
        return {
          hasError: true,
          data: Strings.SOMETHING_WENT_WRONG
        }
      }
    } catch (error) {
      return {
        hasError: true,
        data: Strings.SOMETHING_WENT_WRONG
      }
    }
  };

  const handleRegister = async (
    email,
    password,
    confirm_password,
  ) => {
    const registerResponse = await register(
      email,
      password,
      confirm_password,
    );

    if (registerResponse.status === 200) {
      return { hasError: false, message: "" };
    } else if (registerResponse.status === 400) {
      const errorMsg = registerResponse.data.message
        ? registerResponse.data.message
        : messageServer;
      return { hasError: true, message: errorMsg };
    }
  };
  const handleLogin = async (email: string, password: string) => {
    const loginResponse = await login(email, password);
    if (loginResponse.status === 200) {
      const sendOTPResponse = await sendOTP(loginResponse.data.session_id);
      if (sendOTPResponse.status === 200) {
        await authSignInCompleted(email, loginResponse.data.session_id, Strings.USER_TYPE.INDIVIDUAL);
        return { hasError: false, message: "" };
      } else {
        return { hasError: true, message: messageServer };
      }
    } else {
      return { hasError: true, message: messageServer };
    }
  };
  return { handleRegister, handleLogin, emailAvailability, checkEmailAvail };
};
export default CreateAccountFormController;
