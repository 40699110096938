import { Strings } from "../../../../../assets/strings"
import { changePassword, editSelfProfile, getOwnerProfile, requestEmailChange, verifyEmailChange } from "../../../../util/organization/profile"

const ProfileEditScreenController = () => {
    const getUserProfile = async (token: string) => {
      try {
        const response = await getOwnerProfile(token)
        if (response.status === 200) {
          return {
            hasError: false,
            data: response.data
          }
        } else {
          return {
            hasError: true,
            message: response.data.message
          }
        }
      } catch (error) {
        return {
          hasError: true,
          message: Strings.SOMETHING_WENT_WRONG
        }  
      }
    }

    const updateProfile = async (token: string, firstName: string, lastName: string) => {
      try {
        const response = await editSelfProfile(token, firstName, lastName)
        if (response.status === 200) {
          return {
            hasError: false,
            data: response.data
          }
        } else {
          return {
            hasError: true,
            message: response.data.message
          }
        }
      } catch (error) {
        return {
          hasError: true,
          message: Strings.SOMETHING_WENT_WRONG
        }  
      }
  }

  const changePasscode = async (token: string, currentPass: string, newPass: string) => {
    try {
      const response = await changePassword(token, currentPass, newPass)
      if (response.status === 200) {
        return {
          hasError: false,
          data: response.data
        }
      } else {
        return {
          hasError: true,
          message: response.data.message
        }
      }
    } catch (error) {
      return {
        hasError: true,
        message: Strings.SOMETHING_WENT_WRONG
      }  
    }
}

    const raiseEmailChangeRequest = async (token: string, email: string) => {
        try {
          const response = await requestEmailChange(token, email)
          if (response.status === 200) {
            return {
              hasError: false,
              data: response.data
            }
          } else {
            return {
              hasError: true,
              message: response.data.message
            }
          }
        } catch (error) {
          return {
            hasError: true,
            message: Strings.SOMETHING_WENT_WRONG
          }  
        }
    }
    
      const verifyEmailChangePasscode = async (token: string, email: string, passcode: string) => {
        try {
          const response = await verifyEmailChange(token, email, passcode)
          if (response.status === 200) {
            return {
              hasError: false,
              data: response.data
            }
          } else {
            return {
              hasError: true,
              message: response.data.message
            }
          }
        } catch (error) {
          return {
            hasError: true,
            message: Strings.SOMETHING_WENT_WRONG
          }  
        }
    }

    return { raiseEmailChangeRequest, verifyEmailChangePasscode, updateProfile, getUserProfile, changePasscode }
}

export default ProfileEditScreenController