/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { createContext, useContext } from "react";

export const RootContext = createContext<{
  dimensions: {
    height: number;
    width: number;
  };
}>({
  dimensions: {
    height: 0,
    width: 0,
  },
});

export const RootContextProvider: React.FC<{
  dimension: { width: number; height: number };
}> = ({ children, dimension }) => {
  return (
    <RootContext.Provider
      value={{
        dimensions: dimension,
      }}
    >
      {children}
    </RootContext.Provider>
  );
};

export const useRootContext = () => useContext(RootContext);
