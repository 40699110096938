import React, { useRef, useState } from "react";
import { View, Text, Animated, Pressable } from "react-native";
import { Strings } from "../../../../../assets/strings";
import { theme } from "../../../../theme";
import ContinueButton from "../../../Buttons/Continue";
import ProgressBarTimer from "../../../progessBarTimerButton/ProgressBarTimer";
import { TableMappingDropDown } from "./TableMappingDropDown";
import Ionicons from "@expo/vector-icons/Ionicons";
import ErrorText from "../../../ErrorText";
import { csvColumnMappingType } from "../../../../screen/Organization/types";
import { uploadCSV } from "../../../../util/organization/uploadCSV";
import { useOrgStoreContext } from "../../../../context/orgStoreContext/StoreProvider";
import { useOrgAuthContext } from "../../../../context/OrganizationAuthContext";

type props = {
  CSVFile: File;
  CSVData: string[];
  onDismiss: () => void;
  onUpload: () => void;
};
export const TableMappingFormat: React.FC<props> = ({
  CSVFile,
  CSVData,
  onDismiss,
  onUpload,
}) => {
  const rowData = {
    first_name: "",
    last_name: "",
    email: "",
    passport_number: "",
    date_of_birth: "",
    citizenship: "",
    employer: "",
  };
  const [data, setData] = useState<csvColumnMappingType>(rowData);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const progressBarValue = useRef(new Animated.Value(0)).current;
  const { orgAuthState } = useOrgAuthContext();

  const uploadDocumentHandler = async (file: File) => {
    setMessage("");

    let isNotEmpty = true
    for(const key in Object.keys(data)) {
      if(key == "employer") {
        continue
      } else if(data[key]=="") {
        isNotEmpty = false
      }
    }

    if (isNotEmpty) {
      const val = Object.values(data).some(
        (obj, index) => Object.values(data).indexOf(obj) !== index
      );
      if (!val) {
        setIsLoading(true);
        const res = await uploadCSV(
          orgAuthState.orgUserToken,
          orgAuthState.orgId,
          CSVFile,
          data
        );
        progressBarValue.setValue(1);
        setTimeout(async () => {
          setIsLoading(false);
          !res.hasError ? onUpload() : setMessage(res.message);
        }, 500);
      } else {
        setIsLoading(false);
        setMessage(Strings.SELECT_UNIQUE_COLUMN_NAME);
      }
    } else {
      setIsLoading(false);
      setMessage(Strings.SELECT_ALL_COLUMN);
    }
  };
  return (
    <View>
      <Pressable
        style={{ alignItems: "flex-end", marginTop: -15 }}
        onPress={onDismiss}
      >
        <Ionicons name="close-circle" size={35} color={theme.colors.btn} />
      </Pressable>
      <Text
        style={{
          ...theme.fonts.medium,
          fontSize: 20,
          flex: 1,
          marginVertical: 10,
        }}
      >
        {Strings.MAP_CSV_COLUMN}
      </Text>
      {Object.keys(data).map((item, index) => (
        <TableMappingDropDown
          item={item}
          key={index}
          data={CSVData}
          zIndex={Object.keys(data).length - index}
          setData={(key: string, value: string) => {
            setData({ ...data, [key]: value });
          }}
        />
      ))}
      <ErrorText txtStyle={{ marginVertical: 10 }} error={message}></ErrorText>
      <View style={{ alignItems: "flex-end", marginTop: 20 }}>
        <ProgressBarTimer
          showProgressBar={isLoading}
          handleFunction={() => uploadDocumentHandler(CSVFile)}
          progressBarValue={progressBarValue}
        >
          <ContinueButton
            name={Strings.CONTINUE}
            onPress={() => setIsLoading(true)}
          />
        </ProgressBarTimer>
      </View>
    </View>
  );
};
