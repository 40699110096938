import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const profilePageHeaderStyles = StyleSheet.create({
    headerContainer: {flexDirection: "row"},
    headerTxt: {
        flex: 1,
        color: theme.colors.btn,
        fontSize: 30,
        fontWeight: "bold"
    },
    editBtnStyle: {
        justifyContent: "center",
        alignItems: "center",
        borderWidth: 3,
        borderColor: theme.colors.bottom2,
        height: 40,
        width: 150,
        marginEnd: 20,
        borderRadius: 6
    }
})