import { forwardRef, useImperativeHandle, useRef } from "react";
import Dropzone from "react-dropzone";
import { Platform } from "react-native";
import "./_cursor.scss";

const DropView = (props, ref) => {
  const { children, onDrop } = props;
  const dropView: React.MutableRefObject<any> = useRef();

  useImperativeHandle(ref, () => ({
    open: () => {
      dropView.current.open();
    },
  }));

  return (
    <Dropzone
      ref={dropView}
      onDrop={onDrop}
      {...props}
      onDragOver={(e) => {
        e.dataTransfer.dropEffect = "copy";
      }}
    >
      {({ getRootProps, getInputProps, open }) => (
        <div onClick={open} {...getRootProps({ className: "dropzone" })}>
          <div>
            <input {...getInputProps()} />
            {children}
          </div>
        </div>
      )}
    </Dropzone>
  );
};

const DropViewWeb = forwardRef(DropView);

const DropViewNative = (props, ref) => {
  const { children } = props;
  return <>{children}</>;
};
const Component = Platform.select({
  native: () => DropViewNative,
  web: () => DropViewWeb,
})();

export default Component;
