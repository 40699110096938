import React from "react";
import { AsyncStorageKeys } from "../common/Constant";
import { multiGet } from "../common/AsyncStorage";
import { OrganizationAction, OrganizationActionType } from "../context/OrganizationAuthContext";
import { UserType } from "../context/AuthContext";

const {
    ORG_SESSION_TOKEN,
    ORG_SIGN_IN_COMPLETED,
    ORG_USER_EMAIL,
    ORG_USER_ID,
    ORG_ID,
} = AsyncStorageKeys;

export const getOrganizationToken = async (userType: UserType, orgDispatch: React.Dispatch<OrganizationAction>) => {
    const userTokenInStorage = await multiGet([
        ORG_SESSION_TOKEN,
        ORG_SIGN_IN_COMPLETED,
        ORG_USER_EMAIL,
        ORG_USER_ID,
        ORG_ID,
    ]);
    const orgUserToken = userTokenInStorage[0][1]
      ? userTokenInStorage[0][1]
      : null;
    const isOrgSignInCompleted = userTokenInStorage[1][1]
      ? Boolean(userTokenInStorage[1][1])
      : false;
    const orgUserEmail = userTokenInStorage[2][1]
      ? userTokenInStorage[2][1]
      : null;
    const orgUserId = userTokenInStorage[3][1]
      ? userTokenInStorage[3][1]
      : null;
    const orgId = userTokenInStorage[4][1] ? userTokenInStorage[4][1] : null;

    orgDispatch({
      type: OrganizationActionType.OrgRestoreData,
      payload: {
        orgUserEmail,
        orgUserToken,
        isOrgSignInCompleted,
        orgUserId,
        orgId,
        userType,
      },
    });
};