import React from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { Strings } from '../../../../assets/strings';
import { travelHistoryHeaderStyles } from './TravelHistoryHeader.Styles';

type props = {};

const TravelHistoryHeader: React.FC<props> = () => {
  return (
    <View style={travelHistoryHeaderStyles.container}>
      <Text style={travelHistoryHeaderStyles.textStyle}>{Strings.DATE}</Text>
      <Text
        style={[travelHistoryHeaderStyles.textStyle, { textAlign: 'center' }]}
      >
        {Strings.TYPE}
      </Text>
      <Text
        style={[travelHistoryHeaderStyles.textStyle, { textAlign: 'center' }]}
      >
        {Strings.LOCATION}
      </Text>
    </View>
  );
};

export default React.memo(TravelHistoryHeader);
