import React from "react";
import { Image, View } from "react-native";
import { Images } from "../../../assets/images";
import { paymentSuccessStyles } from "./PaymentSuccess.Styles";
import { replace } from "../../navigation/RouteNavigation";
import { Screens } from "../../common/Constant";
import { useStoreContext } from "../../context/storeContext/StoreProvider";
import Animation from "react-native-web-lottie";
import { Lottie } from "../../../assets/lottie";
import { useRoute } from "@react-navigation/native";
import { useOrgStoreContext } from "../../context/orgStoreContext/StoreProvider";
type props = {};
const PaymentSuccess: React.FC<props> = () => {
  const { setProfileData, profileData } = useStoreContext();
  const { setOrganizationData, organizationData } = useOrgStoreContext();
  const { params } = useRoute();
  const redirectToDashboard = () => {
    const isOrganization = params && params?.type === "organization";
    if (isOrganization) {
      setOrganizationData({
        ...organizationData,
        stripe_setup_completed: true,
      });
      replace(Screens.ADMIN_SCREEN, null);
    } else {
      setProfileData({
        ...profileData,
        stripe_setup_completed: true,
      });
      replace(Screens.DRAWER, null);
    }
  };
  return (
    <View style={paymentSuccessStyles.container}>
      <Image
        source={Images.MAIN_LOGO}
        style={paymentSuccessStyles.img}
        resizeMode="contain"
      />
      <Animation
        style={{ height: 300 }}
        source={Lottie.PAYMENT_SUCCESS}
        autoPlay
        onAnimationFinish={redirectToDashboard}
      />
    </View>
  );
};

export default React.memo(PaymentSuccess);
