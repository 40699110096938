import { StyleSheet } from "react-native";
import { theme } from "../../../theme";
const deleteModalStyles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#70707070",
  },
  closeContainer: {
    height: 50,
    width: 230,
    backgroundColor: theme.colors.btn,
    borderRadius: 20,
  },
  closeBtn: {
    marginLeft: 180,
    alignItems: "center",
    height: 50,
    justifyContent: "center",
  },
  shadow2: {
    height: 55,
    width: 240,
    marginLeft: 50,
    backgroundColor: theme.colors.btnshadow,
    borderRadius: 20,
  },
  shadow1: {
    borderTopEndRadius: 30,
    width: 250,
    height: 181,
    backgroundColor: theme.colors.btnshadow,
    borderRadius: 30,
    borderBottomLeftRadius: 50,
  },
  modalView: {
    width: 240,
    height: 196,
    position: "absolute",
    borderRadius: 30,
    borderWidth: 5,
    borderColor: theme.colors.btn,
    backgroundColor: theme.colors.modalColor,
  },
  title1: {
    color: theme.colors.btn,
    fontSize: 16,
    marginTop: 10,
    textAlign: "center",
    flexWrap: "wrap",
  },
  imageStyle: {
    alignSelf: "center",
    marginTop: 10,
  },
  btnContainer: {
    flex: 1,
    flexDirection: "row",
    marginTop: 20,
    alignItems: "center"
  },
  cancelBtn: {
    flex: 1,
    marginHorizontal: 3,
    height: 27,
  },
  deleteMsg: {
    color: theme.colors.btn,
    fontSize: 16,
    textAlign: "center",
    marginTop: "30%",
  },
});
export default deleteModalStyles;
