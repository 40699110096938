import React, { useState } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../../assets/strings";
import IconButton from "../../../Buttons/IconButton";
import { headerStyles } from "./Header.Styles";

type props = {
  onSearchClicked: () => void;
  onAddUserClicked: () => void;
  isBtnEnabled: boolean;
  onCSVExport: () => void;
};

const Header: React.FC<props> = ({
  onSearchClicked,
  onAddUserClicked,
  isBtnEnabled,
  onCSVExport,
}) => {
  return (
    <View style={headerStyles.container}>
      <Text style={headerStyles.headingStyle}>
        {Strings.CLIENTS.toUpperCase()}
      </Text>
      {isBtnEnabled && (
        <IconButton name = "Filter" iconName="filter" onPress={onSearchClicked}></IconButton>
      )}
      {isBtnEnabled && (
        <IconButton
          name = "Export to CSV"
          iconName="export"
          style={{ marginStart: 10 }}
          onPress={onCSVExport}
        ></IconButton>
      )}
      <IconButton
        name = "Add New User"
        iconName="adduser"
        style={{ marginStart: 10 }}
        onPress={onAddUserClicked}
      ></IconButton>
    </View>
  );
};

export default React.memo(Header);
