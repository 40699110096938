import { getError, getSuccess } from "../axiosInstances/handleResponse";
import {OrgApis } from "../axiosInstances/Apis";
import { ClientUpdateRequestBody } from "../../screen/Organization/types";
import { autoVisasOrgPrivateApi } from "../axiosInstances/autoVisasOrgPrivateApi";

export const getClientsList = async (organizationId: string, token: string) => {
    try {
        const options = {
          method: "GET",
          url: `${OrgApis.ORGANIZATION}/${organizationId}${OrgApis.CLIENTS}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        };
        const apiResponse = await autoVisasOrgPrivateApi.request(options);
        return getSuccess(apiResponse); 

      } catch (error) {        
        return getError(error);
      }
};

export const checkEmailAvailabilityForClient = async (organizationId: string, token: string, email: string) => {
    try {
        const options = {
          method: "POST",
          url: `${OrgApis.ORGANIZATION}/${organizationId}${OrgApis.CLIENTS}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: {
            email: email
          }
        };
        const apiResponse = await autoVisasOrgPrivateApi.request(options);
        return getSuccess(apiResponse); 

      } catch (error) {        
        return getError(error);
      }
};

export const updateClient = async (organizationId: string, token: string, clientId: string,  body: ClientUpdateRequestBody) => {
        try {
            const options = {
              method: "PUT",
              url: `${OrgApis.ORGANIZATION}/${organizationId}${OrgApis.CLIENTS}/${clientId}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              data: {
                ...body
              }
            };
            const apiResponse = await autoVisasOrgPrivateApi.request(options);
            return getSuccess(apiResponse); 
    
          } catch (error) {        
            return getError(error);
          }
};

export const deleteClient = async (organizationId: string, token: string, clientId: string) => {
  try {
      const options = {
        method: "DELETE",
        url: `${OrgApis.ORGANIZATION}/${organizationId}${OrgApis.CLIENTS}/${clientId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      };
      const apiResponse = await autoVisasOrgPrivateApi.request(options);
      return getSuccess(apiResponse); 

    } catch (error) {        
      return getError(error);
    }
};

export const getClientProfile = async (organizationId: string, token: string, clientId: string) => {
    try {
        const options = {
            method: "GET",
            url: `${OrgApis.ORGANIZATION}/${organizationId}${OrgApis.CLIENTS}/${clientId}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        };
        const apiResponse = await autoVisasOrgPrivateApi.request(options);
        return getSuccess(apiResponse); 
        
    } catch (error) {        
        return getError(error);
    }
};

export const getClientDocuments = async (organizationId: string, token: string, clientId: string) => {
  try {
      const options = {
          method: "GET",
          url: `${OrgApis.ORGANIZATION}/${organizationId}${OrgApis.CLIENTS}/${clientId}${OrgApis.GET_DOCUMENTS}`,
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
          }
      };
      const apiResponse = await autoVisasOrgPrivateApi.request(options);
      return getSuccess(apiResponse); 
      
  } catch (error) {        
      return getError(error);
  }
};

export const deleteClientDocument = async (organizationId: string, token: string, clientId: string, docId: string) => {
  try {
      const options = {
          method: "DELETE",
          url: `${OrgApis.ORGANIZATION}/${organizationId}${OrgApis.CLIENTS}/${clientId}${OrgApis.GET_DOCUMENTS}/${docId}`,
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
          }
      };
      const apiResponse = await autoVisasOrgPrivateApi.request(options);
      return getSuccess(apiResponse); 
      
  } catch (error) {        
      return getError(error);
  }
}; 

export const downloadClientDocument = async (organizationId: string, token: string, clientId: string, docId: string) => {
  try {
    const getUserInfoResponse = await autoVisasOrgPrivateApi.get(
      `${OrgApis.ORGANIZATION}/${organizationId}${OrgApis.CLIENTS}/${clientId}${OrgApis.GET_DOCUMENTS}/${docId}/download`,
      { responseType: "blob" }
    );
    if (getUserInfoResponse.status === 200) {
      const url = URL.createObjectURL(getUserInfoResponse.data);
      const fileName =
        getUserInfoResponse.headers["content-disposition"]?.split(
          "filename="
        )?.[1];
      return { url, fileName: JSON.parse(fileName) };
    }
  } catch (error) {
    console.error(error);
  }
}; 

