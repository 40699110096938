import { Apis } from "./axiosInstances/Apis";
import { autoVisasPrivateApi } from "./axiosInstances/autovisasPrivateApi";
import { getSuccess, getError } from "./axiosInstances/handleResponse";

export const getNonImmigrantStatus = async () => {
    try {
        const options = {
          method: "GET",
          url: Apis.NON_IMMIGRANT_STATUS,
          headers: {
            "Content-Type": "application/json",
          },
        };
    
        const nonImmigrantStatusResponse = await autoVisasPrivateApi.request(options);
        
        return getSuccess(nonImmigrantStatusResponse);
    } catch (error) {
      console.error(error.response);
      return getError(error);
    }
}