import React from "react";
import { ScrollView, View } from "react-native";
import BgImageContainer from "../../components/BgImageContainer";
import RegisterStep from "../../components/RegisterStep";
import signUpStyles from "./SignUp.Styles";
type Props = {
  pageNumber: number;
  onlyLogoutOptionEnabled?: boolean;
};
const SignUpHOC: React.FC<Props> = ({
  pageNumber,
  children,
  onlyLogoutOptionEnabled,
}) => {
  return (
    <BgImageContainer onlyLogoutOptionEnabled={onlyLogoutOptionEnabled}>
      <ScrollView contentContainerStyle={signUpStyles.container}>
        <View style={[signUpStyles.body]}>
          <RegisterStep pageNumber={pageNumber} />
          <View style={signUpStyles.formContainer}>{children}</View>
        </View>
      </ScrollView>
    </BgImageContainer>
  );
};

export default SignUpHOC;
