import React from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../../assets/strings";
import { theme } from "../../../../theme";
import DocumentUploadButton from "../../../Buttons/DocumentUploadButton";

type props = {
  onUploadDoc: () => void;
};

const ClientDocumentsHeader: React.FC<props> = ({ onUploadDoc }) => {
  return (
    <View style={{ flexDirection: "row" }}>
      <Text
        style={{
          fontSize: 30,
          flex: 1,
          color: theme.colors.btn,
          fontFamily: "Brother1816Black",
        }}
      >
        {Strings.YOUR_DOCUMENTS.toUpperCase()}
      </Text>
      <DocumentUploadButton style={{ marginEnd: 5 }} onPress={onUploadDoc} />
    </View>
  );
};

export default React.memo(ClientDocumentsHeader);
