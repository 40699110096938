import { Apis } from "./axiosInstances/Apis";
import { autoVisasPublicApi } from "./axiosInstances/autovisasPublicApi";
import { getError, getSuccess } from "./axiosInstances/handleResponse";

export const checkEmailAvailability = async (email: string) => {
  try {
    const options = {
      method: "POST",
      url: `${Apis.USERS}${Apis.EMAIL_AVAILABLE}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email,
      },
    };
    const updateUserInfoResponse = await autoVisasPublicApi.request(options);
    return getSuccess(updateUserInfoResponse);
  } catch (error) {
    console.error(error);
    return getError(error);
  }
};
