import { CommonActions, NavigationContainerRef, StackActions } from "@react-navigation/native";
import * as React from "react";
import { RootNavigatorParamList } from "./types";
export const navigationRef: any = React.createRef<NavigationContainerRef<RootNavigatorParamList>>();

export const navigate = (name: string, params: object) => {
  navigationRef.current?.dispatch(StackActions.push(name, params));
};
export const replace = (name: string, params: object) => {
  navigationRef.current?.dispatch(StackActions.replace(name, params));
};

export const popBack = () => {
  navigationRef.current?.dispatch(StackActions.pop(1))
}

export const reset = (name: string, params: object) => {
  navigationRef.current?.dispatch((state) => {
    return CommonActions.reset({
      ...state,
      routes: [
        {
          name,
        },
      ],
      index: 0,
    });
  });
};
