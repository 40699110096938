import React from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import Animation from "react-native-web-lottie";
import { Lottie } from "../../../../../assets/lottie";
import { Strings } from "../../../../../assets/strings";
import ClickableText from "../../../ClickableText";
import { confirmationContentStyles } from "./ConfirmationContent.Styles";

type props = {
  onClientPageClicked: () => void;
};

const ConfirmationContent: React.FC<props> = ({ onClientPageClicked }) => {
  return (
    <View style={confirmationContentStyles.container}>
      <Animation
        style={{ height: 200 }}
        source={Lottie.PAYMENT_SUCCESS}
        autoPlay
      />
      <Text style={confirmationContentStyles.txt}>
        {Strings.CLIENT_ADDED_MSG}
      </Text>
      <ClickableText
        containerStyle={{ marginTop: 20 }}
        text={Strings.GO_TO_CLIENT_PAGE}
        onPress={onClientPageClicked}
      />
    </View>
  );
};

export default React.memo(ConfirmationContent);
