import { AsyncStorageKeys } from "../common/Constant";
import { getStorageValue } from "./../common/AsyncStorage";
import { Apis } from "./axiosInstances/Apis";
import { autoVisasPrivateApi } from "./axiosInstances/autovisasPrivateApi";
import { getError, getSuccess } from "./axiosInstances/handleResponse";

export const requestEmailChange = async (uid: string, email: string) => {
  try {
    const userTokenInStorage = await getStorageValue(
      AsyncStorageKeys.USER_SESSION_TOKEN
    );
    const options = {
      method: "POST",
      url: `${Apis.USERS}/${uid}/email-change-request`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userTokenInStorage}`,
      },
      data: {
        email: email
      },
    };
    const response = await autoVisasPrivateApi.request(options);
    return getSuccess(response)
  } catch (error) {
    return getError(error);
  }
};

export const verifyEmailChange = async (uid: string, email: string, passcode: string) => {
    try {
      const userTokenInStorage = await getStorageValue(
        AsyncStorageKeys.USER_SESSION_TOKEN
      );
      const options = {
        method: "POST",
        url: `${Apis.USERS}/${uid}/email-change-verify`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userTokenInStorage}`,
        },
        data: {
          email: email,
          passcode: passcode
        },
      };
      const response = await autoVisasPrivateApi.request(options);
      return getSuccess(response)
    } catch (error) {
      return getError(error);
    }
  };
