import { StyleSheet } from "react-native";

export const stripeButtonStyles = StyleSheet.create({
    activeButtonStyle: {
        paddingVertical: 10,
        paddingHorizontal: 25,
        borderRadius: 5,
        backgroundColor: '#4169E1',
      },
    inActiveButtonStyle: {
        paddingVertical: 10,
        paddingHorizontal: 25,
        borderRadius: 5,
      },
    activeTextStyle: {
        color: "white",
        fontSize: 12
    },
    inActiveTextStyle: {
        color: "grey",
        fontSize: 12  
    },
})