import React from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../assets/strings";
import CustomButton from "../../Buttons/CustomButton";
import CustomModal from "../CustomModal";
import { pauseAccountModalStyles } from "./PauseAccountModal.Styles";

type props = {
  visible: boolean;
  onDismiss: () => void;
  onPause: () => void;
  isLoading: boolean;
};

const PauseAccountModal: React.FC<props> = ({
  visible,
  onDismiss,
  onPause,
  isLoading,
}) => {
  return (
    <CustomModal visible={visible} onDismiss={onDismiss}>
      <Text style={pauseAccountModalStyles.heading}>{Strings.WARNING}</Text>
      <Text style={pauseAccountModalStyles.contentStyle}>
        {Strings.CONFIRM_PAUSE_ACCOUNT}
      </Text>
      <View style={pauseAccountModalStyles.btnConatiner}>
        <CustomButton
          btnStyle={{ margin: 10 }}
          onPress={onDismiss}
          name={Strings.CANCEL}
        />
        <CustomButton
          btnStyle={{ margin: 10 }}
          onPress={onPause}
          isLoading={isLoading}
          name={Strings.Profile.PAUSE}
        />
      </View>
    </CustomModal>
  );
};

export default React.memo(PauseAccountModal);
