import { StyleSheet } from "react-native";

export const IconButtonStyles = StyleSheet.create({
  buttonGradient: {
    paddingVertical: 10,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
    minWidth: 65,
    minHeight: 40,
  },
});
