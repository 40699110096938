import { getError, getSuccess } from "./axiosInstances/handleResponse";
import { Apis, OrgApis } from "./axiosInstances/Apis";
import { autoVisasPublicApi } from "./axiosInstances/autovisasPublicApi";
import { autoVisasOrgPublicApi } from "./axiosInstances/autoVisasOrgPublicApi";

export const passwordResetRequest = async (email: string) => {
  try {
    const options = {
      method: "POST",
      url: `${Apis.USERS}/password-reset-request`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email: email
      },
    };

    const apiResponse = await autoVisasPublicApi.request(options);
    return getSuccess(apiResponse);
  } catch (error) {
    console.error(error.response);
    return getError(error);
  }
};

export const passwordResetConfirm = async (token: string, password: string, confirm_password: string) => {
    try {
        const options = {
          method: "POST",
          url: `${Apis.USERS}/password-reset-confirm`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          data: {
            password: password,
            confirm_password: confirm_password
          },
    };
    
        const apiResponse = await autoVisasPublicApi.request(options);
        return getSuccess(apiResponse);
    } catch (error) {
        console.error(error.response);
        return getError(error);
    }
}

export const passwordResetRequestForOrganization = async (email: string) => {
  try {
    const options = {
      method: "POST",
      url: `${OrgApis.ORGANIZATION}/password-reset-request`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email: email
      },
    };

    const apiResponse = await autoVisasOrgPublicApi.request(options);
    return getSuccess(apiResponse);
  } catch (error) {
    console.error(error.response);
    return getError(error);
  }
};

export const passwordResetConfirmForOrg = async (token: string, password: string, confirm_password: string) => {
  try {
      const options = {
        method: "POST",
        url: `${OrgApis.ORGANIZATION}/password-reset-confirm`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        data: {
          password: password,
          confirm_password: confirm_password
        },
  };
  
      const apiResponse = await autoVisasOrgPublicApi.request(options);
      return getSuccess(apiResponse);
  } catch (error) {
      console.error(error.response);
      return getError(error);
  }
}
