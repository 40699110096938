import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const editClientScreenStyles = StyleSheet.create({
  innerContainer: {
    marginTop: 20,
    width: 500,
    flex: 1,
    alignSelf: "center",  
  },
  heading: {
    fontSize: 30,
    color: theme.colors.btn,
    fontWeight: "bold",
  },
    formContainer: {flex: 1, marginRight: 5 },
    label: {
      fontSize: 16,
      color: '#9797FF',
    },
    input: { backgroundColor: '#F0FFFF' },
    errorContainer: {
      height: 22.5
    },
    errorText: {
      color: "red",
      textAlign: "center",
      marginStart: "8%",
      marginVertical: 5
    },
    btnContainer: {
        marginVertical: 30,
        paddingHorizontal: "10%"
      },
})