import { Strings } from "../../../assets/strings";
import { csvColumnMappingType } from "../../screen/Organization/types";
import { autoVisasOrgPrivateApi } from "./../axiosInstances/autoVisasOrgPrivateApi";
export const uploadCSV = async (
  token,
  orgId,
  csvData,
  columnData: csvColumnMappingType
) => {
  try {
    const data = new FormData();
    data.append("file", csvData, csvData.name);
    Object.entries(columnData).map(([key, value]) => {
      if(value) {
          value = (value as string).replace(/(\r\n|\n|\r)/gm, "");
      }
      data.append(key, value as string);
    });

    const options = {
      method: "POST",
      url: `organization/${orgId}/onboarding/csv/jobs`,
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=---011000010111000001101001",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    const uploadCSVResponse = await autoVisasOrgPrivateApi.request(options);
    if (uploadCSVResponse?.status === 200) {
      return { hasError: false, data: uploadCSVResponse };
    } else {
      return { hasError: true, message: Strings.UPLOAD_ERROR };
    }
  } catch (error) {
    return { hasError: true, message: Strings.UPLOAD_ERROR };
  }
};
