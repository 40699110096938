import { Strings } from "../../../../assets/strings/index";
import { AsyncStorageKeys } from "../../../common/Constant";
import { verifyOTP } from "../../../util/2FactorAuth/verifyOTP";
import { sendOTP } from "../../../util/2FactorAuth/sendOTP";
import { removeStorageKeys } from "../../../common/AsyncStorage";
import { multiSet } from "../../../common/AsyncStorage";
import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";

const TwoFactorAuthController = () => {
  const { signIn } = useContext(AuthContext);

  const handleOTPVerification = async (
    session: string,
    email: string,
    code: string
  ) => {
    const OTPResponse = await verifyOTP(session, code);
    if (OTPResponse.status === 200) {
      removeStorageKeys([AsyncStorageKeys.SignIn_COMPLETED]);
      signIn(email, OTPResponse);
    } else {
      return { hasError: true, message: Strings.INVALID_CODE };
    }

    return { hasError: false, message: "" };
  };

  const handleSendOTP = async (session: string) => {
    const OTPResponse = await sendOTP(session);

    if (OTPResponse.status === 200) {
      return { hasError: false, message: Strings.SEND_OTP_SUCCESS_MESSAGE };
    } else if (OTPResponse.status === 429) {
      return { hasError: true, message: Strings.SEND_OTP_WARNING };
    } else if (OTPResponse.status === 401) {
      await removeStorageKeys([
        AsyncStorageKeys.USER_EMAIL,
        AsyncStorageKeys.SignIn_COMPLETED,
      ]);
      return { hasError: true, message: Strings.SEND_OTP_USER_SESSION_EXPIRED };
    }
    return { hasError: true, message: Strings.SERVER_ERROR };
  };

  return { handleOTPVerification, handleSendOTP };
};

export default TwoFactorAuthController;
