import { useRef, useState } from "react";
import { Pressable } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Text } from "react-native-paper";
import { createButtonStyles } from "./Create.Styles";
import React from "react";
import Loading from "../../Loading";

type Props = {
  onPress: () => void;
  name: string;
  style?: {};
  isLoading?: boolean;
};

const CreateButton: React.FC<Props> = ({ onPress, name, style, isLoading }) => {
  const ref = useRef(null);
  return (
    <Pressable style={[createButtonStyles.button, style]} onPress={onPress}>
      <LinearGradient
        ref={ref}
        colors={["#9797FF", "#BE8DFA", "#D7EEFF"]}
        start={{ x: 0.1, y: 0.0 }}
        end={{ x: 0.0, y: 3.0 }}
        style={createButtonStyles.buttonGradient}
      >
        {isLoading ? (
          <Loading size="small" color="#F0FFFF" />
        ) : (
          <Text style={createButtonStyles.buttonText}>{name}</Text>
        )}
      </LinearGradient>
    </Pressable>
  );
};

export default React.memo(CreateButton);
