import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const subscriptionDetailsStyles = StyleSheet.create({
    container: { marginVertical: 20 },
    validityText: {
        textAlign: "center",
        color: theme.colors.btn,
        fontSize: 17,
      },
    manageText: { textAlign: "center", marginTop: 10, fontSize: 15 }
})