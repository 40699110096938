import { StyleSheet } from "react-native";
import { theme } from "../../../theme";
export const browseFile = StyleSheet.create({
  closeIcon: {
    marginLeft: 250,
    alignItems: "center",
    height: 50,
    justifyContent: "center",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#70707070",
    flexDirection: "row",
  },
  closeContainer: {
    height: 50,
    width: 300,
    backgroundColor: theme.colors.btn,
    borderRadius: 20,
  },
  shadow2: {
    height: 55,
    width: 310,
    marginLeft: 50,
    backgroundColor: theme.colors.btnshadow,
    borderRadius: 20,
  },
  shadow1: {
    borderTopEndRadius: 30,
    width: 315,
    height: 355,
    backgroundColor: theme.colors.btnshadow,
    borderRadius: 30,
    borderBottomLeftRadius: 50,
  },
  modalView: {
    width: 300,
    height: 350,
    position: "absolute",
    backgroundColor: theme.colors.modalColor,
    borderRadius: 30,
    borderWidth: 5,
    borderColor: theme.colors.btn,
    paddingBottom: 30,
  },
  actionsContainer: {
    backgroundColor: theme.colors.modalColor,
    width: "100%",
    paddingTop: 15,
    flexDirection: "column",
    borderRadius: 30,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 5,
  },
  button: {
    height: 35,
    width: "100%",
    borderStyle: "solid",
    backgroundColor: theme.colors.btn,
    borderRadius: 15,
    alignItems: "center",
    justifyContent: "center",
    margin: 5,
  },
  shadow3: {
    width: "100%",
    height: 38,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 40,
    borderBottomRightRadius: 40,
    borderBottomLeftRadius: 50,
    borderStyle: "solid",
    marginVertical: 3,
    backgroundColor: theme.colors.btnshadow,
  },
  btntxt: {
    color: "white",
    fontSize: 14,
    fontFamily: "Brother1816Black",
    textTransform: "uppercase",
  },
});
