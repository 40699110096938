import { StyleSheet } from "react-native"

export const actionStyles = StyleSheet.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
    },
    imageStyle: {
        flex: 1
  }
})