import { Pressable, View, Text } from "react-native";
import { theme } from "../../../theme";
import ColumnItem from "../ColumnItem";
import { AntDesign } from "@expo/vector-icons";
import React from "react";
import { dependentRowDataStyles } from "./DependentRowData.Styles";
import { navigate } from "../../../navigation/RouteNavigation";
import { Screens } from "../../../common/Constant";
import {
  ActionType,
  DependentInfo,
  DependentList,
} from "../../../screen/StatusProtector/types/Dependentypes";
// @ts-ignore
import { StatusType } from "../../../screen/StatusProtector/types/DeshboardTypes";
import { encryptedData } from "../../../common/Utils";
type props = {
  data: DependentList;
  actionHandler?: (dependentInfo: DependentInfo, actionType: ActionType) => void;
  isOrg: boolean;
};

const DependentRowData: React.FC<props> = ({ data, actionHandler, isOrg}) => {
  const crypto = encryptedData({
    dependentData: {
      dId: data.did,
      first_name: data?.first_name,
      last_name: data?.last_name,
      date_of_birth: data?.date_of_birth,
      passport_number: data?.passport_number,
      citizenship: data?.citizenship,
    },
    email: data?.email,
  });
  const isWarningTag =
    data?.non_immigrant_status?.status?.toLocaleLowerCase() ===
    StatusType.WARNING;
  return (
    <View style={dependentRowDataStyles.container}>
      <ColumnItem
        title={
          data.profile_completed
            ? data.first_name + " " + data.last_name
            : data.email
        }
        style={{ flex: 1.5 }}
      />

      <ColumnItem
        title={
          data?.non_immigrant_status
            ? data?.non_immigrant_status?.visa_type
            : "-"
        }
        style={{ flex: 1 }}
      />
      <Pressable
        style={{ flex: 1, alignItems: isOrg ? "center" : "flex-start" }}
        onPress={() => {
          isWarningTag &&
            actionHandler(
              {
                visible: true,
                tags: data.non_immigrant_status.tags,
              },
              ActionType.VIEW_TAG
            );
        }}
      >
        <Text
          style={
            isWarningTag
              ? { color: "#8FCAFD", textDecorationLine: "underline" }
              : { color: theme.colors.green }
          }
          accessibilityRole="link"
        >
          {data?.non_immigrant_status
            ? data?.non_immigrant_status?.status
            : "-"}
        </Text>
      </Pressable>
      {!isOrg 
        && (
        <View style={dependentRowDataStyles.pressableImg}>
          <AntDesign
            onPress={() =>
              !data?.profile_completed
                ? actionHandler(
                    {
                      visible: true,
                      dId: data.did,
                      email: data.email,
                    },
                    ActionType.EDIT
                  )
                : navigate(Screens.DEPENDENT, {
                    data: crypto,
                  })
            }
            name="filetext1"
            size={20}
            color={data?.profile_completed ? theme.colors.btn : "red"}
          />
          <AntDesign
            onPress={() => {
              actionHandler({ visible: true, dId: data.did }, ActionType.DELETE);
            }}
            name="delete"
            size={20}
            color={theme.colors.btn}
          />
        </View>
      )}
    </View>
  );
};

export default React.memo(DependentRowData);
