import { Animated, Pressable, StyleSheet, View } from "react-native";
/* Components */
import DocumentModal from "../../UploadModal/DocumentModal";
import { LinearGradient } from "expo-linear-gradient";
import { Text } from "react-native-paper";
import { theme } from "../../../theme";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import UploadDocumentsController from "../../../screen/UploadDocuments/controller/UploadDocumentsController";
import { Strings } from "../../../../assets/strings";
import BrowseFile from "../../UploadModal/BrowseFile";
import ProgressBarTimer from "../../progessBarTimerButton/ProgressBarTimer";
import { documentsApiResponse } from "../../../screen/UploadDocuments/types";
import { AuthContext } from "../../../context/AuthContext";
import { useOrgAuthContext } from "../../../context/OrganizationAuthContext";
type Props = {
  setMessage: (type?: string, mess?: string) => void;
  disabled?: boolean;
  dId?: string;
  clientId?: string;
};
const UploadDocumentsButton: React.FC<Props> = ({
  setMessage,
  disabled,
  dId,
  clientId,
}) => {
  const [visible, setVisible] = useState(false);
  const [showFiles, setShowFiles] = useState([]);
  const { validateFileType } = UploadDocumentsController();
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);
  const progressBarValue = useRef(new Animated.Value(0)).current;
  const [isLoading, setIsLoading] = useState(false);
  let savedCallback = useRef(null);
  const { uploadDocumentsFile, getSingleResponse } = useMemo(
    () => UploadDocumentsController(),
    []
  );
  const { loginState } = useContext(AuthContext);
  const { orgAuthState } = useOrgAuthContext();
  
  const validateFile = (files: File[]) => {
    setMessage("");
    if (validateFileType(files)) {
      setShowFiles([...showFiles, ...files]);
    } else {
      hideModal();
      setMessage(Strings.TYPE_MESSAGE_ERROR, Strings.UNSUPPORTED_FILETYPE);
    }
  };
  useEffect(() => {
    return () => {
      clearTimeout(savedCallback.current);
    };
  }, []);

  const resetAnimation = (type: string, message: string) => {
    savedCallback.current = setTimeout(() => {
      setMessage(type, message);
      setIsLoading(false);
    }, 500);
  };

  const uploadFiles = useCallback(async (files: File[]) => {
    setIsLoading(true);
    const documentsApiResponse: documentsApiResponse[] = dId || clientId
      ? await uploadDocumentsFile(files, dId, loginState, clientId, orgAuthState)
      : await uploadDocumentsFile(files);
    const response = getSingleResponse(documentsApiResponse);
    if (!response.hasError) {
      setShowFiles([]);
      resetAnimation(Strings.TYPE_MESSAGE_SUCCESSFUL, response.message);
    } else {
      resetAnimation(Strings.TYPE_MESSAGE_ERROR, response.message);
    }
  }, []);

  return (
    <View style={UploadDocumentsButtonStyles.container}>
      <View style={UploadDocumentsButtonStyles.buttonContainer}>
        <ProgressBarTimer
          showProgressBar={isLoading}
          handleFunction={() => uploadFiles(showFiles)}
          progressBarValue={progressBarValue}
        >
          <Pressable
            onPress={showModal}
            style={UploadDocumentsButtonStyles.button}
            disabled={disabled}
          >
            <LinearGradient
              colors={
                disabled ? ["#AEAEAE", "#C5C5C5"] : ["#9797FF", "#BE8DFA"]
              }
              locations={[0.1, 1]}
              start={{ x: 0.4, y: 0.5 }}
              style={UploadDocumentsButtonStyles.gradientButton}
            >
              <Text style={UploadDocumentsButtonStyles.textButton}>
                Upload Documents
              </Text>
            </LinearGradient>
          </Pressable>
        </ProgressBarTimer>
      </View>
      {!showFiles?.length ? (
        <DocumentModal
          visible={visible}
          onDrop={(files) => validateFile(files)}
          onDismiss={hideModal}
        />
      ) : (
        <BrowseFile
          visible={visible}
          onDismiss={() => {
            hideModal();
            setShowFiles([]);
          }}
          onDrop={(files) => validateFile(files)}
          onUpload={() => {
            hideModal();
            setIsLoading(true);
          }}
          onPress={hideModal}
          itemList={showFiles}
          setItemList={setShowFiles}
        />
      )}
    </View>
  );
};

const UploadDocumentsButtonStyles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 18,
    marginLeft: 16,
    marginRight: 35,
    alignItems: "flex-end",
  },
  buttonContainer: {
    maxWidth: 215,
    width: "100%",
  },
  button: {
    height: 39.14,
  },
  gradientButton: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
  },
  textButton: {
    ...theme.fonts.black,
    fontSize: 16,
    color: "#F0FFFF",
    textAlign: "center",
    textTransform: "uppercase",
    marginRight: 5,
    marginLeft: 5,
  },
});

export default UploadDocumentsButton;
