import { getError, getSuccess } from "../axiosInstances/handleResponse";
import { OrgApis } from "../axiosInstances/Apis";
import { autoVisasOrgPrivateApi } from "../axiosInstances/autoVisasOrgPrivateApi";

export const runStatusProtector = async (orgId: string, clientId: string, token: string) => {
    try {
        const options = {
            method: "GET",
            url: `${OrgApis.ORGANIZATION}/${orgId}${OrgApis.CLIENTS}/${clientId}${OrgApis.RUN_STATUS_PROTECTOR}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            }
        };
        const apiResponse = await autoVisasOrgPrivateApi.request(options);
        return getSuccess(apiResponse);
    } catch (error) {
        getError(error);
    }
}