import * as React from "react";
import { multiGet } from "../common/AsyncStorage";
import { AsyncStorageKeys, Screens } from "../common/Constant";
import Loading from "../components/Loading";
import { useOrgStoreContext } from "../context/orgStoreContext/StoreProvider";
import { OrganizationData } from "../context/orgStoreContext/type/StoreContextType";
import { getOrganizationMeta } from "../util/organization/getOrganizationMetaData";
import { replace } from "./RouteNavigation";

const ProtectedOrgRouteScreen = ({ children, ...rest }) => {
  const [isLoading, setLoading] = React.useState(true);
  const { setOrganizationData, organizationData } = useOrgStoreContext();

  const redirectTo = (orgData: OrganizationData) => {
      if (
        !orgData.meta_data_completed &&
        rest.route.name !== Screens.CREATE_ORGANIZATION_SCREEN
      ) {
        replace(Screens.CREATE_ORGANIZATION_SCREEN, null);
      }
      if (
        orgData.meta_data_completed &&
        !orgData.stripe_setup_completed &&
        rest.route.name !== Screens.ORGANIZATION_PAYMENT
      ) {
        replace(Screens.ORGANIZATION_PAYMENT, null);
      }
      if (
        orgData.meta_data_completed &&
        orgData.stripe_setup_completed &&
        (rest.route.name === Screens.CREATE_ORGANIZATION_SCREEN ||
          rest.route.name === Screens.ORGANIZATION_PAYMENT ||
          rest.route.name === Screens.ORGANIZATION_PAYMENT_SUCCESS ||
          rest.route.name === Screens.ORGANIZATION_PAYMENT_FAILURE)
      ) {
        replace(Screens.ADMIN_SCREEN, null);
      }
  };
  const fetchData = async (controller) => {
    if (!organizationData) {
      const [token, orgId] = await multiGet([
        AsyncStorageKeys.ORG_SESSION_TOKEN,
        AsyncStorageKeys.ORG_ID,
      ]);
      getOrganizationMeta(orgId[1], token[1], controller)
        .then(async (res) => {
          await setOrganizationData(res.data);
          redirectTo(res.data);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    } else {
      setLoading(false);
      redirectTo(organizationData);
    }
  };
  React.useEffect(() => {
    setLoading(true);
    const controller = new AbortController();
    fetchData(controller);
    return () => {
      controller.abort();
    };
  }, []);
  if (isLoading) {
    return <Loading />;
  }
  return children;
};

export default ProtectedOrgRouteScreen;
