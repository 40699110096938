import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const createButtonStyles = StyleSheet.create({
  button: {
    width: "100%",
  },
  buttonGradient: {
    paddingVertical: 10,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
  },
  buttonText: {
    ...theme.fonts.black,
    fontSize: 16,
    color: theme.colors.bgColor,
    textAlign: "center",
    textTransform: "uppercase",
  },
});
