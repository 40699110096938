import { StyleSheet } from "react-native";

export const planDescriptionStyles = StyleSheet.create({
    txtStyle: { fontWeight: 'bold', fontSize: 20, flex: 0.5},
    paymentTxt: {fontWeight: "bold", fontSize: 25},
    container: { marginTop: 50},
    paymentView: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    btnContainer: { flex: 3, marginTop: 30 }
})