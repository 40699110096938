import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, Pressable, ViewStyle, Animated, Easing, View } from "react-native";
import { ProgressBar, Text } from "react-native-paper";

/* Logic */
import { theme } from "../../../theme";

/* Components */
import Loading from "../../Loading";

type Props =  {
    buttonContainerStyle?: ViewStyle;
    name: string;
    onPress: () => void;
    isDisabled?: boolean;
    isLoading?: boolean;
};

const ProgressButton: React.FC<Props> = ({
    name,
    onPress,
    isDisabled = false,
    isLoading = false,
}) => {

    const [showProgressBar, setShowProgressBar] = useState(true);
    const progressBarValue = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        if (showProgressBar) {
            Animated.timing(progressBarValue, {
                toValue: 1,
                duration: 60000,
                useNativeDriver: true,
                easing: Easing.linear,
            })
                .start(() => {setShowProgressBar(false)});
        }
    }, [showProgressBar]);

    if (showProgressBar) {
        return (
            <View style={textButtonStyles.progressBarContainer}>
                <Text style={[textButtonStyles.text, textButtonStyles.progressBarText]}>Resend Code</Text>
                <ProgressBar progress={progressBarValue} style={textButtonStyles.progressBar} color="#C0C0FF" />
            </View>
        );
    }

    return (
        <Pressable
            disabled={isDisabled}
            style={[textButtonStyles.button, {  }]}
            onPress={onPress}
        >   
            {isLoading ? (
              <Loading size="small" color="#F0FFFF" />
            ) : (
              <Text style={[textButtonStyles.text, textButtonStyles.buttonText]}>{name}</Text>
            )}
        </Pressable>
    );
};


const textButtonStyles = StyleSheet.create({
    button: {
        maxWidth: 224,
        width: "100%",
        height: 45,
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 2,
        borderColor:  "#C0C0FF",
        transitionDuration: '1s',
        marginTop: 32,
        borderRadius: 5,
        opacity: 0.6,
        backgroundColor: "#C0C0FF", 
    },
    progressBarContainer: {
        width: 224,
        height: 45,
        marginTop: 32,
    },
    text: {
        fontFamily: "Brother1816Black",
        fontSize: 14,
        textTransform: "uppercase",
        
    },
    progressBarText: {
        position: "absolute",
        zIndex: 2,
        left: 61.5,
        top: 12,
        opacity: 0.6,
        color: theme.colors.btn,
    },
    progressBar: {
        width: 224,
        height: 45,
        backgroundColor: theme.colors.transparent,
        borderWidth: 2,
        borderColor: "#C0C0FF",
        borderRadius: 5,
        opacity: 0.6,
    },
    buttonText: {
        textAlign: "center",
        marginVertical: 10,
        color: theme.colors.modalColor,
    },
});

export default ProgressButton;