import { useRoute } from "@react-navigation/native";
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { View } from "react-native";
import { HelperText, Text } from "react-native-paper";
import { Strings } from "../../../../assets/strings";
import { Screens } from "../../../common/Constant";
import { decryptedData } from "../../../common/Utils";
import BgImageContainer from "../../../components/BgImageContainer";
import CustomButton from "../../../components/Buttons/CustomButton";
import ConfirmInfoInput from "../../../components/ConfirmInfo/ConfirmInfoInput";
import { DatePickerInput } from "../../../components/datePicker/DatePicker";
import DropDown from "../../../components/dropDown/DropDown";
import ErrorText from "../../../components/ErrorText";
import FormInput from "../../../components/FormInput";
import { ProgressBar } from "../../../components/progressBar/ProgressBar";
import { useOrgAuthContext } from "../../../context/OrganizationAuthContext";
import { useRootContext } from "../../../context/rootContext/RootContext";
import validationSchema from "../../../data/validationSchema";
import { replace } from "../../../navigation/RouteNavigation";
import { theme } from "../../../theme";
import { getCountriesList } from "../../../util/countries";
import { Client, ClientUpdateRequestBody } from "../types";
import EditClientScreenController from "./controller/EditClientScreenController";
import { editClientScreenStyles } from "./EditClientScreen.Styles";
import { OrganizationNavigatorScreenProps } from "../../../navigation/types";

type props = {
};

const EditClientScreen: React.FC<props> = () => {
  const [editMsg, setEditMsg] = useState("");
  const [list, setList] = useState([]);
  const { params } = useRoute<OrganizationNavigatorScreenProps<"EditOrganizationClientScreen">["route"]>();
  const client: Client = useMemo(
    () => decryptedData(params.data),
    [params.data]
  );

  const formattedDate = client?.date_of_birth
    ? moment(moment(client?.date_of_birth).format(Strings.DATE_FORMAT)).toDate()
    : "";
  const [date, setDate] = useState(formattedDate);
  const [dobError, setDobError] = useState("");
  const {
    dimensions: { width: width },
  } = useRootContext();
  const { orgAuthState } = useOrgAuthContext();
  const { updateClientProfile } = EditClientScreenController();

  useEffect(() => {
    getCountriesList().then((res) => {
      const list = res.data.map((item) => ({
        label: item.name,
        value: item.alpha3Code,
      }));
      setList(list);
    });
  }, []);

  const editClient = async (body: ClientUpdateRequestBody) => {
    setEditMsg(Strings.LOADING_STARTED);
    await updateClientProfile(
      orgAuthState.orgId,
      orgAuthState.orgUserToken,
      client.uid,
      body
    ).then((res) => {
      if (!res.hasError) {
        setEditMsg("");
        replace(Screens.CLIENT_SCREEN, null);
      } else {
        setEditMsg(res.data);
      }
    });
  };

  return (
    <ProgressBar loading={client ? false : true}>
      <BgImageContainer isOrganization={true}>
        {client ? (
          <View
            style={[
              editClientScreenStyles.innerContainer,
              width < 500 && { width: "95%" },
            ]}
          >
            <Text style={editClientScreenStyles.heading}>Edit Client</Text>
            <Formik
              initialValues={{
                first_name: client?.first_name,
                last_name: client?.last_name,
                email: client?.email,
                passport: client?.passport_number,
                citizenship: client?.citizenship,
              }}
              validationSchema={validationSchema.updateClientDetails}
              onSubmit={(values) => {
                setDobError("");
                if (!date) {
                  setDobError(Strings.Profile.ENTER_DOB);
                } else
                  editClient({
                    first_name: values.first_name,
                    last_name: values.last_name,
                    date_of_birth: moment(date).format("yyyy-MM-DD"),
                    passport_number: values.passport,
                    citizenship: values.citizenship,
                    email: values.email,
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
              }) => (
                <View style={{ marginTop: 30 }}>
                  <View style={{ flexDirection: "row", flex: 1 }}>
                    <View style={editClientScreenStyles.formContainer}>
                      <FormInput
                        label={Strings.FIRST_NAME}
                        inputPlaceholder={Strings.FIRST_NAME}
                        inputValue={values.first_name}
                        setInputValue={handleChange("first_name")}
                        mode="outlined"
                        error={touched.first_name && errors.first_name}
                        labelStyle={editClientScreenStyles.label}
                        style={editClientScreenStyles.input}
                        activeOutlineColor="#9797FF"
                        outlineColor="#9797FF"
                        dense
                      />
                      {(touched.first_name && errors.first_name) ===
                        undefined && (
                        <View
                          style={editClientScreenStyles.errorContainer}
                        ></View>
                      )}
                    </View>
                    <View style={editClientScreenStyles.formContainer}>
                      <FormInput
                        label={Strings.LAST_NAME}
                        inputPlaceholder={Strings.LAST_NAME}
                        inputValue={values.last_name}
                        setInputValue={handleChange("last_name")}
                        mode="outlined"
                        error={touched.last_name && errors.last_name}
                        labelStyle={editClientScreenStyles.label}
                        style={editClientScreenStyles.input}
                        activeOutlineColor="#9797FF"
                        outlineColor="#9797FF"
                        dense
                      />
                      {(touched.last_name && errors.last_name) ===
                        undefined && (
                        <View
                          style={editClientScreenStyles.errorContainer}
                        ></View>
                      )}
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      flex: 1,
                      zIndex: 2,
                    }}
                  >
                    <View style={editClientScreenStyles.formContainer}>
                      <FormInput
                        label={Strings.EMAIL}
                        inputPlaceholder={Strings.EMAIL}
                        inputValue={values.email}
                        setInputValue={handleChange("email")}
                        mode="outlined"
                        error={touched.email && errors.email}
                        labelStyle={editClientScreenStyles.label}
                        style={editClientScreenStyles.input}
                        activeOutlineColor="#9797FF"
                        outlineColor="#9797FF"
                        dense
                      />
                      {(touched.email && errors.email) === undefined && (
                        <View
                          style={editClientScreenStyles.errorContainer}
                        ></View>
                      )}
                    </View>
                    <View style={editClientScreenStyles.formContainer}>
                      <DatePickerInput
                        setSelectedDate={setDate}
                        selectedDate={date}
                        customInput={
                          <>
                            <ConfirmInfoInput
                              editable={false}
                              label={Strings.ConfirmInfo.DATE_OF_BIRTH}
                              placeholder={Strings.ConfirmInfo.DATE_OF_BIRTH}
                              value={
                                date ? moment(date).format("yyyy-MM-DD") : ""
                              }
                              handleBlur={handleBlur}
                            />
                            {dobError ? (
                              <HelperText
                                style={{
                                  color: theme.colors.red,
                                  fontWeight: "700",
                                  marginTop: -20,
                                }}
                                type="error"
                                visible={true}
                              >
                                {dobError}
                              </HelperText>
                            ) : null}
                          </>
                        }
                      />
                    </View>
                  </View>
                  <View style={{ flexDirection: "row", flex: 1, zIndex: 1 }}>
                    <View style={editClientScreenStyles.formContainer}>
                      <FormInput
                        label={Strings.PASSPORT_NUMBER}
                        inputPlaceholder={Strings.PASSPORT_NUMBER}
                        inputValue={values.passport}
                        setInputValue={handleChange("passport")}
                        mode="outlined"
                        error={touched.passport && errors.passport}
                        labelStyle={editClientScreenStyles.label}
                        style={editClientScreenStyles.input}
                        activeOutlineColor="#9797FF"
                        outlineColor="#9797FF"
                        dense
                      />
                      {(touched.passport && errors.passport) === undefined && (
                        <View
                          style={editClientScreenStyles.errorContainer}
                        ></View>
                      )}
                    </View>
                    <View style={[editClientScreenStyles.formContainer]}>
                      <Text
                        style={[
                          {
                            ...theme.fonts.black,
                            marginBottom: 8,
                            fontSize: 16,
                            color: theme.colors.btn,
                            textTransform: "uppercase",
                          },
                        ]}
                      >
                        {Strings.CITIZEN_SHIP}
                      </Text>
                      <DropDown
                        receiptTypeOptions={list}
                        onSelection={handleChange("citizenship")}
                        error={touched.citizenship && errors.citizenship}
                        selectedOption={values.citizenship}
                      />
                    </View>
                  </View>
                  {editMsg && editMsg !== Strings.LOADING_STARTED ? (
                    <ErrorText
                      txtStyle={{ marginVertical: 10 }}
                      error={editMsg}
                    />
                  ) : null}
                  <View style={editClientScreenStyles.btnContainer}>
                    <CustomButton
                      isLoading={editMsg === Strings.LOADING_STARTED}
                      onPress={handleSubmit}
                      name="Save"
                    />
                  </View>
                </View>
              )}
            </Formik>
          </View>
        ) : null}
      </BgImageContainer>
    </ProgressBar>
  );
};

export default React.memo(EditClientScreen);
