import { StyleSheet } from "react-native";

export const loadingButtonStyles = StyleSheet.create({
    button: {
      width: '100%',
      borderRadius: 3
    },
    buttonText: {
      fontSize: 14,
      color: "white",
      textAlign: 'center'
    },
  });