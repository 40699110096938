import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const orgSignUpStyles = StyleSheet.create({
  container: {
    flex: 1,
    width: 550,
    alignSelf: "center",
  },
  submitButtonStyle: {
    marginTop: 32,
  },
  formContainer: { width: "100%" },
  label: {
    fontSize: 16,
    color: "#9797FF",
  },
  input: { backgroundColor: "#F0FFFF" },
  btnStyle: { width: "100%", maxWidth: "100%", marginVertical: 20 },
  errorContainer: {
    height: 22.5,
  },
  policyContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    fontSize: 16,
    color: "#5B3396",
    marginLeft: 10,
  },
  intro: {
    fontSize: 20,
    marginTop: 20,
    color: "#808080",
  },
  heading: {
    color: theme.colors.btn,
    fontSize: 30,
    fontWeight: "bold",
    textAlign: "center",
  },
});
