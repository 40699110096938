import { autoVisasOrgPrivateApi } from "./../axiosInstances/autoVisasOrgPrivateApi";
import { getError, getSuccess } from "../axiosInstances/handleResponse";
import { OrgApis } from "../axiosInstances/Apis";
import { OrganizationRequestBody } from "../../screen/Organization/types";

export const createOrganizationRequest = async (
  token: string,
  orgId: string,
  createOrganizationRequestBody: OrganizationRequestBody
) => {
  try {
    const options = {
      method: "POST",
      url: `${OrgApis.ORGANIZATION}/${orgId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        ...createOrganizationRequestBody,
      },
    };
    const apiResponse = await autoVisasOrgPrivateApi.request(options);
    const { data, status } = getSuccess(apiResponse);
    return {
      status: status,
      data: data,
    };
  } catch (error) {
    return getError(error);
  }
};
