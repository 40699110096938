import { OrgApis } from "../axiosInstances/Apis";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AsyncStorageKeys } from "../../common/Constant";
import { autoVisasOrgPublicApi } from "../axiosInstances/autoVisasOrgPublicApi";

export const orgRefreshToken = async (refresh_token) => {
  const {
    ORG_USER_EMAIL,
    ORG_USER_ID,
    ORG_SESSION_TOKEN,
    ORG_SESSION_REFRESH_TOKEN,
    ORG_EXPIRES_AT,
  } = AsyncStorageKeys;
  try {
    const options = {
      method: "POST",
      url: OrgApis.ORG_REFRESH_TOKEN,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${refresh_token}`,
      },
    };
    const response = await autoVisasOrgPublicApi.request(options);
    return {
      userId: response.data.uid,
      accessToken: response.data.access_token.token,
      refreshToken: response.data.refresh_token.token,
      expiresAt: response.data.access_token.expires_at,
      status: response.status,
      organizationId: response.data.organization_id,
      organizationRole: response.data.organization_role,
    };
  } catch (error) {
    const keys = [
      ORG_USER_EMAIL,
      ORG_USER_ID,
      ORG_SESSION_TOKEN,
      ORG_SESSION_REFRESH_TOKEN,
      ORG_EXPIRES_AT,
    ];
    await AsyncStorage.multiRemove(keys);
  }
};
