import { StyleSheet } from "react-native";
import { theme } from "../../theme";

const dashboardStyles = StyleSheet.create({
  container: {
    width: "100%",
    alignItems: "center",
    marginBottom: "5%",
  },
  logoImage: {
    height: 80,
    maxWidth: 350,
    marginBottom: 20,
    marginLeft: 5,
  },
  body: {
    maxWidth: 755,
    width: "100%",
    zIndex: 2,
    marginTop: 50,
  },
  title: {
    fontSize: 30,
    textTransform: "uppercase",
    color: "#9797FF",
    paddingLeft: 49.9,
    marginBottom: 10,
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 31,
  },
  nameContainer: {
    marginLeft: 18,
  },
  name: {
    ...theme.fonts.black,
    fontSize: 39,
    color: "#9797FF",
    textTransform: "uppercase",
  },
  tags: {
    fontSize: 15,
    textTransform: "none",
    color: "#F0FFFF",
  },
  footerText: {
    marginBottom: 12,
    color: "white",
    alignSelf: "center",
    textAlign: "center",
    ...theme.fonts.medium,
    fontSize: 15,
  },
  modalStyle: {
    borderRadius: 10,
    borderWidth: 4,
    height: 530,
  },
});
export default dashboardStyles;
