import { Font, Fonts } from "react-native-paper/lib/typescript/src/types";
import { DefaultTheme, configureFonts, MD2LightTheme } from "react-native-paper";
import { fontConfig } from "./data/fontsConfig";
interface FontsCustom extends Fonts {
  black: Font;
  bold: Font;
}
export const theme = {
  ...MD2LightTheme,
  fonts: configureFonts({ config: fontConfig, isV3: false}) as FontsCustom,
  colors: {
    ...MD2LightTheme,
    // <<========= these are the required colors list for ThemeColors and Theme for react-navigation =======>
    primary: "#000",
    background: "#FFF",
    surface: "green",
    accent: "#9797FF",
    error: "rgba(179, 38, 30, 1)",
    text: "#000",
    onSurface: "#000",
    disabled: "#000",
    placeholder: "grey",
    backdrop: "rgba(0, 0, 0, 0.3)",
    notification: "#000",
    // color for  react-navigation
    card: "",
    border: "",
    // ============ //
    secondary: "#FFF",
    black: "black",
    third: "#DFDFE0",
    fourth: "#E5E5E5",
    blue: "#0768FC",
    borderInputs: "#DBDBDB",
    transparent: "transparent",
    active: "#038C7F",
    inactive: "#F7F7F7",
    textInactive: "#5F586E",
    pending: "#625B71",
    expired: "#A61C1C",
    errorContainer: "rgba(249, 222, 220, 1)",
    onError: "rgba(255, 255, 255, 1)",
    onErrorContainer: "rgba(65, 14, 11, 1)",
    onPrimary: "rgba(255, 255, 255, 1)",
    onPrimaryContainer: "rgba(33, 0, 93, 1)",
    bgColor: "#D7EEFF",
    bottom: "#BE8DFA",
    bottom2: "#CFAEFF",
    btn: "#9797FF",
    btnshadow: "#C0C0FF",
    btnGradient: ["#9797FF", "#BE8DFA", "#D7EEFF"],
    modalColor: "#F0FFFF",
    fontColorMedium: "#5a6078",
    red: "#E53935",
    yellow: "#FBC02D",
    green: "#81C784",
    white: "#FFFFFF",
    inputActiveOutlineColor: "#9797FF",
    inputOutlineColor: "transparent",
    divider: "#DEE2E6",
    tableHeadingColor: "#ADB5BD",
  },
};
