import { autoVisasPrivateApi } from "./../axiosInstances/autovisasPrivateApi";
import { initialLoginType } from "./../../context/AuthContext/index";
import { autoVisasPublicApi } from "./../axiosInstances/autovisasPublicApi";
import { getError, getSuccess } from "../axiosInstances/handleResponse";
import { Apis } from "../axiosInstances/Apis";
import { CreateDependentRequestBody } from "../../screen/StatusProtector/types/Dependentypes";

export const createDependentById = async (
  userData: initialLoginType,
  payload: CreateDependentRequestBody
) => {
  try {
    const options = {
      method: "POST",
      url: `${Apis.USERS}/${userData.userId + "" + Apis.DEPENDENTS}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userData.userToken}`,
      },
      data: {
        ...payload,
      },
    };
    const apiResponse = await autoVisasPrivateApi.request(options);
    const { data, status } = getSuccess(apiResponse);
    return {
      status: status,
      data: data,
    };
  } catch (error) {
    return getError(error);
  }
};
