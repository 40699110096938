import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const addDependentModalStyles = StyleSheet.create({
  contentStyle: {
    marginVertical: 20,
    fontSize: 15,
    color: "#737373",
  },

  container: {
    alignItems: "flex-start",
    flex: 1,
  },
  btnContainer: {
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  formContainer: {
    flex: 1,
    width: "100%",
    flexDirection: "column",
  },
  inputContainer: {
    width: "100%",
    marginTop: 30,
  },
});
