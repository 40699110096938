import { useContext, useEffect, useRef, useState } from "react";
import { StyleSheet, View, Pressable, Animated, Easing } from "react-native";
import { Text } from "react-native-paper";
import { LinearGradient } from "expo-linear-gradient";
/* Components */
import { AntDesign } from "@expo/vector-icons";
import { theme } from "../../../theme";
import ConfirmInfoController from "../../../screen/ConfirmInfo/controller/ConfirmStatusController";
import { Strings } from "../../../../assets/strings";
import ProgressBarTimer from "../../progessBarTimerButton/ProgressBarTimer";
import { SPClientController } from "../../../screen/Organization/SPClientScreen/controller/SPClientScreenController";
import { useOrgAuthContext } from "../../../context/OrganizationAuthContext";
import { AuthContext } from "../../../context/AuthContext";

type Props = {
  setMessage: (type?: string, mess?: string) => void;
  reloadNonImmigrantStatusCallback?: () => void;
  disabled?: boolean;
  clientId?: string;
};

const ButtonCheckMyStatus: React.FC<Props> = ({
  setMessage,
  reloadNonImmigrantStatusCallback,
  disabled,
  clientId,
}) => {
  const { handleCBP, cancelCBPRequest } = ConfirmInfoController();
  const { getRunStatusProtector } = SPClientController();
  const { orgAuthState } = useOrgAuthContext();
  const { loginState } = useContext(AuthContext);
  const [showProgressBar, setShowProgressBar] = useState(false);

  let progressBarValue = useRef(new Animated.Value(0)).current;
  let savedCallback = useRef(null);
  useEffect(() => {
    return () => {
      clearTimeout(savedCallback.current);
    };
  }, []);

  const resetAnimation = (message: string) => {
    savedCallback.current = setTimeout(() => {
      if (message === Strings.CHECK_STATUS_SUCCESFULLY) {
        setMessage(Strings.TYPE_MESSAGE_CBP, message);
      } else {
        setMessage(Strings.TYPE_MESSAGE_ERROR, message);
      }
      setShowProgressBar(false);
    }, 500);
  };

  const checkStatusHandle = () => {
    if (clientId) {
      getRunStatusProtector(clientId, orgAuthState.orgId, orgAuthState.orgUserToken)
        .then((response) => {
          progressBarValue.setValue(1);
          if (!response.hasError) {
            reloadNonImmigrantStatusCallback();
            resetAnimation(Strings.CHECK_STATUS_SUCCESFULLY);
          } else {
            resetAnimation(response.data);
          }
        })
        .catch((e) => console.error(e));
    } else {
      handleCBP(loginState.userId)
        .then(({ hasError, message }) => {
          progressBarValue.setValue(1);
          if (!hasError) {
            reloadNonImmigrantStatusCallback();
            resetAnimation(Strings.CHECK_STATUS_SUCCESFULLY);
          } else {
            resetAnimation(message);
          }
        });
    }
  };

  return (
    <View style={buttonCheckMyStatusStyles.container}>
      <ProgressBarTimer
        showProgressBar={showProgressBar}
        cancelRequest={cancelCBPRequest}
        handleFunction={checkStatusHandle}
        progressBarValue={progressBarValue}
      >
        <View style={buttonCheckMyStatusStyles.buttonContainer}>
          <Pressable
            onPress={() => setShowProgressBar(true)}
            disabled={disabled}
            style={buttonCheckMyStatusStyles.button}
          >
            <LinearGradient
              colors={
                disabled ? ["#AEAEAE", "#C5C5C5"] : ["#9797FF", "#BE8DFA"]
              }
              locations={[0.1, 1]}
              start={{ x: 0.4, y: 0.5 }}
              style={[buttonCheckMyStatusStyles.gradientButton]}
            >
              <>
                <Text style={buttonCheckMyStatusStyles.textButton}>
                  Check my status
                </Text>
                <AntDesign name="right" size={24} color="#F0FFFF" />
              </>
            </LinearGradient>
          </Pressable>
        </View>
      </ProgressBarTimer>
    </View>
  );
};

const buttonCheckMyStatusStyles = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: 16,
    marginRight: 35,
    marginTop: 18,
    alignItems: "flex-end",
  },
  buttonContainer: {
    minWidth: 205,
    width: "100%",
  },
  button: {
    height: 39.14,
  },
  gradientButton: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
    paddingHorizontal: 15,
  },
  textButton: {
    ...theme.fonts.black,
    fontSize: 16,
    color: "#F0FFFF",
    textAlign: "center",
    textTransform: "uppercase",
    marginRight: 5,
    marginLeft: 5,
    marginBottom: 2,
  },
  progressBarContainer: {
    width: 224,
    height: 45,
  },
  text: {
    fontFamily: "Brother1816Black",
    fontSize: 14,
    textTransform: "uppercase",
  },
  progressBarText: {
    position: "absolute",
    zIndex: 2,
    left: 61.5,
    top: 12,
    opacity: 0.6,
    color: theme.colors.btn,
  },
  progressBar: {
    width: 224,
    height: 45,
    backgroundColor: theme.colors.transparent,
    borderWidth: 2,
    borderColor: "#C0C0FF",
    borderRadius: 5,
    opacity: 0.6,
  },
});

export default ButtonCheckMyStatus;
