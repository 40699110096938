import { StyleSheet } from "react-native";
import { theme } from "../../theme";
const documentTextStyles = StyleSheet.create({
  btnsContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 32,
  },
  button: {
    width: 224,
    paddingVertical: 10.5,
    borderStyle: "solid",
    backgroundColor: "#926FC5",
    borderRadius: 5,
    alignItems: "center",
    marginVertical: 15,
    marginRight: 10,
    justifyContent: "center",
  },
  btnTxt: {
    fontFamily: "Brother1816Black",
    fontSize: 16,
    color: theme.colors.bgColor,
    textTransform: "uppercase",
    backgroundColor: "transparent",
  },
  link: {
    fontFamily: "FilsonSoftBook",
    fontSize: 16,
    color: "#9797FF",
    textTransform: "uppercase",
    textDecorationLine: "underline",
  },
  continueButtonContainer: {
    width: "100%",
    marginTop: 16,
    alignItems: "flex-end",
  },
  documentTextContainer: { marginVertical: 10, marginHorizontal: 5 },
  documentText: {
    color: theme.colors.surface,
    marginTop: 8,
    ...theme.fonts.regular,
    fontSize: 16,
  },
});
export default documentTextStyles;
