import { getStorageValue } from "../common/AsyncStorage";
import { AsyncStorageKeys } from "../common/Constant";
import { Apis } from "./axiosInstances/Apis";
import { autoVisasPublicApi } from "./axiosInstances/autovisasPublicApi";
import { getError, getSuccess } from "./axiosInstances/handleResponse";

export const createCheckoutLink = async (
  user_id: string,
  product_id: string,
  price_id: string
) => {
  const userTokenInStorage = await getStorageValue(
    AsyncStorageKeys.USER_SESSION_TOKEN
  );
  try {
    const options = {
      method: "POST",
      url: `${Apis.BILLING}/${user_id}${Apis.CHECKOUT}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userTokenInStorage}`,
      },
      data: {
        product_id,
        price_id,
      },
    };

    const apiResponse = await autoVisasPublicApi.request(options);
    return getSuccess(apiResponse);
  } catch (error) {
    console.error(error.response);
    return getError(error);
  }
};
