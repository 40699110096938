import { orgSendOtp } from "./../../../../util/organization/otp/orgSendOtp";
import { Strings } from "../../../../../assets/strings";
import { removeStorageKeys } from "../../../../common/AsyncStorage";
import { AsyncStorageKeys } from "../../../../common/Constant";
import { orgVerifyOTP } from "../../../../util/organization/otp/orgVerifyOtp";
import { useOrgAuthContext } from "../../../../context/OrganizationAuthContext";

export const OrgSignUpVerificationController = () => {
  const { orgSignIn } = useOrgAuthContext();
  const handleOTPVerification = async (
    session: string,
    email: string,
    code: string
  ) => {
    const OTPResponse = await orgVerifyOTP(session, code);
    if (OTPResponse.status === 200) {
      removeStorageKeys([AsyncStorageKeys.ORG_SIGN_IN_COMPLETED]);
      await orgSignIn(email, OTPResponse.data, Strings.USER_TYPE.ORGANIZATION);
    } else {
      return { hasError: true, message: Strings.INVALID_CODE };
    }
    return { hasError: false, message: "" };
  };

  const handleResendOTP = async (session: string) => {
    const OTPResponse = await orgSendOtp(session);

    if (OTPResponse.status === 200) {
      return { hasError: false, message: Strings.SEND_OTP_SUCCESS_MESSAGE };
    } else if (OTPResponse.status === 429) {
      return { hasError: true, message: Strings.SEND_OTP_WARNING };
    } else if (OTPResponse.status === 401) {
      await removeStorageKeys([
        AsyncStorageKeys.USER_EMAIL,
        AsyncStorageKeys.SignIn_COMPLETED,
      ]);
      return { hasError: true, message: Strings.SEND_OTP_USER_SESSION_EXPIRED };
    }
    return { hasError: true, message: Strings.SERVER_ERROR };
  };

  return { handleOTPVerification, handleResendOTP };
};
