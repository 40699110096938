/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { createContext, useContext } from "react";
import { OrgStoreContextData } from "./data/StoreContextDefaultData";
import { OrganizationData, OrgStoreContextType } from "./type/StoreContextType";

export const OrgStoreContext =
  createContext<OrgStoreContextType>(OrgStoreContextData);
export const OrgStoreContextProvider: React.FC = ({ children }) => {
  const [organizationData, setOrganizationData] = useState<OrganizationData>(
    OrgStoreContextData.organizationData
  );

  return (
    <OrgStoreContext.Provider
      value={{
        organizationData,
        setOrganizationData,
      }}
    >
      {children}
    </OrgStoreContext.Provider>
  );
};
export const useOrgStoreContext = () => useContext(OrgStoreContext);
