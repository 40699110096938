import React from "react";
import { Pressable } from "react-native";
import { Text } from "react-native-paper";
import { clickAbleTextStyles } from "./ClickableText.Styles";

type props = {
  text: string;
  style?: {};
  containerStyle?: {};
  onPress: () => void;
};

const ClickableText: React.FC<props> = ({
  text,
  style,
  onPress,
  containerStyle,
}) => {
  return (
    <Pressable style={containerStyle} onPress={onPress}>
      <Text style={[style, clickAbleTextStyles.textStyle]}>{text}</Text>
    </Pressable>
  );
};

export default React.memo(ClickableText);
