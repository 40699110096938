import { StyleSheet } from "react-native"

export const headerStyles = StyleSheet.create({
    container: {
        width: "100%",
        paddingHorizontal: 20,
        paddingVertical: 10,
        flexDirection: "row",
    },
    
})