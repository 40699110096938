import React from 'react';
import { Pressable, View } from 'react-native';
import { Text } from 'react-native-paper';
import { stripeButtonStyles } from './StripePlanButton.Styles';

type props = {
  activeBtn: string;
  setActiveBtn: (value: string) => void;
  title: string;
};

const StripePlanButton: React.FC<props> = ({
  title,
  activeBtn,
  setActiveBtn,
}) => {
  return (
    <View>
      <Pressable
        onPress={() => setActiveBtn(title)}
        style={
          activeBtn === title
            ? stripeButtonStyles.activeButtonStyle
            : stripeButtonStyles.inActiveButtonStyle
        }
      >
        <Text
          style={
            activeBtn === title
              ? stripeButtonStyles.activeTextStyle
              : stripeButtonStyles.inActiveTextStyle
          }
        >
          {title}
        </Text>
      </Pressable>
    </View>
  );
};

export default React.memo(StripePlanButton);
