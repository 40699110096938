import React, { useState } from "react";
import OrganizationDetails from "../../../components/Organization/OrganizationDetails";
import { useOrgStoreContext } from "../../../context/orgStoreContext/StoreProvider";

type props = {};

const EditOrganizationScreen: React.FC<props> = () => {
  const { organizationData } = useOrgStoreContext();

  return (
    <OrganizationDetails organizationData={organizationData} isEdit={true} />
  );
};

export default React.memo(EditOrganizationScreen);
