import { useEffect } from "react";
import { StyleSheet, View, Animated, Easing } from "react-native";
import { ProgressBar, Text } from "react-native-paper";

import { theme } from "../../theme";
type Props = {
  showProgressBar?: boolean;
  cancelRequest?: () => void;
  handleFunction?: () => void;
  progressBarValue?: Animated.Value;
  timer?: number;
};

const ProgressBarTimer: React.FC<Props> = ({
  children,
  showProgressBar,
  handleFunction,
  cancelRequest,
  progressBarValue,
  timer = 15000,
}) => {
  useEffect(() => {
    if (showProgressBar) {
      setTimeout(() => {
        handleFunction();
      }, 1000);
      const animation = Animated.timing(progressBarValue, {
        toValue: 1,
        duration: timer,
        useNativeDriver: true,
        easing: Easing.linear,
      });
      animation.start(({ finished }) => {
        finished && cancelRequest && cancelRequest();
      });
    }
    return () => {
      cancelRequest && cancelRequest();
      progressBarValue.setValue(0);
    };
  }, [showProgressBar]);

  return (
    <View>
      {showProgressBar ? (
        <View style={buttonCheckMyStatusStyles.progressBarContainer}>
          <Text
            style={[
              buttonCheckMyStatusStyles.text,
              buttonCheckMyStatusStyles.progressBarText,
            ]}
          >
            Loading
          </Text>
          <ProgressBar
            progress={progressBarValue}
            style={buttonCheckMyStatusStyles.progressBar}
            color="#C0C0FF"
          />
        </View>
      ) : (
        children
      )}
    </View>
  );
};

const buttonCheckMyStatusStyles = StyleSheet.create({
  progressBarContainer: {
    width: 224,
    height: 45,
  },
  text: {
    fontFamily: "Brother1816Black",
    fontSize: 14,
    textTransform: "uppercase",
  },
  progressBarText: {
    position: "absolute",
    zIndex: 2,
    left: 61.5,
    top: 12,
    opacity: 0.6,
    color: theme.colors.btn,
  },
  progressBar: {
    width: 224,
    height: 45,
    backgroundColor: theme.colors.transparent,
    borderWidth: 2,
    borderColor: "#C0C0FF",
    borderRadius: 5,
    opacity: 0.6,
  },
});

export default ProgressBarTimer;
