import { StyleSheet } from "react-native";

export const adminScreenStyles = StyleSheet.create({
  scrollContainer: {
    height: "90%",
    alignItems: "center",
    alignSelf: "center",
  },
  errorTxt: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 15,
    color: "red",
    marginVertical: 10,
  }
});
