import React from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../assets/strings";
import { useRootContext } from "../../../context/rootContext/RootContext";
import BorderButton from "../../Buttons/BorderButton";
import CustomButton from "../../Buttons/CustomButton";
import CustomModal from "../../Modal/CustomModal";
import Warnings from "../../Warnings";

type props = {
  visible: boolean;
  onDismiss: () => void;
  tags: [];
};

const DependentTagsModal: React.FC<props> = ({ visible, onDismiss, tags }) => {
  const {
    dimensions: { width },
  } = useRootContext();

  return (
    <CustomModal
      modalStyle={{ width: width > 600 ? 560 : "90%", height: 350 }}
      visible={visible}
      onDismiss={onDismiss}
    >
      <View style={{ flex: 1 }}>
        <Warnings tags={tags} />
      </View>
      <BorderButton
        style={{ alignSelf: "flex-end", margin: 10 }}
        onPress={onDismiss}
        title={Strings.CANCEL}
      />
    </CustomModal>
  );
};

export default React.memo(DependentTagsModal);
