import React from "react";
import {
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
  ActivityIndicator,
} from "react-native";
import { theme } from "../../theme";

type Props = {
  color?: string;
  size?: number | "small" | "large" | undefined;
  loadingStyle?: StyleProp<ViewStyle>;
};

const Loading: React.FC<Props> = ({
  color = theme.colors.primary,
  size = "large",
  loadingStyle,
}) => {
  return (
    <View style={loadingStyles.container}>
      <ActivityIndicator
        animating={true}
        size={size}
        color={color}
        style={loadingStyle}
      />
    </View>
  );
};

const loadingStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default Loading;
