import React, { useEffect, useMemo, useState } from "react";
import { Image, View, Text } from "react-native";
import { Card } from "react-native-paper";
import { Images } from "../../../../assets/images";
import { Strings } from "../../../../assets/strings";
import StripeSubmitButton from "../../../components/Buttons/StripeSubmitButton";
import OrgPaymentController from "./controller/OrgPaymentContoller";
import { useOrgStoreContext } from "../../../context/orgStoreContext/StoreProvider";

type props = {};

const OrganizationPayment: React.FC<props> = () => {
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState<{
    product_id: string;
    price_id: string;
    mode: string;
    name: string;
    currency: string;
    recurring_interval: string;
    tiers: { unit_amount: number; up_to: number }[];
  }>();
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { getOrgPlans, getOrgCheckoutLink } = OrgPaymentController();
  const { organizationData } = useOrgStoreContext();
  const priceCurrency = "US$";
  
  const onSubscribe = async () => {
    setLoading(true);
    await getOrgCheckoutLink(
      organizationData.organization_id,
      plans.product_id,
      plans.price_id
    ).then((res) => {
      if (!res.hasError) {
        window.open(res.data.url, "_self");
      }
      setLoading(false);
    });
  };
  const getActivePlans = async () => {
    setLoading(true);
    await getOrgPlans().then((res) => {
      if (!res.hasError) {
        setPlans(res.data.organization_plans[0]);
        setHasError(false);
      } else {
        setHasError(true);
        setErrorMessage(res.data);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getActivePlans();
  }, []);

  return (
    <View style={{ alignItems: "center", flex: 1 }}>
      <Image
        style={{ width: 250, height: 75, marginTop: 100, marginBottom: 50 }}
        source={Images.MAIN_LOGO}
        resizeMode="contain"
      />
      <View>
        <Text style={{ fontWeight: "bold", fontSize: 22 }}>{plans?.name}</Text>
        <Text style={{ marginTop: 30 }}>{Strings.STARTING_AT}</Text>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text style={{ fontWeight: "bold", fontSize: 35 }}>
            {priceCurrency + plans?.tiers?.[0]?.unit_amount / 100}
          </Text>
          <View>
            <Text>{Strings.PER_UNIT}</Text>
            <Text>per {plans?.recurring_interval}</Text>
          </View>
        </View>
        <Text>{Strings.BILLED_BASED_ON_USAGE}</Text>
        <Card
          style={{ width: 350, backgroundColor: "white", marginVertical: 15 }}
          elevation={5}
        >
          <View style={{ flex: 1, padding: 10 }}>
            <Text>
              0-{plans?.tiers[0]?.up_to} used: {priceCurrency}
              {plans?.tiers?.[0].unit_amount / 100} {Strings.PER_UNIT}
            </Text>
            <Text>
              {plans?.tiers[0]?.up_to + 1}-{plans?.tiers[1]?.up_to} used:{" "}
              {priceCurrency}
              {plans?.tiers?.[1].unit_amount / 100} {Strings.PER_UNIT}
            </Text>
            <Text>
              {plans?.tiers[1]?.up_to + 1}+ used: {priceCurrency}
              {plans?.tiers?.[2].unit_amount / 100} {Strings.PER_UNIT}
            </Text>
          </View>
        </Card>
        <StripeSubmitButton
          style={{ marginTop: 50 }}
          title={Strings.SUBSCRIBE}
          isLoading={false}
          onSubscribe={onSubscribe}
        />
      </View>
    </View>
  );
};

export default React.memo(OrganizationPayment);
