import { orgUserRegister } from "../../../../util/organization/orgUserRegister";
import LoginController from "../../../Login/controller/LoginController";

export const OrganizationSignUpController = () => {
  const { handleLogin } = LoginController();
  const handleOrgRegister = async (fistName, lastName, email, password) => {
    const registerResponse = await orgUserRegister(
      fistName,
      lastName,
      email,
      password
    );
    if (registerResponse.status === 200) {
      handleLogin(email, password);
    } else {
      return registerResponse;
    }
  };

  return { handleOrgRegister };
};
