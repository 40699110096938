import { useState, useContext } from "react";
import { Pressable, ScrollView, StyleSheet, View } from "react-native";
import { Text, TextInput } from "react-native-paper";
import { Formik, replace } from "formik";
import { Link } from "@react-navigation/native";
import { StackActions, useNavigation } from "@react-navigation/native";
import validationSchema from "../../data/validationSchema";
import { theme } from "../../theme";
import { AuthContext } from "../../context/AuthContext/index";
import { login } from "../../util/login";
import { Screens } from "../../common/Constant";
import { sendOTP } from "../../util/2FactorAuth/sendOTP";
import BgImageContainer from "../../components/BgImageContainer";
import FormInput from "../../components/FormInput";
import SubmitButton from "../../components/Buttons/Submit";
import { navigate } from "../../navigation/RouteNavigation";
import { Strings } from "../../../assets/strings";
import LoginController from "./controller/LoginController";

const Login = () => {
  const [isPasswordNotVisible, setIsPasswordNotVisible] = useState(true);
  const [authErrorMessage, setAuthErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const { handleLogin } = LoginController();
  
  return (
    <BgImageContainer>
      <ScrollView contentContainerStyle={loginStyles.container}>
        <View style={loginStyles.body}>
          <Text style={loginStyles.title}>Login</Text>
          {authErrorMessage !== "" && (
            <Text style={loginStyles.authError}>{authErrorMessage}</Text>
          )}
          <View style={loginStyles.formContainer}>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={validationSchema.login}
              onSubmit={async (values) => {
                setLoading(true);

                const loginRes = await handleLogin(values.email, values.password);
                loginRes && setAuthErrorMessage(loginRes.data.message);
                
                setLoading(false);
              }}
            >
              {({ values, errors, touched, handleSubmit, handleChange }) => (
                <>
                  <View style={loginStyles.inputsContainer}>
                    <View style={loginStyles.inputContainer}>
                      <FormInput
                        label="Email"
                        inputPlaceholder="Email"
                        inputValue={values.email}
                        setInputValue={handleChange("email")}
                        mode="outlined"
                        error={touched.email && errors.email}
                        labelStyle={loginStyles.label}
                        style={loginStyles.input}
                        activeOutlineColor="#9797FF"
                        outlineColor="transparent"
                        dense
                      />
                    </View>
                    <View style={loginStyles.inputContainer}>
                      <FormInput
                        label="Password"
                        inputPlaceholder="Password"
                        inputValue={values.password}
                        setInputValue={handleChange("password")}
                        mode="outlined"
                        error={touched.password && errors.password}
                        secureTextEntry={isPasswordNotVisible}
                        labelStyle={loginStyles.label}
                        style={loginStyles.input}
                        activeOutlineColor="#9797FF"
                        outlineColor="transparent"
                        dense
                        iconRight={
                          <TextInput.Icon
                            icon={isPasswordNotVisible ? "eye-off" : "eye"}
                            onPress={() =>
                              setIsPasswordNotVisible((prev) => !prev)
                            }
                          />
                        }
                      />
                    </View>
                    <SubmitButton
                      onPress={handleSubmit}
                      name="Sign in"
                      isLoading={isLoading}
                    />
                  </View>
                  <Pressable
                    style={{ width: 130 }}
                    onPress={() =>
                      navigate(Screens.PASSWORD_RESET_REQUEST, null)
                    }
                  >
                    <Text
                      style={{
                        fontSize: 15,
                        marginBottom: 10,
                        color: theme.colors.btn,
                      }}
                    >
                      {Strings.RESET_PASSWORD}
                    </Text>
                  </Pressable>
                  <View>
                    <Text style={loginStyles.text}>
                      Don't have an account? Sign Up as an
                      <Link
                        style={loginStyles.link}
                        to={{ screen: Screens.CREATE_ACCOUNT }}
                      >
                        Individual
                      </Link>
                      or
                      <Link
                        style={loginStyles.link}
                        to={{ screen: Screens.ORGANIZATION_USER_SIGNUP }}
                      >
                        Organization
                      </Link>
                    </Text>
                  </View>
                </>
              )}
            </Formik>
          </View>
        </View>
      </ScrollView>
    </BgImageContainer>
  );
};

const loginStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    marginTop: 82,
    marginRight: "10%",
  },
  body: {
    justifyContent: "center",
    marginBottom: "17%",
  },
  title: {
    ...theme.fonts.black,
    fontSize: 38,
    color: "#9797FF",
    textTransform: "uppercase",
  },
  authError: {
    marginTop: 24,
    fontSize: 16,
    color: theme.colors.red,
  },
  formContainer: {
    marginTop: 24,
  },
  inputsContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  inputContainer: {
    maxWidth: 224,
    width: "100%",
    marginBottom: 24,
    marginRight: 20,
  },
  label: {
    fontSize: 16,
    color: "#9797FF",
    textTransform: "uppercase",
  },
  input: { backgroundColor: "#F0FFFF" },
  button: {
    width: 255,
    marginVertical: 16,
    borderRadius: 5,
    // backgroundColor: theme.colors.blue,
  },
  textBtn: { color: theme.colors.secondary },
  text: {
    fontSize: 16,
    color: "#5B3396",
  },
  link: {
    marginHorizontal: 5,
    textDecorationLine: "underline",
  },
});

export default Login;
