import { theme } from "./../../../theme";
import { StatusType } from "../types/DeshboardTypes";
import { getNonImmigrantStatus } from "../../../util/getNonImmigrantStatus";
import { Strings } from "../../../../assets/strings";
import { getUserInfo } from "../../../util/apiUserInfo";
import documentsController from "../../../components/Documents/controller/documentsController";
import { deleteDocument } from "../../../util/deleteDocument";
import { getTravelHistory } from "../../../util/getTravelHistory";
import { getDependentList } from "../../../util/dependent/getDependentList";

const StatusProtectorController = () => {
  const { getDocuments, findValidDocuments } = documentsController();
  const controller = new AbortController();

  const getStatusColor = (status: string) => {
    if (status === StatusType.VALID) return theme.colors.green;
    else if (status === StatusType.IN_VALID || status === StatusType.ERROR) return theme.colors.red;
    else if (status === StatusType.WARNING) return theme.colors.yellow;
    else return "#BE8DFA";
  };
  const getStatusIcon = (status: string) => {
    if (status === StatusType.IN_VALID) {
      return "close-circle-outline";
    } else if (status === StatusType.VALID) {
      return "checkbox-marked";
    } else {
      return "checkbox-blank-circle-outline";
    }
  };

  const getNonImmigrantStatusApi = () => {
    return getNonImmigrantStatus().then((nonImmigrantStatusResponse) => {
      if (nonImmigrantStatusResponse.status === 200 && nonImmigrantStatusResponse.data) {
        return {
          hasError: false,
          data: {
            admitUntil: nonImmigrantStatusResponse.data.expiration_date,
            dateEntry: nonImmigrantStatusResponse.data.issue_date,
            status: nonImmigrantStatusResponse.data.status,
            tags: nonImmigrantStatusResponse.data.tags,
            visa_type: nonImmigrantStatusResponse.data.visa_type,
          },
        };
      } else {
        return { hasError: true, message: Strings.SOMETHING_WENT_WRONG };
      }
    });
  };
  const getUserInfoApi = () => {
    return getUserInfo(controller).then((userInfoResponse) => {
      if (userInfoResponse.status === 200) {
        return {
          hasError: false,
          data: userInfoResponse.data,
        };
      } else {
        return { hasError: true, message: Strings.SOMETHING_WENT_WRONG };
      }
    });
  };

  const getDocumentsApi = () => {
    return getDocuments().then((response) => {
      if (!response.hasError) {
        const documentsData = findValidDocuments(response.data);
        return {
          error: "",
          data: documentsData,
        };
      } else {
        return {
          error: response.message,
          data: null,
        };
      }
    });
  };

  const getDependentListApi = () => {
    return getDependentList().then((response) => {
      if (response.status === 200) {
        const dependentList = findValidDocuments(response.data);
        return {
          data: dependentList,
        };
      } else {
        return {
          data: [],
        };
      }
    });
  };
  const deleteDoc = async (uid: string, docId: string) => {
    try {
      const response = await deleteDocument(uid, docId);
      if (response.status === 200) {
        return {
          hasError: false,
          data: response.data,
        };
      } else {
        return {
          hasError: true,
          data: Strings.SOMETHING_WENT_WRONG,
        };
      }
    } catch (error) {
      return {
        hasError: true,
        data: Strings.SOMETHING_WENT_WRONG,
      };
    }
  };

  const getTravelHistoryOfUser = async () => {
    try {
      const response = await getTravelHistory();
      if (response.status === 200) {
        return {
          hasError: false,
          data: response.data,
        };
      } else {
        return {
          hasError: true,
          data: response.data,
        };
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    getStatusColor,
    getStatusIcon,
    getNonImmigrantStatusApi,
    getUserInfoApi,
    getDocumentsApi,
    deleteDoc,
    getTravelHistoryOfUser,
    getDependentListApi,
  };
};
export default StatusProtectorController;
