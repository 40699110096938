import { StyleSheet } from "react-native";
import { theme } from "../../../../theme";

export const tableHeaderStyles = StyleSheet.create({
    container: {
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
      },
      text: { flex: 1, textAlign: "center", color: theme.colors.btn, fontWeight: "bold", fontFamily: "Brother1816Black" }
})