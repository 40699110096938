import { StyleSheet } from "react-native";

export const filterModalStyles = StyleSheet.create({
    mainHeading: {
        fontSize: 25,
        fontWeight: "bold",
        textAlign: "center",
        marginTop: 5,
      },
      text: {
        fontSize: 15,
        fontWeight: "bold",
        marginTop: 10,
      },
      search: {
        borderColor: "#808080",
        borderWidth: 1,
        marginTop: 10,
        zIndex: 1,
        height: 45
      }
})