import React from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { Client } from "../../../../screen/Organization/types";
import { Strings } from "../../../../../assets/strings";
import { Screens } from "../../../../common/Constant";
import { encryptedData } from "../../../../common/Utils";
import { navigate } from "../../../../navigation/RouteNavigation";

type Props = {
    client: Client;
};

const ClientNameText: React.FC<Props> = ({ client }) => {
    const getClientName = (client: Client) => {
        return client.first_name || client.last_name
          ? `${client.first_name} ${client.last_name}`
          : Strings.N_A;
    };

    return (
        <React.Fragment >
            {client.non_immigrant_status && 
            client.non_immigrant_status?.status.toLocaleLowerCase() !== "processing"
                ? (
                    <Pressable
                        style={styles.text}
                        onPress={() => navigate(Screens.STATUS_PROTECTOR_CLIENT, {
                                data: encryptedData(client)})
                        }
                    >
                        <Text 
                            style={{ color: 'rgb(91, 51, 150)' }} 
                            numberOfLines={1} 
                            ellipsizeMode="tail"
                        >
                            {getClientName(client)}
                        </Text>
                        <Text 
                            style={{ color: "rgb(91, 51, 150)", fontSize: 11 }}
                            numberOfLines={1}
                            ellipsizeMode="tail"
                        >
                            {`[${client.email}]`}
                        </Text>
                    </Pressable>
                )
                : (
                    <View style={{ flex: 1 }}>
                        <Text 
                            style={{ color: 'rgb(91, 51, 150)' }}
                            numberOfLines={1}
                        >
                            {getClientName(client)}
                        </Text>
                        <Text 
                            style={{ color: "rgb(91, 51, 150)", fontSize: 11 }}
                            numberOfLines={1}
                            ellipsizeMode="tail"
                        >
                            {`[${client.email}]`}
                        </Text>
                    </View>
                )
            }
        </React.Fragment>
    );
}

const styles = StyleSheet.create({
    text: {
        flex: 1,
        fontSize: 13,
        color: "rgb(91, 51, 150)",
        textAlign: "left",
        justifyContent: "center",
        marginHorizontal: 1,
    }
});


export default ClientNameText;