import { autoVisasPrivateApi } from "./../axiosInstances/autovisasPrivateApi";
import { autoVisasPublicApi } from "./../axiosInstances/autovisasPublicApi";
import { getError, getSuccess } from "../axiosInstances/handleResponse";
import { Apis } from "../axiosInstances/Apis";
import { CreateDependentProfileRequestBody } from "../../screen/StatusProtector/types/Dependentypes";
import { initialLoginType } from "../../context/AuthContext";

export const createDependentProfile = async (
  userData: initialLoginType,
  dependentId: string,
  payload: CreateDependentProfileRequestBody
) => {
  try {
    const options = {
      method: "POST",
      url: `${Apis.USERS}/${userData.userId}${Apis.DEPENDENTS}/${dependentId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userData.userToken}`,
      },
      data: {
        ...payload,
      },
    };
    const apiResponse = await autoVisasPrivateApi.request(options);
    const { data, status } = getSuccess(apiResponse);
    return {
      status: status,
      data: data,
    };
  } catch (error) {
    return getError(error);
  }
};
