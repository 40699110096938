import { Strings } from "../../../../assets/strings";
import { createCheckoutLink } from "../../../util/createCheckoutLink";
import { getPricingTable } from "../../../util/getPricingtable";

const StripePaymentController = () => {
  const getPlans = async () => {
    const response = await getPricingTable();
    if (response.status === 200) {
      return {
        hasError: false,
        data: response.data,
      };
    } else {
      return {
        hasError: true,
        data: Strings.SOMETHING_WENT_WRONG,
      };
    }
  };

  const getCheckoutLink = async (
    userId: string,
    productId: string,
    priceId: string
  ) => {
    const response = await createCheckoutLink(userId, productId, priceId);
    if (response.status === 200) {
      return {
        hasError: false,
        data: response.data,
      };
    } else {
      return {
        hasError: true,
        data: Strings.SOMETHING_WENT_WRONG,
      };
    }
  };

  return { getPlans, getCheckoutLink };
};

export default StripePaymentController;
