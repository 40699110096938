import { AsyncStorageKeys } from "./../../common/Constant";
import { getLocalStorageValue } from "../../common/AsyncStorage";
import { autoVisasPrivateApi } from "../axiosInstances/autovisasPrivateApi";
import { getError, getSuccess } from "../axiosInstances/handleResponse";

export const getDependentDocList = async (dId: string) => {
  try {
    const userId = await getLocalStorageValue(AsyncStorageKeys.USER_ID);
    const options = {
      method: "GET",
      url: `documents/${userId}/dependent/${dId}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const getDependentListResponse = await autoVisasPrivateApi.request(options);
    return getSuccess(getDependentListResponse);
  } catch (error) {
    console.error(error);
    return getError(error);
  }
};
