import moment from "moment";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { Text } from "react-native-paper";
import { DocumentBody } from "../../../screen/StatusProtector/types/DeshboardTypes";
import { AntDesign } from "@expo/vector-icons";
import { theme } from "../../../theme";
import { formatDate } from "../../../common/DateFormat";
import { Strings } from "../../../../assets/strings";

type Props = {
  data: DocumentBody;
  downloadFile: (uid: string, docId: string) => void;
  deleteDocument: (data: DocumentBody) => void;
};

const TableContent: React.FC<Props> = ({
  data,
  downloadFile,
  deleteDocument,
}) => {
  const admissionOrEffectiveDate = formatDate(data.admit_date, "DD-MM-YY");
  const admitUnit = formatDate(data.admit_until, "DD-MM-YY");

  return (
    <View style={tableRowStyles.tableRow}>
      <View
        style={[
          tableRowStyles.tableCellContainer,
          tableRowStyles.firstTableCell,
        ]}
      >
        <Text style={tableRowStyles.tableCell}>
          {`${data.given_name} ${data.family_name}`}
        </Text>
      </View>
      <View style={tableRowStyles.tableCellContainer}>
        <Text style={tableRowStyles.tableCell}>
          {data.visa_class ? data.visa_class : Strings.N_A}
        </Text>
      </View>
      <View style={[tableRowStyles.tableCellContainer, { flex: 2 }]}>
        <Text style={tableRowStyles.tableCell}>{admissionOrEffectiveDate}</Text>
      </View>
      <View style={tableRowStyles.tableCellContainer}>
        <Text style={tableRowStyles.tableCell}>{admitUnit}</Text>
      </View>
      <View style={tableRowStyles.docContainer}>
        <TouchableOpacity
          onPress={() => downloadFile(data.uid, data.id)}
          style={[tableRowStyles.tableCellContainer]}
        >
          <Text
            style={[tableRowStyles.tableCell, tableRowStyles.link]}
            accessibilityRole="link"
          >
            {data.doc_type}
          </Text>
        </TouchableOpacity>
        <AntDesign
          onPress={() => deleteDocument(data)}
          name="delete"
          size={18}
          color={theme.colors.btn}
        />
      </View>
    </View>
  );
};

const tableRowStyles = StyleSheet.create({
  tableRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    minHeight: 24,
  },
  tableCellContainer: {
    flex: 1,
    alignContent: "center",
    justifyContent: "flex-end",
  },
  firstTableCell: {
    flex: 2,
    alignItems: "flex-start",
  },
  tableCell: {
    fontSize: 14,
    color: "#9797FF",
    textTransform: "uppercase",
    textAlign: "center",
  },
  link: {
    color: "#8FCAFD",
    textDecorationLine: "underline",
  },
  docContainer: {
    flex: 2,
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
});

export default TableContent;
