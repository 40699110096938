import { getOnboardingCSVUsers } from "../../../../util/organization/getOnboardingCSVUsers";
import { getOnboardingUserByJobId } from "../../../../util/organization/getOnboardingUserByJobId";

export const OnboardingController = () => {
  const getCsvUserList = async (orgId: string, token: string) => {
    const res = await getOnboardingCSVUsers(orgId, token);
    return res;
  };

  const getCSVUserByJobId = async (
    orgId: string,
    token: string,
    jobId: string
  ) => {
    const res = await getOnboardingUserByJobId(orgId, token, jobId);
    return res;
  };
  const validateCsvFileType = (file: File) => {
    var filePath = file?.name;
    var allowedExtensions = /(\.csv)$/i;
    if (filePath) {
      if (!allowedExtensions.exec(filePath)) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };
  return { validateCsvFileType, getCsvUserList, getCSVUserByJobId };
};
