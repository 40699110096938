import { useState, useEffect, useContext, useMemo } from "react";
import { View, Text, Pressable } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { theme } from "../../theme";
import FormInput from "../../components/FormInput";
import BgImageContainer from "../../components/BgImageContainer";
import { deleteAccount, updateUserInfo } from "../../util/apiUserInfo";
import moment from "moment";
import { DatePickerInput } from "../../components/datePicker/DatePicker";
import { Strings } from "../../../assets/strings";
import DropDown from "../../components/dropDown/DropDown";
import { reset } from "../../navigation/RouteNavigation";
import { AsyncStorageKeys, Screens } from "../../common/Constant";
import { AuthContext } from "../../context/AuthContext";
import DeleteAccount from "../../components/Dialog/DeleteAccount";
import { useStoreContext } from "../../context/storeContext/StoreProvider";
import { profileStyles } from "./Profile.Styles";
import BorderButton from "../../components/Buttons/BorderButton";
import ProfileController from "./controller/ProfileController";
import { getStorageValue } from "../../common/AsyncStorage";
import { ProgressBar } from "../../components/progressBar/ProgressBar";
import { getCountriesList } from "../../util/countries";
import { formatDate } from "../../common/DateFormat";
import SubscriptionDetails from "../../components/Profile/SubscriptionDetails";
import PauseAccountModal from "../../components/Modal/PauseAccountModal";
import ChangeEmailModal from "../../components/Profile/Modals/ChangeEmailModal";
import SubscriptionInfoModal from "../../components/Profile/SubscriptionInfoModal";
import ErrorText from "../../components/ErrorText";

const DateFormat = "YYYY-MM-DD";
const Profile = () => {
  const { signOut } = useContext(AuthContext);
  const { FAMILY_NAME, GIVEN_NAME, DATE_OF_BIRTH, PASSPORT_ID } =
    Strings.ConfirmInfo;
  const {
    EDIT_YOUR,
    PROFILE,
    EDIT,
    SAVE,
    ENTER_LAST_NAME,
    ENTER_FIRST_NAME,
    ENTER_CITIZEN_SHIP,
    ENTER_PASSPORT_ID,
    ENTER_DOB,
    DELETE_ACCOUNT,
    RESUME,
    PAUSE,
  } = Strings.Profile;
  const [isEdit, setEdit] = useState(false);
  const [userId, setUserId] = useState("");
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoadingStarted, setIsLoadingStarted] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [email, setEmail] = useState("");
  const [subInfoModalVisible, setSubInfoModalVisible] = useState(false);
  const { setProfileData, profileData, setCountriesData, countriesData } =
    useStoreContext();
  const {
    pauseUserAccount,
    resumeUserAccount,
    getBillingPortalLink,
    raiseEmailChangeRequest,
    verifyEmailChangePasscode,
  } = ProfileController();
  const [profileInfo, setProfileInfo] = useState(profileData);

  const formattedDate = profileData?.date_of_birth
    ? moment(
        moment(profileData?.date_of_birth).format(Strings.DATE_FORMAT)
      ).toDate()
    : new Date();
  const [error, setError] = useState(null);
  const getUserId = async () => {
    const userId = await getStorageValue(AsyncStorageKeys.USER_ID);
    setUserId(userId);
  };

  const subscriptionValidDate = useMemo(() => {
    return formatDate(
      profileData.subscription_vaild_till
        ? profileData.subscription_vaild_till
        : Strings.ZERO_UTC_DATE,
      Strings.DATE_FORMAT
    );
  }, [profileData]);

  const onManageSubscriptionClicked = () => {
    profileData.organization_id
      ? setSubInfoModalVisible(true)
      : moveToBillingPortal();
  };

  const moveToBillingPortal = async () => {
    setLoading(true);
    await getBillingPortalLink(profileData.uid).then((res) => {
      if (!res.hasError) {
        setErrorMsg("");
        window.open(res.URL.url);
      } else {
        setErrorMsg(res.message);
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    getUserId();
    if (!countriesData.length) {
      getCountriesList().then((res) => {
        const list = res.data.map((item) => ({
          label: item.name,
          value: item.alpha3Code,
        }));
        setCountriesData(list);
      });
    }
  }, []);

  const btnColors = [
    theme.colors.btnshadow,
    theme.colors.bottom2,
    theme.colors.bottom2,
  ];

  const inputTextColor = {
    colors: {
      text: "#9797FF",
    },
  };

  const updateUserData = (key, value) => {
    setProfileInfo({
      ...profileInfo,
      [key]: value,
    });
  };

  const deleteProfile = () => {
    deleteAccount()
      .then(async (res) => {
        await signOut();
        reset(Screens.LOGIN, null);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateUserProfile = async () => {
    let errorData = null;
    if (!profileData?.last_name.trim()) {
      errorData.last_name = { ENTER_LAST_NAME };
    }
    if (!profileData?.first_name.trim()) {
      errorData.first_name = { ENTER_FIRST_NAME };
    }
    if (!profileData?.citizenship.trim()) {
      errorData.citizenship = { ENTER_CITIZEN_SHIP };
    }
    if (!profileData?.passport_number.trim()) {
      errorData.passport_number = { ENTER_PASSPORT_ID };
    }
    if (moment(profileData?.date_of_birth, DateFormat).isValid() === false) {
      errorData.date_of_birth = { ENTER_DOB };
    }
    setError(errorData);
    if (errorData) {
      return;
    }
    setLoading(true);
    await updateUserInfo(profileInfo, userId)
      .then((res) => {
        if (res.status === 200) {
          setProfileData({ ...profileInfo });
          setEdit(!isEdit);
        }
      })
      .catch((e) => {
        console.log(e);
        setErrorMsg(Strings.SOMETHING_WENT_WRONG);
      });
    setLoading(false);
  };

  const pauseAccount = async (enable: boolean) => {
    setIsLoadingStarted(true);
    const request = enable ? pauseUserAccount : resumeUserAccount;
    await request().then((response) => {
      if (!response.hasError) {
        setProfileData({
          ...profileInfo,
          account_paused: enable,
        });
        setErrorMsg("");
        setModalVisible(false);
      } else {
        setErrorMsg(response.data);
      }
    });
    setIsLoadingStarted(false);
  };

  const sendOtpForEmailEdit = async (email: string) => {
    const response = await raiseEmailChangeRequest(profileData.uid, email);
    return response;
  };

  const verifyEmailChange = async (email: string, passcode: string) => {
    const response = await verifyEmailChangePasscode(
      profileData.uid,
      email,
      passcode
    );
    !response.hasError &&
      setProfileData({
        ...profileData,
        email: email,
      });
    return response;
  };

  return (
    <BgImageContainer>
      <ProgressBar loading={isLoading}>
        <View style={profileStyles.container}>
          <View style={profileStyles.innerContainer}>
            {!profileData.subscription_active &&
            !profileData.organization_id ? (
              <ErrorText
                error={Strings.PLAN_EXPIRED}
                txtStyle={{ textAlign: "center", marginBottom: 30 }}
              />
            ) : null}
            <View style={profileStyles.item}>
              <View style={profileStyles.contain}>
                <Text style={profileStyles.editYour}>{EDIT_YOUR}</Text>
                <Text style={profileStyles.profile}>{PROFILE}</Text>
              </View>
              {!isEdit ? (
                <BorderButton
                  onPress={() => setEdit(!isEdit)}
                  style={[profileStyles.btnEdit, profileStyles.btnSave]}
                  title={EDIT}
                />
              ) : (
                <LinearGradient
                  start={{ x: 0, y: 0.0 }}
                  end={{ x: 0.1, y: 0.0 }}
                  style={profileStyles.btnSave}
                  // Button Linear Gradient
                  colors={btnColors}
                >
                  <Pressable
                    onPress={() => {
                      updateUserProfile();
                    }}
                    style={profileStyles.btn}
                  >
                    <Text style={[profileStyles.btnText]}>{SAVE}</Text>
                  </Pressable>
                </LinearGradient>
              )}
            </View>
            <View style={[profileStyles.item, { zIndex: 2 }]}>
              <View style={profileStyles.contain}>
                <FormInput
                  label={FAMILY_NAME}
                  inputPlaceholder={FAMILY_NAME}
                  inputValue={profileInfo?.last_name}
                  error={error?.last_name}
                  editable={isEdit}
                  setInputValue={(value) => {
                    updateUserData("last_name", value);
                  }}
                  inputWidth="100%"
                  inputStyle={profileStyles.input}
                  theme={inputTextColor}
                  activeUnderlineColor="#9797FF"
                />
              </View>
              <View style={{ flex: 1 }}>
                <DatePickerInput
                  disable={!isEdit}
                  setSelectedDate={(val) =>
                    updateUserData(
                      "date_of_birth",
                      moment(val).format("yyyy-MM-DD")
                    )
                  }
                  selectedDate={formattedDate}
                  customInput={
                    <View style={profileStyles.contain}>
                      <FormInput
                        inputWidth="100%"
                        label={DATE_OF_BIRTH}
                        inputPlaceholder={DATE_OF_BIRTH}
                        inputValue={profileInfo.date_of_birth}
                        editable={isEdit}
                        error={error?.date_of_birth}
                        inputStyle={profileStyles.input}
                        theme={inputTextColor}
                        activeUnderlineColor="#9797FF"
                      />
                    </View>
                  }
                />
              </View>
            </View>
            <View style={profileStyles.item}>
              <View style={profileStyles.contain}>
                <FormInput
                  label={GIVEN_NAME}
                  inputPlaceholder={GIVEN_NAME}
                  inputValue={profileInfo.first_name}
                  inputWidth="100%"
                  editable={isEdit}
                  error={error?.first_name}
                  setInputValue={(value) => {
                    updateUserData("first_name", value);
                  }}
                  inputStyle={profileStyles.input}
                  theme={inputTextColor}
                  activeUnderlineColor="#9797FF"
                />
              </View>

              <View style={[profileStyles.contain]}>
                <FormInput
                  label={PASSPORT_ID}
                  inputPlaceholder={PASSPORT_ID}
                  inputValue={profileInfo.passport_number}
                  inputWidth="100%"
                  editable={isEdit}
                  error={error?.passport_number}
                  setInputValue={(value) => {
                    updateUserData("passport_number", value);
                  }}
                  inputStyle={profileStyles.input}
                  theme={inputTextColor}
                  activeUnderlineColor="#9797FF"
                />
              </View>
            </View>
            <View style={[profileStyles.item, { zIndex: 1, marginBottom: 0 }]}>
              <View style={[profileStyles.contain]}>
                <Text
                  style={[
                    {
                      ...theme.fonts.black,
                      marginBottom: 6,
                      fontSize: 22,
                      color: theme.colors.btn,
                      textTransform: "uppercase",
                    },
                  ]}
                >
                  {Strings.CITIZEN_SHIP}
                </Text>
                <View style={{ maxWidth: 272 }}>
                  <DropDown
                    isProfile={true}
                    disable={!isEdit}
                    receiptTypeOptions={countriesData}
                    onSelection={(value) => {
                      updateUserData("citizenship", value);
                    }}
                    selectedOption={
                      profileInfo.citizenship
                        ? profileInfo.citizenship
                        : countriesData?.[0]?.value
                    }
                  />
                </View>
              </View>
              <View style={[profileStyles.contain, { flexDirection: "row" }]}>
                <FormInput
                  label={Strings.EMAIL}
                  inputPlaceholder={Strings.EMAIL}
                  inputValue={profileData.email}
                  inputWidth="100%"
                  editable={false}
                  error={error?.email}
                  setInputValue={(value) => {
                    updateUserData("email", value);
                  }}
                  inputStyle={profileStyles.input}
                  theme={inputTextColor}
                  activeUnderlineColor="#9797FF"
                />
                <Pressable onPress={() => setEmail(profileData.email)}>
                  <Text
                    style={{
                      color: theme.colors.bottom2,
                      fontSize: 15,
                      marginStart: -150,
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    Edit Email
                  </Text>
                </Pressable>
              </View>
            </View>
            <View style={{ flexDirection: "row" }}>
              <BorderButton
                onPress={() => setIsDialogVisible(true)}
                style={[profileStyles.btnEdit, profileStyles.btnDelete]}
                title={DELETE_ACCOUNT}
              />
              <BorderButton
                onPress={
                  profileData.account_paused
                    ? () => pauseAccount(false)
                    : () => setModalVisible(!modalVisible)
                }
                style={[profileStyles.btnEdit, profileStyles.btnDelete]}
                title={profileData.account_paused ? RESUME : PAUSE}
              />
            </View>
            <DeleteAccount
              isDialogVisible={isDialogVisible}
              setIsDialogVisible={setIsDialogVisible}
              deleteHandler={deleteProfile}
              message={Strings.DELETE_ACCOUNT_WARNING}
            />
            <PauseAccountModal
              visible={modalVisible}
              onDismiss={() => setModalVisible(!modalVisible)}
              onPause={() => pauseAccount(true)}
              isLoading={isLoadingStarted}
            />

            <SubscriptionDetails
              subscriptionValidDate={subscriptionValidDate}
              onManageSubscriptionClicked={onManageSubscriptionClicked}
            />
            {errorMsg ? (
              <Text style={profileStyles.errorText}>{errorMsg}</Text>
            ) : null}
            <ChangeEmailModal
              visible={email ? true : false}
              onDismiss={() => setEmail("")}
              email={email}
              onSendOtpClicked={sendOtpForEmailEdit}
              onVerifyPasscode={verifyEmailChange}
            />
            <SubscriptionInfoModal
              visible={subInfoModalVisible}
              onDismiss={() => setSubInfoModalVisible(false)}
            />
          </View>
        </View>
      </ProgressBar>
    </BgImageContainer>
  );
};

export default Profile;
