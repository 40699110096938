import { Strings } from "../../../../../assets/strings";
import { getClient } from "../../../../util/organization/getClient"
import { getClientFamilyInfo } from "../../../../util/organization/getClientFamilyInfo";
import { getClientNonImmigrantStatus } from "../../../../util/organization/getClientNonImmigrantStatus";
import { getTravelClientList } from "../../../../util/organization/getTravelClientList";
import { runStatusProtector } from "../../../../util/organization/runStatusProtector";

export const SPClientController = () => {
    const getClientInfo = async (clientId: string, orgId: string, token: string) => {
        try {
            const response = await getClient(clientId, orgId, token);
    
            if (response.status === 200) {
                return {
                    hasError: false,
                    data: response.data,
                }
            } else {
                return {
                    hasError: true,
                    data: Strings.SOMETHING_WENT_WRONG,
                }
            }
        } catch (error) {
            return {
                hasError: true,
                data: Strings.SOMETHING_WENT_WRONG,
            }
        }
    };

    const getClientNonImmigrantStatusInfo = async (clientId: string, orgId: string, token: string) => {
        try {
            const response = await getClientNonImmigrantStatus(clientId, orgId, token)

            if (response.status === 200) {
                return {
                    hasError: false,
                    data: response.data
                }
            } else {
                return {
                    hasError: true,
                    data: Strings.SOMETHING_WENT_WRONG,
                }
            }
        } catch (error) {
            return {
                hasError: true,
                data: Strings.SOMETHING_WENT_WRONG,
            }
        }
    };

    const getTravelList = async (clientId: string, orgId: string, token: string) => {
        try {
            const response = await getTravelClientList(orgId, clientId, token);

            if (response.status === 200) {
                return {
                    hasError: false,
                    data: response.data
                }
            } else {
                return {
                    hasError: true,
                    data: Strings.SOMETHING_WENT_WRONG
                }
            }
        } catch (error) {
            return {
                hasError: true,
                data: Strings.SOMETHING_WENT_WRONG,
            }
        }
    };

    const getRunStatusProtector = async (clientId: string, orgId: string, token: string) => {
        try {
            const response = await runStatusProtector(orgId, clientId, token);

            if (response.status === 200) {
                return {
                    hasError: false,
                    data: response.data,
                }
            } else {
                return {
                    hasError: true,
                    data: Strings.SOMETHING_WENT_WRONG,
                }
            }
        } catch (error) {
            return  {
                hasError: true,
                data: Strings.SOMETHING_WENT_WRONG,
            }
        }
    };

    const getClientFamily = async (clientId: string, orgId: string, token: string) => {
        try {
            const response = await getClientFamilyInfo(clientId, orgId, token);

            if (response.status === 200) {
                return {
                    hasError: false,
                    data: response.data,
                }
            } else {
                return {
                    hasError: true,
                    data: Strings.SOMETHING_WENT_WRONG,
                }
            }
        } catch (error) {
            return {
                hasError: true,
                data: Strings.SOMETHING_WENT_WRONG,
            }
        }
    }

    return {
        getClientInfo,
        getClientNonImmigrantStatusInfo,
        getTravelList,
        getRunStatusProtector,
        getClientFamily
    }
}