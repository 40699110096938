import React from "react";
import { Animated, Easing, Pressable, View } from "react-native";
import { Feather } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";
import { actionStyles } from "./Actions.Styles";
import { theme } from "../../../../theme";
import { AntDesign } from "@expo/vector-icons";
import AVTooltip from "../../../Tooltip"

type props = {
  onEdit: () => void;
  onDelete: () => void;
  onDocumentsClicked: () => void;
  onStatusRefreshClicked: () => void;
  duration: number;
  isProfileCompleted: boolean;
  style?: {};
};

const Actions: React.FC<props> = ({
  onDelete,
  onDocumentsClicked,
  onEdit,
  onStatusRefreshClicked,
  isProfileCompleted,
  duration,
  style,
}) => {
  const spinValue = new Animated.Value(0);
  Animated.loop(
    Animated.timing(spinValue, {
      toValue: 7,
      duration: duration,
      easing: Easing.linear,
      useNativeDriver: true,
    })
  ).start();

  const spin = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "360deg"],
  });

  return (
    <View style={[actionStyles.container, style]}>
      <View style={actionStyles.imageStyle}>
        <AVTooltip text="Edit User">
          <Feather
            name="edit"
            size={18}
            color={theme.colors.btn}
            onPress={onEdit}
          />
        </AVTooltip>
      </View>
      <View style={actionStyles.imageStyle}>
        <AVTooltip text="User Documents">

          <Ionicons
            name="document-text-outline"
            size={18}
            onPress={onDocumentsClicked}
            color={theme.colors.btn}
          />

        </AVTooltip>
      </View>
      <View style={actionStyles.imageStyle}>
        <AVTooltip text="Refresh Status">
          <Animated.View style={{ transform: [{ rotate: spin }], flex: 1 }}>
            <Feather
              name="refresh-cw"
              size={18}
              color={isProfileCompleted ? theme.colors.btn : theme.colors.red}
              onPress={isProfileCompleted ? onStatusRefreshClicked : () => { }}
            />
          </Animated.View>
        </AVTooltip>
      </View>
      <View style={actionStyles.imageStyle}>
        <AVTooltip text="Delete User">
          <AntDesign
            onPress={onDelete}
            name="delete"
            size={18}
            color={theme.colors.btn}
          />
        </AVTooltip>
      </View>
    </View>
  );
};

export default React.memo(Actions);
