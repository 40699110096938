import React from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../../assets/strings";
import IconButton from "../../../Buttons/IconButton";
import { adminHeaderStyles } from "./AdminHeader.Styles";

type props = {
  onAddUserClicked: () => void;
};

const AdminHeader: React.FC<props> = ({ onAddUserClicked }) => {
  return (
    <View style={adminHeaderStyles.container}>
      <Text style={adminHeaderStyles.headerText}>
        {Strings.ADMINS.toUpperCase()}
      </Text>
      <IconButton name="Add New Admin" iconName="adduser" onPress={onAddUserClicked} />
    </View>
  );
};

export default React.memo(AdminHeader);
