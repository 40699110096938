import { autoVisasOrgPrivateApi } from "./../axiosInstances/autoVisasOrgPrivateApi";
import { getStorageValue } from "../../common/AsyncStorage";
import { AsyncStorageKeys } from "../../common/Constant";
import { Apis, OrgApis } from "../axiosInstances/Apis";
import { getError, getSuccess } from "../axiosInstances/handleResponse";

export const createOrgCheckoutLink = async (
  org_id: string,
  product_id: string,
  price_id: string
) => {
  const userTokenInStorage = await getStorageValue(
    AsyncStorageKeys.ORG_SESSION_TOKEN
  );
  try {
    const options = {
      method: "POST",
      url: `${OrgApis.CHECK_OUT}/${org_id}${Apis.CHECKOUT}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userTokenInStorage}`,
      },
      data: {
        product_id,
        price_id,
      },
    };

    const apiResponse = await autoVisasOrgPrivateApi.request(options);
    return getSuccess(apiResponse);
  } catch (error) {
    console.error(error.response);
    return getError(error);
  }
};
