import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet, View, ViewStyle } from "react-native";
import { DataTable, Text } from "react-native-paper";
import { theme } from "../../theme";
import { Strings } from "../../../assets/strings";

type Props<T> = {
    headerNames: string[];
    data: T[];
    tableCell: (item: T, index: number) => React.ReactNode;
    alignFirstColumn?: boolean;
    headerStyle?: ViewStyle;
};

const Table = <T extends unknown>({ data, headerNames, tableCell, alignFirstColumn = false, headerStyle}: Props<T>) => {
    const FIRSTCOLUMN: number = 0;
    const LONGSTRINGVALUE = 9;

    return (
        <DataTable style={styles.container}>
            <DataTable.Header style={[styles.header, headerStyle]}>
                {headerNames.map((name: string, index: number) => (
                    <DataTable.Title
                        style={{ paddingVertical: 20 }}
                        textStyle={[
                            styles.headerText,
                            alignFirstColumn 
                            && index === FIRSTCOLUMN
                                ? { textAlign: "left" }
                                : {}
                        ]} 
                        key={index}
                        numberOfLines={name.length >= LONGSTRINGVALUE ? 2 : 1}
                    >
                        {name}
                    </DataTable.Title>
                ))}
            </DataTable.Header>
            <ScrollView>
                {data.length
                    ? data.map((item, index) => (
                        tableCell(item, index)
                    ))
                    : (
                        <Text style={styles.noDataText}>
                            {Strings.NO_DATA_AVAILABLE}
                        </Text>  
                    )
                }
            </ScrollView>
        </DataTable>
    );
}

const styles = StyleSheet.create({
    container: {
        height: 400,
        backgroundColor: "white",
        marginTop: 10,
        borderRadius: 9,
        borderColor: theme.colors.btn,
        borderWidth: 4,
        paddingHorizontal: 20,
        paddingBottom: 20,
    },
    header: {
        alignItems: "center",
        justifyContent: "center",
        borderBottomWidth: 0,
        height: 'auto',
    },
    headerText: {
        width: "100%",
        fontSize: 15,
        fontWeight: "bold",
        color:  theme.colors.btn,
        textAlign: "center",
        textTransform: "uppercase",
        fontFamily:"Brother1816Black",
    },
    noDataText: { 
        textAlign: "center",
        marginTop: 80,
    },  
});

export default Table;