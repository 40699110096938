import { createContext, useMemo, useReducer } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AsyncStorageKeys } from "../../common/Constant";
import { multiSet, setStorageValue } from "../../common/AsyncStorage";
import { addMinutes } from "../../util/addMinutes";
import Cookies from "js-cookie";
import { Strings } from "../../../assets/strings";

export type AuthContextType = {
  signIn?: (email: string, user: any) => Promise<void>;
  signOut?: () => Promise<void>;
  authSignInCompleted?: (email: string, session: string, userType: UserType) => Promise<void>;
  loginState?: initialLoginType;
  loginDispatch?: React.Dispatch<LoginAction>;
};

const defaultValues: AuthContextType = {
  //authInviteEmailId: async (_: string) => {},
  signOut: async () => {},
  signIn: async (email: string, user: any) => {},
  authSignInCompleted: async (email: string, session: string, userType: UserType) => {},
};

const AuthContext = createContext<AuthContextType>(defaultValues);

export type UserType = string | "individual" | "organization" | null;


export enum ActionType {
  RestoreData = "RESTORE_DATA",
  SignIn = "SIGN_IN",
  SignOut = "SIGN_OUT",
  AuthSignInCompleted = "AUTH_SIGN_IN_COMPLETED",
}
export interface initialLoginType {
  isLoading?: boolean;
  email?: string;
  userToken?: string;
  userId?: string;
  isSignInCompleted?: boolean;
  userType?: UserType;
}
export interface LoginAction {
  type: ActionType;
  payload?: initialLoginType;
}

const AuthProvider = (props) => {
  const initialLoginState: initialLoginType = {
    isLoading: true,
    email: "",
    userToken: null,
    //userInviteEmail: null,
    isSignInCompleted: false,
    userType: "",
  };

  const LoginReducer = (prevState: initialLoginType, action: LoginAction) => {
    const { type, payload } = action;
    switch (type) {
      case ActionType.RestoreData:
        return {
          email: payload?.email,
          userToken: payload?.userToken,
          userId: payload?.userId,
          isSignInCompleted: payload?.isSignInCompleted,
          isLoading: payload?.isLoading,
          userType: payload?.userType,
        };

      case ActionType.SignIn:
        return {
          ...prevState,
          email: payload.email,
          userToken: payload.userToken,
          userId: payload?.userId,
          isLoading: false,
        };
      case ActionType.SignOut:
        return {
          ...prevState,
          email: null,
          userToken: null,
          userId: null,
          isLoading: false,
          userType: "",
        };
      case ActionType.AuthSignInCompleted:
        return {
          ...prevState,
          isSignInCompleted: payload.isSignInCompleted,
          email: payload.email,
          userType: payload.userType,
          isLoading: false,
        };
      default:
        return initialLoginState;
    }
  };

  const [loginState, dispatch] = useReducer(LoginReducer, initialLoginState);

  // Function de Sign In, Sign Out, Sign Up
  const authContext = useMemo(
    () => ({
      signIn: async (email, user) => {
        let userToken = null;

        try {
          const userSession = {
            userId: [AsyncStorageKeys.USER_ID, user.userId],
            userSessionToken: [
              AsyncStorageKeys.USER_SESSION_TOKEN,
              user.accessToken,
            ],
            expireAt: [AsyncStorageKeys.EXPIRES_AT, user.expiresAt],
            userSessionRt: [
              AsyncStorageKeys.USER_SESSION_REFRESH_TOKEN,
              user.refreshToken,
            ],
          };

          await AsyncStorage.multiSet([
            userSession.userId,
            userSession.userSessionToken,
            userSession.expireAt,
            userSession.userSessionRt,
          ] as [string, string][]);
          userToken = user.accessToken;
        } catch (e) {
          console.log(e);
        }
        dispatch({
          type: ActionType.SignIn,
          payload: { email, userToken, userId: user.userId },
        });
      },
      signOut: async () => {
        try {
          const keys = [
            AsyncStorageKeys.USER_EMAIL,
            AsyncStorageKeys.USER_ID,
            AsyncStorageKeys.USER_SESSION_TOKEN,
            AsyncStorageKeys.USER_SESSION_REFRESH_TOKEN,
            AsyncStorageKeys.EXPIRES_AT,
            AsyncStorageKeys.USER_TYPE,
          ];
          await AsyncStorage.multiRemove(keys);
        } catch (e) {
          console.log(e);
        }

        dispatch({ type: ActionType.SignOut });
      },
      authSignInCompleted: async (email: string, session: string, userType: UserType) => {
        try {
          await multiSet([
            [AsyncStorageKeys.USER_EMAIL, email],
            [AsyncStorageKeys.SignIn_COMPLETED, "true"],
            [AsyncStorageKeys.USER_TYPE, userType],
          ]);
          const sessionDateExpired = addMinutes(5);
          Cookies.set(AsyncStorageKeys.USER_SESSION, session, {
            expires: sessionDateExpired,
            sameSite: "none",
            secure: true,
          });
          dispatch({
            type: ActionType.AuthSignInCompleted,
            payload: { isSignInCompleted: true, email: email, userType: userType },
          });
        } catch (error) {
          console.error(error);
        }
      },
    }),
    []
  );

  return (
    <AuthContext.Provider
      value={{
        signIn: authContext.signIn,
        signOut: authContext.signOut,
        authSignInCompleted: authContext.authSignInCompleted,
        loginState: loginState,
        loginDispatch: dispatch,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
