import { autoVisasPublicApi } from "../axiosInstances/autovisasPublicApi";

export const sendOTP = async (session_id: string) => {
  try {
    const options = {
      method: "POST",
      url: `/otp/${session_id}/send`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const sendOTPResponse = await autoVisasPublicApi.request(options);

    return {
      status: sendOTPResponse.status,
    };
  } catch (error) {
    console.error(error.response);

    return {
      data: error.response.data,
      status: error.response.status,
    };
  }
};
