import { Font } from "react-native-paper/lib/typescript/src/types";

export const fontConfig = {
  web: {
    regular: {
      fontFamily: "FilsonSoftBook",
      fontWeight: "400",
    } as Font,
    medium: {
      fontFamily: "Roboto_500Medium",
      fontWeight: "500",
    } as Font,
    light: {
      fontFamily: "Roboto_300Light",
      fontWeight: "300",
    } as Font,
    thin: {
      fontFamily: "Roboto_100Thin",
      fontWeight: "100",
    } as Font,
    bold: {
      fontFamily: "Roboto_700Bold",
      fontWeight: "700",
    } as Font,
    black: {
      fontFamily: "Brother1816Black",
      fontWeight: "900",
    } as Font,
  },
  ios: {
    regular: {
      fontFamily: "FilsonSoftBook",
      fontWeight: "400",
    } as Font,
    medium: {
      fontFamily: "Roboto_500Medium",
      fontWeight: "500",
    } as Font,
    light: {
      fontFamily: "Roboto_300Light",
      fontWeight: "300",
    } as Font,
    thin: {
      fontFamily: "Roboto_100Thin",
      fontWeight: "100",
    } as Font,
    bold: {
      fontFamily: "Roboto_700Bold",
      fontWeight: "700",
    } as Font,
    black: {
      fontFamily: "Brother1816Black",
      fontWeight: "900",
    } as Font,
  },
  android: {
    regular: {
      fontFamily: "Roboto_400Regular",
      fontWeight: "400",
    } as Font,
    medium: {
      fontFamily: "Roboto_500Medium",
      fontWeight: "500",
    } as Font,
    light: {
      fontFamily: "Roboto_300Light",
      fontWeight: "300",
    } as Font,
    thin: {
      fontFamily: "Roboto_100Thin",
      fontWeight: "100",
    } as Font,
    bold: {
      fontFamily: "Roboto_700Bold",
      fontWeight: "700",
    } as Font,
    black: {
      fontFamily: "Roboto_900Black",
      fontWeight: "900",
    } as Font,
  },
};
