import { ScrollView, StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../assets/strings";
import { warningsText } from "../../data/warnings";

const Warnings = ({ tags }) => {
  
  if (!tags?.length || !tags) {
    return (
      <View style={warningsStyles.container}>
        <View style={warningsStyles.noWarningsContainer}>
          <Text>{Strings.EMPTY_WARNINGS}</Text>
        </View>
      </View>
    );
  }

  return (
    <View style={warningsStyles.container}>
      <ScrollView contentContainerStyle={warningsStyles.scrollContainer}>
        <View>
          {tags.map((tag: string, index: number) => {
            if (warningsText[tag] !== undefined) {
              return (
                <View
                  key={index}
                  style={[
                    warningsStyles.listContainer,
                    tags.length - 1 === index && warningsStyles.lastBorderStyle,
                  ]}
                >
                  <Text style={warningsStyles.listText}>
                    - {warningsText[tag]}
                  </Text>
                </View>
              );
            }
          })}
        </View>
      </ScrollView>
    </View>
  );
};

const warningsStyles = StyleSheet.create({
  container: {
    width: "100%",
    height: 204,
    backgroundColor: "#F0FFFF",
  },
  noWarningsContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  scrollContainer: {
    padding: 16,
  },
  listContainer: {
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#9797FF",
  },
  lastBorderStyle: {
    borderBottomWidth: 0,
  },
  listText: {
    fontSize: 18,
    color: "#9797FF",
    textAlign: "left",
  },
});

export default Warnings;
