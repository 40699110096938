import { View, StyleSheet } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../assets/strings";

export type Props = {
    admitUntil: string;
    hasPendingAOS: string;
    hasPendingNIV: string;
}

const DateAdmitUntil: React.FC<Props> = ({ admitUntil, hasPendingAOS, hasPendingNIV }: Props) => {
    return (
        <View style={styles.container}>
            <View style={{ flexDirection: "row" }}>
                <Text style={styles.dateAdmitTitle}>
                {Strings.ADMIT_UNTIL}
                </Text>
                <Text style={styles.dateAdmit}>
                {admitUntil ? admitUntil : Strings.UNKNOWN}
                </Text>
            </View>
            {hasPendingAOS && (
                <Text style={styles.dateAdmitTitle}>
                {Strings.HAS_PENDING_AOS}
                </Text>
            )}
            {hasPendingNIV && (
                <Text style={styles.dateAdmitTitle}>
                {Strings.HAS_PENDING_NIV}
                </Text>
            )}
        </View>
    )
};

const styles = StyleSheet.create({
    container: {
        maxWidth: 258,
        justifyContent: "center",
        padding: 12,
        borderTopWidth: 4,
        borderLeftWidth: 4,
        borderRightWidth: 4,
        borderColor: "#BE8DFA",
        borderTopLeftRadius: 22,
        borderTopRightRadius: 22,
        backgroundColor: "#F0FFFF",
        textTransform: "uppercase",
        shadowOffset: {
          width: 7,
          height: 4,
        },
        shadowColor: "#E0CAFF",
        shadowRadius: 2,
        shadowOpacity: 1,
        marginLeft: 31,
    },
    dateAdmit: {
        fontSize: 16,
        color: "#9797FF",
    },
    dateAdmitTitle: {
        fontSize: 16,
        color: "#9797FF",
        marginRight: 9,
    }
})

export default DateAdmitUntil;