import React from "react";
import { Pressable } from "react-native";
import { Text } from "react-native-paper";
import Loading from "../../Loading";
import { stripeSubmitButtonStyles } from "./StripeSubmitButton.Styles";

type props = {
  onSubscribe: () => void;
  isLoading: boolean;
  title: string;
  style?: {};
};

const StripeSubmitButton: React.FC<props> = ({
  onSubscribe,
  isLoading,
  title,
  style,
}) => {
  return (
    <Pressable
      onPress={onSubscribe}
      style={[stripeSubmitButtonStyles.container, style]}
    >
      {isLoading ? (
        <Loading size="small" color="#F0FFFF" />
      ) : (
        <Text style={stripeSubmitButtonStyles.textStyle}>{title}</Text>
      )}
    </Pressable>
  );
};

export default React.memo(StripeSubmitButton);
