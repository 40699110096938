import React from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../assets/strings";
import ClickableText from "../../ClickableText";
import { subscriptionDetailsStyles } from "./SubscriptionDetails.Styles";

type props = {
  subscriptionValidDate: string;
  onManageSubscriptionClicked: () => void;
};

const SubscriptionDetails: React.FC<props> = ({
  subscriptionValidDate,
  onManageSubscriptionClicked,
}) => {
  return (
    <View style={subscriptionDetailsStyles.container}>
      <Text
        style={subscriptionDetailsStyles.validityText}
      >{`${Strings.SUBSCRIPTION_VALID_TILL} ${subscriptionValidDate}`}</Text>
      <ClickableText
        style={subscriptionDetailsStyles.manageText}
        text={Strings.MANAGE_SUBSSCRIPTION}
        onPress={onManageSubscriptionClicked}
      />
    </View>
  );
};

export default React.memo(SubscriptionDetails);
