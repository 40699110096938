import { AcceptOrgInviteRequestBody } from "../../screen/Organization/types";
import { Apis, OrgApis } from "../axiosInstances/Apis";
import { autoVisasOrgPublicApi } from "../axiosInstances/autoVisasOrgPublicApi";
import { getSuccess, getError } from "../axiosInstances/handleResponse";

export const acceptOrgInvite = async (
  invite_id: string,
  data: AcceptOrgInviteRequestBody
) => {
  try {
    const options = {
      method: "POST",
      url: `${OrgApis.ORGANIZATION}${Apis.INVITES}/${invite_id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        ...data,
      },
    };
    const apiResponse = await autoVisasOrgPublicApi.request(options);
    return getSuccess(apiResponse);
  } catch (error) {
    return getError(error);
  }
};

export const getOrgInvite = async (invite_id: string) => {
  try {
    const options = {
      method: "GET",
      url: `${OrgApis.ORGANIZATION}${Apis.INVITES}/${invite_id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const apiResponse = await autoVisasOrgPublicApi.request(options);
    return getSuccess(apiResponse);
  } catch (error) {
    return getError(error);
  }
};
