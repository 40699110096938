import * as React from "react";
import { View, Pressable, Image, StyleSheet } from "react-native";
import { Text } from "react-native-paper";
import Ionicons from '@expo/vector-icons/Ionicons';
import { Images } from "../../../assets/images";
import { theme } from "../../theme";

type Props = {
    document: File;
    deleteFile: () => void;
}

const DocumentFile: React.FC<Props> = ({ document, deleteFile }) => {
    return (
        <View style={documentFileStyles.item}>
            <View style={documentFileStyles.subContainer}>
                <View style={documentFileStyles.closeBtnContainer}>
                    <Pressable
                        onPress={deleteFile}
                    >
                        <Ionicons name="close-circle" size={20} color={theme.colors.btn} />
                    </Pressable>
                </View>
                <Image
                style={documentFileStyles.image}
                resizeMode="contain"
                source={Images.SUCCESS_UPLOAD}
                />
                <Text style={documentFileStyles.fileName} numberOfLines={2}>
                    {document?.name}
                </Text>
            </View>
        </View>
    )
};

const documentFileStyles = StyleSheet.create({
    item: {
        flex: 1,
        width: 90,
        height: 90,
        marginVertical: 10,
        marginEnd: 10,
    },
    subContainer: { 
        width: 90, 
        height: "100%" 
    },
    closeBtnContainer: { 
        position: "absolute", 
        right: 6, 
        top: -9, 
        zIndex: 2, 
    },
    image: {
        width: 90,
        height: 50,
    },
    fileName: {
        color: "black",
        textAlign: "center",
        fontSize: 9,
        marginTop: 7,
        width: 90,
    },
});

export default DocumentFile;