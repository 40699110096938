import { Formik } from "formik";
import React from "react";
import { ScrollView, View, ViewStyle } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../../assets/strings";
import validationSchema from "../../../../data/validationSchema";
import { theme } from "../../../../theme";
import CustomButton from "../../../Buttons/CustomButton";
import ErrorText from "../../../ErrorText";
import FormInput from "../../../FormInput";
import CustomShadowModal from "../../../Modal/CustomShadowModal";
import { changePasswordModalStyles } from "./ChangePasswordModal.Styles";

type props = {
  visible: boolean;
  onDismiss: () => void;
  style: ViewStyle;
  error: string;
  onChange: (currentPass: string, newPass: string) => void;
};

const ChangePasswordModal: React.FC<props> = ({
  visible,
  onDismiss,
  style,
  error,
  onChange,
}) => {
  return (
    <CustomShadowModal visible={visible} onDismiss={onDismiss} style={style}>
      <Text style={changePasswordModalStyles.text}>
        {Strings.CHANGE_PASSWORD}
      </Text>
      <ScrollView showsVerticalScrollIndicator={false}>
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validationSchema={validationSchema.changePassword}
          onSubmit={(values) => {
            onChange(values.password, values.confirmPassword);
          }}
        >
          {({ values, errors, touched, handleSubmit, handleChange }) => (
            <View style={{ width: "100%", marginTop: 20 }}>
              <View>
                <View style={changePasswordModalStyles.inputContainer}>
                  <FormInput
                    label={Strings.CURRENT_PASS}
                    inputPlaceholder={Strings.CURRENT_PASS}
                    inputValue={values.password}
                    setInputValue={handleChange("password")}
                    mode="outlined"
                    inputWidth="100%"
                    error={touched.password && errors.password}
                    labelStyle={changePasswordModalStyles.label}
                    style={changePasswordModalStyles.input}
                    activeOutlineColor="#9797FF"
                    outlineColor={theme.colors.btn}
                    dense
                  />
                  {(touched.password && errors.password) === undefined && (
                    <View
                      style={changePasswordModalStyles.errorContainer}
                    ></View>
                  )}
                </View>
                <View style={changePasswordModalStyles.inputContainer}>
                  <FormInput
                    label={Strings.NEW_PASS}
                    inputPlaceholder={Strings.NEW_PASS}
                    inputValue={values.confirmPassword}
                    setInputValue={handleChange("confirmPassword")}
                    mode="outlined"
                    inputWidth="100%"
                    error={touched.confirmPassword && errors.confirmPassword}
                    labelStyle={changePasswordModalStyles.label}
                    style={changePasswordModalStyles.input}
                    activeOutlineColor="#9797FF"
                    outlineColor={theme.colors.btn}
                    dense
                  />
                  {(touched.confirmPassword && errors.confirmPassword) ===
                    undefined && (
                    <View
                      style={changePasswordModalStyles.errorContainer}
                    ></View>
                  )}
                </View>
              </View>
              <CustomButton
                style={{ marginTop: 10 }}
                onPress={handleSubmit}
                name={Strings.UPDATE}
                isLoading={error === Strings.LOADING_STARTED}
              />
              {error && error === Strings.LOADING_SUCCESS ? (
                <Text
                  style={{ color: "green", marginTop: 5, textAlign: "center" }}
                >
                  {Strings.PASSWORD_CHANGED}
                </Text>
              ) : error !== Strings.LOADING_STARTED ? (
                <ErrorText txtStyle={{ marginTop: 5 }} error={error} />
              ) : null}
            </View>
          )}
        </Formik>
      </ScrollView>
    </CustomShadowModal>
  );
};

export default React.memo(ChangePasswordModal);
