import { AxiosInstance } from "axios";
import { Apis } from "./axiosInstances/Apis";
import { getError, getSuccess } from "./axiosInstances/handleResponse";

export const getCBP = async (
  user_id: string,
  controller: AbortController,
  instance: AxiosInstance,
  api: string
) => {
  try {
    const options = {
      method: "GET",
      url: api,
      headers: {
        "Content-Type": "application/json",
      },
      signal: controller.signal,
    };

    const cbpResponse = await instance.request(options);
    return getSuccess(cbpResponse);
  } catch (error) {
    console.error(error.response);
    return getError(error);
  }
};
