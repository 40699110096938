import { useRef } from 'react';
import { Pressable } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { Text } from 'react-native-paper';
import { buttonStyles } from '../Buttons/Button/Button.Styles';
import { AntDesign } from '@expo/vector-icons';
import Loading from '../Loading';
import { loadingButtonStyles } from './LoadingButton.Styles';

type Props = {
  onPress: () => void;
  isLoadingStart: boolean;
  isSuccess: boolean;
  name: string;
  disabled?: boolean;
  style?: {};
};

const LoadingButton: React.FC<Props> = ({
  onPress,
  name,
  style,
  disabled,
  isLoadingStart = false,
  isSuccess = false,
}) => {
  const ref = useRef(null);

  return (
    <Pressable
      disabled={disabled}
      style={[loadingButtonStyles.button, style]}
      onPress={onPress}
    >
      {isLoadingStart ? (
        <Loading size="small" color="#F0FFFF" />
      ) : isSuccess ? (
        <AntDesign name="check" size={20} color="#F0FFFF" />
      ) : (
        <Text style={loadingButtonStyles.buttonText}>{name}</Text>
      )}
    </Pressable>
  );
};

export default LoadingButton;
