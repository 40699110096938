import React, { useMemo } from "react";
import { Pressable, View } from "react-native";
import { Text } from "react-native-paper";
import { FontAwesome5 } from "@expo/vector-icons";
import { AntDesign } from "@expo/vector-icons";
import { documentListItemStyles } from "./DocumentListItem.Styles";
import { DocumentBody } from "../../../../screen/StatusProtector/types/DeshboardTypes";
import { formatDate } from "../../../../common/DateFormat";
import { Strings } from "../../../../../assets/strings";
import { theme } from "../../../../theme";
import AVTooltip from "../../../Tooltip";

type props = {
  document: DocumentBody;
  onDocDownload: () => void;
  onDocDelete: () => void;
};

const DocumentListItem: React.FC<props> = ({
  document,
  onDocDelete,
  onDocDownload,
}) => {
  const admissionOrEffectiveDate = formatDate(document.admit_date, "DD-MM-YY");
  const admitUntil = formatDate(document.admit_until, "DD-MM-YY");
  const visaClass = useMemo(() => {
    return document.visa_class ? document.visa_class
      : Strings.N_A;
  }, [document]);

  return (
    <View style={{ marginVertical: 5 }}>
      <View style={documentListItemStyles.horizontal} />
      <View style={documentListItemStyles.container}>
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={[documentListItemStyles.text, { textAlign: "left" }]}
        >
          {document.file_name ? document.file_name : Strings.N_A}
        </Text>
        <Text style={documentListItemStyles.text}>{visaClass}</Text>
        <Text style={documentListItemStyles.text}>
          {admissionOrEffectiveDate}
        </Text>
        <Text style={documentListItemStyles.text}>{admitUntil}</Text>
        <Text style={documentListItemStyles.text}>{document.doc_type}</Text>
        <View style={documentListItemStyles.actionContainer}>


          <View style={documentListItemStyles.action}>
            <AVTooltip text="Download File" >
                <FontAwesome5
                  onPress={onDocDownload}
                  name="file-download"
                  size={20}
                  color={theme.colors.btn}
                />
            </AVTooltip>
          </View>
          <View style={documentListItemStyles.action}>
            <AVTooltip text="Delete File">
              <AntDesign
                onPress={onDocDelete}
                name="delete"
                size={20}
                color={theme.colors.btn}
              />
            </AVTooltip>
          </View>
        </View>
      </View>
    </View>
  );
};

export default React.memo(DocumentListItem);
