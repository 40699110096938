import { StyleSheet } from "react-native";
import { theme } from "../../../theme";
const documentModalStyle = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#70707070",
  },
  closeContainer: {
    height: 50,
    width: 300,
    backgroundColor: theme.colors.btn,
    borderRadius: 20,
  },
  closeBtn: {
    marginLeft: 250,
    alignItems: "center",
    height: 50,
    justifyContent: "center",
  },
  dividerContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 30,
  },
  divider: {
    height: 3,
    borderRadius: 2,
    flex: 2,
    backgroundColor: theme.colors.btn,
  },
  shadow2: {
    height: 55,
    width: 310,
    marginLeft: 50,
    backgroundColor: theme.colors.btnshadow,
    borderRadius: 20,
  },
  shadow1: {
    borderTopEndRadius: 30,
    width: 315,
    height: 305,
    backgroundColor: theme.colors.btnshadow,
    borderRadius: 30,
    borderBottomLeftRadius: 50,
  },
  modalView: {
    width: 300,
    height: 320,
    position: "absolute",
    borderRadius: 30,
    borderWidth: 5,
    borderColor: theme.colors.btn,
    backgroundColor: theme.colors.modalColor,
  },
  title: {
    color: theme.colors.btn,
    fontSize: 13,
    flex: 1,
    textAlign: "center",
    fontWeight: "600",
    fontFamily: "FilsonSoftBook",
    textTransform: "uppercase",
  },
  title1: {
    color: theme.colors.btn,
    fontSize: 30,
    marginTop: 50,
    marginHorizontal: 20,
    textTransform: "uppercase",
    textAlign: "center",
    fontFamily: "Brother1816Black",
  },
  actionsContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    height: 35,
    width: 250,
    borderStyle: "solid",
    backgroundColor: theme.colors.btn,
    borderRadius: 15,
    alignItems: "center",
    justifyContent: "center",
  },
  shadow3: {
    height: 38,
    width: 254,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 40,
    borderBottomRightRadius: 40,
    borderBottomLeftRadius: 50,
    borderStyle: "solid",
    backgroundColor: theme.colors.btnshadow,
  },
  btntxt: {
    color: "white",
    fontSize: 14,
    fontFamily: "Brother1816Black",
    textTransform: "uppercase",
  },
});
export default documentModalStyle;
