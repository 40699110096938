import { LinearGradient } from "expo-linear-gradient";
import React, { useRef } from "react";
import { IconButtonStyles } from "./IconButton.Styles";
import { AntDesign } from "@expo/vector-icons";
import AVTooltip from "../../Tooltip"
type props = {
  name: any;
  onPress: () => void;
  style?: {};
  iconName?: any;
};

const IconButton: React.FC<props> = ({ name, onPress, style, iconName }) => {
  const ref = useRef(null);

  return (
    <AVTooltip text={name} pressableStyle={style} onPress={onPress}>     
        <LinearGradient
          ref={ref}
          colors={["#9797FF", "#BE8DFA"]}
          locations={[0.2, 0.7]}
          start={{ x: 0.1, y: 0.5 }}
          end={{ x: 0.5, y: 0.5 }}
          style={IconButtonStyles.buttonGradient}
        >
          <AntDesign name={iconName} size={20} color="white" />
        </LinearGradient>
    </AVTooltip>

  );
};

export default IconButton;
