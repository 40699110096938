import React from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../../assets/strings";
import { tableHeaderStyles } from "./TableHeader.Styles";

type props = {};

const {
  NAME,
  ADMISSION_CLASS,
  ADMISSION_OR_EFFECTIVE_DATE,
  VALID_UNTIL,
  DOCUMENT,
  ACTIONS,
} = Strings.TableHeader;

const TableHeader: React.FC<props> = () => {
  return (
    <View style={tableHeaderStyles.container}>
      <Text style={[tableHeaderStyles.text, { textAlign: "left" }]}>
        {NAME.toUpperCase()}
      </Text>
      <Text style={tableHeaderStyles.text}>
        {ADMISSION_CLASS.toUpperCase()}
      </Text>
      <Text style={tableHeaderStyles.text}>
        {ADMISSION_OR_EFFECTIVE_DATE.toUpperCase()}
      </Text>
      <Text style={tableHeaderStyles.text}>{VALID_UNTIL.toUpperCase()}</Text>
      <Text style={tableHeaderStyles.text}>{DOCUMENT.toUpperCase()}</Text>
      <Text style={tableHeaderStyles.text}>{ACTIONS.toUpperCase()}</Text>
    </View>
  );
};

export default React.memo(TableHeader);
