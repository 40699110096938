import { Text } from "react-native-paper";
import React, { useState } from "react";
import uploadDocumentStyles from "./UploadDocumentsForm.Styles";
import SignUpHOC from "../../hoc/SignUpHOC";
import UploadDocumentsContent from "../../components/UploadDocumentsContent";

type Props = {};

const UploadDocumentsForm: React.FC<Props> = () => {
  const [errorMessage, setErrorMessage] = useState("");
  return (
    <SignUpHOC pageNumber={2} onlyLogoutOptionEnabled={true}>
      {errorMessage !== "" && (
        <Text style={uploadDocumentStyles.errorText}>{errorMessage}</Text>
      )}
      <UploadDocumentsContent setErrorMessage={setErrorMessage} />
    </SignUpHOC>
  );
};

export default UploadDocumentsForm;
