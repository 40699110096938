import { StyleSheet } from "react-native";

export const travelRowStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    paddingHorizontal: 20,
    marginVertical: 5,
  },

  textStyle: {
    flex: 1,
    fontSize: 16,
    color: "#9797FF",
  },
});
