import { StyleSheet } from "react-native";
import { theme } from "../../../../theme";

export const headerStyles = StyleSheet.create({
    headingStyle: {
        fontSize: 34,
        flex: 1,
        fontFamily: "Brother1816Black",
        color: theme.colors.btn
    },
    container :{
        flexDirection: "row",
        width: "100%",      },

})