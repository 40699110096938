import { getLatestDocument } from "./getDocument";

export const checkUploadedDocuments = (
  userId: string,
  callback: (checkDocuments) => void
) => {
  getLatestDocument(userId)
    .then((document) => {
      if (document.data) {
        const data = document.data;
        callback({
          first_name: data?.first_name,
          last_name: data?.family_name,
          date_of_birth: data?.birth_date,
          passport_number: data?.passport_number,
          citizenship: data?.passport_country,
          admission_class: data?.admission_class,
          stripe_setup_completed: true,
        });
      } else {
        callback(null);
      }
    })
    .catch((err) => console.error("Error: ", err));
};
