import { multiGet } from "../common/AsyncStorage";
import { userInfo } from "../data/userInfo";
import { Apis } from "./axiosInstances/Apis";
import { autoVisasPrivateApi } from "./axiosInstances/autovisasPrivateApi";
import { getError, getSuccess } from "./axiosInstances/handleResponse";

export const pauseAccount = async () => {
    try {
      // Get the user info in localStorage
      const userSession = await multiGet(["userId"]);
      const options = {
        method: "POST",
        url: `${Apis.USERS}/${userSession[userInfo.id.position][userInfo.id.value]}${Apis.PAUSE}`,
        headers: {
          "Content-Type": "application/json",
        }      
    };
      const updateUserInfoResponse = await autoVisasPrivateApi.request(options);
      return getSuccess(updateUserInfoResponse);
    } catch (error) {
      console.error(error);
      return getError(error)
    }
  };