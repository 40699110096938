import React from "react";
import { StyleSheet, View } from "react-native";

type Props = {
    children: React.ReactNode;
}

const StatusProtectorBody: React.FC<Props> = ({ children }: Props) => {
    return (
        <View style={styles.container}>
            {children}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        borderLeftWidth: 4,
        borderBottomWidth: 4,
        borderRightWidth: 4,
        borderColor: "#BE8DFA",
        borderBottomLeftRadius: 22,
        borderBottomRightRadius: 22,
        marginHorizontal: 31,
        shadowOffset: {
        width: 7,
        height: 4,
        },
        shadowColor: "#E0CAFF",
        shadowRadius: 2,
    }
});

export default StatusProtectorBody;