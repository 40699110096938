import { Pressable, View } from "react-native";
/* Components */
import { LinearGradient } from "expo-linear-gradient";
import { Text } from "react-native-paper";
import React, { useState } from "react";
import { Strings } from "../../../../assets/strings";
import { addDependentButtonStyles } from "./AddDependentButton.Styles";
type Props = {
  disabled?: boolean;
  onPress: () => void;
};
const AddDependentButton: React.FC<Props> = ({ disabled, onPress }) => {
  return (
    <View style={addDependentButtonStyles.container}>
      <View style={addDependentButtonStyles.buttonContainer}>
        <Pressable
          style={addDependentButtonStyles.button}
          disabled={disabled}
          onPress={onPress}
        >
          <LinearGradient
            colors={disabled ? ["#AEAEAE", "#C5C5C5"] : ["#9797FF", "#BE8DFA"]}
            locations={[0.1, 1]}
            start={{ x: 0.4, y: 0.5 }}
            style={addDependentButtonStyles.gradientButton}
          >
            <Text style={addDependentButtonStyles.textButton}>
              {Strings.ADD_DEPENDENT}
            </Text>
          </LinearGradient>
        </Pressable>
      </View>
    </View>
  );
};

export default React.memo(AddDependentButton);
