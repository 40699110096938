import React from "react";
import { View } from "react-native";
import { Strings } from "../../../../assets/strings";
import ColumnItem from "../ColumnItem";
import { headerStyles } from "./HeaderStyles";

type props = {
  isOrg: boolean;
};

const Header: React.FC<props> = ({ isOrg }: props) => {
  const FIRST_POSITION = 0;
  const LAST_POSITION = isOrg 
    ? Strings.DEPENDENT_HEADERS.ORGANIZATION.length - 1 
    : Strings.DEPENDENT_HEADERS.INDIVIDUAL.length - 1;
  return (
    <View style={headerStyles.container}>
      {isOrg 
        ? Strings.DEPENDENT_HEADERS.ORGANIZATION.map((title, index) => (
          <ColumnItem
            key={`${index}-organization`}
            title={title}
            isHeading={true}
            style={{
              flex: index === FIRST_POSITION ? 1.5 : 1,
              alignItems: index === LAST_POSITION ? "center" : "flex-start",
            }}
          />
        ))
        : Strings.DEPENDENT_HEADERS.INDIVIDUAL.map((title, index) => (
          <ColumnItem
            key={`${index}-individual`}
            title={title}
            isHeading={true}
            style={{
              flex: index === FIRST_POSITION ? 1.5 : 1,
              alignItems: index === LAST_POSITION ? "center" : "flex-start",
            }}
          />
        ))
      }
    </View>
  );
};

export default React.memo(Header);
