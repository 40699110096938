import { Image, StyleSheet } from "react-native";
import { Images } from "../../../../assets/images";

const Logo = () => {
    return (
        <Image
            source={Images.LOGO}
            style={styles.logo}
            resizeMode={"contain"}
        />
    );
};

const styles = StyleSheet.create({
    logo: {
        height: 80,
        maxWidth: 350,
        marginBottom: 20,
        marginLeft: 5,
    }
})

export default Logo;