export const Images = {
  LOGO: require("./status_logo.png"),
  SUCCESS_UPLOAD: require("./successful_upload.svg"),
  MAIN_LOGO: require("./logo2.png"),
  SPHERE: require("./sphere.png"),
  AUTOVISA_LOGO: require("./logo.svg"),
  CLOUD: require("./Clouds.svg"),
  STAT: require("./Stat.svg"),
  SETTINGS: require("./menu_settings.svg"),
  ORGANIZATION: require("./organization.png"),
  LOG_OUT: require("./menu_logout.svg"),
  VERTICAL_MENU: require("./vertical_menu.png"),
  USER: require("./user.png"),
  LADINGPAGE1: require("./StatusProtectorMainPage.svg"),
  TRAVEL: require("./Travel.svg"),
  CONSEQUENCESIMAGE: require("./ConsequencesImage.svg"),
  MONITOR: require("./Monitor.svg"),
  DETECT: require("./Eye.svg"),
  NOTIFY: require("./Notification.svg"),
  PROTECT: require("./Protect.svg"),
  ORGANIZE: require("./Frame.svg"),
  ALERT: require("./Alert.svg"),
  PEOPLE: require("./people.png"),
  ADMIN: require("./admin.png"),
  ONBOARDING: require("./onboarding.png"),
};
