import moment from "moment";
import React from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../assets/strings";
import { TravelHistoryData } from "../../../screen/StatusProtector/types/DeshboardTypes";
import { travelRowStyles } from "./TravelRowData.Styles";

type props = {
  travelData: TravelHistoryData;
};

const TravelRowData: React.FC<props> = ({ travelData }) => {
  const date = moment(travelData.date).utc().format("DD-MM-YY");
  return (
    <View style={travelRowStyles.container}>
      <Text style={travelRowStyles.textStyle}>{date}</Text>
      <Text style={[travelRowStyles.textStyle, { textAlign: "center" }]}>
        {travelData.is_arrival ? Strings.ARRIVAL : Strings.DEPARTURE}
      </Text>
      <Text style={[travelRowStyles.textStyle, { textAlign: "center" }]}>
        {travelData.location}
      </Text>
    </View>
  );
};

export default React.memo(TravelRowData);
