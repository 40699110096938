import { StyleSheet } from "react-native";

export const travelHistoryHeaderStyles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        paddingHorizontal: 20,
        paddingVertical: 10
      },

      textStyle: {
        flex: 1,
        fontSize: 16,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: '#9797FF',
      }
})