import { Strings } from "../../../../../assets/strings";
import { multiGet } from "../../../../common/AsyncStorage";
import { useOrgAuthContext } from "../../../../context/OrganizationAuthContext";
import { createOrganizationRequest } from "../../../../util/organization/createOrganization";
import { OrganizationRequestBody } from "../../types";

const CreateOrganizationController = () => {
  const { orgAuthState } = useOrgAuthContext();

  const createOrganization = async (requestBody: OrganizationRequestBody) => {
    try {
      if (requestBody === null || requestBody === undefined) {
        return {
          hasError: true,
          data: Strings.SOMETHING_WENT_WRONG,
        };
      } else {
        const response = await createOrganizationRequest(
          orgAuthState.orgUserToken,
          orgAuthState.orgId,
          requestBody
        );
        if (response.status === 200) {
          return {
            hasError: false,
            data: response.data,
          };
        } else if (response.status === 409) {
          return {
            hasError: true,
            data: Strings.ALREADY_ASSOCIATED,
          };
        } else if (response.status === 400) {
          return {
            hasError: true,
            data: Strings.BAD_REQUEST,
          };
        } else if (response.status === 401) {
          return {
            hasError: true,
            data: Strings.UNAUTHORIZED,
          };
        } else {
          return {
            hasError: true,
            data: Strings.SOMETHING_WENT_WRONG,
          };
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return { createOrganization };
};

export default CreateOrganizationController;
