import { initialLoginType } from "./../../context/AuthContext/index";
import { autoVisasPrivateApi } from "../axiosInstances/autovisasPrivateApi";

export const uploadDependentDocument = async (
  dependentId,
  documentData,
  userData: initialLoginType
) => {
  try {
    const data = new FormData();
    data.append("file", documentData, documentData.name);
    const options = {
      method: "POST",
      url: `/documents/${userData.userId}/dependent/${dependentId}/parse`,
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=---011000010111000001101001",
        Authorization: `Bearer ${userData.userToken}`,
      },
      data: data,
    };
    const uploadDocumentResponse = await autoVisasPrivateApi.request(options);
    return {
      userInfoData: uploadDocumentResponse.data[0],
      status: uploadDocumentResponse.status,
    };
  } catch (error) {
    console.log(error);

    return error;
  }
};
