import { updateAdminProfile } from "./../../../../util/organization/updateAdminProfile";
import { Strings } from "../../../../../assets/strings";
import { useOrgStoreContext } from "../../../../context/orgStoreContext/StoreProvider";
import { useOrgAuthContext } from "../../../../context/OrganizationAuthContext";
const EditAdminScreenController = () => {
  const { organizationData } = useOrgStoreContext();
  const { orgAuthState } = useOrgAuthContext();
  const editAdmin = async (first_name: string, last_name: string, user_id) => {
    try {
      const response = await updateAdminProfile(
        orgAuthState.orgUserToken,
        organizationData.organization_id,
        user_id,
        first_name,
        last_name
      );

      if (response.status === 200) {
        return {
          hasError: false,
          data: response.data,
        };
      } else {
        return {
          hasError: true,
          message: response.data.message,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        hasError: true,
        message: Strings.SOMETHING_WENT_WRONG,
      };
    }
  };

  return { editAdmin };
};

export default EditAdminScreenController;
