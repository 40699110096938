import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const customModalStyles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#70707070",
  },
  modalView: {
    minHeight: 196,
    borderRadius: 30,
    borderWidth: 3,
    padding: 20,
    borderColor: theme.colors.btn,
    backgroundColor: theme.colors.modalColor,
  },
});
