import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const pauseAccountModalStyles = StyleSheet.create({
    heading: {
        fontSize: 20,
        color: "red",
        fontWeight: "bold",
        textAlign: "center",
      },
      contentStyle: {
        fontSize: 15,
        marginVertical: 10,
        color: theme.colors.btn,
      },
      btnConatiner: { flexDirection: "row", justifyContent: "space-between" },

})