import moment from "moment";
import { Strings } from "../../assets/strings";
import { inputDateFormat } from "./Constant";

export const formatDate = (date: string, format: string) => {
  const formatDate = moment(date, inputDateFormat);

  return date !== Strings.ZERO_DATE
    ? formatDate.year() === 9999
      ? "D/S"
      : date === Strings.ZERO_UTC_DATE 
        ? Strings.N_A 
        : formatDate.format(format)
    : Strings.UNKNOWN;
};
