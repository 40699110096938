import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { theme } from "../../../theme";

type Props = {
    children: React.ReactNode;
    style?: StyleProp<ViewStyle>
}

export const TableRow: React.FC<Props> = ({ children, style, ...rest }: Props) => {
    return (
        <View {...rest}>
            <View style={styles.horizontalLine}></View>
            <View style={[styles.container, style]}>
                {children}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "row",
        minHeight: 48,
        alignItems: "center",
        paddingHorizontal: 16,
    },
    horizontalLine: {
        height: 1,
        backgroundColor: theme.colors.btn,
        width: '100%',
    }
});