import { Strings } from "../../../../assets/strings";
import { getDocument } from "../../../util/getDocument";

const documentsController = () => {
  const getDocuments = async () => {
    const documentsResponse = await getDocument();

    if (documentsResponse?.status === 200) {
      return { hasError: false, data: documentsResponse.data };
    } else {
      return { hasError: true, message: Strings.DOCUMENT_ERROR };
    }
  };

  const findValidDocuments = (documents) => {
    const newDataWithDocuments = documents.filter(
      (document) =>
        document.doc_type !== Strings.UNKNOWN_TYPE_FILE &&
        document.doc_type !== Strings.EMPTY_TYPE_FILE
    );

    return newDataWithDocuments;
  };

  return { getDocuments, findValidDocuments };
};

export default documentsController;
