import { StyleSheet } from "react-native";

export const dependentStyles = StyleSheet.create({
  container: { flex: 1, backgroundColor: "#F0FFFF", height: 204 },
  tableContainer: {
    flex: 1,
    marginBottom: 20,
  },
  errorText: {
    color: "red",
    marginVertical: 10,
    fontWeight: "bold",
  },
});
