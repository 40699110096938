import { View } from "react-native";
import { Text } from "react-native-paper";
import { Entypo } from "@expo/vector-icons";
import React from "react";
import { headerStyles } from "./Header.Styles";
import { theme } from "../../../theme";

type props = {
  title: string;
  onDismiss: () => void;
};

const Header: React.FC<props> = ({ title, onDismiss }) => {
  return (
    <View style={headerStyles.container}>
      <Text style={headerStyles.textStyle}>{title}</Text>
      <Entypo
        onPress={onDismiss}
        name="cross"
        size={30}
        color={theme.colors.btnshadow}
      />
    </View>
  );
};

export default React.memo(Header);
