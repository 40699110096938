import { Strings } from "../../../../../assets/strings"
import { acceptOrgInvite, getOrgInvite } from "../../../../util/organization/organizationInvitation"
import { AcceptOrgInviteRequestBody } from "../../types"

const OrganizationInviteScreenController = () => {
    const getInvite = async (invite_id: string) => {
        try {
            const response = await getOrgInvite(invite_id)            
            if (response.status === 200) {
                return {
                    hasError: false,
                    data: response.data
                }
            } else {
                return {
                    hasError: true,
                    message: response.data.message
                }
            }
        } catch(error) {
            return {
                hasError: true,
                message: Strings.SOMETHING_WENT_WRONG
            }
        }
    }

    const postInvite = async (invite_id: string, data: AcceptOrgInviteRequestBody) => {
        try {
            const response = await acceptOrgInvite(invite_id, data)            
            if (response.status === 200) {
                return {
                    hasError: false,
                    data: response.data
                }
            } else {
                return {
                    hasError: true,
                    message: response.data.message
                }
            }
        } catch(error) {
            return {
                hasError: true,
                message: Strings.SOMETHING_WENT_WRONG
            }
        }
    }

    return { getInvite, postInvite }
}

export default OrganizationInviteScreenController