import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const uploadDocModalStyles = StyleSheet.create({
    contentText: {
        flex: 1,
        marginTop: 20,
        color: "#737373",
        fontSize: 17,
      },
      btn: {
        flex: 2,
        justifyContent: "flex-start",
        marginBottom: 20
      },
      errorText: {
        fontSize: 16,
        color: theme.colors.red,
      },
})