import React from "react";
import { useState } from "react";
import { View } from "react-native";
import { Strings } from "../../../assets/strings";
import { DependentData } from "../../screen/StatusProtector/types/Dependentypes";
import UploadDocumentsController from "../../screen/UploadDocuments/controller/UploadDocumentsController";
import DocumentText from "../DocumentText";
import BrowseFile from "../UploadModal/BrowseFile";
import DocumentModal from "../UploadModal/DocumentModal";

type props = {
  setErrorMessage: (msg: string) => void;
  isUploadDocForDependent?: boolean;
  onContinue?: (data?: DependentData) => void;
  style?: {};
  dId?: string;
  clientId?: string;
};

const UploadDocumentsContent: React.FC<props> = ({
  setErrorMessage,
  onContinue,
  style,
  dId,
  clientId,
}) => {
  const [visible, setVisible] = useState(false);
  const [showFiles, setShowFiles] = useState<File[]>([]);
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);
  const { validateFileType } = UploadDocumentsController();

  const validateFile = (files: File[]) => {
    if (validateFileType(files)) {
      setErrorMessage("");
      setShowFiles([...showFiles, ...files]);
    } else {
      hideModal();
      setErrorMessage(Strings.UNSUPPORTED_FILETYPE);
    }
  };
  return (
    <View style={style}>
      <DocumentText
        onUploadDoc={showModal}
        onContinue={onContinue}
        setErrorMessage={setErrorMessage}
        files={showFiles}
        dId={dId}
        clientId={clientId}
      />
      {!showFiles?.length ? (
        <DocumentModal
          visible={visible}
          onDrop={(files) => validateFile(files)}
          onDismiss={hideModal}
        />
      ) : (
        <BrowseFile
          visible={visible}
          onDismiss={() => {
            hideModal();
          }}
          onDrop={(files) => validateFile(files)}
          onPress={hideModal}
          itemList={showFiles}
          onUpload={() => {
            hideModal();
          }}
          setItemList={setShowFiles}
        />
      )}
    </View>
  );
};

export default React.memo(UploadDocumentsContent);
