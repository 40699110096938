import moment from "moment";
import { ScrollView, StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { DocumentBody } from "../../screen/StatusProtector/types/DeshboardTypes";
import { downloadDocument } from "../../util/getDocument";
/* Components */
import TableContent from "../Table/TableContent";
import TableHeader from "../Table/TableHeader";
import { downloadClientDocument } from "../../util/organization/clients";
import { useOrgAuthContext } from "../../context/OrganizationAuthContext";

type Props = {
  documents: DocumentBody[];
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  deleteDocument: (document: DocumentBody) => void;
  clientId?: string;
};

const Documents: React.FC<Props> = ({
  documents,
  isLoading,
  setIsLoading,
  deleteDocument,
  clientId,
}) => {
  const { orgAuthState } = useOrgAuthContext();
  const downloadFile = async (uid: string, docId: string) => {
    setIsLoading(true);
    const { url, fileName } = clientId
      ? await downloadClientDocument(orgAuthState.orgId, orgAuthState.orgUserToken, clientId, docId)
      : await downloadDocument(uid, docId);
    const element = document.createElement("a");
    element.href = url;
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    URL.revokeObjectURL(url);
    element.remove();
    setIsLoading(false);
  };
  return (
    <View style={documentsStyles.container}>
      <View style={documentsStyles.tableContainer}>
        {documents.length ? (
          <>
            <TableHeader />
            <ScrollView contentContainerStyle={documentsStyles.tableRowScroll}>
              {documents.map((document) => (
                <TableContent
                  downloadFile={downloadFile}
                  key={document.id}
                  data={document}
                  deleteDocument={deleteDocument}
                />
              ))}
            </ScrollView>
          </>
        ) : (
          <View style={documentsStyles.containerNotDocuments}>
            {!isLoading ? (
              <Text style={documentsStyles.notFoundDocumentsText}>
                Documents empty
              </Text>
            ) : null}
          </View>
        )}
      </View>
    </View>
  );
};

const documentsStyles = StyleSheet.create({
  containerLoading: {
    height: 204,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F0FFFF",
  },
  container: {
    flex: 1,
    backgroundColor: "#F0FFFF",
  },
  documentBody: {
    height: 204,
    backgroundColor: "#F0FFFF",
  },
  notFoundPage: {
    justifyContent: "center",
    alignItems: "center",
  },
  notFoundText: {
    fontFamily: "Roboto_500Medium",
    fontSize: 16,
    color: "#9797FF",
  },
  tableContainer: {
    flex: 1,
    marginBottom: 34.6,
  },
  tableRowScroll: {
    height: 96,
  },
  containerNotDocuments: {
    height: 169.4,
    alignItems: "center",
    justifyContent: "center",
  },
  notFoundDocumentsText: {
    fontFamily: "FilsonSoftBook",
    fontSize: 16,
    color: "#9797FF",
  },
});

export default Documents;
