import React from "react";
import { StyleProp, StyleSheet, ViewStyle, View, TextStyle } from "react-native";
import { DataTable, Text } from "react-native-paper";

type Props = React.ComponentPropsWithRef<typeof View> & {
    children: React.ReactNode;
    textStyles?: TextStyle;
}

const TableCell: React.FC<Props> = ({ children, textStyles, ...rest }: Props) => {
    return (
        <Text style={[styles.text, textStyles]} numberOfLines={1}>
            {children}
        </Text>
    );
}

const styles = StyleSheet.create({
    text: {
        flex: 1,
        fontSize: 13,
        color: "rgb(91, 51, 150)",
        textAlign: "center",
        justifyContent: "center",
        marginHorizontal: 1,
    }
});

export default TableCell;