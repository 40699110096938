import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const editAdminScreenStyles = StyleSheet.create({
  inputsContainer: {
    flexDirection: "row",
    zIndex: 2,
    width: "100%",
  },
  inputContainer: {
    flex: 1,
    margin: 5,
  },
  label: {
    fontSize: 16,
    color: "#9797FF",
    textTransform: "uppercase",
  },

  input: { backgroundColor: "#F0FFFF" },
  errorContainer: {
    height: 22.5,
  },
  errorText: {
    marginTop: 20,
  },
  innerContainer: {
    paddingTop: 20,
    width: 500,
    alignSelf: "center",
  },
  heading: {
    color: theme.colors.btn,
    fontSize: 30,
    fontWeight: "bold",
  },
});
