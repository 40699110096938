import { View, Image } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../assets/strings";
import { Images } from "../../../assets/images";
import BgImageContainer from "../../components/BgImageContainer";
import SubmitButton from "../../components/Buttons/Submit";
import { styles } from "./HomeStyles";
import Pricing from "../../components/Cards/Pricing";
import { useNavigation } from "@react-navigation/native";
import { Screens } from "../../common/Constant";
import { Video, ResizeMode } from "expo-av";
import { Videos } from "../../../assets/videos";

const Home = () => {
    const { 
        MAIN_TITLE, 
        MAIN_DESCRIPTION, 
        BUTTON_NAME, 
        CONTEXT1, 
        CONTEXT2,
        CONSEQUENCES_TITLE,
        CONSEQUENCES_CONTENT,
        WHAT_STATUS_PROTECTOR_DOES_TITLE,
        WHAT_STATUS_PROTECTOR_DOES_CONTENT,
        PRICING_TITLE,
        PRINCING_SUBTITLE,
        PRICING,
        HOME_FOOTER
    } = Strings.LandingPage;
    const navigation = useNavigation();

    return (
        <BgImageContainer footer={HOME_FOOTER} isHome={true}>
            <View style={styles.container}>
                {
                    // Section #1
                }
                <View style={[styles.sectionOne]}>
                    <View style={styles.sectionOne_descriptionContainer}>
                        <Text style={styles.heroTitle}>{MAIN_TITLE}</Text>
                        <Text style={[styles.text, { marginVertical: 32 }]}>{MAIN_DESCRIPTION}</Text>
                        <SubmitButton name={BUTTON_NAME} onPress={() => navigation.navigate(Screens.CREATE_ACCOUNT)} />
                    </View>
                    <Image source={Images.LADINGPAGE1} resizeMode="contain" style={styles.sectionOne_image} />
                </View>
                {
                    // Section #2
                }
                <View style={[styles.sectionTwo]}>
                    <View style={[styles.minSectionContainer, styles.subSectionTwo]}>
                        <View style={styles.sectionTwo_descriptionContainer}>
                            <Text style={[styles.text, { marginBottom: 32, }]}>{ CONTEXT1 }</Text>
                            <Text style={styles.text}>{ CONTEXT2 }</Text>
                        </View>
                        <Image source={Images.TRAVEL} resizeMode="contain" style={styles.sectionTwo_image} />
                    </View>
                </View>

                {
                    // Section #3
                }
                <View style={styles.sectionThree}>
                    <Text style={styles.title}>
                        {CONSEQUENCES_TITLE}
                    </Text>
                    <View style={styles.subSectionThree}>
                        <View style={styles.sectionThree_descriptionContainer}>
                            {
                                CONSEQUENCES_CONTENT.map((text) => (
                                    <View key={text.id}  style={{ flexDirection: "row" }}>
                                        <View style={[styles.point, { marginTop: 9.5, marginRight: 12, backgroundColor: "#212529", }]}></View>
                                        <Text style={[styles.text, { marginBottom: 16 }]}>
                                            { text.description }
                                        </Text>
                                    </View>
                                ))
                            }
                            <SubmitButton 
                                name={BUTTON_NAME} 
                                onPress={() => navigation.navigate(Screens.CREATE_ACCOUNT)} 
                                style={styles.sectionThree_button} 
                            />
                        </View>
                        <Image source={Images.CONSEQUENCESIMAGE} resizeMode="contain" style={styles.sectionThree_image} />
                    </View>
                </View>

                {
                    // Section #4
                }
                <View style={styles.sectionFour}>
                    <Text style={styles.title}>
                        {WHAT_STATUS_PROTECTOR_DOES_TITLE}
                    </Text>
                    <View style={styles.subSectionFour}>
                        {
                            WHAT_STATUS_PROTECTOR_DOES_CONTENT.map((card) => (
                                <View key={card.id} style={styles.sectionFour_cardContainer}>
                                    <View style={[styles.sectionFour_imageContainer, { backgroundColor: card.backgroundImageColor, }]}>
                                        <Image source={card.image} resizeMode="contain" style={styles.sectionFour_image} />
                                    </View>
                                    <Text style={styles.sectionFour_title}>{card.title}</Text>
                                    <Text style={styles.text}>{card.description}</Text>
                                </View>
                            ))
                        }
                    </View>
                </View>
                {
                    // Video section
                }            
                <View style={styles.videoSection}>
                    <Video
                        resizeMode={ResizeMode.STRETCH}
                        style={styles.video}
                        source={{ 
                            uri: Videos.AUTOVISAS_VIDEO
                        }}
                        useNativeControls
                    />
                </View>

                {
                    // Section #5
                }
                <View style={styles.sectionFive}>
                    <Text style={styles.title}>
                        {PRICING_TITLE}
                    </Text>
                    <View style={styles.sectionFive_cardsContainer}>
                        {
                            PRICING.map((card) => (
                                <Pricing
                                    key={card.id}
                                    title={card.title}
                                    subTitle={PRINCING_SUBTITLE}
                                    content={card.content}
                                    navigateTo={card.navigateTo}
                                    btnName={card.btnName}
                                />
                            ))
                        }
                    </View>
                </View>
            </View>
        </BgImageContainer>
    );
}

export default Home;