export type OrganizationRequestBody = {
  name: string;
  address: Address;
  contact: Contact;
};

export type Address = {
  line_1: string;
  line_2: string;
  city: string;
  state: string;
  zip: string;
};

export type Contact = {
  phone: string;
  email: string;
};

export type OrganizationMetaData = {
  oid: string;
  name: string;
  address: Address;
  email: Contact;
};

export type OrgMetaDataRow = {
  [key: string]: string;
};
export type OrganizationUser = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  organization_id: string;
  role: string;
};
export type OnboardingCSVUserType = {
  job_id: string;
  job_name: string;
  created_at: string;
  total_rows: number;
  success_count: number;
  error_count: number;
};

export type AddNewManagementUserRequestBody = {
  email: string;
  is_admin: boolean;
};

export type DeleteManagementUserRequestBody = {
  uid: string;
  is_admin: boolean;
};

export type UserPreference = {
  email_status_alert_active: boolean;
  phone_status_alert_active: boolean;
  email_alert_days: Number[];
  phone_alert_days: Number[];
};

export type Client = {
  uid: string;
  email: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  passport_number: string;
  citizenship: string;
  email_secondary: string;
  phone: string;
  phone_secondary: string;
  preference: UserPreference;
  account_paused: boolean;
  profile_completed: boolean;
  relationship: string;
  organization_id: string;
  employer_name: string;
  stripe_setup_completed: boolean;
  subscription_active: boolean;
  subscription_vaild_till: string;
  non_immigrant_status: NonImmigrantStatusType;
};

export type ClientType = {
  uid: string;
  email: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  passport_number: string;
  citizenship: string;
  email_secondary: string;
  phone: string;
  phone_secondary: string;
  preference: UserPreference;
  account_paused: boolean;
  profile_completed: boolean;
  relationship: string;
  organization_id: string;
  employer_name: string;
  stripe_setup_completed: boolean;
  subscription_active: boolean;
  subscription_vaild_till: string;
}

export type NonImmigrantStatusType = {
  user_id: string;
  document_meta_id: string;
  status: string;
  visa_type: string;
  document_id: string;
  issue_date: string;
  expiration_date: string;
  tags: [];
  dismissed_tags: [];
  last_fetch_at: string;
  total_cap_out_days_in_country: number,
  total_cap_out_days_allowed: number,
  cap_out_days_remaining: number,
  has_i140: boolean,
  is_intermittent: boolean,
} | undefined;

export type OrganizationClient = {
  oid: string;
  client_id: string;
  organization: OrganizationMetaData;
  client: Client;
};

export type ClientInviteResponseBody = {
  oid: string;
  email: string;
  organization: OrganizationMetaData;
};

export type ClientUpdateRequestBody = {
  first_name: string;
  last_name: string;
  date_of_birth: string | Date;
  passport_number: string;
  citizenship: string;
  email?: string;
};

export const OrgMetaDataRowDefaultValue = {
  "Name: ": "Name",
  "Email: ": "Email",
  "Phone: ": "Phone",
  "Address: ": "Address",
};

export type OrganizationInvite = {
  id: number;
  organization_name: string;
  organization_id: string;
  email: string;
  created_at: string;
};

export type AcceptOrgInviteRequestBody = {
  invite_secret: string;
  first_name: string;
  last_name: string;
  password: string;
};

export type ClientDocumentResponse = {
  birth_date: string;
  date_of_entry: string;
  document_id: string;
  document_meta_id: string;
  expiration: string;
  family_name: string;
  first_name: string;
  passport_country: string;
  passport_number: string;
  uid: string;
};
export enum DataActionType {
  Edit = "EDIT",
  Delete = "DELETE",
  Add = "Add",
  Warning = "WARNING",
}

export type Profile = {
  id: string;
  first_name: string;
  last_name: string;
  role: string;
  email: string;
  organization_id: string;
};
export type csvColumnMappingType = {
  first_name: string;
  last_name: string;
  email: string;
  passport_number: string;
  date_of_birth: string;
  citizenship: string;
};
export enum CSV_STATUS {
  SUCCESS = "Success",
  FAILURE = "FAILURE",
}
export type csvUploadType = {
  row_number: number;
  status: CSV_STATUS;
  error: string;
};
