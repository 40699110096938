import React from "react";
import { Modal, Pressable, View } from "react-native";
import { Card, Text } from "react-native-paper";
import { theme } from "../../../theme";
import { EvilIcons } from "@expo/vector-icons";
import { customShadowModalStyles } from "./CustomShadowModal.Styles";
import { useRootContext } from "../../../context/rootContext/RootContext";

type props = {
  visible: boolean;
  onDismiss: () => void;
  style: {};
  children: {};
};

const CustomShadowModal: React.FC<props> = ({
  visible,
  onDismiss,
  style,
  children,
}) => {
  const {
    dimensions: { width: width },
  } = useRootContext();
  return visible ? (
    <Modal visible={visible} onDismiss={onDismiss} transparent>
      <View style={customShadowModalStyles.centeredView}>
        <View style={style}>
          <View style={customShadowModalStyles.closeOuterShadow}>
            <Card style={customShadowModalStyles.closeContainer}>
              <Pressable
                style={customShadowModalStyles.closeBtn}
                onPress={onDismiss}
              >
                <EvilIcons
                  name="close"
                  size={30}
                  color={theme.colors.btnshadow}
                />
              </Pressable>
            </Card>
          </View>
          <View style={customShadowModalStyles.backgroundShadow} />
          <Card style={[customShadowModalStyles.modalView]}>{children}</Card>
        </View>
      </View>
    </Modal>
  ) : null;
};

export default React.memo(CustomShadowModal);
