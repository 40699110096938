import { Apis } from "./axiosInstances/Apis";
import { autoVisasPrivateApi } from "./axiosInstances/autovisasPrivateApi";
import { getSuccess, getError } from "./axiosInstances/handleResponse";

/* Logic */
export const getDocument = async () => {
  try {
    const options = {
      method: "GET",
      url: Apis.GET_DOCUMENTS,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const getDocumentResponse = await autoVisasPrivateApi.request(options);

    return getSuccess(getDocumentResponse);
  } catch (error) {
    console.error(error.response);
    return getError(error);
  }
};
export const getLatestDocument = async (userId) => {
  try {
    const options = {
      method: "GET",
      url: `${Apis.GET_DOCUMENTS}/${userId}/latest`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const getDocumentResponse = await autoVisasPrivateApi.request(options);
    return getSuccess(getDocumentResponse);
  } catch (error) {
    console.error(error.response);
    return getError(error);
  }
};

export const downloadDocument = async (userId, docId) => {
  try {
    const getUserInfoResponse = await autoVisasPrivateApi.get(
      `/documents/${userId}/download/${docId}`,
      { responseType: "blob" }
    );
    if (getUserInfoResponse.status === 200) {
      const url = URL.createObjectURL(getUserInfoResponse.data);
      const fileName =
        getUserInfoResponse.headers["content-disposition"]?.split(
          "filename="
        )?.[1];
      return { url, fileName: JSON.parse(fileName) };
    }
  } catch (error) {
    console.error(error);
  }
};
