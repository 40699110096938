import React, { useContext, useEffect, useState } from "react";
import { View, Text, Linking } from "react-native";
import { Formik } from "formik";
import { AuthContext } from "../../context/AuthContext";

/* Components */
import FormInput from "../../components/FormInput";
import ContinueButton from "../../components/Buttons/Continue";
import validationSchema from "../../data/validationSchema";
import createAccountStyles from "./CreateAccountForm.Styles";
import CreateAccountFormController from "./controller/CreateAccountFormController";
import { Strings } from "../../../assets/strings";
import TextLink from "../../components/TextLink";
import Checkbox from "../../components/Checkbox";
import {
  PRIVACY_POLICY_URL,
  Screens,
  TERMS_AND_CONDITIONS_URL,
} from "../../common/Constant";
import { reset } from "../../navigation/RouteNavigation";
import SignUpHOC from "../../hoc/SignUpHOC";
import { Link } from "@react-navigation/native";
import { validateEmail } from "../../util/utils";

type Props = {
  // formNumber: number;
  // setFormNumber: (formNo: number) => void;
  setErrorMessage: (mess: string) => void;
};
const CreateAccountForm: React.FC = ({}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [addedToWaitList, setAddedToWaitList] = useState(false);
  const { loginState } = useContext(AuthContext);
  //const emailId = loginState.userInviteEmail;
  const { handleRegister, handleLogin, emailAvailability, checkEmailAvail } =
    CreateAccountFormController();
  const [errorMessage, setErrorMessage] = useState("");
  const onSubmitHandler = async (email, password, confirm_password) => {
    try {
      if (validateEmail(email)) {
        setIsLoading(true);
        setErrorMessage("");
        await checkEmailAvail(email).then(async (response) => {
          if (!response.hasError) {
            const { available } = response.data;
            if (available) {
              const addedToWaitList = await emailAvailability(email);
              if (addedToWaitList) {
                setIsLoading(false);
                setAddedToWaitList(true);
              } else loginHandle(email, password, confirm_password);
            } else {
              setIsLoading(false);
              setErrorMessage(Strings.EMAIL_ALREADY_EXISTS);
            }
          } else {
            setIsLoading(false);
            setErrorMessage(response.data);
          }
        });
      } else {
        setErrorMessage(Strings.INVALID_EMAIL);
      }
    } catch (error) {
      console.error("error", error);
    }
  };
  const loginHandle = async (email, password, confirm_password) => {
    try {
      setIsLoading(true);
      const { hasError, message } = await handleRegister(
        email,
        password,
        confirm_password
      );
      if (!hasError) {
        const { hasError, message } = await handleLogin(email, password);
        if (!hasError) {
          reset(Screens.TWO_FACTOR_AUTH, null);
        } else {
          setErrorMessage(message);
        }
      } else {
        setErrorMessage(message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("error", error);
    }
  };

  return (
    <SignUpHOC pageNumber={0}>
      {errorMessage !== "" && (
        <Text style={createAccountStyles.errorText}>{errorMessage}</Text>
      )}
      <Formik
        initialValues={{
          email: "",
          password: "",
          confirmPassword: "",
        }}
        validationSchema={validationSchema.register}
        onSubmit={async (values, actions) => {
          await onSubmitHandler(
            values.email,
            values.password,
            values.confirmPassword
            //loginState.userInviteEmail
          );

          actions.resetForm({
            values: {
              email: values.email,
              password: values.password,
              confirmPassword: values.confirmPassword,
            },
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <>
            <View style={createAccountStyles.inputsContainer}>
              <View
                style={[createAccountStyles.inputContainer, { marginRight: 5 }]}
              >
                <FormInput
                  label="Email"
                  inputPlaceholder="Email"
                  inputValue={values.email}
                  setInputValue={handleChange("email")}
                  mode="outlined"
                  error={touched.email && errors.email}
                  onBlur={handleBlur("email")}
                  labelStyle={createAccountStyles.label}
                  style={createAccountStyles.input}
                  activeOutlineColor="#9797FF"
                  outlineColor="transparent"
                  dense
                />
                {(touched.email && errors.email) === undefined && (
                  <View style={createAccountStyles.errorContainer}></View>
                )}
              </View>
              <View
                style={[createAccountStyles.inputContainer, { marginRight: 5 }]}
              >
                <FormInput
                  label="Password"
                  inputPlaceholder="Password"
                  inputValue={values.password}
                  setInputValue={handleChange("password")}
                  mode="outlined"
                  error={touched.password && errors.password}
                  onBlur={handleBlur("password")}
                  secureTextEntry
                  labelStyle={createAccountStyles.label}
                  style={createAccountStyles.input}
                  activeOutlineColor="#9797FF"
                  outlineColor="transparent"
                  dense
                />
                {(touched.password && errors.password) === undefined && (
                  <View style={createAccountStyles.errorContainer}></View>
                )}
              </View>
              <View style={createAccountStyles.inputContainer}>
                <FormInput
                  label="Confirm Password"
                  inputPlaceholder="Confirm Password"
                  inputValue={values.confirmPassword}
                  setInputValue={handleChange("confirmPassword")}
                  mode="outlined"
                  error={touched.confirmPassword && errors.confirmPassword}
                  onBlur={handleBlur("confirmPassword")}
                  secureTextEntry
                  labelStyle={createAccountStyles.label}
                  style={createAccountStyles.input}
                  activeOutlineColor="#9797FF"
                  outlineColor="transparent"
                  dense
                />
                {(touched.confirmPassword && errors.confirmPassword) ===
                  undefined && (
                  <View style={createAccountStyles.errorContainer}></View>
                )}
              </View>
            </View>
            <View style={{ marginBottom: 32 }}>
              <Text style={[createAccountStyles.text, { marginLeft: 0 }]}>
                Already have an account?
                <Link
                  style={[createAccountStyles.link, { marginLeft: 5 }]}
                  to={{ screen: Screens.LOGIN }}
                >
                  Login
                </Link>
              </Text>
            </View>
            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              <View style={createAccountStyles.policyContainer}>
                <Checkbox onChecked={(val) => setChecked(val)} />
                <Text selectable={false} style={createAccountStyles.text}>
                  {Strings.I_AGREE_WITH}
                  <TextLink
                    text={Strings.TERMS_AND_CONDITIONS}
                    url={TERMS_AND_CONDITIONS_URL}
                  />
                  {Strings.AND}
                  <TextLink
                    text={Strings.PRIVACY_POLICY}
                    url={PRIVACY_POLICY_URL}
                  />
                </Text>
              </View>
              <ContinueButton
                onPress={handleSubmit}
                isLoading={isLoading}
                isDisable={!isChecked}
                name="Continue"
              />
            </View>
          </>
        )}
      </Formik>
    </SignUpHOC>
  );
};

export default CreateAccountForm;
