import { Screens } from "../../src/common/Constant";
const {
  HOME,
  UPLOAD_DOCUMENTS,
  LOGIN,
  TWO_FACTOR_AUTH,
  CREATE_ACCOUNT,
  ORGANIZATION_USER_SIGNUP,
  ORGANIZATION_SIGNUP_VERIFICATION,
  CREATE_ORGANIZATION_SCREEN,
  EDIT_ORGANIZATION_SCREEN,
  EDIT_ORG_CLIENT_SCREEN,
  DRAWER,
  CONFIRM_INFO,
  CLIENT_SCREEN,
  CLIENT_DOCUMENTS_SCREEN,
  ADMIN_SCREEN,
  PAYMENT_SUCCESS,
  PAYMENT_FAILURE,
  STRIPE_PAYMENT,
  DEPENDENT,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_CONFIRM,
  EDIT_ADMIN_SCREEN,
  ORGANIZATION_INVITE_SCREEN,
  ORG_PROFILE_EDIT_SCREEN,
  PROFILE,
  ORGANIZATION_PAYMENT,
  ORGANIZATION_PAYMENT_SUCCESS,
  ORGANIZATION_PAYMENT_FAILURE,
  ONBOARDING_USERS_SCREEN,
  ORGANIZATION,
  AUTOVISAS,
  STATUS_PROTECTOR_CLIENT
} = Screens;
export const linking = {
  prefixes: [],
  config: {
    screens: {
      [HOME]: {
        path: "/"
      },
      [LOGIN]: {
        path: "login"
      },
      [PASSWORD_RESET_REQUEST]: {
        path: "resetPassword"
      },
      [PASSWORD_RESET_CONFIRM]: {
        path: "confirmResetPassword"
      },
      [TWO_FACTOR_AUTH]: {
        path: "verifyIndividualOTP",
      },
      [ORGANIZATION_SIGNUP_VERIFICATION]: {
        path: "verifyOrganizationOTP",
      },
      [CREATE_ACCOUNT]: {
        path: "createIndividualAccount",
      },
      [ORGANIZATION_USER_SIGNUP]: {
        path: "createOrganizationAccount",
      },
      [AUTOVISAS]: {
        initialRouteName: "autoVisa/statusProtector",
        screens: {
          [UPLOAD_DOCUMENTS]: {
            path: "autoVisa/uploadDocuments",
          },
          [CONFIRM_INFO]: {
            path: "autoVisa/confirmInfo",
          },
          [PAYMENT_SUCCESS]: {
            path: "autoVisa/payment/success",
          },
          [PAYMENT_FAILURE]: {
            path: "autoVisa/payment/failure",
          },
          [STRIPE_PAYMENT]: {
            path: "autoVisa/payment",
          },
          [DEPENDENT]: {
            path: "autoVisa/dependent",
          },
          [DRAWER]: {
            initialRouteName: "autoVisa/statusProtector",
            screens: {
              StatusProtector: {
                path: "autoVisa/statusProtector",
              },
              [PROFILE]: {
                path: "autoVisa/profile",
              },
            },
          },
        },
      },
      [ORGANIZATION]: {
        initialRouteName: "organization/*",
        screens: {
          [ADMIN_SCREEN]: {
            path: "organization/*"
          },
          [ORG_PROFILE_EDIT_SCREEN]: {
            path: "organization/profile/edit",
          },
          [CREATE_ORGANIZATION_SCREEN]: {
            path: "organization/createOrganization",
          },
          [EDIT_ORGANIZATION_SCREEN]: {
            path: "organization/editOrganization",
          },
          [EDIT_ORG_CLIENT_SCREEN]: {
            path: "organization/client/edit",
          },
          [CLIENT_SCREEN]: {
            path: "organization/clients",
          },
          [CLIENT_DOCUMENTS_SCREEN]: {
            path: "organization/client/documents",
          },
          [EDIT_ADMIN_SCREEN]: {
            path: "organization/admin/edit",
          },
          [ORGANIZATION_PAYMENT]: {
            path: "organization/organizationPayment",
          },
          [ORGANIZATION_PAYMENT_SUCCESS]: {
            path: "organization/payment/success/:type?/:session_id?",
          },
          [ORGANIZATION_PAYMENT_FAILURE]: {
            path: "organization/payment/failure/:type?/:session_id?",
          },
          [ORGANIZATION_PAYMENT]: {
            path: "organization/organizationPayment",
          },
          [ORGANIZATION_PAYMENT_SUCCESS]: {
            path: "organization/payment/success/:type?/:session_id?",
          },
          [ORGANIZATION_PAYMENT_FAILURE]: {
            path: "organization/payment/failure/:type?/:session_id?",
          },
          [ONBOARDING_USERS_SCREEN]: {
            path: "organization/onboardUsers",
          },
          [ORGANIZATION_PAYMENT]: {
            path: "organization/organizationPayment",
          },
          [STATUS_PROTECTOR_CLIENT]: {
            path: "organization/client",
          }
        },
      },
    },
  },
};
