import { LinearGradient } from "expo-linear-gradient";
import React, { useRef } from "react";
import { Pressable } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { documentUploadButtonStyles } from "./DocumentUploadButton.Styles";

type props = {
  onPress: () => void;
  style?: {};
};

const DocumentUploadButton: React.FC<props> = ({ onPress, style }) => {
  const ref = useRef(null);

  return (
    <Pressable style={style} onPress={onPress}>
      <LinearGradient
        ref={ref}
        colors={["#9797FF", "#BE8DFA"]}
        locations={[0.2, 0.7]}
        start={{ x: 0.1, y: 0.5 }}
        end={{ x: 0.5, y: 0.5 }}
        style={documentUploadButtonStyles.buttonGradient}
      >
        <MaterialIcons
          onPress={onPress}
          name="upload-file"
          size={20}
          color="white"
        />
      </LinearGradient>
    </Pressable>
  );
};

export default React.memo(DocumentUploadButton);
