import React from "react";
import { multiGet } from "../common/AsyncStorage";
import { ActionType, LoginAction } from "../context/AuthContext";
import { AsyncStorageKeys } from "../common/Constant";
import { UserType } from "../context/AuthContext";

const {
    USER_SESSION_TOKEN,
    SignIn_COMPLETED,
    USER_EMAIL,
    USER_ID,
} = AsyncStorageKeys

export const getIndividualToken = async (userType: UserType, loginDispatch: React.Dispatch<LoginAction>) => {
    const userTokenInStorage = await multiGet([
      USER_SESSION_TOKEN,
      SignIn_COMPLETED,
      USER_EMAIL,
      USER_ID,
    ]);
    const userToken = userTokenInStorage[0][1]
      ? userTokenInStorage[0][1]
      : null;
    const isSignInCompleted = userTokenInStorage[1][1]
      ? Boolean(userTokenInStorage[1][1])
      : false;
    const email = userTokenInStorage[2][1] ? userTokenInStorage[2][1] : null;
    const userId = userTokenInStorage[3][1] ? userTokenInStorage[3][1] : null;

    loginDispatch({
      type: ActionType.RestoreData,
      payload: {
        email,
        userToken,
        isSignInCompleted,
        isLoading: false,
        userId,
        userType,
      },
    });
};