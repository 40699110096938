import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../assets/strings";

/* Logic */
import { theme } from "../../../theme";
const TableHeader = () => {
  const {
    NAME,
    ADMISSION_CLASS,
    ADMISSION_OR_EFFECTIVE_DATE,
    VALID_UNTIL,
    DOCUMENT,
  } = Strings.TableHeader;

  return (
    <View style={tableHeaderStyles.tableHeader}>
      <View
        style={[
          tableHeaderStyles.tableTitleContainer,
          tableHeaderStyles.firstTableTitle,
        ]}
      >
        <Text style={tableHeaderStyles.tableTitle} ellipsizeMode='tail'>
          {NAME}
        </Text>
      </View>
      <View style={tableHeaderStyles.tableTitleContainer}>
        <Text style={tableHeaderStyles.tableTitle} ellipsizeMode='tail'>
          {ADMISSION_CLASS}
        </Text>
      </View>
      <View style={[tableHeaderStyles.tableTitleContainer, { flex: 2 }]}>
        <Text style={tableHeaderStyles.tableTitle} ellipsizeMode='tail'>
          {ADMISSION_OR_EFFECTIVE_DATE}
        </Text>
      </View>
      <View style={tableHeaderStyles.tableTitleContainer}>
        <Text style={tableHeaderStyles.tableTitle} ellipsizeMode='tail'>
          {VALID_UNTIL}
        </Text>
      </View>
      <View style={[tableHeaderStyles.tableTitleContainer, { flex: 2 }]}>
        <Text style={tableHeaderStyles.tableTitle} ellipsizeMode='tail'>
          {DOCUMENT}
        </Text>
      </View>
    </View>
  );
};

const tableHeaderStyles = StyleSheet.create({
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
  },
  tableTitleContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
    paddingTop: 16,
    paddingBottom: 8,
  },
  firstTableTitle: {
    flex: 2,
    alignItems: "flex-start",
  },
  tableTitle: {
    ...theme.fonts.black,
    fontSize: 16,
    textTransform: "uppercase",
    textAlign: "center",
    color: "#9797FF",
  },
});

export default TableHeader;
