import {
  useFonts,
  Roboto_300Light,
  Roboto_400Regular,
  Roboto_500Medium,
  Roboto_700Bold,
  Roboto_900Black,
} from "@expo-google-fonts/roboto";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { StatusBar } from "expo-status-bar";
import { Provider as PaperProvider } from "react-native-paper";
/* Logic */
import { theme } from "./src/theme";
import { AuthProvider } from "./src/context/AuthContext/index";

/* Components */
import Loading from "./src/components/Loading";
import AppUI from "./src/components/AppUI";
import React, { useEffect } from "react";
import { Dimensions } from "react-native";
import { RootContextProvider } from "./src/context/rootContext/RootContext";

export default function App() {
  try {
    const headTag = document.querySelector("head");
    const icon = document.createElement("link");
    const attributeRel = document.createAttribute("rel");
    attributeRel.value = "icon";
    const attributeHref = document.createAttribute("href");
    attributeHref.value =
      "https://autovisas-assets.s3.us-west-1.amazonaws.com/favicon.png";
    icon.setAttributeNode(attributeRel);
    icon.setAttributeNode(attributeHref);
    headTag.appendChild(icon);
  } catch (e) {
    //Browser tabs do not exist on android and iOS, so let's just do nothing here.
  }
  let [fonstLoaded] = useFonts({
    Roboto_300Light,
    Roboto_400Regular,
    Roboto_500Medium,
    Roboto_700Bold,
    Roboto_900Black,
    Brother1816Black: require("./assets/fonts/Brother1816Black/font.woff"),
    FilsonSoftBook: require("./assets/fonts/FilsonSoftBook/font.woff"),
  });
  const [dimension, setDimension] = React.useState<{
    height: number;
    width: number;
  }>({
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
  });
  useEffect(() => {
    let isMounted = true;
    Dimensions.addEventListener("change", ({ window: { width, height } }) => {
      if (isMounted) {
        setDimension({ width: width, height: height });
      }
    });

    return () => {
      isMounted = false;
      Dimensions.removeEventListener("change", () => {});
    };
  }, []);

  if (!fonstLoaded) {
    return <Loading />;
  }

  return (
    <PaperProvider theme={theme}>
      <RootContextProvider dimension={dimension}>
        <SafeAreaProvider>
          <AppUI />
          <StatusBar style="auto"></StatusBar>
        </SafeAreaProvider>
      </RootContextProvider>
    </PaperProvider>
  );
}
