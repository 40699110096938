import { useCallback, useEffect, useState } from "react";
import { Text } from "react-native-paper";
import { getLocalStorageValue } from "../../common/AsyncStorage";
import { AsyncStorageKeys } from "../../common/Constant";
import { signUpInfo } from "../../data/signUpInfo";
import registerStepStyles from "./RegisterStep.Styles";

const RegisterStep = ({ pageNumber }) => {
  const [email, setEmail] = useState(null);
  const title = signUpInfo[pageNumber]?.title;
  const description = signUpInfo[pageNumber]?.description;
  const getDescription = useCallback(
    (
      description: (email?: string) => string,
      pageNumber: number,
      email = null
    ) => {
      if (pageNumber === 1) {
        return description(email);
      }

      return description();
    },
    []
  );

  useEffect(() => {
    let isMounted = true;
    const getEmail = async () => {
      const email = await getLocalStorageValue(AsyncStorageKeys.USER_EMAIL);
      isMounted && setEmail(email);
    };
    getEmail();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <Text style={[registerStepStyles.title, registerStepStyles.textColor]}>
        {title}
      </Text>
      <Text style={registerStepStyles.textContainer}>
        <Text style={registerStepStyles.description}>
          {getDescription(description, pageNumber, email)}
        </Text>
      </Text>
    </>
  );
};

export default RegisterStep;
