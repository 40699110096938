import { Images } from "../../assets/images";
import { Strings } from "../../assets/strings";
import { Screens } from "../common/Constant";

export default [
  {
    name: Strings.STATUS,
    image: Images.STAT,
    navigateTo: Screens.STATUS_PROTECTOR,
  },
  {
    name: Strings.SETTING,
    image: Images.SETTINGS,
    navigateTo: Screens.PROFILE,
  },
  {
    name: Strings.LOGOUT,
    image: Images.LOG_OUT,
    navigateTo: "",
  },
];
