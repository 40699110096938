import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { ScrollView } from "react-native";
import { Strings } from "../../../../../assets/strings";
import { useOrgAuthContext } from "../../../../context/OrganizationAuthContext";
import { useRootContext } from "../../../../context/rootContext/RootContext";
import { OnboardingController } from "../../../../screen/Organization/Onboarding/controller/OnboardingController";
import {
  csvUploadType,
  CSV_STATUS,
} from "../../../../screen/Organization/types";
import { theme } from "../../../../theme";
import CustomShadowModal from "../../../Modal/CustomShadowModal";
import { ProgressBar } from "../../../progressBar/ProgressBar";

type props = {
  data?: { id: string; fileName: string };
  visible: boolean;
  onDismiss: () => void;
};

const ErrorCountModal: React.FC<props> = ({ data, visible, onDismiss }) => {
  const {
    dimensions: { width: width },
  } = useRootContext();
  const { orgAuthState } = useOrgAuthContext();
  const [isLoading, setLoading] = useState(true);
  const [csvUploadDetails, setCsvUploadDetails] = useState<csvUploadType[]>([]);
  const { ROW_NUMBER, ERROR_MESSAGE, ERROR_COUNT_ON, FILE } =
    Strings.ERROR_COUNT_MODAL;
  const { getCSVUserByJobId } = OnboardingController();
  const updateData = [
    {
      row_number: 13231238213,
      status: CSV_STATUS.FAILURE, //Failure
      error: "13231238213 is not a valid date",
    },
    {
      row_number: 13231238214,
      status: CSV_STATUS.SUCCESS, //Failure
      error: "13231238214 is not a valid date",
    },
  ];
  const getCSVUserDetail = useCallback(async () => {
    setLoading(true);
    const res = await getCSVUserByJobId(
      orgAuthState.orgId,
      orgAuthState.orgUserToken,
      data?.id
    );
    if (res.status === 200) {
      setCsvUploadDetails(res.data);
    } else {
      setCsvUploadDetails(updateData);
    }
    setLoading(false);
  }, [data]);

  useEffect(() => {
    if (data?.id) {
      getCSVUserDetail();
    }
  }, [data]);
  const rowElement = useCallback(
    (column1: string, column2: string, isHeader?: boolean) => {
      return (
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            paddingHorizontal: 10,
            paddingVertical: 8,
            borderTopWidth: 1,
            borderTopColor: !isHeader
              ? theme.colors.divider
              : theme.colors.transparent,
          }}
        >
          <Text style={{ flex: 1 }}>{column1}</Text>
          <Text style={{ flex: 1.5, textAlign: isHeader ? "center" : "left" }}>
            {column2}
          </Text>
        </View>
      );
    },
    []
  );
  return (
    <CustomShadowModal
      style={[{ width: 500, height: 300 }, width < 475 && { width: "90%" }]}
      visible={visible}
      onDismiss={() => {
        onDismiss();
        setCsvUploadDetails([]);
      }}
    >
      <ScrollView style={{ flex: 1 }}>
      <ProgressBar loading={isLoading}>
        <Text
          style={{
            textAlign: "center",
            fontSize: 15,
            marginVertical: 15,
            color: theme.colors.accent,
          }}
        >
          {`${ERROR_COUNT_ON}${data?.fileName} ${FILE}`}
        </Text>
        <View>
          {csvUploadDetails.length ? (
            <>
              {rowElement(ROW_NUMBER, ERROR_MESSAGE, true)}
              {csvUploadDetails.map(
                (data, index) =>
                  data.status === CSV_STATUS.FAILURE && (
                    <View key={index}>
                      {rowElement("" + data.row_number, data.error)}
                    </View>
                  )
              )}
            </>
          ) : !isLoading ? (
            <Text
              style={{
                textAlign: "center",
                marginTop: 80,
              }}
            >
              {Strings.NO_DATA_AVAILABLE}
            </Text>
          ) : null}
        </View>
      </ProgressBar>
      </ScrollView>
    </CustomShadowModal>
  );
};

export default React.memo(ErrorCountModal);
