import { StyleSheet } from "react-native";
const menuStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    backgroundColor: "#BE8DFA",
    zIndex: 1,
  },
  btn: {
    flex: 1,
    paddingVertical: 16,
    paddingHorizontal: 10,
    justifyContent: "center",
  },
  text: {
    fontSize: 13,
    textAlign: "center",
    textTransform: "uppercase",
  },
  firstActiveButton: {
    backgroundColor: "#F0FFFF",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 6,
    shadowOffset: {
      width: 4,
      height: 1,
    },
    shadowColor: "#C0C0FF",
    shadowRadius: 1,
    shadowOpacity: 0.5,
    zIndex: 1,
  },
  firstInactiveButton: {
    backgroundColor: "#BE8DFA",
    borderBottomLeftRadius: 18,
    borderTopWidth: 4,
    borderColor: "rgba(192, 192, 255, 0.5)",
  },
  lastInactiveButton: {
    backgroundColor: "#BE8DFA",
    borderBottomRightRadius: 18,
    borderBottomLeftRadius: 18,
    borderTopWidth: 4,
    borderColor: "rgba(192, 192, 255, 0.5)",
  },
  textActiveButton: {
    color: "#BE8DFA",
    fontSize: 15,
  },
  textInactiveButton: {
    color: "#E0CAFF",
    fontSize: 13,
  },
  lastActiveButton: {
    backgroundColor: "#F0FFFF",
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 6,
    shadowOffset: {
      width: -4,
      height: 1,
    },
    shadowColor: "#C0C0FF",
    shadowRadius: 1,
    shadowOpacity: 0.5,
    zIndex: 1,
  },
  middleActiveButton: {
    backgroundColor: "#F0FFFF",
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowColor: "#C0C0FF",
    shadowRadius: 1,
    shadowOpacity: 0.5,
    zIndex: 2,
  },
  middleInactiveButton: {
    backgroundColor: "#BE8DFA",
    borderTopWidth: 4,
    borderColor: "rgba(192, 192, 255, 0.5)",
  },
});
export default menuStyles;
