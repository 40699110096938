import { StyleSheet } from "react-native";
import { theme } from "../../theme";

export const stripePaymentStyles = StyleSheet.create({
    container: { alignItems: 'center', flex: 1},
    stripePlanContainer: {
        flex: 1,
    },
    buttonOuterContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    imageStyle: {
        width: 250 ,
        height: 75,
        marginTop: 100,
        marginBottom: 50    },
    noPlanText: {
        color: theme.colors.btn,
        fontSize: 20,
        fontWeight: "bold",
        alignSelf: "center",
        textAlign: "center",
    }
})