import React, { useState } from "react";
import { ScrollView } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../assets/strings";
import {
  DependentData,
  DependentModalData,
} from "../../../screen/StatusProtector/types/Dependentypes";
import UploadDocumentsContent from "../../UploadDocumentsContent";
import Header from "../Header";
import { uploadDocModalStyles } from "./UploadDocumentModal.Styles";

type props = {
  onDismiss: () => void;
  onContinue: (data: DependentModalData) => void;
  modalData: DependentModalData;
};

const UploadDocumentsModal: React.FC<props> = ({
  onDismiss,
  onContinue,
  modalData,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  return (
    <>
      <Header title={Strings.UPLOAD_STATUS_DOCS} onDismiss={onDismiss} />
      <ScrollView style={{ flex: 1 }}>
        <Text style={[uploadDocModalStyles.contentText]}>
          {Strings.UPLOAD_YOUR_DOCUMENTS_DESCRIPTION}
        </Text>
        {errorMessage !== "" && (
          <Text style={uploadDocModalStyles.errorText}>{errorMessage}</Text>
        )}
        <UploadDocumentsContent
          style={uploadDocModalStyles.btn}
          setErrorMessage={setErrorMessage}
          isUploadDocForDependent={true}
          dId={modalData.dId}
          onContinue={(data: DependentData) => {
            onContinue({ ...modalData, pageNo: 2, dependentData: data });
          }}
        />
      </ScrollView>
    </>
  );
};

export default React.memo(UploadDocumentsModal);
