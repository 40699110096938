import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  DependentInfo,
  DependentModalData,
} from "../../../screen/StatusProtector/types/Dependentypes";
import CustomModal from "../../Modal/CustomModal";
import AddDependentModal from "../AddDependentModal";
import ConfirmInfoModal from "../ConfirmInfoModal";
import UploadDocumentsModal from "../UploadDocumentsModal";

type props = {
  dependentInfo: DependentInfo;
  onDismiss: (profileCreated?: boolean) => void;
  modalStyle: {};
};

const DependentModals: React.FC<props> = ({
  dependentInfo,
  onDismiss,
  modalStyle,
}) => {
  const initialModalData: DependentModalData = {
    pageNo: 0,
  };
  const [modalData, setModalData] =
    useState<DependentModalData>(initialModalData);

  useEffect(() => {
    if (dependentInfo.dId) {
      setModalData({
        pageNo: 1,
        ...dependentInfo,
      });
    }
  }, [dependentInfo.dId]);
  const onCancel = (profileCreated?: boolean) => {
    onDismiss(profileCreated);
    setModalData(initialModalData);
  };
  const handleOnContinue = (data: DependentModalData) => {
    setModalData({ ...modalData, ...data });
  };
  const renderElement = useCallback(() => {
    if (modalData.pageNo === 0) {
      return (
        <AddDependentModal onDismiss={onCancel} onContinue={handleOnContinue} />
      );
    } else if (modalData.pageNo === 1) {
      return (
        <UploadDocumentsModal
          onDismiss={onCancel}
          modalData={modalData}
          onContinue={handleOnContinue}
        />
      );
    } else {
      return (
        <ConfirmInfoModal
          onDismiss={onCancel}
          modalData={modalData}
          onCreate={() => onCancel(true)}
        />
      );
    }
  }, [modalData]);
  return (
    <CustomModal
      visible={dependentInfo.visible}
      onDismiss={onCancel}
      modalStyle={modalStyle}
    >
      {renderElement()}
    </CustomModal>
  );
};

export default React.memo(DependentModals);
