import React from "react";
import { Dialog, Portal, Text, Button } from "react-native-paper";
import { Strings } from "../../../assets/strings";
import { useRootContext } from "../../context/rootContext/RootContext";
import { theme } from "../../theme";
type Props = {
  isDialogVisible: boolean;
  setIsDialogVisible: (value: boolean) => void;
  deleteHandler: () => void;
  message?: string;
};
const DeleteAccount: React.FC<Props> = ({
  isDialogVisible,
  setIsDialogVisible,
  deleteHandler,
  message,
}) => {
  const {
    dimensions: { width: width },
  } = useRootContext();
  return (
    <Portal>
      <Dialog
        style={{
          width: width < 470 ? "90%" : 470,
          alignSelf: "center",
          backgroundColor: "white",
        }}
        visible={isDialogVisible}
        onDismiss={() => setIsDialogVisible(false)}
      >
        <Dialog.Title style={{ color: "red" }}>{Strings.WARNING}</Dialog.Title>
        <Dialog.Content>
          <Text
            style={{
              ...theme.fonts.medium,
              color: theme.colors.textInactive,
              marginVertical: 10,
              lineHeight: 25,
            }}
          >
            {message}
          </Text>
        </Dialog.Content>
        <Dialog.Actions>
          <Button
            textColor={theme.colors.btn}
            onPress={() => setIsDialogVisible(false)}
          >
            {Strings.CANCEL}
          </Button>
          <Button textColor={theme.colors.btn} onPress={deleteHandler}>
            {Strings.DELETE}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};

export default DeleteAccount;
