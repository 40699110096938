import { Strings } from "../../../../../assets/strings";
import { updateClient } from "../../../../util/organization/clients";
import { ClientUpdateRequestBody } from "../../types";

const EditClientScreenController = () => {

    const updateClientProfile = async (oid: string, token: string, cid: string, body: ClientUpdateRequestBody) => {
        try {
            const response = await updateClient(oid, token, cid, body);
            if (response.status === 200) {
                return {
                    hasError: false,
                    data: response.data
                }
            } else {
                return {
                    hasError: true,
                    data: response.data.message
                }
            }
        } catch (error) {
            console.log(error); 
            return {
                hasError: true,
                data: Strings.SOMETHING_WENT_WRONG
            }
        }
    } 

    return { updateClientProfile }
}

export default EditClientScreenController