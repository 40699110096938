import React from "react";
import { View, Image } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../../assets/strings";
import {
  Client,
  OrganizationUser,
} from "../../../../screen/Organization/types";
import deleteModalStyles from "../DeleteModal.Styles";
import { AntDesign } from "@expo/vector-icons";
import { theme } from "../../../../theme";
import CustomButton from "../../../Buttons/CustomButton";
import ErrorText from "../../../ErrorText";

type props = {
  user?: OrganizationUser;
  client?: Client;
  onDismiss: () => void;
  onDelete: () => void;
  error: string;
};

const ModalContent: React.FC<props> = ({
  user,
  onDelete,
  onDismiss,
  client,
  error,
}) => {
  return (
    <View style={{ flex: 1 }}>
      <AntDesign
        style={deleteModalStyles.imageStyle}
        name="delete"
        size={24}
        color={theme.colors.btn}
      />
      <Text style={deleteModalStyles.title1}>
        {`${Strings.ARE_YOU_SURE_TO_DLT} ${
          user
            ? user.first_name
              ? user.first_name
              : user.email
            : client?.email
        }`}
      </Text>
      <View style={deleteModalStyles.btnContainer}>
        <CustomButton
          onPress={onDismiss}
          name={Strings.CANCEL}
          style={deleteModalStyles.cancelBtn}
        />
        <CustomButton
          onPress={onDelete}
          isLoading={error === Strings.LOADING_STARTED}
          name={Strings.DELETE}
          style={deleteModalStyles.cancelBtn}
        />
      </View>
      {error && error !== Strings.LOADING_STARTED ? (
        <ErrorText txtStyle={{ marginTop: 5 }} error={error} />
      ) : null}
    </View>
  );
};

export default React.memo(ModalContent);
