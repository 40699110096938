import { useRoute } from "@react-navigation/native";
import { Formik } from "formik";
import React, { useState } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../assets/strings";
import BgImageContainer from "../../components/BgImageContainer";
import FormInput from "../../components/FormInput";
import LoadingButton from "../../components/LoadingButton";
import validationSchema from "../../data/validationSchema";
import { theme } from "../../theme";
import ResetPasswordRequestController from "./controller/ResetPasswordRequestController";
import { resetPasswordRequestStyles } from "./ResetPasswordRequest.Styles";

type props = {};

const ResetPasswordRequest: React.FC<props> = () => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoadingStart, setIsLoadingStarted] = useState(false);
  const { params } = useRoute();
  const isOrganization = params && params.isOrg ? true : false;

  const {
    raisePasswordResetRequest,
    raisePasswordResetRequestForOrganization,
  } = ResetPasswordRequestController();

  const sendPasswordResetRequest = async (email: string) => {
    setIsLoadingStarted(true);
    await raisePasswordResetRequest(email).then((res) => {
      if (!res.hasError) {
        setHasError(false);
        setErrorMessage("");
        setIsSuccess(true);
      } else {
        setIsSuccess(false);
        setHasError(true);
        setErrorMessage(res.message);
      }
    });
    setIsLoadingStarted(false);
  };

  const sendPasswordResetRequestForOrg = async (email: string) => {
    setIsLoadingStarted(true);
    await raisePasswordResetRequestForOrganization(email).then((res) => {
      if (!res.hasError) {
        setHasError(false);
        setErrorMessage("");
        setIsSuccess(true);
      } else {
        setIsSuccess(false);
        setHasError(true);
        setErrorMessage(res.message);
      }
    });
    setIsLoadingStarted(false);
  };

  return (
    <BgImageContainer isOrganization={isOrganization}>
      <Text
        style={{
          fontSize: 25,
          color: theme.colors.btn,
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {Strings.ENTER_EMAIL}
      </Text>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={validationSchema.checkEmail}
        onSubmit={(values) => {
          isOrganization
            ? sendPasswordResetRequestForOrg(values.email)
            : sendPasswordResetRequest(values.email);
        }}
      >
        {({ values, errors, touched, handleSubmit, handleChange }) => (
          <View
            style={{
              marginTop: 30,
            }}
          >
            <View style={resetPasswordRequestStyles.formContainer}>
              <FormInput
                label={Strings.EMAIL}
                inputPlaceholder={Strings.EMAIL}
                inputValue={values.email}
                setInputValue={handleChange("email")}
                mode="outlined"
                error={touched.email && errors.email}
                labelStyle={resetPasswordRequestStyles.label}
                style={resetPasswordRequestStyles.input}
                activeOutlineColor="#9797FF"
                outlineColor="#9797FF"
                dense
              />
              {(touched.email && errors.email) === undefined && (
                <View style={resetPasswordRequestStyles.errorContainer}></View>
              )}
            </View>
            <View style={resetPasswordRequestStyles.btnContainer}>
              <LoadingButton
                style={resetPasswordRequestStyles.btnStyle}
                name={Strings.RESET_PASSWORD}
                onPress={handleSubmit}
                isLoadingStart={isLoadingStart}
                isSuccess={isSuccess}
              />
            </View>
            {hasError ? (
              <Text style={[resetPasswordRequestStyles.errorText]}>
                {errorMessage}
              </Text>
            ) : isSuccess ? (
              <Text style={resetPasswordRequestStyles.text}>
                {Strings.CHANGE_PASSWORD_SUCCESS_TEXT}
              </Text>
            ) : null}
          </View>
        )}
      </Formik>
    </BgImageContainer>
  );
};

export default React.memo(ResetPasswordRequest);
