import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const nonImmigrantStatusStyles = StyleSheet.create({
  statusInfoContainer: {
    backgroundColor: "#BE8DFA",
    borderRadius: 31,
    paddingVertical: 8,
    paddingHorizontal: 20,
    shadowOffset: {
      width: 7,
      height: 6,
    },
    shadowColor: "#E0CAFF",
    shadowRadius: 2,
    shadowOpacity: 1,
  },
  statusInfo: {
    fontSize: 29,
    textTransform: "uppercase",
    marginLeft: 10,
  },
  sInfo: {
      color: "#F0FFFF",
  },
  statusInfoValidation: {
      ...theme.fonts.black,
      color: "#F0FFFF",
  },
})