import { Formik } from "formik";
import React from "react";
import { Pressable, View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../../assets/strings";
import { useRootContext } from "../../../../context/rootContext/RootContext";
import validationSchema from "../../../../data/validationSchema";
import { theme } from "../../../../theme";
import CustomButton from "../../../Buttons/CustomButton";
import GreenButton from "../../../Buttons/GreenButton";
import FormInput from "../../../FormInput";
import CustomShadowModal from "../../../Modal/CustomShadowModal";
import { userFormStyles } from "../../UserForm/UserForm.Styles";

type props = {
  visible: boolean;
  onDismiss: () => void;
  isLoading: boolean;
  onSendInvitation: (email: string) => void;
  message: string;
};

const AddAdminModal: React.FC<props> = ({
  visible,
  onDismiss,
  isLoading,
  onSendInvitation,
  message,
}) => {
  const {
    dimensions: { width: width },
  } = useRootContext();
  return (
    <CustomShadowModal
      style={[{ width: 400, height: 300 }, width < 475 && { width: "90%" }]}
      visible={visible}
      onDismiss={onDismiss}
    >
      <Text
        style={{
          fontFamily:"Brother1816Black",
          color: theme.colors.btn,
          textAlign: "center",
          fontWeight: "bold",
          fontSize: 20,
          marginTop: 15,
        }}
      >
        {Strings.ADD_ADMIN}
      </Text>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={validationSchema.checkEmail}
        onSubmit={(values) => {
          onSendInvitation(values.email);
        }}
      >
        {({ values, errors, touched, handleSubmit, handleChange }) => (
          <View style={{ marginTop: 30, flex: 1 }}>
            <View style={{ flex: 1, margin: 5 }}>
              <FormInput
                label={Strings.EMAIL}
                inputPlaceholder={Strings.EMAIL}
                inputValue={values.email}
                setInputValue={handleChange("email")}
                mode="outlined"
                error={touched.email && errors.email}
                labelStyle={userFormStyles.label}
                style={userFormStyles.input}
                activeOutlineColor="#9797FF"
                outlineColor={theme.colors.btn}
                dense
              />
              {(touched.email && errors.email) === undefined && (
                <View style={userFormStyles.errorContainer}></View>
              )}
            </View>
            <View style={{ flex: 1 }}>        
            <CustomButton
              style={{marginTop:20}}
              onPress={handleSubmit}
              name={Strings.SEND_INVITATION}
              isLoading={isLoading}
            />
              {message && message !== Strings.LOADING_STARTED ? (
                <Text
                  style={{
                    textAlign: "center",
                    color:
                      message === Strings.INVITATION_SENT ? "green" : "red",
                    fontSize: 15,
                    marginTop: 15,
                  }}
                >
                  {message}
                </Text>
              ) : null}
            </View>
          </View>
        )}
      </Formik>
    </CustomShadowModal>
  );
};

export default React.memo(AddAdminModal);
