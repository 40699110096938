import { Strings } from "../../../../assets/strings"
import { passwordResetConfirm, passwordResetConfirmForOrg } from "../../../util/passwordReset"

const ResetPasswordConfirmController = () => {
    const sendPasswordRequestConfirmation = async (token: string, password: string, confirm_password: string) => {
        try {
            const response = await passwordResetConfirm(token, password, confirm_password)            
            if (response.status === 200) {
                return {
                    hasError: false,
                    data: response.data
                }
            } else {
                return {
                    hasError: true,
                    message: response.data.message
                }
            }
        } catch(error) {
            return {
                hasError: true,
                message: Strings.SOMETHING_WENT_WRONG
            }
        }
    }

    const sendPasswordRequestConfirmationForOrg = async (token: string, password: string, confirm_password: string) => {
        try {
            const response = await passwordResetConfirmForOrg(token, password, confirm_password)            
            if (response.status === 200) {
                return {
                    hasError: false,
                    data: response.data
                }
            } else {
                return {
                    hasError: true,
                    message: response.data.message
                }
            }
        } catch(error) {
            return {
                hasError: true,
                message: Strings.SOMETHING_WENT_WRONG
            }
        }
    }

    return { sendPasswordRequestConfirmation, sendPasswordRequestConfirmationForOrg } 
}

export default ResetPasswordConfirmController