import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const customShadowModalStyles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#70707070",
  },
  closeOuterShadow: {
    width: "96%",
    height: 58,
    backgroundColor: theme.colors.btnshadow,
    borderRadius: 30,
  },
  closeContainer: {
    width: "98%",
    backgroundColor: theme.colors.btn,
    borderRadius: 30,
  },
  closeBtn: {
    alignItems: "flex-end",
    marginEnd: 10,
    height: 50,
    justifyContent: "center",
  },
  backgroundShadow: {
    flex: 1,
    width: "86%",
    backgroundColor: theme.colors.btnshadow,
    borderBottomEndRadius: 30,
    borderBottomStartRadius: 30,
  },

  modalView: {
    flex: 1,
    width: "84%",
    height: "98%",
    padding: 10,
    position: "absolute",
    borderRadius: 30,
    borderWidth: 5,
    borderColor: theme.colors.btn,
    backgroundColor: theme.colors.modalColor,
  },
});
