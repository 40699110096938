import { Strings } from "../../../../assets/strings";
import { requestEmailChange, verifyEmailChange } from "../../../util/emailEdit";
import { getBillingPortalURL } from "../../../util/getBillingPortalUrl";
import { pauseAccount } from "../../../util/pauseAccount";
import { resumeAccount } from "../../../util/resumeAccount";

const ProfileController = () => {
  const pauseUserAccount = async () => {
    try {
      const response = await pauseAccount();
      if (response.status === 202) {
        return {
          hasError: false,
          data: response.data,
        };
      } else {
        return {
          hasError: true,
          data: Strings.PAUSE_ACCOUNT_ERROR,
        };
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resumeUserAccount = async () => {
    try {
      const response = await resumeAccount();
      if (response.status === 202) {
        return {
          hasError: false,
          data: response.data,
        };
      } else {
        return {
          hasError: true,
          data: Strings.RESUME_ACCOUNT_ERROR,
        };
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBillingPortalLink = async (uid: string) => {
    try {
      const response = await getBillingPortalURL(uid)
      if (response.status === 200) {
        return {
          hasError: false,
          URL: response.data
        }
      } else {
        return {
          hasError: true,
          message: Strings.SOMETHING_WENT_WRONG
        }
      }
    } catch (error) {
      console.log(error);   
      return {
        hasError: true,
        message: Strings.SOMETHING_WENT_WRONG
      }  
    }
  }

  const raiseEmailChangeRequest = async (uid: string, email: string) => {
    try {
      const response = await requestEmailChange(uid, email)
      if (response.status === 200) {
        return {
          hasError: false,
          data: response.data
        }
      } else {
        return {
          hasError: true,
          message: response.data.message
        }
      }
    } catch (error) {
      return {
        hasError: true,
        message: Strings.SOMETHING_WENT_WRONG
      }  
    }
  }

  const verifyEmailChangePasscode = async (uid: string, email: string, passcode: string) => {
    try {
      const response = await verifyEmailChange(uid, email, passcode)
      if (response.status === 200) {
        return {
          hasError: false,
          data: response.data
        }
      } else {
        return {
          hasError: true,
          message: response.data.message
        }
      }
    } catch (error) {
      return {
        hasError: true,
        message: Strings.SOMETHING_WENT_WRONG
      }  
    }
  }

  return { pauseUserAccount, resumeUserAccount, getBillingPortalLink, raiseEmailChangeRequest, verifyEmailChangePasscode };
};

export default ProfileController;
