import { autoVisasPrivateApi } from "./axiosInstances/autovisasPrivateApi";

/* Logic */
import { userInfo } from "../data/userInfo";
import { multiGet } from "../common/AsyncStorage";
import { getError, getSuccess } from "./axiosInstances/handleResponse";

export const getUserInfo = async (controller: AbortController) => {
  try {
    // Get the user info in localStorage
    const userSession = await multiGet(["userId"]);
    const options = {
      method: "GET",
      url: `/users/${userSession[userInfo.id.position][userInfo.id.value]}`,
      headers: {
        "Content-Type": "application/json",
      },
      signal: controller.signal,
    };
    const getUserInfoResponse = await autoVisasPrivateApi.request(options);
    return getSuccess(getUserInfoResponse);
  } catch (error) {
    console.error(error);
    return getError(error)
  }
};
export const deleteAccount = async () => {
  try {
    // Get the user info in localStorage
    const userSession = await multiGet(["userId"]);
    const options = {
      method: "DELETE",
      url: `/users/${userSession[userInfo.id.position][userInfo.id.value]}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const getUserInfoResponse = await autoVisasPrivateApi.request(options);
    return getUserInfoResponse;
  } catch (error) {
    console.error(error);
  }
};

export const updateUserInfo = async (data, userId) => {
  try {
    const options = {
      method: "PUT",
      url: `/users/${userId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };
    const updateUserInfoResponse = await autoVisasPrivateApi.request(options);
    return updateUserInfoResponse;
  } catch (error) {
    console.error(error);
  }
};
