import React from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../assets/strings";
import { useRootContext } from "../../../context/rootContext/RootContext";
import BorderButton from "../../Buttons/BorderButton";
import { profilePageHeaderStyles } from "./ProfilePageHeader.Styles";

type props = {
  onEdit: () => void;
  isEditable: boolean;
};

const { EDIT, SAVE } = Strings.Profile;

const ProfilePageHeader: React.FC<props> = ({ onEdit, isEditable }) => {
  const {
    dimensions: { width: width },
  } = useRootContext();
  return (
    <>
      <View style={profilePageHeaderStyles.headerContainer}>
        <Text
          style={[
            profilePageHeaderStyles.headerTxt,
            width < 513 && { fontSize: 25 },
          ]}
        >
          {Strings.DEPENDENT}
        </Text>
        <BorderButton
          onPress={onEdit}
          style={[
            profilePageHeaderStyles.editBtnStyle,
            width < 513 && { width: 100, height: 30 },
          ]}
          title={isEditable ? SAVE : EDIT}
        />
      </View>
    </>
  );
};

export default React.memo(ProfilePageHeader);
