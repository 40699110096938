import { Card, Text } from "react-native-paper";
import { Pressable, ScrollView, View } from "react-native";
import React, { useRef } from "react";

import DropView from "../../DropView";
import { Strings } from "../../../../assets/strings";
import documentModalStyle from "./DocumentModal.Styles";
import SupportedFileMessage from "../SupportedFileMessage";
import CustomShadowModal from "../../Modal/CustomShadowModal";
import { useRootContext } from "../../../context/rootContext/RootContext";

type Props = {
  visible: boolean;
  onDismiss: () => void;
  onDrop: (files: File[]) => void;
  message?: string;
};

const DocumentModal: React.FC<Props> = (props) => {
  const { visible, onDismiss, onDrop } = props;
  const dropViewRef: React.MutableRefObject<any> = useRef();
  const {
    dimensions: { width: width },
  } = useRootContext();
  return visible ? (
    <DropView ref={dropViewRef} onDrop={onDrop} multiple={true} noClick={true}>
      <CustomShadowModal
        visible={visible}
        onDismiss={onDismiss}
        style={[
          { height: 350 },
          width > 453 ? { width: 400 } : { width: "90%" },
        ]}
      >
        <ScrollView>
          <Text style={documentModalStyle.title1}>
            {Strings.DRAG_AND_DROP_FILE}
          </Text>
          <View style={documentModalStyle.dividerContainer}>
            <View style={documentModalStyle.divider}></View>
            <Text style={documentModalStyle.title}>{Strings.OR}</Text>
            <View style={documentModalStyle.divider}></View>
          </View>
          <Card.Actions style={documentModalStyle.actionsContainer}>
            <View style={documentModalStyle.shadow3}>
              <Pressable
                style={documentModalStyle.button}
                onPress={() => dropViewRef.current.open()}
              >
                <Text style={documentModalStyle.btntxt}>
                  {Strings.SELECT_FILES}
                </Text>
              </Pressable>
            </View>
          </Card.Actions>
          <SupportedFileMessage message={props.message} />
        </ScrollView>
      </CustomShadowModal>
    </DropView>
  ) : null;
};

export default DocumentModal;
