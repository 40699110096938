import { Formik } from "formik";
import React, { useState } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../../assets/strings";
import validationSchema from "../../../../data/validationSchema";
import { theme } from "../../../../theme";
import CustomButton from "../../../Buttons/CustomButton";
import FormInput from "../../../FormInput";
import { enterEmailModalStyles } from "./EnterEmailModalContent.Styles";

type props = {
  checkEmail: (email: string) => void;
};

const EnterEmailModalContent: React.FC<props> = ({ checkEmail }) => {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Text style={enterEmailModalStyles.heading}>
        ENTER EMAIL OF THE CLIENT
      </Text>
      <Formik
        initialValues={{
          email: "",
          employer: "",
        }}
        validationSchema={validationSchema.createClient}
        onSubmit={async (values) => {
          setLoading(true);
          await checkEmail(values.email);
          setLoading(false);
        }}
      >
        {({ values, errors, touched, handleSubmit, handleChange }) => (
          <View style={{ marginTop: "10%", flex: 1, marginHorizontal: 30 }}>
            <FormInput
              inputPlaceholder={Strings.EMAIL}
              label={Strings.EMAIL}
              inputValue={values.email}
              setInputValue={handleChange("email")}
              mode="outlined"
              inputWidth="100%"
              error={touched.email && errors.email}
              labelStyle={enterEmailModalStyles.label}
              style={enterEmailModalStyles.input}
              activeOutlineColor="#9797FF"
              outlineColor={theme.colors.btn}
              dense
            />
            {(touched.email && errors.email) === undefined && (
              <View style={enterEmailModalStyles.errorContainer}></View>
            )}
            <CustomButton
              onPress={handleSubmit}
              name="Check Email"
              isLoading={loading}
            />
          </View>
        )}
      </Formik>
    </>
  );
};

export default React.memo(EnterEmailModalContent);
