import { Images } from "../../assets/images";
import { Strings } from "../../assets/strings";
import { Screens } from "../common/Constant";

export default [
  {
    name: Strings.ORGANIZATION,
    image: Images.ORGANIZATION,
    navigateTo: Screens.EDIT_ORGANIZATION_SCREEN,
  },
  {
    name: Strings.ADMIN,
    image: Images.ADMIN,
    navigateTo: Screens.ADMIN_SCREEN,
  },
  {
    name: Strings.CLIENT,
    image: Images.PEOPLE,
    navigateTo: Screens.CLIENT_SCREEN,
  },
  {
    name: Strings.PROFILE,
    image: Images.USER,
    navigateTo: Screens.ORG_PROFILE_EDIT_SCREEN,
  },
  {
    name: Strings.ONBOARDING,
    image: Images.ONBOARDING,
    navigateTo: Screens.ONBOARDING_USERS_SCREEN,
  },
  {
    name: Strings.LOGOUT,
    image: Images.LOG_OUT,
    navigateTo: "",
  },
];
