import { Strings } from "../../../../assets/strings";
import { autoVisasPrivateApi } from "../../../util/axiosInstances/autovisasPrivateApi";
import { getCBP } from "../../../util/getCBP";

const ConfirmInfoController = () => {
  const controller = new AbortController();

  const handleCBP = async (user_id: string) => {
    const api = `/status-protector/${user_id}/run`;
    const cbpResponse = await getCBP(
      user_id,
      controller,
      autoVisasPrivateApi,
      api
    );
    if (cbpResponse.status === 200) {
      return { hasError: false, message: "" };
    } else {
      return { hasError: true, message: Strings.CBP_SERVER_ERROR };
    }
  };
  const cancelCBPRequest = () => {
    controller.abort();
  };

  return {
    handleCBP,
    cancelCBPRequest,
  };
};

export default ConfirmInfoController;
