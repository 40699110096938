import React, { useState } from "react";
import { ScrollView } from "react-native";
import { useRootContext } from "../../../../context/rootContext/RootContext";
import CustomModal from "../../../Modal/CustomModal";
import EditEmail from "../EditEmail";
import VerifyOtp from "../VerifyOtp";

type props = {
  email: string;
  visible: boolean;
  onDismiss: () => void;
  onSendOtpClicked: (email: string) => Promise<{
    hasError: boolean;
    data?: any;
    message?: undefined;
  }>;
  onVerifyPasscode: (
    email: string,
    passcode: string
  ) => Promise<{
    hasError: boolean;
    data?: any;
    message?: undefined;
  }>;
};

const ChangeEmailModal: React.FC<props> = ({
  email,
  visible,
  onDismiss,
  onSendOtpClicked,
  onVerifyPasscode,
}) => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalNumber, setModalNumber] = useState(0);
  const [changedEmail, setChangedEmail] = useState(email);
  const [isLoading, setIsLoading] = useState(false);
  const [isSendPasscodeAgain, setIsSetPasscodeAgain] = useState(false);

  const {
    dimensions: { width: width },
  } = useRootContext();

  const hideModal = () => {
    setModalNumber(0);
    setHasError(false);
    setErrorMessage("");
    onDismiss();
  };

  const onSendOtp = async (email: string, sendPasscodeAgain = false) => {
    setIsSetPasscodeAgain(false);
    !sendPasscodeAgain && setIsLoading(true);
    await onSendOtpClicked(email).then((res) => {
      if (!res.hasError) {
        setHasError(false);
        setErrorMessage("");
        setIsSetPasscodeAgain(sendPasscodeAgain);
        !sendPasscodeAgain && setChangedEmail(email);
        !sendPasscodeAgain && setModalNumber(1);
      } else {
        setHasError(true);
        setErrorMessage(res.message);
      }
    });
    !sendPasscodeAgain && setIsLoading(false);
  };

  const onChangeEmailClicked = async (email: string, passcode: string) => {
    setIsSetPasscodeAgain(false);
    setIsLoading(true);
    await onVerifyPasscode(email, passcode).then((res) => {
      if (!res.hasError) {
        setHasError(false);
        setErrorMessage("");
        hideModal();
      } else {
        setHasError(true);
        setErrorMessage(res.message);
      }
    });
    setIsLoading(false);
  };

  return (
    <CustomModal
      modalStyle={[
        { width: 500, height: 310 },
        width < 550 && { width: "90%" },
      ]}
      visible={visible}
      onDismiss={onDismiss}
    >
      <ScrollView showsVerticalScrollIndicator={false} style={{ flex: 1 }}>
        {modalNumber === 0 ? (
          <EditEmail
            email={email}
            onDismiss={hideModal}
            isLoading={isLoading}
            onSendOtpClicked={onSendOtp}
            hasError={hasError}
            errorMessage={errorMessage}
          />
        ) : modalNumber === 1 ? (
          <VerifyOtp
            email={changedEmail}
            isLoading={isLoading}
            onDismiss={hideModal}
            isSendPasscodeAgain={isSendPasscodeAgain}
            onChangeEmail={onChangeEmailClicked}
            onResendPasscodeAgain={onSendOtp}
            hasError={hasError}
            errorMessage={errorMessage}
          />
        ) : null}
      </ScrollView>
    </CustomModal>
  );
};

export default React.memo(ChangeEmailModal);
