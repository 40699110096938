import { Text } from "react-native-paper";
import React from "react";

import deleteModalStyles from "./DeleteModal.Styles";
import { Client, OrganizationUser } from "../../../screen/Organization/types";
import ModalContent from "./ModalContent";
import CustomShadowModal from "../CustomShadowModal";
import { useRootContext } from "../../../context/rootContext/RootContext";

type Props = {
  visible: boolean;
  isDeleted?: boolean;
  onDismiss: () => void;
  onDelete: () => void;
  user?: OrganizationUser;
  client?: Client;
  error?: string;
};

const DeleteModal: React.FC<Props> = ({
  visible,
  isDeleted,
  onDismiss,
  onDelete,
  user,
  client,
  error,
}) => {
  const {
    dimensions: { width: width },
  } = useRootContext();

  return visible ? (
    <CustomShadowModal
      visible={visible}
      onDismiss={onDismiss}
      style={{
        width: width > 450 ? 400 : "90%",
        height: 230,
      }}
    >
      {isDeleted ? (
        <Text style={deleteModalStyles.deleteMsg}>User Deleted</Text>
      ) : (
        <ModalContent
          user={user}
          client={client}
          onDelete={onDelete}
          onDismiss={onDismiss}
          error={error}
        />
      )}
    </CustomShadowModal>
  ) : null;
};

export default DeleteModal;
