import { View, Pressable, StyleSheet } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../assets/strings";
import { MenuType } from "../../screen/StatusProtector/types/DeshboardTypes";
import menuStyles from "./Menu.Styles";

type Props = {
  isActiveMenu: (menu: MenuType) => boolean;
  changeActiveMenu: (menu: MenuType) => void;
};

const Menu: React.FC<Props> = ({ isActiveMenu, changeActiveMenu }: Props) => {
  return (
    <View style={menuStyles.container}>
      <Pressable
        onPress={() => changeActiveMenu(MenuType.STATUS)}
        style={[
          isActiveMenu(MenuType.STATUS)
            ? menuStyles.firstActiveButton
            : menuStyles.firstInactiveButton,
          menuStyles.btn,
        ]}
      >
        <Text
          style={[
            menuStyles.text,
            isActiveMenu(MenuType.STATUS)
              ? menuStyles.textActiveButton
              : menuStyles.textInactiveButton,
          ]}
        >
          {Strings.YOUR_INFORMATION}
        </Text>
      </Pressable>
      <Pressable
        onPress={() => changeActiveMenu(MenuType.DOCUMENTS)}
        style={[
          isActiveMenu(MenuType.DOCUMENTS)
            ? menuStyles.middleActiveButton
            : menuStyles.middleInactiveButton,
          menuStyles.btn,
        ]}
      >
        <Text
          style={[
            menuStyles.text,
            isActiveMenu(MenuType.DOCUMENTS)
              ? menuStyles.textActiveButton
              : menuStyles.textInactiveButton,
          ]}
        >
          {Strings.YOUR_DOCUMENTS}
        </Text>
      </Pressable>

      <Pressable
        onPress={() => changeActiveMenu(MenuType.WARNINGS)}
        style={[
          isActiveMenu(MenuType.WARNINGS)
            ? menuStyles.middleActiveButton
            : menuStyles.middleInactiveButton,
          menuStyles.btn,
        ]}
      >
        <Text
          style={[
            menuStyles.text,
            isActiveMenu(MenuType.WARNINGS)
              ? menuStyles.textActiveButton
              : menuStyles.textInactiveButton,
          ]}
        >
          {Strings.YOUR_WARNINGS}
        </Text>
      </Pressable>

      <Pressable
        onPress={() => changeActiveMenu(MenuType.TRAVEL)}
        style={[
          isActiveMenu(MenuType.TRAVEL)
            ? menuStyles.middleActiveButton
            : menuStyles.middleInactiveButton,
          menuStyles.btn,
        ]}
      >
        <Text
          style={[
            menuStyles.text,
            isActiveMenu(MenuType.TRAVEL)
              ? menuStyles.textActiveButton
              : menuStyles.textInactiveButton,
          ]}
        >
          {Strings.TRAVEL_HISTORY}
        </Text>
      </Pressable>
      <Pressable
        onPress={() => changeActiveMenu(MenuType.DEPENDENTS)}
        style={[
          isActiveMenu(MenuType.DEPENDENTS)
            ? menuStyles.lastActiveButton
            : menuStyles.lastInactiveButton,
          menuStyles.btn,
        ]}
      >
        <Text
          style={[
            menuStyles.text,
            isActiveMenu(MenuType.DEPENDENTS)
              ? menuStyles.textActiveButton
              : menuStyles.textInactiveButton,
          ]}
        >
          {Strings.DEPENDENT}
        </Text>
      </Pressable>
    </View>
  );
};

export default Menu;
