import { getError, getSuccess } from "./axiosInstances/handleResponse";
import { Apis } from "./axiosInstances/Apis";
import { autoVisasPublicApi } from "./axiosInstances/autovisasPublicApi";

export const login = async (email, password) => {
  try {
    const options = {
      method: "POST",
      url: Apis.LOGIN,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email: email,
        password: password,
      },
    };

    const apiResponse = await autoVisasPublicApi.request(options);
    return getSuccess(apiResponse);
  } catch (error) {
    console.error(error.response);
    return getError(error);
  }
};
