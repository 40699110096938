export const Apis = {
  SLOT_AVAILABLE: "/users/slot-available",
  ADD_TO_WAIT_LIST: "/waitlist/join",
  LOGIN: "/users/login",
  NON_IMMIGRANT_STATUS: "/nonImmigrantStatus",
  GET_DOCUMENTS: "/documents",
  GET_ORGANIZATION_LIST: "/organization/me",
  USERS: "/users",
  DEPLOY: "/deploy",
  REMOVE: "/remove",
  REDEPLOY: "/redeploy",
  INVITE: "/invite",
  MANAGER: "/manager",
  INVITES: "/invites",
  ACCEPT: "/accept",
  REJECT: "/reject",
  PAUSE: "/pause",
  RESUME: "/resume",
  EMAIL_AVAILABLE: "/email-available",
  TRAVEL: "/travel",
  BILLING: "/billing",
  PRICING_TABLE: "/pricing-table",
  CHECKOUT: "/checkout",
  COUNTRIES: "/countries/all",
  DEPENDENTS: "/dependents",
  PORTAL: "/portal",
};
export const OrgApis = {
  ORGANIZATION: "/organization",
  ORG_REFRESH_TOKEN: "/organization/refresh",
  ORG_USER_LOGIN: "/organization/login",
  ORG_USER_REGISTER: "/organization/signup",
  USERS: "/users",
  CLIENTS: "/clients",
  GET_DOCUMENTS: "/documents",
  PROFILE: "/profile",
  PAYMENT: "/billing/pricing-table?type=organization",
  CHECK_OUT: "/billing/organization",
  getCSVUsers: "/onboarding/csv/jobs",
  NON_IMMIGRANT_STATUS: "/non-immigrant-status",
  TRAVEL: "/travel",
  RUN_STATUS_PROTECTOR: "/status-protector/run",
  FAMILY: "/family",
};
