import { OrgApis } from "../axiosInstances/Apis";
import { autoVisasOrgPrivateApi } from "../axiosInstances/autoVisasOrgPrivateApi";
import { getSuccess, getError } from "../axiosInstances/handleResponse";

export const getOwnerProfile = async (token: string) => {
    try {
        const options = {
          method: "GET",
          url: `${OrgApis.ORGANIZATION}${OrgApis.PROFILE}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        };
        const apiResponse = await autoVisasOrgPrivateApi.request(options);
        return getSuccess(apiResponse); 

      } catch (error) {        
        return getError(error);
      }
};

export const editSelfProfile = async (token: string, firstName: string, lastName: string) => {
  try {
    const options = {
      method: "POST",
      url: `${OrgApis.ORGANIZATION}${OrgApis.PROFILE}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        first_name: firstName,
        last_name: lastName,
      },
    };
    const apiResponse = await autoVisasOrgPrivateApi.request(options);
    return getSuccess(apiResponse);
  } catch (error) {
    console.error(error.response);
    return getError(error);
  }
};

export const changePassword = async (token: string, currentPass: string, newPass: string) => {
  try {
    const options = {
      method: "POST",
      url: `${OrgApis.ORGANIZATION}/change-password`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        current_password: currentPass,
        new_password: newPass,
      },
    };
    const apiResponse = await autoVisasOrgPrivateApi.request(options);
    return getSuccess(apiResponse);
  } catch (error) {
    console.error(error.response);
    return getError(error);
  }
};

export const requestEmailChange = async (token: string, email: string) => {
  try {
    const options = {
      method: "POST",
      url: `${OrgApis.ORGANIZATION}/email-change-request`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        email: email
      },
    };
    const response = await autoVisasOrgPrivateApi.request(options);
    return getSuccess(response)
  } catch (error) {
    return getError(error);
  }
};

export const verifyEmailChange = async (token: string, email: string, passcode: string) => {
    try {
      const options = {
        method: "POST",
        url: `${OrgApis.ORGANIZATION}/email-change-verify`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          email: email,
          passcode: passcode
        },
      };
      const response = await autoVisasOrgPrivateApi.request(options);
      return getSuccess(response)
    } catch (error) {
      return getError(error);
    }
  };
