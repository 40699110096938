import { Text } from "react-native";
import { Strings } from "../../../assets/strings";
import { theme } from "../../theme";

const SupportedFileMessage = ({ message }) => {
  return (
    <Text
      style={{
        ...theme.fonts.regular,
        color: theme.colors.btn,
        fontSize: 13,
        fontWeight: "600",
        marginHorizontal: 10,
        marginTop: 10,
        textAlign: "center",
      }}
    >
      {message ? message : Strings.SUPPORTED_FILETYPE}
    </Text>
  );
};
export default SupportedFileMessage;
