import { Formik } from "formik";
import { Animated, View } from "react-native";
import { HelperText, Text } from "react-native-paper";
import { Strings } from "../../../../assets/strings";
import validationSchema from "../../../data/validationSchema";
import { theme } from "../../../theme";
import SubmitButton from "../../Buttons/Submit";
import DropDown from "../../dropDown/DropDown";
import { dependentInfoContentStyles } from "./DependentInfoContent.Styles";
import React, { useContext, useEffect, useRef, useState } from "react";
import ConfirmInfoInput from "../../ConfirmInfo/ConfirmInfoInput";
import { DependentModalData } from "../../../screen/StatusProtector/types/Dependentypes";
import { createDependentProfile } from "../../../util/dependent/createDependentProfile";
import { getCountriesList } from "../../../util/countries";
import { useStoreContext } from "../../../context/storeContext/StoreProvider";
import { formatDate } from "../../../common/DateFormat";
import ProfilePageHeader from "../../Dependent/ProfilePageHeader";
import { AuthContext } from "../../../context/AuthContext";
import { updateDependentProfile } from "../../../util/dependent/updateDependentProfile";
import ProgressBarTimer from "../../progessBarTimerButton/ProgressBarTimer";

type props = {
  isDependentScreen?: boolean;
  onCreate?: () => void;
  modalData?: DependentModalData;
  editable?: boolean;
  setIsLoading?: (loading: boolean) => void;
  isLoading?: boolean;
};

const { GIVEN_NAME, FAMILY_NAME, DATE_OF_BIRTH, PASSPORT_ID } =
  Strings.ConfirmInfo;

const DependentInfoContent: React.FC<props> = ({
  onCreate,
  isDependentScreen = false,
  modalData,
  editable,
  setIsLoading,
  isLoading,
}) => {
  const { setCountriesData, countriesData } = useStoreContext();
  const [isEditable, setEditable] = useState(editable);
  const { loginState } = useContext(AuthContext);
  let progressBarValue = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (!countriesData.length) {
      getCountriesList().then((res) => {
        const list = res.data.map((item) => ({
          label: item.name,
          value: item.alpha3Code,
        }));
        setCountriesData(list);
      });
    }
  }, []);

  const updateDependentProfileHandler = async (values) => {
    setIsLoading(true);
    const data = await updateDependentProfile(
      loginState,
      modalData.dependentData.dId,
      {
        first_name: values.first_name,
        last_name: values.family_name,
        date_of_birth: values.birth_date,
        passport_number: values.passport_number,
        citizenship: values.passport_country,
        email: values.email,
      }
    );

    setIsLoading(false);
  };
  const createDependentProfileHandler = async (values) => {
    const data = await createDependentProfile(loginState, modalData.dId, {
      first_name: values.first_name,
      last_name: values.family_name,
      date_of_birth: values.birth_date,
      passport_number: values.passport_number,
      citizenship: values.passport_country,
    });
    if (data.status === 200) {
      onCreate();
    }
    progressBarValue.setValue(1);
    setTimeout(async () => {
      setIsLoading(false);
    }, 500);
  };

  return (
    <Formik
      initialValues={{
        first_name: modalData?.dependentData
          ? modalData.dependentData.first_name
          : "",
        family_name: modalData?.dependentData
          ? modalData.dependentData.last_name
          : "",
        birth_date: modalData?.dependentData
          ? formatDate(modalData.dependentData.date_of_birth, "YYYY-MM-DD")
          : "",
        passport_number: modalData?.dependentData
          ? modalData.dependentData.passport_number
          : "",
        passport_country: modalData?.dependentData
          ? modalData.dependentData.citizenship
          : "",
        email: modalData ? modalData.email : "",
      }}
      validationSchema={validationSchema.confirmDependentInfo}
      onSubmit={async (values) => {
        if (!isDependentScreen) {
          !isLoading
            ? setIsLoading(true)
            : createDependentProfileHandler(values);
        } else {
          updateDependentProfileHandler(values);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
      }) => (
        <>
          {isDependentScreen && (
            <ProfilePageHeader
              isEditable={isEditable}
              onEdit={() =>
                isEditable ? handleSubmit() : setEditable(!isEditable)
              }
            />
          )}
          <View style={{ marginTop: 10, zIndex: 2 }}>
            <View style={dependentInfoContentStyles.inputsContainer}>
              <ConfirmInfoInput
                editable={isEditable}
                label={GIVEN_NAME}
                placeholder={GIVEN_NAME}
                value={values.first_name}
                inputContainer={dependentInfoContentStyles.inputContainer}
                setValue={handleChange("first_name")}
                error={touched.first_name && errors.first_name}
                handleBlur={handleBlur("first_name")}
                outlineColor={theme.colors.inputActiveOutlineColor}
              />
              <ConfirmInfoInput
                editable={isEditable}
                label={FAMILY_NAME}
                placeholder={FAMILY_NAME}
                value={values.family_name}
                inputContainer={dependentInfoContentStyles.inputContainer}
                setValue={handleChange("family_name")}
                error={touched.family_name && errors.family_name}
                handleBlur={handleBlur("family_name")}
                outlineColor={theme.colors.inputActiveOutlineColor}
              />
            </View>
            <View style={dependentInfoContentStyles.inputsContainer}>
              <ConfirmInfoInput
                editable={isEditable}
                label={DATE_OF_BIRTH}
                placeholder={DATE_OF_BIRTH}
                value={values.birth_date}
                inputContainer={dependentInfoContentStyles.inputContainer}
                setValue={handleChange("birth_date")}
                error={touched.birth_date && errors.birth_date}
                handleBlur={handleBlur("birth_date")}
                outlineColor={theme.colors.inputActiveOutlineColor}
              />
              <ConfirmInfoInput
                editable={isEditable}
                label={PASSPORT_ID}
                placeholder={PASSPORT_ID}
                value={values.passport_number}
                inputContainer={dependentInfoContentStyles.inputContainer}
                setValue={handleChange("passport_number")}
                error={touched.passport_number && errors.passport_number}
                handleBlur={handleBlur("passport_number")}
                outlineColor={theme.colors.inputActiveOutlineColor}
              />
            </View>
            <View style={[dependentInfoContentStyles.inputsContainer]}>
              <ConfirmInfoInput
                editable={isDependentScreen ? isEditable : !editable}
                label={Strings.EMAIL}
                placeholder={Strings.EMAIL}
                value={values.email}
                inputContainer={dependentInfoContentStyles.inputContainer}
                setValue={handleChange("email")}
                error={touched.email && errors.email}
                handleBlur={handleBlur("email")}
                outlineColor={theme.colors.inputActiveOutlineColor}
              />
              <View style={{ flex: 1 }}>
                <Text
                  style={[
                    {
                      ...theme.fonts.black,
                      marginBottom: 1,
                      fontSize: 16,
                      color: theme.colors.btn,
                      textTransform: "uppercase",
                    },
                  ]}
                >
                  {Strings.CITIZEN_SHIP}
                </Text>
                <>
                  <View
                    style={[
                      dependentInfoContentStyles.dropdownStyle,
                      {
                        borderColor: !isDependentScreen
                          ? theme.colors.inputActiveOutlineColor
                          : theme.colors.transparent,
                        zIndex: 5,
                      },
                    ]}
                  >
                    <DropDown
                      disable={!isEditable}
                      receiptTypeOptions={countriesData}
                      onSelection={handleChange("passport_country")}
                      selectedOption={values.passport_country}
                    />
                  </View>
                  <HelperText
                    style={{ color: theme.colors.red, fontWeight: "700" }}
                    type="error"
                    visible={
                      !!(touched.passport_country && errors.passport_country)
                    }
                  >
                    {errors.passport_country}
                  </HelperText>
                </>
              </View>
            </View>
          </View>
          {!isDependentScreen && (
            <View style={dependentInfoContentStyles.btnContainer}>
              <ProgressBarTimer
                showProgressBar={isLoading}
                handleFunction={handleSubmit}
                timer={62000}
                progressBarValue={progressBarValue}
              >
                <SubmitButton
                  isLoading={isLoading}
                  onPress={handleSubmit}
                  name={Strings.ADD_DEPENDENT}
                />
              </ProgressBarTimer>
            </View>
          )}
        </>
      )}
    </Formik>
  );
};
export default React.memo(DependentInfoContent);
