import React from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../../assets/strings";
import { NonImmigrantStatusType } from "../../../../screen/Organization/types";
import AVTooltip from "../../../Tooltip";
import { clientBoxItemStyles } from "../ClientBoxItem/ClientBoxItem.Styles";
import { theme } from "../../../../theme";

type props = {
    nonImmigrantStatus: NonImmigrantStatusType;
    profileCompleted: boolean;
    openModal: () => void;
}

const ClientStatusText: React.FC<props> = ({ profileCompleted, nonImmigrantStatus, openModal }) => {
    
    if (profileCompleted) {
        if (nonImmigrantStatus) {
            const isWarningStatus = 
                nonImmigrantStatus?.status.toLocaleLowerCase() === Strings.WARNING_STATUS.toLocaleLowerCase();

            if (isWarningStatus) {
                return (
                    <View  style={[style.warningTextStyle]}>
                        <AVTooltip text="View Warnings" pressableStyle={[style.warningTextStyle]}>
                            <Text
                                style={[style.warningTextStyle, style.warningText]}
                                onPress={openModal}
                                numberOfLines={1}
                            >
                                {nonImmigrantStatus?.status}
                            </Text>
                        </AVTooltip>
                    </View>
                )
            }

            if (nonImmigrantStatus.status.toLowerCase() === Strings.INVALID.toLowerCase()) {
                return <Text style={[style.textStyle, style.completeYourProfileText]} numberOfLines={1}>
                    {nonImmigrantStatus?.status}
                </Text>
            }

            return (
                <Text style={[style.textStyle, style.successfulText]} numberOfLines={1}>
                    {nonImmigrantStatus?.status}
                </Text>
            )

        }

        return (
            <Text style={[style.textStyle, style.warningText]} numberOfLines={1}>
                { Strings.N_A }
            </Text>
        )
    }

    return (
        <Text
            style={[style.textStyle, style.completeYourProfileText]}
            numberOfLines={2}
        >
            { Strings.COMPLETE_YOUR_PROFILE }
        </Text>
    )
};

const style = StyleSheet.create({
    textStyle: {
        flex: 1,
        alignSelf: "center",
        fontSize: 13,
        color: "rgb(91, 51, 150)",
        textAlign: "center",
        justifyContent: "center",
        marginHorizontal: 1,
    },
    warningTextStyle: {
        flex: 1,
        fontSize: 13,
        color: theme.colors.btn,
        textAlign: "center",
        justifyContent: "center",
        marginHorizontal: 1,
        textDecorationLine: "underline"
    },
    warningText: {
        color: "rgb(251, 192, 45)",
    },
    successfulText: {
        color: "rgb(129, 199, 132)"
    },
    completeYourProfileText: {
        color: "red",
    },
});

export default ClientStatusText;