import React, { useMemo } from "react";
import { View, Text } from "react-native";
import { theme } from "../../../../theme";
import DropDown from "../../../dropDown/DropDown";
type props = {
  item: string;
  zIndex: number;
  setData: (key, value) => void;
  data: string[];
};
export const TableMappingDropDown: React.FC<props> = ({
  item,
  zIndex,
  setData,
  data,
}) => {
  const columnData = useMemo(
    () =>
      data.map((item) => {
        return {
          label: item,
          value: item,
        };
      }),
    [data]
  );
  return (
    <View
      style={{
        flexDirection: "row",
        zIndex: zIndex,
        alignItems: "center",
        marginTop: 10,
      }}
    >
      <Text style={{ flex: 1 }}>{item}</Text>
      <View
        style={{
          borderWidth: 1,
          borderRadius: 5,
          marginTop: 5,
          flexDirection: "row",
          alignSelf: "flex-start",
          borderColor: theme.colors.inputActiveOutlineColor,
        }}
      >
        <DropDown
          receiptTypeOptions={columnData}
          onSelection={(val) => setData(item, val)}
          selectedOption={null}
        />
      </View>
    </View>
  );
};
