import { getError, getSuccess } from "./axiosInstances/handleResponse";
import { autoVisasPublicApi } from "./axiosInstances/autovisasPublicApi";
import { Apis } from "./axiosInstances/Apis";

export const slotAvailability = async () => {
  try {
    const options = {
      method: "GET",
      url: Apis.SLOT_AVAILABLE,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const getSlotAvailable = await autoVisasPublicApi.request(options);
    const { available } = getSuccess(getSlotAvailable)?.data;
    return available;
  } catch (error) {
    return getError(error);
  }
};
export const addToWaitList = async (email) => {
  try {
    const options = {
      method: "POST",
      url: Apis.ADD_TO_WAIT_LIST,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email,
      },
    };
    const apiResponse = await autoVisasPublicApi.request(options);
    const { data, status } = getSuccess(apiResponse);
    return {
      email: data.email,
      status,
    };
  } catch (error) {
    return getError(error);
  }
};
