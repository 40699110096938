import { ReactElement, useState } from "react";
import { Text, TextInput, HelperText } from "react-native-paper";
import { StyleSheet, TextStyle, View, ViewStyle } from "react-native";
import { theme } from "../../theme";
import ClickableText from "../ClickableText";
type Props = {
  inputPlaceholder: any;
  inputValue: any;
  setInputValue?: any;
  containerStyle?: ViewStyle;
  label?: any;
  iconRight?: ReactElement<any, any>;
  inputWidth?: any;
  labelStyle?: TextStyle;
  inputStyle?: {};
  error?: string;
  editable?: boolean;
  autoCapitalize?: "none" | "sentences" | "words" | "characters" | undefined;
  placeholderTextColor?: string;
  mode?: "flat" | "outlined" | undefined;
  style?: {};
  activeOutlineColor?: string;
  outlineColor?: string;
  dense?: boolean | undefined;
  onBlur?: (e: any) => void;
  secureTextEntry?: boolean;
  theme?: {};
  activeUnderlineColor?: string;
  updateText?: string;
  onUpdatePress?: () => void;
};

const FormInput: React.FC<Props> = ({
  label,
  inputPlaceholder,
  inputValue,
  setInputValue,
  iconRight = false,
  inputWidth = 350,
  containerStyle,
  labelStyle,
  inputStyle,
  error = false,
  editable = true,
  updateText,
  onUpdatePress,
  ...inputProps
}) => {
  const onChangeValue = (inputValue) => {
    setInputValue(inputValue);
  };

  return (
    <View
      style={[inputStyles.container, { maxWidth: inputWidth }, containerStyle]}
    >
      <View style={{ flexDirection: "row" }}>
        <Text style={[inputStyles.label, labelStyle]}>{label}</Text>
        {updateText ? (
          <ClickableText
            style={inputStyles.updateTxt}
            text={updateText}
            onPress={onUpdatePress}
          />
        ) : null}
      </View>
      <TextInput
        placeholder={inputPlaceholder}
        value={inputValue}
        onChangeText={(inputValue) => onChangeValue(inputValue)}
        style={[
          inputStyles.textInput,
          {
            maxWidth: inputWidth,
          },
          inputStyle,
        ]}
        right={iconRight}
        editable={editable}
        error={!!error}
        {...inputProps}
      />
      {error ? (
        <HelperText
          style={inputStyles.error}
          type="error"
          visible={error !== undefined}
        >
          {error}
        </HelperText>
      ) : null}
    </View>
  );
};

const inputStyles = StyleSheet.create({
  container: {
    width: "100%",
  },
  label: {
    marginBottom: 1,
    fontSize: 22,
    color: theme.colors.btn,
    fontFamily: "Brother1816Black",
    textTransform: "uppercase",
    flex: 1,
  },
  updateTxt: {
    marginBottom: 1,
    fontSize: 15,
    marginEnd: 5,
    color: theme.colors.bottom2,
  },
  textInput: {
    fontSize: 22,
    color: theme.colors.btn,
    marginTop: 15,
    alignSelf: "baseline",
    backgroundColor: "transparent",
  },
  error: {
    color: theme.colors.red,
    fontWeight: "700",
  },
});

export default FormInput;
