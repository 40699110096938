export enum StatusType {
  IN_VALID = "invalid",
  VALID = "valid",
  WARNING = "warning",
  ERROR = "error",
}
export enum MenuType {
  STATUS = "status",
  DOCUMENTS = "documents",
  WARNINGS = "warnings",
  TRAVEL = "travel",
  DEPENDENTS = "dependents"
}
export enum Tags {
  HAS_PENDING_NIV = "hasPendingNIV",
  HAS_PENDING_AOS = "hasPendingAOS",
}
export const TagsForWarning = {
  unexpectedType: "Unexpected Admission Class",
  gapInDates: "Gap in Dates",
  majorGapInDates: "Major gap in dates",
  cutShort: "Unexpected Cut Short expiration date",
  extended: "Unexpected Extended expiration date",
  expiringSoon: "Status Expires Soon (less than six months)",
};
export const I94_EXPIRED = "Your I-94 is expired";
export type ImmigrantStatus = {
  admitUntil: string;
  dateEntry: string;
  status: string;
  tags: string[];
  visa_type: string;
};

export type UserInfo = {
  fullName: string;
  citizenship: string;
  passportNumber: string;
  admissionClass: string;
};
export type DocumentBody = {
  id: string;
  uid: string;
  given_name: string;
  family_name: string;
  visa_class: string;
  admit_date: string;
  admit_until: string;
  doc_type: string;
  file_name: string;
};

export type TravelHistoryData = {
  date: string;
  id: string;
  is_arrival: boolean;
  location: string;
  uid: string;
};
