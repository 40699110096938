import { StackActions } from "@react-navigation/native";
import { Formik } from "formik";
import React, { useState } from "react";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Button, Text } from "react-native-paper";
import { Strings } from "../../../../assets/strings";
import {
  PRIVACY_POLICY_URL,
  Screens,
  TERMS_AND_CONDITIONS_URL,
} from "../../../common/Constant";
import BgImageContainer from "../../../components/BgImageContainer";
import SubmitButton from "../../../components/Buttons/Submit";
import Checkbox from "../../../components/Checkbox";
import ErrorText from "../../../components/ErrorText";
import FormInput from "../../../components/FormInput";
import { ProgressBar } from "../../../components/progressBar/ProgressBar";
import TextLink from "../../../components/TextLink";
import { useRootContext } from "../../../context/rootContext/RootContext";
import validationSchema from "../../../data/validationSchema";
import { OrganizationSignUpController } from "./controller/OrganizationSignUpController";
import { orgSignUpStyles } from "./OrgSignup.Styles";
import { useNavigation } from "@react-navigation/native";

type props = {};

const OrganizationSignUp: React.FC<props> = () => {
  const [isChecked, setChecked] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigation = useNavigation();

  const {
    dimensions: { width: width },
  } = useRootContext();
  const { handleOrgRegister } = OrganizationSignUpController();

  return (
    <ProgressBar loading={false}>
      <BgImageContainer isOrganization={true}>
        <ScrollView>
          <View
            style={[
              orgSignUpStyles.container,
              width < 600 && {
                width: "100%",
                paddingHorizontal: 20,
              },
            ]}
          >
            <Text style={orgSignUpStyles.heading}>
              {Strings.CREATE_ACCOUNT}
            </Text>
            <Text style={orgSignUpStyles.intro}>
              {Strings.CREATE_ACCOUNT_DESC}
            </Text>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                confirmPassword: "",
              }}
              validationSchema={validationSchema.orgSignUp}
              onSubmit={async (values) => {
                setMessage("");
                if (isChecked) {
                  setLoading(true);
                  const response = await handleOrgRegister(
                    values.firstName,
                    values.lastName,
                    values.email,
                    values.password
                  );
                  if (response) {
                    setMessage(response.data.message);
                  }
                  setLoading(false);
                } else {
                  setMessage(Strings.TERMS_CONDITION_CHECKBOX_WARNING);
                }
              }}
            >
              {({ values, errors, touched, handleSubmit, handleChange }) => (
                <View
                  style={{
                    width: "100%",
                    marginTop: 15,
                  }}
                >
                  <View style={orgSignUpStyles.formContainer}>
                    <FormInput
                      label={Strings.FIRST_NAME}
                      inputPlaceholder={Strings.FIRST_NAME}
                      inputValue={values.firstName}
                      setInputValue={handleChange("firstName")}
                      mode="outlined"
                      inputWidth="100%"
                      error={touched.firstName && errors.firstName}
                      labelStyle={orgSignUpStyles.label}
                      style={orgSignUpStyles.input}
                      activeOutlineColor="#9797FF"
                      outlineColor="#9797FF"
                      dense
                    />
                  </View>
                  <View style={orgSignUpStyles.formContainer}>
                    <FormInput
                      label={Strings.LAST_NAME}
                      inputPlaceholder={Strings.LAST_NAME}
                      inputValue={values.lastName}
                      setInputValue={handleChange("lastName")}
                      mode="outlined"
                      inputWidth="100%"
                      error={touched.lastName && errors.lastName}
                      labelStyle={orgSignUpStyles.label}
                      style={orgSignUpStyles.input}
                      activeOutlineColor="#9797FF"
                      outlineColor="#9797FF"
                      dense
                    />
                  </View>
                  <View style={orgSignUpStyles.formContainer}>
                    <FormInput
                      label={Strings.EMAIL}
                      inputPlaceholder={Strings.EMAIL}
                      inputValue={values.email}
                      setInputValue={handleChange("email")}
                      mode="outlined"
                      inputWidth="100%"
                      error={touched.email && errors.email}
                      labelStyle={orgSignUpStyles.label}
                      style={orgSignUpStyles.input}
                      activeOutlineColor="#9797FF"
                      outlineColor="#9797FF"
                      dense
                    />
                  </View>
                  <View style={orgSignUpStyles.formContainer}>
                    <FormInput
                      label={Strings.PASSWORD}
                      inputPlaceholder={Strings.PASSWORD}
                      inputValue={values.password}
                      setInputValue={handleChange("password")}
                      mode="outlined"
                      inputWidth="100%"
                      error={touched.password && errors.password}
                      labelStyle={orgSignUpStyles.label}
                      style={orgSignUpStyles.input}
                      activeOutlineColor="#9797FF"
                      outlineColor="#9797FF"
                      secureTextEntry={true}
                      dense
                    />
                  </View>
                  <View style={orgSignUpStyles.formContainer}>
                    <FormInput
                      label={Strings.CONFIRM_PASSWORD}
                      inputPlaceholder={Strings.CONFIRM_PASSWORD}
                      inputValue={values.confirmPassword}
                      setInputValue={handleChange("confirmPassword")}
                      mode="outlined"
                      inputWidth="100%"
                      secureTextEntry={true}
                      error={touched.confirmPassword && errors.confirmPassword}
                      labelStyle={orgSignUpStyles.label}
                      style={orgSignUpStyles.input}
                      activeOutlineColor="#9797FF"
                      outlineColor="#9797FF"
                      dense
                    />
                  </View>
                  <Text style={{ color: "#5B3396", marginVertical: 20 }}>
                    {Strings.ALREADY_HAVE_ACCOUNT}
                    <Text
                      style={{
                        color: "#5B3396",
                        textDecorationLine: "underline",
                        marginLeft: 5,
                      }}
                      onPress={() => navigation.navigate(Screens.LOGIN)}
                    >
                      {Strings.LOGIN}
                    </Text>
                  </Text>
                  <View style={orgSignUpStyles.policyContainer}>
                    <Checkbox
                      onChecked={(val) => {
                        setChecked(val);
                      }}
                    />
                    <Text selectable={false} style={orgSignUpStyles.text}>
                      {Strings.I_AGREE_WITH}
                      <TextLink
                        text={Strings.TERMS_AND_CONDITIONS}
                        url={TERMS_AND_CONDITIONS_URL}
                      />
                      {Strings.AND}
                      <TextLink
                        text={Strings.PRIVACY_POLICY}
                        url={PRIVACY_POLICY_URL}
                      />
                    </Text>
                  </View>
                  <SubmitButton
                    style={orgSignUpStyles.btnStyle}
                    onPress={handleSubmit}
                    isLoading={isLoading}
                    isDisabled={!errors && !isChecked}
                    name={Strings.CONTINUE}
                  />
                  {message ? (
                    <ErrorText
                      txtStyle={{ marginBottom: 10 }}
                      error={message}
                    />
                  ) : null}
                </View>
              )}
            </Formik>
          </View>
        </ScrollView>
      </BgImageContainer>
    </ProgressBar>
  );
};

export default React.memo(OrganizationSignUp);
