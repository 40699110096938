import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ScrollView, View } from "react-native";
import { Text } from "react-native-paper";
import useMenuStateHandler from "../../StatusProtector/hook/useMenuStateHandler";
import BgImageContainer from "../../../components/BgImageContainer";
import dashboardStyles from "../../StatusProtector/StatusProtector.Styles";
import Logo from "../../../components/StatusProtector/Logo";
import DateAdmitUntil from "../../../components/StatusProtector/DateAdmitUntil";
import NonImmigrantStatus from "../../../components/StatusProtector/NonImmigrantStatus";
import StatusProtectorBody from "../../../components/StatusProtector/StatusProtectorBody";
import Status from "../../../components/Status";
import Documents from "../../../components/Documents";
import Warnings from "../../../components/Warnings";
import Dependents from "../../../components/Dependents";
import Menu from "../../../components/Menu";
import TravelHistory from "../../../components/TravelHistory";
import { MenuType } from "../../StatusProtector/types/DeshboardTypes";
import { NonImmigrantStatusType } from "../types";
import { decryptedData } from "../../../common/Utils";
import { useRoute } from "@react-navigation/native";
import { useOrgAuthContext } from "../../../context/OrganizationAuthContext";
import { formatDate } from "../../../common/DateFormat";
import { SPClientController } from "./controller/SPClientScreenController";
import ClientDocumentScreenController from "../ClientDocumentsScreen/controller/ClientDocumentScreenController";
import { Tags } from "../../StatusProtector/types/DeshboardTypes";
import { Strings } from "../../../../assets/strings";
import uploadDocumentStyles from "../../UploadDocuments/UploadDocumentsForm.Styles";
import documentsController from "../../../components/Documents/controller/documentsController";
import FooterButtons from "../../../components/StatusProtector/FooterButtons";
import { ProgressBar } from "../../../components/progressBar/ProgressBar";
import ErrorText from "../../../components/ErrorText";
import { DocumentBody } from "../../StatusProtector/types/DeshboardTypes";
import DeleteDocumentModal from "../../../components/StatusProtector/DeleteDocumentModal";
import { Client } from "../types";
import { OrganizationNavigatorScreenProps } from "../../../navigation/types";
import { DependentList } from "../../StatusProtector/types/Dependentypes";

type Props = {
}

const StatusProtectorClient: React.FC<Props> = () => {
    const { params } = useRoute<OrganizationNavigatorScreenProps<"ClientStatusProtectorScreen">["route"]>();
    const clientFromParams: Client = useMemo(() => (
        decryptedData(params.data)
    ), [params.data]);

    const [nonImmigrantStatus, setNonImmigrantStatus] = useState<NonImmigrantStatusType>({
        user_id: clientFromParams.non_immigrant_status.user_id,
        document_meta_id: clientFromParams.non_immigrant_status.document_meta_id,
        status: clientFromParams.non_immigrant_status.status,
        visa_type: clientFromParams.non_immigrant_status.visa_type,
        document_id: clientFromParams.non_immigrant_status.document_id,
        issue_date: clientFromParams.non_immigrant_status.issue_date,
        expiration_date: clientFromParams.non_immigrant_status.expiration_date,
        tags: clientFromParams.non_immigrant_status.tags,
        dismissed_tags: clientFromParams.non_immigrant_status.dismissed_tags,
        last_fetch_at: clientFromParams.non_immigrant_status.last_fetch_at,
        total_cap_out_days_in_country: clientFromParams.non_immigrant_status.total_cap_out_days_in_country,
        total_cap_out_days_allowed: clientFromParams.non_immigrant_status.total_cap_out_days_allowed,
        cap_out_days_remaining: clientFromParams.non_immigrant_status.cap_out_days_remaining,
        has_i140: clientFromParams.non_immigrant_status.has_i140,
        is_intermittent: clientFromParams.non_immigrant_status.is_intermittent,
    });
    const [documents, setDocuments] = useState([]);
    const [travelHistory, setTravelHistory] = useState([]);
    const [dependentList, setDependentList] = useState<DependentList[]>([]);
    const [isLoading, setLoading] = useState(true);
    const [message, setError] = useState("");
    const [typeMessage, setTypeMessage] = useState(Strings.TYPE_MESSAGE_ERROR);
    const [isDelectDocLoading, setDeleteDocLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [docToDelete, setDocToDelete] = useState<DocumentBody>();
    const { changeActiveMenu, isActiveMenu, activeMenu } = useMenuStateHandler(MenuType.STATUS);
    const { orgAuthState } = useOrgAuthContext(); 
    const {
        getClientNonImmigrantStatusInfo,
        getTravelList,
        getClientFamily
    } = SPClientController();

    const {
        getAllDocs,
        deleteDocument
    } = ClientDocumentScreenController();

    const { findValidDocuments } = documentsController();

    const getNonImmgrantStatus = async () => {
        const response = await getClientNonImmigrantStatusInfo(clientFromParams.uid, orgAuthState.orgId, orgAuthState.orgUserToken);
        if (!response.hasError) setNonImmigrantStatus(response.data);
    }

    useEffect(() => {
        setLoading(true);
        getNonImmgrantStatus();
        
        setLoading(false);
    }, []);

    const getDocuments = useCallback(async () => {
        setLoading(true);
        const response = await getAllDocs(orgAuthState.orgId, orgAuthState.orgUserToken, clientFromParams.uid);
        if (!response.hasError) {
            const documentsData = findValidDocuments(response.data);
            setDocuments(documentsData);
            setError("");
        } else {
            setError(response.data);
        }
        setLoading(false);
    }, [setLoading]);

    const getTravelHistory = useCallback(async () => {
        setLoading(true);
        const response = await getTravelList(clientFromParams.uid, orgAuthState.orgId, orgAuthState.orgUserToken);
        if (!response.hasError) setTravelHistory(response.data);
        setLoading(false);
    }, [setLoading]);

    const getClientFamilyData = useCallback(async () => {
        setLoading(true);
        const response = await getClientFamily(clientFromParams.uid, orgAuthState.orgId, orgAuthState.orgUserToken);
        if (!response.hasError) setDependentList(response.data);
        setLoading(false)
    }, [setLoading]);

    useEffect(() => {
        let ignore = false; 

        if (!ignore) {
            if (isActiveMenu(MenuType.DOCUMENTS)) {
                getDocuments();
            }
    
            if (isActiveMenu(MenuType.TRAVEL)) {
                getTravelHistory();
            }

            if(isActiveMenu(MenuType.DEPENDENTS)) {
                getClientFamilyData();
            }
        }

        return () => {
            ignore = true;
        }
        
    }, [activeMenu]);

    const showDeleteDocModal = (documentBody: DocumentBody) => {
        setDocToDelete(documentBody);
        setModalVisible(!modalVisible);
    };

    const deleteDoc = async () => {
        setDeleteDocLoading(true);

        const response = await deleteDocument(orgAuthState.orgId, orgAuthState.orgUserToken, clientFromParams.uid, docToDelete.id);
        setModalVisible(!modalVisible);
        if (!response.hasError) {
            await getDocuments();
            await getNonImmgrantStatus();
        } else {
            setError(response.data);
        }

        setDeleteDocLoading(false);
    }

    const hasPendingTags = useMemo(() => {
        const tags: string[] = nonImmigrantStatus?.tags;
        return {
          hasPendingAOS: tags?.find((tag) => tag === Tags.HAS_PENDING_AOS),
          hasPendingNIV: tags?.find((tag) => tag === Tags.HAS_PENDING_NIV),
        };
    }, [nonImmigrantStatus?.tags]);

   return (
        <BgImageContainer isOrganization={true} footer={Strings.STATUS_PROTECTOR_FOOTER}>
            <View style={{ flex: 1 }}>
                <ScrollView contentContainerStyle={dashboardStyles.container}>
                    <ProgressBar loading={isLoading}>
                        {!clientFromParams.subscription_active &&
                        !clientFromParams.organization_id ? (
                        <ErrorText
                            error={Strings.PLAN_EXPIRED}
                            txtStyle={{ textAlign: "center" }}
                        />
                        ) : null}
                        <View style={dashboardStyles.body}>
                            <Logo />
                            <DateAdmitUntil
                                admitUntil={formatDate(nonImmigrantStatus?.expiration_date, "MMM. DD, YYYY")}
                                hasPendingNIV={hasPendingTags.hasPendingNIV}
                                hasPendingAOS={hasPendingTags.hasPendingAOS}
                            />
                            <NonImmigrantStatus
                                status={nonImmigrantStatus?.status}
                            /> 
                            <StatusProtectorBody>
                                {isActiveMenu(MenuType.STATUS) ? (
                                    <Status
                                        name={`${clientFromParams.first_name}${clientFromParams.last_name}`}
                                        passportNumber={clientFromParams.passport_number}
                                        dateEntry={formatDate(nonImmigrantStatus?.issue_date, "MM/DD/YY")}
                                        admisionClass={nonImmigrantStatus?.visa_type ? nonImmigrantStatus?.visa_type : "N/A"}
                                        citizenship={clientFromParams.citizenship}
                                    />
                                ) : isActiveMenu(MenuType.DOCUMENTS) ? (
                                    <Documents
                                        documents={documents}
                                        isLoading={isLoading}
                                        setIsLoading={setLoading}
                                        deleteDocument={showDeleteDocModal}
                                        clientId={clientFromParams.uid}
                                    />
                                ) : isActiveMenu(MenuType.WARNINGS) ? (
                                    <Warnings tags={nonImmigrantStatus?.tags} />
                                ) : isActiveMenu(MenuType.TRAVEL) ? (
                                    <TravelHistory
                                        isLoading={isLoading}
                                        travelHistoryList={travelHistory}
                                    />
                                ) : (
                                    <Dependents
                                        dependentList={dependentList}
                                        isOrg={true}
                                    />
                                )}
                                <Menu isActiveMenu={isActiveMenu} changeActiveMenu={changeActiveMenu} />
                            </StatusProtectorBody>
                            {message ? (
                                <Text
                                style={[
                                    typeMessage === Strings.TYPE_MESSAGE_ERROR
                                    ? uploadDocumentStyles.errorText
                                    : uploadDocumentStyles.successfulText,
                                    { marginLeft: 45, marginTop: 15 },
                                ]}
                                >
                                {message}
                                </Text>
                            ) : null}
                            <FooterButtons
                                activeMenu={activeMenu}
                                setMessage={(type: string, message: string) => {
                                    setTypeMessage(type);
                                    setError(message);
                                }}
                                reloadNonImmigrantStatusCallback={getNonImmgrantStatus}
                                disabled={clientFromParams.account_paused}
                                isActiveMenu={isActiveMenu}
                                uploadedSuccessFully={() => {
                                    getDocuments();
                                    getNonImmgrantStatus();
                                }}
                                clientId={clientFromParams.uid}
                            />
                        </View>
                    </ProgressBar>
                </ScrollView>
                <DeleteDocumentModal
                    visible={modalVisible}
                    onDismiss={() => setModalVisible(!modalVisible)}
                    isLoading={isDelectDocLoading}
                    docName={docToDelete ? docToDelete.file_name : ""}
                    onDelete={deleteDoc}
                />
            </View>
        </BgImageContainer>
    );
};

export default React.memo(StatusProtectorClient);