import React, { useState } from "react";
import { ScrollView, View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../assets/strings";
import { useRootContext } from "../../../context/rootContext/RootContext";
import CustomButton from "../../Buttons/CustomButton";
import Checkbox from "../../Checkbox";
import CustomShadowModal from "../CustomShadowModal";
import { filterModalStyles } from "./FilterModal.Styles";

type props = {
  visible: boolean;
  onDismiss: () => void;
  setFilter: (status: string[]) => void;
  filter: string[];
  statusList: string[];
};

const FilterModal: React.FC<props> = ({
  visible,
  onDismiss,
  setFilter,
  filter,
  statusList,
}) => {
  const [filterStatus, setFilteredStatus] = useState([]);
  const {
    dimensions: { width: width },
  } = useRootContext();

  const closeModal = () => {
    setFilteredStatus(filter.length ? [...filter] : []);
    onDismiss();
  };

  const addStatusFilter = (newStatus: string) => {
    !filterStatus.length && setFilteredStatus[newStatus];

    let removeStatus = false;
    filterStatus.map((status) => {
      if (newStatus === status) {
        removeStatus = true;
      }
    });

    removeStatus
      ? setFilteredStatus(filterStatus.filter((status) => status !== newStatus))
      : setFilteredStatus((status) => [...status, newStatus]);
  };

  const applyFilter = () => {
    setFilter(filterStatus);
    onDismiss();
  };

  const clearFilter = () => {
    setFilteredStatus([]);
    setFilter([]);
    onDismiss();
  };

  return (
    <CustomShadowModal
      visible={visible}
      onDismiss={closeModal}
      style={{
        width: width > 450 ? 400 : "90%",
        height: 300,
      }}
    >
      <Text style={filterModalStyles.mainHeading}>{Strings.FILTER}</Text>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ paddingHorizontal: "10%" }}
      >
        {statusList.length ? (
          <>
            <Text style={[filterModalStyles.text, { marginBottom: 10 }]}>
              {Strings.STATUS}
            </Text>
            {statusList.map((status) => {
              return (
                <View
                  style={{
                    flexDirection: "row",
                    marginVertical: 5,
                    alignItems: "center",
                  }}
                  key={status}
                >
                  <Checkbox
                    defaultChecked={filter.includes(status)}
                    onChecked={() => addStatusFilter(status)}
                  />
                  <Text style={{ marginStart: 8 }}>{status}</Text>
                </View>
              );
            })}
          </>
        ) : null}
        <View style={{ flex: 1, marginTop: 10, flexDirection: "row" }}>
          {filter.length ? (
            <CustomButton
              style={{ marginHorizontal: 3 }}
              onPress={clearFilter}
              name="Reset"
            />
          ) : null}
          <CustomButton
            style={{ marginHorizontal: 3 }}
            onPress={applyFilter}
            name={Strings.SEARCH}
          />
        </View>
      </ScrollView>
    </CustomShadowModal>
  );
};

export default React.memo(FilterModal);
