import React from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Strings } from "../../../../assets/strings";
import { theme } from "../../../theme";
import { nonImmigrantStatusStyles } from "./NonImmigrantStatus.Styles";
import StatusProtectorController from "../../../screen/StatusProtector/controller/StatusProtectorController";

type props = {
  status: string | undefined;
};

const NonImmigrantStatus: React.FC<props> = ({ status }: props) => {
  const { getStatusColor, getStatusIcon } = StatusProtectorController();
  const isEmptyStatus = status ? true : false;

  return (
    <View
      style={[
        nonImmigrantStatusStyles.statusInfoContainer,
        {
          backgroundColor: getStatusColor(
            status?.toLocaleLowerCase()
          ),
        },
      ]}
    >
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {isEmptyStatus 
          ? (
            <>
              <MaterialCommunityIcons
                name={getStatusIcon(
                  status?.toLocaleLowerCase()
                )}
                size={30}
                color={theme.colors.secondary}
              />
              <Text style={nonImmigrantStatusStyles.statusInfo}>
                <Text style={nonImmigrantStatusStyles.sInfo}>
                  {Strings.NON_IMMIGRANT_STATUS}
                </Text>
                <Text style={nonImmigrantStatusStyles.statusInfoValidation}>
                  {status}
                </Text>
              </Text>
            </>
          )
          : (
            <Text style={[nonImmigrantStatusStyles.statusInfo, { paddingTop: 10 }]}>
              <Text style={nonImmigrantStatusStyles.sInfo}>
                {Strings.EMPTY_STATUS}
              </Text>
            </Text>
          )}
      </View>
    </View>
  );
};

export default React.memo(NonImmigrantStatus);
