import { ErrorMessage, Formik } from "formik";
import Cookies from "js-cookie";
import React, { useCallback, useState } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../assets/strings";
import { multiGet, removeStorageKeys } from "../../../common/AsyncStorage";
import { AsyncStorageKeys, Screens } from "../../../common/Constant";
import BgImageContainer from "../../../components/BgImageContainer";
import ProgressButton from "../../../components/Buttons/Progress";
import SubmitButton from "../../../components/Buttons/Submit";
import ErrorText from "../../../components/ErrorText";
import FormInput from "../../../components/FormInput";
import { useOrgAuthContext } from "../../../context/OrganizationAuthContext";
import { useRootContext } from "../../../context/rootContext/RootContext";
import validationSchema from "../../../data/validationSchema";
import { OrgSignUpVerificationController } from "./controller/OrgSignUpVerificationController";
import { orgSignupVerificationStyles } from "./OrgSignupVerification.Styles";
type props = {};
const OrgSignUpVerification: React.FC<props> = () => {
  const [codeSent, setCodeSent] = useState(false);
  const [userData, setUserData] = useState<{
    email: string;
    session: string;
  }>();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    dimensions: { width: width },
  } = useRootContext();
  const { orgAuthState } = useOrgAuthContext();
  const { handleOTPVerification, handleResendOTP } =
    OrgSignUpVerificationController();

  React.useEffect(() => {
    multiGet([AsyncStorageKeys.ORG_USER_EMAIL])
      .then((response) => {
        const [userEmail] = response;
        const session = Cookies.get(AsyncStorageKeys.ORG_SESSION);
        if (session !== undefined && userEmail[1] !== null) {
          setUserData({ email: userEmail[1], session });
        }
      })
      .catch((e) => console.error(e));
  }, []);

  const handleOTP = useCallback(
    async (code: string) => {
      setMessage("");
      if (userData && userData?.email !== null && userData?.session !== null) {
        const data = await handleOTPVerification(
          userData.session,
          userData.email,
          code
        );
        data.hasError && setMessage(data.message);
      } else {
        setMessage(Strings.SEND_OTP_USER_SESSION_EXPIRED);
        removeStorageKeys([
          AsyncStorageKeys.ORG_USER_EMAIL,
          AsyncStorageKeys.ORG_SIGN_IN_COMPLETED,
        ]);
      }
    },
    [userData]
  );
  const handleResendCode = useCallback(async () => {
    setMessage("");
    setLoading(true);
    const response = await handleResendOTP(userData.session);
    if (!response.hasError) {
      setCodeSent(true);
    } else {
      setMessage(response.message);
    }
    setLoading(false);
  }, [userData]);
  return (
    <BgImageContainer isOrganization={true}>
      <View style={orgSignupVerificationStyles.container}>
        <View style={width > 780 ? { width: 500 } : { width: "90%" }}>
          <Text style={orgSignupVerificationStyles.heading}>
            {Strings.OTP_VERIFICATION}
          </Text>
          <Text style={orgSignupVerificationStyles.intro}>
            {`${Strings.OTP_VERIFY_DESC} ${orgAuthState.orgUserEmail}`}
          </Text>
          <Formik
            initialValues={{
              otp: "",
            }}
            validationSchema={validationSchema.verifyOTP}
            onSubmit={(values) => handleOTP(values.otp)}
          >
            {({ values, errors, touched, handleSubmit, handleChange }) => (
              <View style={{ marginVertical: 30 }}>
                <View>
                  <View style={orgSignupVerificationStyles.formContainer}>
                    <FormInput
                      label={Strings.ENTER_OTP}
                      inputPlaceholder={Strings.ENTER_OTP}
                      inputValue={values.otp}
                      setInputValue={handleChange("otp")}
                      mode="outlined"
                      inputWidth="100%"
                      error={touched.otp && errors.otp}
                      labelStyle={orgSignupVerificationStyles.label}
                      style={orgSignupVerificationStyles.input}
                      activeOutlineColor="#9797FF"
                      outlineColor="#9797FF"
                      dense
                    />
                  </View>
                  <SubmitButton
                    style={orgSignupVerificationStyles.btnStyle}
                    onPress={handleSubmit}
                    name={Strings.CONTINUE}
                  />
                  <View>
                    {codeSent ? (
                      <Text
                        style={{ fontSize: 15, color: "green", marginTop: 20 }}
                      >
                        The code has been sent
                      </Text>
                    ) : (
                      <ProgressButton
                        name="Resend code"
                        onPress={handleResendCode}
                        isLoading={loading}
                      />
                    )}
                  </View>
                  {message ? (
                    <ErrorText
                      txtStyle={{ marginVertical: 20 }}
                      error={message}
                    />
                  ) : null}
                </View>
              </View>
            )}
          </Formik>
        </View>
      </View>
    </BgImageContainer>
  );
};

export default React.memo(OrgSignUpVerification);
