export const AsyncStorageKeys = {
  INVITE_EMAIL: "inviteEmail",
  SignIn_COMPLETED: "signInCompleted",
  SIGNUP_PAGE_NO: "signup_page_no",
  USER_ID: "userId",
  USER_EMAIL: "userEmail",
  USER_SESSION_TOKEN: "userSessionToken",
  EXPIRES_AT: "expires_at",
  USER_SESSION_REFRESH_TOKEN: "userSessionRt",
  USER_SESSION: "userSession",
  ORG_USER_ID: "orgUserId",
  ORG_ID: "orgId",
  ORG_ROLE: "orgRole",
  ORG_USER_EMAIL: "orgUserEmail",
  ORG_SESSION_TOKEN: "orgSessionToken",
  ORG_EXPIRES_AT: "orgExpires_at",
  ORG_SESSION: "orgSession",
  ORG_SIGN_IN_COMPLETED: "orgSignInCompleted",
  ORG_SESSION_REFRESH_TOKEN: "orgSessionRt",
  USER_TYPE: "userType",
};
export const Screens = {
  DRAWER: "Drawer",
  HOME: "Home",
  LOGIN: "Login",
  TWO_FACTOR_AUTH: "TwoFactorAuth",
  CREATE_ACCOUNT: "CreateAccount",
  AUTH_SCREEN: "AuthScreen",
  UPLOAD_DOCUMENTS: "UploadDocuments",
  CONFIRM_INFO: "ConfirmInfo",
  ORGANIZATION_USER_SIGNUP: "OrganizationUserSignup",
  ORGANIZATION_SIGNUP_VERIFICATION: "OrganizationSignupVerification",
  CREATE_ORGANIZATION_SCREEN: "CreateOrganization",
  EDIT_ORGANIZATION_SCREEN: "EditOrganizationScreen",
  EDIT_ORG_CLIENT_SCREEN: "EditOrganizationClientScreen",
  CLIENT_DOCUMENTS_SCREEN: "ClientDocumentsScreen",
  ONBOARDING_USERS_SCREEN: "OnboardingUsersScreen",
  ORGANIZATION_MAIN_SCREEN: "OrganizationMainScreen",
  ORGANIZATION_INVITE_SCREEN: "OrganizationInviteScreen",
  ORG_PROFILE_EDIT_SCREEN: "ProfileEditScreen",
  ORG_PASSWORD_RESET_REQUEST: "OrgPasswordResetRequest",
  ORG_PASSWORD_RESET_CONFIRM: "OrgPasswordResetConfirm",
  CLIENT_SCREEN: "ClientScreen",
  ADMIN_SCREEN: "AdminsScreen",
  EDIT_ADMIN_SCREEN: "EditAdminscreen",
  STATUS_PROTECTOR: "StatusProtector",
  PROFILE: "Profile",
  ALERTS: "Alerts",
  STRIPE_PAYMENT: "StripePayment",
  PAYMENT_SUCCESS: "PaymentSuccess",
  PAYMENT_FAILURE: "PaymentFailure",
  DEPENDENT: "Dependent",
  PASSWORD_RESET_REQUEST: "PasswordResetRequest",
  PASSWORD_RESET_CONFIRM: "PasswordResetConfirm",
  ORGANIZATION_PAYMENT: "OrganizationPayment",
  ORGANIZATION_PAYMENT_SUCCESS: "OrganizationPaymentSuccess",
  ORGANIZATION_PAYMENT_FAILURE: "OrganizationPaymentFailure",
  ORGANIZATION: "organization",
  AUTOVISAS: "autoVisa",
  STATUS_PROTECTOR_CLIENT: "ClientStatusProtectorScreen"
} as const;
export const APP_TITLE = "AutoVisas";
export const APP_TITLES = {
  HOME_TITLE: `Home - ${APP_TITLE}`,
  LOGIN_TITLE: `Login - ${APP_TITLE}`,
  CREATE_INDIVIDUAL_TITLE: `Create an inidividual account - ${APP_TITLE}`,
  CREATE_ORGANIZATION_TITLE: `Create an organization account - ${APP_TITLE}`,
  PASSWORD_RESET_TITLE: `Reset Password - ${APP_TITLE}`,
  PASSWORD_CONFIRM_TITLE: `Confirm Password - ${APP_TITLE}`,
  VERIFY_OTP_TITLE: `OTP Verification - ${APP_TITLE}`,
} as const;
export const TERMS_AND_CONDITIONS_URL =
  "https://autovisas-assets.s3.us-west-1.amazonaws.com/documents/AutoVisas+-+SP+Terms+of+Use+22.11.03.pdf";
export const PRIVACY_POLICY_URL =
  "https://autovisas-assets.s3.us-west-1.amazonaws.com/documents/Autovisas+-+PRIVACY+NOTICE+22_11_29.pdf";
export const inputDateFormat = "YYYY-MM-DDTHH:mm:ss.SSS";
export const SECRET_KEY = "vSP~uF%ePQ9\\TVxK$5#D;AK#HB:9,T}}";
