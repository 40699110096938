import { createDrawerNavigator } from "@react-navigation/drawer";
import DrawerContent from "../Content/index";
import StatusProtector from "../../../screen/StatusProtector";
import Profile from "../../../screen/Profile";
import { APP_TITLE, Screens } from "../../../common/Constant";
import ProtectedRouteScreen from "../../../navigation/ProtectedRouteScreen";
import { StatusProtectorParamList } from "../../../navigation/types";

const DrawerNavigator = () => {
  const Drawer = createDrawerNavigator<StatusProtectorParamList>();
  return (
    <Drawer.Navigator
      initialRouteName={Screens.STATUS_PROTECTOR}
      screenOptions={{
        headerShown: false,
        title: APP_TITLE,
      }}
      drawerContent={(props) => <DrawerContent {...props} />}
    >
      <Drawer.Screen
        name={Screens.STATUS_PROTECTOR}
        component={StatusProtector}
      />
      <Drawer.Screen name={Screens.PROFILE}>
        {(props) => <ProtectedRouteScreen {...props} children={<Profile />} />}
      </Drawer.Screen>
    </Drawer.Navigator>
  );
};

export default DrawerNavigator;
