import React, { useState, useEffect } from "react";
import { ScrollView, View, Text, Pressable } from "react-native";
import { Strings } from "../../../../assets/strings";
import BgImageContainer from "../../../components/BgImageContainer";
import IconButton from "../../../components/Buttons/IconButton";
import { ProgressBar } from "../../../components/progressBar/ProgressBar";
import { useRootContext } from "../../../context/rootContext/RootContext";
import { theme } from "../../../theme";
import { OnboardingType } from "./type/types";
import { useOrgAuthContext } from "../../../context/OrganizationAuthContext";
import { OnboardingController } from "./controller/OnboardingController";
import { OnboardingCSVUserType } from "../types";
import CustomModal from "../../../components/Modal/CustomModal";
import { TableMappingFormat } from "../../../components/Organization/OnboradingScreen/TableMappingCsv/TableMappingFormat";
import ErrorCountModal from "../../../components/Organization/OnboradingScreen/modal/ErrorCountModal";
import DocumentModal from "../../../components/UploadModal/DocumentModal";
import Table from "../../../components/GenericTable";
import { TableRow } from "../../../components/GenericTable/TableRow";
import TableCell from "../../../components/GenericTable/TableCell";
import moment from "moment";

type props = {};

const OnboardingScreen: React.FC<props> = () => {
  const [message, setMessage] = useState(Strings.SUPPORTED_CSV_FILETYPE);
  const { NAME, UPLOAD_TIME, TOTAL_ROWS, SUCCESS_COUNT, ERROR_COUNT } =
    Strings.CsvUserHeader;
  const TableRowTitle = [
    NAME,
    UPLOAD_TIME,
    TOTAL_ROWS,
    SUCCESS_COUNT,
    ERROR_COUNT,
  ];
  const { orgAuthState } = useOrgAuthContext();
  const { validateCsvFileType, getCsvUserList } = OnboardingController();
  // state
  const [uploadCsvModalVisible, setUploadCsvModalVisible] = useState(false);
  const [columnMappingModal, setColumnMappingModal] = useState(false);
  const [headersCSVData, setCSVHeaderData] = useState<string[]>([]);
  const [csvUsersList, setCsvUsersList] = useState<OnboardingCSVUserType[]>([]);
  const [CSVFile, setCSVFile] = useState<File>();
  const [isLoading, setLoading] = useState(true);
  const [errorModal, setErrorModal] = useState({
    data: { id: null, fileName: null },
    visible: false,
  });
  const processCSV = (str, delim = ",") => {
    const headers = str.slice(0, str.indexOf("\n")).split(delim);
    setColumnMappingModal(true);
    setCSVHeaderData(headers);
  };
  const submit = (csvFile: File) => {
    const file = csvFile;
    const reader = new FileReader();
    reader.onload = function (e) {
      const text = e.target.result;
      processCSV(text);
    };

    reader.readAsText(file);
  };
  const validateFile = (file: File) => {
    setMessage("");
    if (validateCsvFileType(file)) {
      setCSVFile(file);
      submit(file);
      setUploadCsvModalVisible(false);
    } else {
      setMessage(Strings.UNSUPPORTED_CSV_FILETYPE);
    }
  
  };
  const getCsvUserListData = () => {
    setLoading(true);
    getCsvUserList(orgAuthState.orgId, orgAuthState.orgUserToken).then(
      (res) => {       
        if (res.status === 200) {
          setCsvUsersList(res.data);
        } else {
          setMessage(res.data);
        }

        setLoading(false);
        
      }
    );
  };
  useEffect(() => getCsvUserListData(), []);
  const [activeTab, setActiveTab] = useState(OnboardingType.CSV);


  const {
    dimensions: { width },
  } = useRootContext();

  return (
    <ProgressBar loading={false}>
      <BgImageContainer isOrganization={true}>
        <ScrollView
          contentContainerStyle={{
            width: width > 700 ? 700 : "95%",
            height: 500,
            alignSelf: "center",
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <Text style={{ fontWeight: "bold", fontSize: 34, flex: 1, color:theme.colors.btn, fontFamily:"Brother1816Black"}}>
              {Strings.ONBOARDING}
            </Text>
            <IconButton
              name="Upload CSV"
              iconName="upload"
              onPress={() => {
                activeTab === OnboardingType.CSV &&
                  setUploadCsvModalVisible(true);
              }}
            />
          </View>         
          <>
            <ProgressBar loading={isLoading}>
              <Table
                headerNames={TableRowTitle}
                alignFirstColumn={true}
                data={csvUsersList}
                tableCell={(user) => (
                  <TableRow key={user.job_id}>
                    <TableCell textStyles={{ textAlign: "left" }}>
                      {user.job_name}
                    </TableCell>
                    <TableCell>
                      {moment(user.created_at).utc().format('MM/DD/YYYY HH:mm')}
                    </TableCell>
                    <TableCell>
                      {user.total_rows}
                    </TableCell>
                    <TableCell textStyles={{ color: theme.colors.green }}>
                      {user.success_count}
                    </TableCell>
                    <TableCell>
                      <Pressable
                        onPress={() => {
                          setErrorModal({
                            data: {id: user.job_id, fileName: user.job_name },
                            visible: true,
                          })
                        }}
                      >
                        <Text style={{ color: theme.colors.red, textDecorationLine: "underline" }}>{user.error_count}</Text>
                      </Pressable>
                    </TableCell>
                  </TableRow>
                )}
              />
              <CustomModal visible={columnMappingModal} modalStyle={{ height: "70%" }}>
                <TableMappingFormat
                  onDismiss={() => setColumnMappingModal(false)}
                  CSVData={headersCSVData}
                  CSVFile={CSVFile}
                  onUpload={() => {
                    getCsvUserListData();
                    setColumnMappingModal(false);
                  }}
                />
              </CustomModal>
              <DocumentModal
                visible={uploadCsvModalVisible}
                onDrop={(file: File[]) => validateFile(file[0])}
                onDismiss={() => setUploadCsvModalVisible(false)}
                message={message}
              />
              <ErrorCountModal
                visible={errorModal.visible}
                data={errorModal.data}
                onDismiss={() => {
                  setErrorModal({ data: null, visible: false });
                }}
              />
            </ProgressBar>
            {/* <FooterButton activeTab={activeTab} setActiveTab={setActiveTab} /> */}
          </>
        </ScrollView>
      </BgImageContainer>
    </ProgressBar>
  );
};

export default React.memo(OnboardingScreen);
