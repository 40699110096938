import { Strings } from "../../../../../assets/strings"
import { deleteClientDocument, getClientDocuments } from "../../../../util/organization/clients"

const ClientDocumentScreenController = () => {
    const getAllDocs = async (organizationId: string, token: string, clientId: string) => {
        try {
            const response = await getClientDocuments(organizationId, token, clientId)
            if (response.status === 200) {
                return {
                    hasError: false,
                    data: response.data
                }
            }  else {
                return {
                    hasError: true,
                    data: response.data.message
                }
            }
        } catch (error) {
            console.log(error);    
            return {
                hasError: true,
                data: Strings.SOMETHING_WENT_WRONG
            }
        }
    }

    const deleteDocument = async (organizationId: string, token: string, clientId: string, docId: string) => {
        try {
            const response = await deleteClientDocument(organizationId, token, clientId, docId)
            if (response.status === 200) {
                return {
                    hasError: false,
                    data: response.data
                }
            }  else {
                return {
                    hasError: true,
                    data: response.data.message
                }
            }
        } catch (error) {
            console.log(error);    
            return {
                hasError: true,
                data: Strings.SOMETHING_WENT_WRONG
            }
        }
    }

    return  { getAllDocs, deleteDocument }
}

export default ClientDocumentScreenController