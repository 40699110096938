import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const profileEditScreenStyles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: "center",
      width: 600,
      alignSelf: "center",
    },
    heading: {
      width: "100%",
      color: theme.colors.btn,
      fontSize: 25,
      fontWeight: "bold",
    },
    inputsContainer: {
        flexDirection: "row",
        width: "100%",
      },
      inputContainer: {
        flex: 1,
        marginEnd: 5,
      },
      label: {
        fontSize: 16,
        color: "#9797FF",
        textTransform: "uppercase",
      },
    
      input: { backgroundColor: "#F0FFFF" },
      errorContainer: {
        height: 22.5,
      },
})