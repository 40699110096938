import { AsyncStorageKeys } from "./../../common/Constant";
import { getLocalStorageValue } from "../../common/AsyncStorage";
import { autoVisasPrivateApi } from "../axiosInstances/autovisasPrivateApi";
import { getError, getSuccess } from "../axiosInstances/handleResponse";
import { Apis } from "../axiosInstances/Apis";

export const getDependentList = async () => {
  try {
    const userId = await getLocalStorageValue(AsyncStorageKeys.USER_ID);
    const options = {
      method: "GET",
      url: `${Apis.USERS}/${userId}/dependents`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const getDependentListResponse = await autoVisasPrivateApi.request(options);
    return getSuccess(getDependentListResponse);
  } catch (error) {
    console.error(error);
    return getError(error);
  }
};
