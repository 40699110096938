import { StyleSheet } from "react-native";
import { theme } from "../../theme";
const confirmInfoStyles = StyleSheet.create({
  checkboxContainer: {
    flexDirection: "row",
    marginVertical: 15,
  },
  inputsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    zIndex: 1,
  },
  inputContainer: {
    minWidth: 225,
    marginRight: 5,
  },
  errorContainer: {
    height: 22.5,
  },
  label: {
    fontSize: 16,
    color: "#9797FF",
    textTransform: "uppercase",
  },
  input: { backgroundColor: "#F0FFFF" },
  continueButtonContainer: {
    alignSelf: "flex-end",
    minWidth: 224,
  },
  buttonContainer: {
    flex: 1,
    alignItems: "flex-end",
    marginBottom: 100,
    paddingHorizontal: 10,
  },
  text: {
    fontSize: 16,
    color: "#5B3396",
    marginLeft: 10,
  },
  errorText: {
    fontSize: 16,
    color: theme.colors.red,
    marginLeft: 30,
    marginBottom: 20,
  },
  infoText: {
    fontSize: 16,
    color: theme.colors.green,
    marginBottom: 10,
    marginTop: -10,
  },
});
export default confirmInfoStyles;
