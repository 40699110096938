import { StyleSheet } from "react-native";
import { theme } from "../../../theme";
export const organizationDetailsStyles = StyleSheet.create({
  body: {
    width: "100%",
    alignItems: "center",
    alignSelf: "center",
  },
  title: {
    ...theme.fonts.black,
    fontSize: 38,
    flex: 1,
    color: "#9797FF",
    fontFamily: "Inter",
  },

  formContainer: {
    width: "100%",
  },
  inputsContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  inputContainer: {
    flex: 1,
    width: "100%",
    marginHorizontal: 3,
  },
  inputContainerFullLength: {
    width: "100%",
    marginBottom: 5,
    marginRight: 20,
  },
  label: {
    fontSize: 16,
    color: "#9797FF",
    textTransform: "uppercase",
  },
  borderBtn: {
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 3,
    borderColor: theme.colors.bottom2,
    paddingHorizontal: 70,
    borderRadius: 6,
  },
  input: { backgroundColor: "#F0FFFF" },
  btnStyle: {
    width: "100%",
  },
  btnContainer: {
    marginVertical: 20,
  },
  errorContainer: {
    height: 22.5,
  },
  errorText: {
    color: "red",
  },
  successText: {
    color: "green",
  },
  dropDownStyle: {
    ...theme.fonts.black,
    marginBottom: 1,
    fontSize: 16,
    marginTop: -25,
    color: theme.colors.btn,
    textTransform: "uppercase",
  },
  subscription: {
    color: theme.colors.btn,
    fontSize: 17,
    fontWeight: "bold",
  }
});
