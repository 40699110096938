import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const addDependentButtonStyles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: 18,
        marginLeft: 16,
        marginRight: 35,
        alignItems: "flex-end",
      },
      buttonContainer: {
        maxWidth: 215,
        width: "100%",
      },
      button: {
        height: 39.14,
      },
      gradientButton: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 5,
      },
      textButton: {
        ...theme.fonts.black,
        fontSize: 16,
        color: "#F0FFFF",
        textAlign: "center",
        textTransform: "uppercase",
        marginRight: 5,
        marginLeft: 5,
      },
})