import React from "react";
import { Pressable, StyleProp, ViewStyle } from "react-native";
import {Tooltip} from "react-native-paper"

type Props = {
  text: string;
  children: React.ReactElement;
  pressableStyle?: StyleProp<ViewStyle>;
  onPress?: any
};

const AVTooltip: React.FC<Props> = ({ text, children,pressableStyle, onPress }) => {
  return (
    <Tooltip title={text} theme={{colors:{surface:"white", tooltip:"rgb(190, 141, 250)"}}} enterTouchDelay={500} leaveTouchDelay={500}>
        <Pressable style={pressableStyle} onPress={onPress}>
            {children}
        </Pressable>       
    </Tooltip>
  );
};


export default AVTooltip;
