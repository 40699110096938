import { AsyncStorageKeys } from "./../common/Constant";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { autoVisasPrivateApi } from "./axiosInstances/autovisasPrivateApi";

/* Logic */
import { userInfo } from "../data/userInfo";
import { getError, getSuccess } from "./axiosInstances/handleResponse";

export const updateUserInfo = async (
  firstName,
  familyName,
  BirthDate,
  passportNumber,
  country
) => {
  try {
    const userId = await AsyncStorage.getItem(AsyncStorageKeys.USER_ID);
    const options = {
      method: "POST",
      url: `/users/${userId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        first_name: firstName,
        last_name: familyName,
        date_of_birth: BirthDate,
        passport_number: passportNumber,
        citizenship: country,
      },
    };
    const updateUserResponse = await autoVisasPrivateApi.request(options);
    return getSuccess(updateUserResponse);
  } catch (error) {
    console.error(error);
    return getError(error);
  }
};
