import { StyleSheet } from "react-native"
import { theme } from "../../../../theme"

export const clientBoxItemStyles = StyleSheet.create({
  horizontalLine: {
    height: 1,
    backgroundColor: theme.colors.btn,
    width: '100%',
  },
  dataContainer: {
    flexDirection: 'row',
    marginVertical: 10,
    alignItems: "center"
  },
  textStyle: {
    flex: 1,
    fontSize: 13,
    color: "rgb(91, 51, 150)",
    textAlign: "center",
    justifyContent: "center",
    marginHorizontal: 1,
  },
  warningTextStyle: {
    flex: 1,
    fontSize: 13,
    color: theme.colors.btn,
    textAlign: "center",
    justifyContent: "center",
    marginHorizontal: 1,
    textDecorationLine: "underline"
  }
})