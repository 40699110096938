import React, { useEffect, useMemo, useState } from "react";
import { ScrollView, ViewStyle } from "react-native";
import { useOrgAuthContext } from "../../../../context/OrganizationAuthContext";
import ClientScreenController from "../../../../screen/Organization/ClientScreen/controller/ClientScreenController";
import ErrorText from "../../../ErrorText";
import CustomShadowModal from "../../../Modal/CustomShadowModal";
import ConfirmationContent from "../ConfirmationContent";
import ConfirmInfoContent from "../ConfirmInfoContent";
import EnterEmailModalContent from "../EnterEmailModalContent";
import UploadDocContent from "../UploadDocContent";

type props = {
  style: ViewStyle;
  visible: boolean;
  client_Id?: any;
  onDismiss: (val: boolean) => void;
  isCreateClient?: boolean;
  clientEmail?: string;
};

const UploadClientDocModal: React.FC<props> = ({
  style,
  visible,
  onDismiss,
  isCreateClient,
  client_Id,
  clientEmail,
}) => {
  const [modalNumber, setModalNumber] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [clientId, setClientId] = useState(client_Id);
  const [data, setData] = useState(null);
  const [email, setEmail] = useState("");
  const { orgAuthState } = useOrgAuthContext();
  const { checkEmailAvailability, updateClientDetails } =
    ClientScreenController();
  const dismiss = (val: boolean) => {
    setModalNumber(0);
    setErrorMessage("");
    onDismiss(val);
  };

  useEffect(() => {
    if (client_Id && isCreateClient) {
      setClientId(client_Id);
      setModalNumber(1);
      setEmail(clientEmail);
    }
  }, [client_Id, isCreateClient]);
  const onCheckEmailClicked = async (email: string) => {
    const response = await checkEmailAvailability(
      orgAuthState.orgId,
      orgAuthState.orgUserToken,
      email
    );
    if (!response.hasError) {
      setClientId(response.data.uid);
      setEmail(email);
      setModalNumber(1);
      setErrorMessage("");
    } else {
      setErrorMessage(response.data);
    }
  };

  const onCreate = async (modalNumber: number) => {
    setModalNumber(modalNumber);
  };

  const renderChildrenToCreateClient = useMemo(() => {
    if (modalNumber === 0) {
      return <EnterEmailModalContent checkEmail={onCheckEmailClicked} />;
    } else if (modalNumber === 1) {
      return (
        <UploadDocContent
          setErrorMessage={() => {}}
          onContinue={(resData) => {
            setData(resData);
            setModalNumber(2);
          }}
          clientId={clientId}
        />
      );
    } else if (modalNumber === 2) {
      return (
        <ConfirmInfoContent
          email={email}
          data={data}
          clientId={clientId}
          onCreateClient={() => onCreate(3)}
        />
      );
    } else if (modalNumber === 3) {
      return <ConfirmationContent onClientPageClicked={() => dismiss(true)} />;
    }
  }, [modalNumber, data, clientId]);

  return (
    <CustomShadowModal
      style={style}
      visible={visible}
      onDismiss={() => dismiss(false)}
    >
      {errorMessage ? (
        <ErrorText
          error={errorMessage}
          txtStyle={{ marginVertical: 5, textAlign: "center" }}
        />
      ) : null}
      <ScrollView showsVerticalScrollIndicator={false}>
        {isCreateClient ? (
          renderChildrenToCreateClient
        ) : (
          <UploadDocContent
            setErrorMessage={() => {}}
            onContinue={() => dismiss(true)}
            clientId={clientId}
          />
        )}
      </ScrollView>
    </CustomShadowModal>
  );
};

export default UploadClientDocModal;
