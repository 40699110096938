import { Formik } from "formik";
import React, { useState } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../../assets/strings";
import validationSchema from "../../../../data/validationSchema";
import CustomButton from "../../../Buttons/CustomButton";
import FormInput from "../../../FormInput";
import { editEmailModalStyles } from "./EditEmailModal.Styles";

type props = {
  email: string;
  onDismiss: () => void;
  hasError: boolean;
  isLoading: boolean;
  errorMessage: string;
  onSendOtpClicked: (email: string) => void;
};

const EditEmail: React.FC<props> = ({
  email,
  onDismiss,
  errorMessage,
  isLoading,
  hasError,
  onSendOtpClicked,
}) => {
  return (
    <>
      <Text style={editEmailModalStyles.heading}>
        {Strings.ENTER_YOUR_EMAIL}
      </Text>
      <Formik
        initialValues={{
          email: email,
        }}
        validationSchema={validationSchema.checkEmail}
        onSubmit={(values) => {
          onSendOtpClicked(values.email);
        }}
      >
        {({ values, errors, touched, handleSubmit, handleChange }) => (
          <View style={{ marginTop: 30 }}>
            <View>
              <View style={editEmailModalStyles.formContainer}>
                <FormInput
                  label={Strings.EMAIL}
                  inputPlaceholder={Strings.EMAIL}
                  inputValue={values.email}
                  setInputValue={handleChange("email")}
                  mode="outlined"
                  error={touched.email && errors.email}
                  labelStyle={editEmailModalStyles.label}
                  style={editEmailModalStyles.input}
                  activeOutlineColor="#9797FF"
                  outlineColor="#9797FF"
                  dense
                />
                {(touched.email && errors.email) === undefined && (
                  <View style={editEmailModalStyles.errorContainer}></View>
                )}
              </View>
            </View>
            <View style={editEmailModalStyles.btnContainer}>
              <CustomButton
                style={{ marginHorizontal: 10 }}
                onPress={onDismiss}
                name={Strings.CANCEL}
              />
              <CustomButton
                isDisable={values.email === email}
                style={{ marginHorizontal: 10 }}
                isLoading={isLoading}
                onPress={handleSubmit}
                name={Strings.SEND_OTP}
              />
            </View>
            {hasError ? (
              <Text style={[editEmailModalStyles.errorText]}>
                {errorMessage}
              </Text>
            ) : null}
          </View>
        )}
      </Formik>
    </>
  );
};

export default React.memo(EditEmail);
