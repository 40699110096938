import React from "react";
import { View } from "react-native";
import confirmInfoStyles from "../../screen/ConfirmInfo/ConfirmInfo.Styles";
import FormInput from "../FormInput";
type Props = {
  label: string;
  placeholder: string;
  value: string;
  setValue?: (value: string) => void;
  error?: string;
  handleBlur: (value: string) => void;
  editable?: boolean;
  outlineColor?: string;
  inputWidth?: any;
  inputContainer?: {};
};
const ConfirmInfoInput: React.FC<Props> = ({
  label,
  placeholder,
  value,
  setValue,
  error,
  handleBlur,
  editable,
  outlineColor,
  inputWidth,
  inputContainer,
}) => (
  <View style={inputContainer}>
    <FormInput
      editable={editable}
      label={label}
      inputPlaceholder={placeholder}
      inputValue={value}
      setInputValue={setValue}
      mode="outlined"
      error={error}
      onBlur={handleBlur}
      inputWidth={inputWidth}
      labelStyle={confirmInfoStyles.label}
      style={confirmInfoStyles.input}
      activeOutlineColor="#9797FF"
      outlineColor={outlineColor ? outlineColor : "transparent"}
      dense
    />
    {error === undefined && (
      <View style={confirmInfoStyles.errorContainer}></View>
    )}
  </View>
);
export default ConfirmInfoInput;
