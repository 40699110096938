import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const userFormStyles = StyleSheet.create({
  inputsContainer: {
    flexDirection: "row",
    zIndex: 2,
    width: "100%",
  },
  inputContainer: {
    flex: 1,
    margin: 5,
  },
  textStyle: {
    color: theme.colors.btn,
    fontSize: 20,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  label: {
    fontSize: 16,
    color: "#9797FF",
    textTransform: "uppercase",
  },

  input: { backgroundColor: "#F0FFFF" },
  errorContainer: {
    height: 22.5,
  },
  errorText: {
    color: "red",
    marginStart: "8%",
    marginTop: 15,
  },
  btnStyle: {
    flex: 1,
    justifyContent: "flex-end",
  },
});
