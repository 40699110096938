import { useRoute } from "@react-navigation/native";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import BgImageContainer from "../../components/BgImageContainer";
import DependentDocuments from "../../components/Dependent/DependentDocuments";
import DependentInfoContent from "../../components/StatusProtector/DependentInfoContent";
import { useRootContext } from "../../context/rootContext/RootContext";
import { dependentStyles } from "./Dependent.Styles";
import { AuthContext } from "../../context/AuthContext";
import { getDependentDocList } from "../../util/dependent/getDependentDocList";
import { DocumentBody } from "../StatusProtector/types/DeshboardTypes";
import { ProgressBar } from "../../components/progressBar/ProgressBar";
import { deleteDependentDoc } from "../../util/dependent/deleteDependentDoc";
import { decryptedData } from "../../common/Utils";
type props = {};

const Dependent: React.FC<props> = () => {
  const {
    dimensions: { width },
  } = useRootContext();
  const { params } = useRoute();
  const [documentList, setDocumentList] = useState<DocumentBody[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { loginState } = useContext(AuthContext);

  const data = useMemo(() => {
    return decryptedData(params.data);
  }, [params.data]);
  const getDependentDocListData = async (dId: string) => {
    setIsLoading(true);
    await getDependentDocList(dId).then((res) => {
      if (res.status === 200) {
        setDocumentList(res.data);
      }
    });
    setIsLoading(false);
  };
  useEffect(() => {
    data.dependentData.dId && getDependentDocListData(data.dependentData.dId);
  }, [data]);
  const deleteDocument = async (docToDelete: DocumentBody) => {
    setIsLoading(true);
    await deleteDependentDoc(loginState, docToDelete.uid, docToDelete.id).then(
      async (res) => {
        if (res.status === 200) {
          await getDependentDocListData(docToDelete.uid);
        }
      }
    );
    setIsLoading(false);
  };
  return (
    <BgImageContainer>
      <ScrollView style={dependentStyles.scrollContainer}>
        <ProgressBar loading={isLoading}>
          <View
            style={[
              dependentStyles.container,
              {
                width: width > 600 ? 750 : "90%",
                zIndex: 1,
              },
            ]}
          >
            <DependentInfoContent
              modalData={data}
              isDependentScreen={true}
              editable={false}
              setIsLoading={setIsLoading}
            />
            <DependentDocuments
              documents={documentList}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              deleteDocument={deleteDocument}
              uploadedSuccessFully={() => {
                getDependentDocListData(data.dependentData.dId);
              }}
              dId={data.dependentData.dId}
            />
          </View>
        </ProgressBar>
      </ScrollView>
    </BgImageContainer>
  );
};

export default React.memo(Dependent);
