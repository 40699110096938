import { useIsFocused, useRoute } from "@react-navigation/native";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../assets/strings";
import { Screens } from "../../../common/Constant";
import BgImageContainer from "../../../components/BgImageContainer";
import CustomButton from "../../../components/Buttons/CustomButton";
import ClickableText from "../../../components/ClickableText";
import ErrorText from "../../../components/ErrorText";
import FormInput from "../../../components/FormInput";
import { ProgressBar } from "../../../components/progressBar/ProgressBar";
import { useRootContext } from "../../../context/rootContext/RootContext";
import validationSchema from "../../../data/validationSchema";
import { replace } from "../../../navigation/RouteNavigation";
import { theme } from "../../../theme";
import { AcceptOrgInviteRequestBody, OrganizationInvite } from "../types";
import OrganizationInviteScreenController from "./controller/OrganizationInviteScreenController";
import { organizationInviteScreenStyles } from "./OrganizationInviteScreen.Styles";

type props = {};

const OrganizationInviteScreen: React.FC<props> = () => {
  const [invitation, setInvitation] = useState<OrganizationInvite>(null);
  const [loading, setLoading] = useState(false);
  const [getMsg, setGetMsg] = useState("");
  const [postLoading, setPostLoading] = useState(false);
  const [postErrorMsg, setPostErrorMsg] = useState("");
  const {
    dimensions: { width: width },
  } = useRootContext();
  const { getInvite, postInvite } = OrganizationInviteScreenController();
  const isFocused = useIsFocused();

  const route = useRoute();
  const id = route.params?.id;
  const secret = route.params?.secret;

  const getInvitation = async () => {
    setLoading(true);
    await getInvite(id).then((res) => {
      if (!res.hasError) {
        setInvitation(res.data);
      } else {
        setGetMsg(res.message);
      }
      setLoading(false);
    });
  };

  const acceptInvitation = async (data: AcceptOrgInviteRequestBody) => {
    setPostLoading(true);
    await postInvite(id, data).then((res) => {
      if (!res.hasError) {
        setPostErrorMsg(Strings.LOADING_SUCCESS);
      } else {
        window.location.reload();
      }
    });
    setPostLoading(false);
  };

  useEffect(() => {
    isFocused && getInvitation();
  }, [isFocused]);

  return (
    <ProgressBar loading={loading}>
      <BgImageContainer isOrganization={true}>
        {!loading ? (
          <View
            style={[
              organizationInviteScreenStyles.container,
              { width: width > 600 ? 700 : "90%" },
            ]}
          >
            {getMsg ? (
              <View style={organizationInviteScreenStyles.msgContainer}>
                <Text style={organizationInviteScreenStyles.getTxt}>
                  {getMsg}
                </Text>
              </View>
            ) : (
              <>
                <Text style={organizationInviteScreenStyles.heading}>
                  {`${Strings.ENTER_DETAILS_TO_ACCEPT_INVITE} ${
                    invitation ? invitation.organization_name : ""
                  }`}
                </Text>
                <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    password: "",
                    confirmPassword: "",
                    email: invitation ? invitation.email : "",
                  }}
                  validationSchema={validationSchema.acceptInvitation}
                  onSubmit={(values) => {
                    acceptInvitation({
                      invite_secret: secret,
                      first_name: values.firstName,
                      last_name: values.lastName,
                      password: values.password,
                    });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                  }) => (
                    <View>
                      <View
                        style={[organizationInviteScreenStyles.inputsContainer]}
                      >
                        <View
                          style={organizationInviteScreenStyles.inputContainer}
                        >
                          <FormInput
                            label={Strings.FIRST_NAME}
                            inputPlaceholder={Strings.FIRST_NAME}
                            inputValue={values.firstName}
                            setInputValue={handleChange("firstName")}
                            mode="outlined"
                            error={touched.firstName && errors.firstName}
                            labelStyle={organizationInviteScreenStyles.label}
                            style={organizationInviteScreenStyles.input}
                            activeOutlineColor="#9797FF"
                            outlineColor={theme.colors.btn}
                            dense
                          />
                          {(touched.firstName && errors.firstName) ===
                            undefined && (
                            <View
                              style={
                                organizationInviteScreenStyles.errorContainer
                              }
                            ></View>
                          )}
                        </View>
                        <View
                          style={organizationInviteScreenStyles.inputContainer}
                        >
                          <FormInput
                            label={Strings.LAST_NAME}
                            inputPlaceholder={Strings.LAST_NAME}
                            inputValue={values.lastName}
                            setInputValue={handleChange("lastName")}
                            mode="outlined"
                            error={touched.lastName && errors.lastName}
                            labelStyle={organizationInviteScreenStyles.label}
                            style={organizationInviteScreenStyles.input}
                            activeOutlineColor="#9797FF"
                            outlineColor={theme.colors.btn}
                            dense
                          />
                          {(touched.lastName && errors.lastName) ===
                            undefined && (
                            <View
                              style={
                                organizationInviteScreenStyles.errorContainer
                              }
                            ></View>
                          )}
                        </View>
                      </View>
                      <View
                        style={[organizationInviteScreenStyles.inputsContainer]}
                      >
                        <View
                          style={organizationInviteScreenStyles.inputContainer}
                        >
                          <FormInput
                            label={Strings.PASSWORD}
                            inputPlaceholder={Strings.PASSWORD}
                            inputValue={values.password}
                            setInputValue={handleChange("password")}
                            mode="outlined"
                            error={touched.password && errors.password}
                            labelStyle={organizationInviteScreenStyles.label}
                            style={organizationInviteScreenStyles.input}
                            activeOutlineColor="#9797FF"
                            outlineColor={theme.colors.btn}
                            dense
                          />
                          {(touched.password && errors.password) ===
                            undefined && (
                            <View
                              style={
                                organizationInviteScreenStyles.errorContainer
                              }
                            ></View>
                          )}
                        </View>
                        <View
                          style={organizationInviteScreenStyles.inputContainer}
                        >
                          <FormInput
                            label={Strings.CONFIRM_PASSWORD}
                            inputPlaceholder={Strings.CONFIRM_PASSWORD}
                            inputValue={values.confirmPassword}
                            setInputValue={handleChange("confirmPassword")}
                            mode="outlined"
                            error={
                              touched.confirmPassword && errors.confirmPassword
                            }
                            labelStyle={organizationInviteScreenStyles.label}
                            style={organizationInviteScreenStyles.input}
                            activeOutlineColor="#9797FF"
                            outlineColor={theme.colors.btn}
                            dense
                          />
                          {(touched.confirmPassword &&
                            errors.confirmPassword) === undefined && (
                            <View
                              style={
                                organizationInviteScreenStyles.errorContainer
                              }
                            ></View>
                          )}
                        </View>
                      </View>
                      <View
                        style={[organizationInviteScreenStyles.inputsContainer]}
                      >
                        <View
                          style={organizationInviteScreenStyles.inputContainer}
                        >
                          <FormInput
                            label={Strings.EMAIL}
                            inputPlaceholder={Strings.EMAIL}
                            inputValue={values.email}
                            setInputValue={handleChange("email")}
                            mode="outlined"
                            editable={false}
                            error={touched.email && errors.email}
                            labelStyle={organizationInviteScreenStyles.label}
                            style={organizationInviteScreenStyles.input}
                            activeOutlineColor="#9797FF"
                            outlineColor={theme.colors.btn}
                            dense
                          />
                          {(touched.email && errors.email) === undefined && (
                            <View
                              style={
                                organizationInviteScreenStyles.errorContainer
                              }
                            ></View>
                          )}
                        </View>
                        <View style={{ flex: 1 }} />
                      </View>
                      <CustomButton
                        style={{ marginTop: 20 }}
                        onPress={handleSubmit}
                        name={Strings.ACCEPT_INVITE}
                        isLoading={postLoading}
                      />
                      {postErrorMsg ? (
                        postErrorMsg !== Strings.LOADING_SUCCESS ? (
                          <ErrorText
                            txtStyle={organizationInviteScreenStyles.errorText}
                            error={postErrorMsg}
                          />
                        ) : (
                          <Text
                            style={organizationInviteScreenStyles.successTxt}
                          >
                            {Strings.INVITATION_ACCEPTED}
                            <ClickableText
                              text={Strings.LOGIN}
                              onPress={() =>
                                replace(Screens.ORGANIZATION_USER_LOGIN, null)
                              }
                            />
                            {Strings.GO_TO_LOGIN_PAGE}
                          </Text>
                        )
                      ) : null}
                    </View>
                  )}
                </Formik>
              </>
            )}
          </View>
        ) : null}
      </BgImageContainer>
    </ProgressBar>
  );
};

export default React.memo(OrganizationInviteScreen);
