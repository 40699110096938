import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { APP_TITLE, Screens } from "../common/Constant";
import DrawerNavigator from "../components/Drawer/Navigator";
import { StackNavigatorParamList } from "./types";
import ProtectedRouteScreen from "./ProtectedRouteScreen";
import UploadDocumentsForm from "../screen/UploadDocuments";
import ConfirmInfoForm from "../screen/ConfirmInfo";
import PaymentSuccess from "../screen/PaymentSuccess";
import PaymentFailure from "../screen/PaymentFailure";
import StripePayment from "../screen/StripePayment";
import Dependent from "../screen/Dependent";

const RootStackNavigator = () => {
  const Stack = createNativeStackNavigator<StackNavigatorParamList>();

  const protectedRoute = (Component, props) => {
    return (
      <ProtectedRouteScreen {...props}>
        <Component />
      </ProtectedRouteScreen>
    );
  };
  return (
    <Stack.Navigator screenOptions={{ headerShown: false, title: APP_TITLE }}>
      <Stack.Screen name={Screens.DRAWER}>
        {(props) => protectedRoute(DrawerNavigator, props)}
      </Stack.Screen>
      <Stack.Screen name={Screens.UPLOAD_DOCUMENTS}>
        {(props) => protectedRoute(UploadDocumentsForm, props)}
      </Stack.Screen>
      <Stack.Screen name={Screens.CONFIRM_INFO}>
        {(props) => protectedRoute(ConfirmInfoForm, props)}
      </Stack.Screen>
      <Stack.Screen name={Screens.STRIPE_PAYMENT}>
        {(props) =>
          protectedRoute(StripePayment, {
            ...props,
          })
        }
      </Stack.Screen>
      <Stack.Screen name={Screens.PAYMENT_SUCCESS}>
        {(props) =>
          protectedRoute(PaymentSuccess, {
            ...props,
          })
        }
      </Stack.Screen>
      <Stack.Screen name={Screens.PAYMENT_FAILURE}>
        {(props) =>
          protectedRoute(PaymentFailure, {
            ...props,
          })
        }
      </Stack.Screen>
      <Stack.Screen name={Screens.DEPENDENT} component={Dependent} />
    </Stack.Navigator>
  );
};

export default RootStackNavigator;
