import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const orgSignupVerificationStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    width: "100%",
  },
  heading: {
    color: theme.colors.btn,
    fontSize: 40,
    fontWeight: "bold",
  },
  intro: {
    fontSize: 17,
    marginTop: 20,
    color: "#808080",
  },
  formContainer: { width: "100%" },
  label: {
    fontSize: 16,
    color: "#9797FF",
  },
  input: { backgroundColor: "#F0FFFF" },
  btnStyle: { width: "100%", maxWidth: "100%", marginTop: 20 },
  errorContainer: {
    height: 22.5,
  },
});
