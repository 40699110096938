import { StyleSheet } from "react-native";
import { theme } from "../../../../theme";

export const verifyOtpStyles = StyleSheet.create({
    formContainer: {flex: 1, marginHorizontal: 5, justifyContent: 'center', alignItems: 'center' },
    label: {
      fontSize: 16,
      color: '#9797FF',
    },
    input: { backgroundColor: '#F0FFFF' },
    errorContainer: {
      height: 22.5
    },
    heading: {
      color: theme.colors.btn,
      fontSize: 20,
      fontWeight: "bold",
      textAlign: "center",
    },
    errorText: {
      color: "red",
      textAlign: "center",
      marginVertical: 5
    },
    btnContainer: {
      marginVertical: 10,
      justifyContent: "center",
      flexDirection: "row"
    },
    resendOtpStyle: {
      alignSelf: "flex-end",
      flex: 1,
      alignItems: "flex-end",
      marginEnd: 10,
    },
    resendOtpText: {
      flex: 2,
      textAlign: "right",
      color: "green",
    }
})