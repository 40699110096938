import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AUTOVISAS_BASE_URL } from "@env";
import { AsyncStorageKeys } from "../../common/Constant";
import { multiSet } from "../../common/AsyncStorage";
import { orgRefreshToken } from "../organization/orgRefreshToken";
import { getOrgSessionFromStorage } from "../organization/getOrgSessionFromStorage";
export const autoVisasOrgPrivateApi = axios.create({
  baseURL: AUTOVISAS_BASE_URL,
});

autoVisasOrgPrivateApi.interceptors.request.use(
  async (config) => {
    const isTokenInStorage = await AsyncStorage.getItem(
      AsyncStorageKeys.ORG_SESSION_TOKEN
    );
    if (isTokenInStorage !== null) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${isTokenInStorage}`,
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

autoVisasOrgPrivateApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      originalRequest._retry = true;
      try {
        const isUserSessionStorage = await getOrgSessionFromStorage();
        if (isUserSessionStorage !== null) {
          const refreshTokenResponse = await orgRefreshToken(
            isUserSessionStorage.orgRefreshToken
          );
          await multiSet([
            [AsyncStorageKeys.ORG_USER_ID, isUserSessionStorage.orgUserId],
            [AsyncStorageKeys.ORG_ID, refreshTokenResponse.organizationId],
            [
              AsyncStorageKeys.ORG_USER_EMAIL,
              isUserSessionStorage.orgUserEmail,
            ],
            [
              AsyncStorageKeys.ORG_SESSION_TOKEN,
              refreshTokenResponse.accessToken,
            ],
            [AsyncStorageKeys.ORG_EXPIRES_AT, refreshTokenResponse.expiresAt],
            [AsyncStorageKeys.ORG_ROLE, refreshTokenResponse.organizationRole],
            [
              AsyncStorageKeys.ORG_SESSION_REFRESH_TOKEN,
              refreshTokenResponse.refreshToken,
            ],
          ]);
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${refreshTokenResponse.accessToken.token}`;
          return autoVisasOrgPrivateApi(originalRequest);
        }
      } catch (error) {
        console.log(error);
        window.location.reload();
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);
