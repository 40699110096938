export type CreateDependentRequestBody = {
  email: string;
  relationship: string;
};
export type CreateDependentProfileRequestBody = {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  passport_number: string;
  citizenship: string;
};
export type UpdateDependentProfileRequestBody = {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  passport_number: string;
  citizenship: string;
  email: string;
};
export type DependentData = {
  dId?: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  passport_number: string;
  citizenship: string;
};
export type DependentModalData = {
  dId?: string;
  pageNo?: number;
  dependentData?: DependentData;
  email?: string;
};
export type DependentList = {
  uid?: string;
  did: string;
  first_name: string;
  email: string;
  last_name: string;
  date_of_birth: string;
  passport_number: string;
  citizenship: string;
  profile_completed: boolean;
  non_immigrant_status: {
    visa_type: string;
    status: string;
    tags: [];
  };
  relationship: string;
};

export type DependentInfo = {
  visible: boolean;
  dId?: string;
  email?: string;
  tags?: [];
};
export enum ActionType {
  DELETE = "delete",
  EDIT = "edit",
  VIEW_TAG = "viewTag",
}
