import React from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { columnItemStyles } from "./ColumnItemStyles";

type props = {
  title: string;
  style?: {};
  isHeading?: boolean;
};

const ColumnItem: React.FC<props> = ({ title, style, isHeading = false }) => {
  return (
    <View style={style}>
      <Text
        style={[
          columnItemStyles.textStyle,
          isHeading && { fontWeight: "bold", textTransform: "uppercase" },
        ]}
      >
        {title}
      </Text>
    </View>
  );
};

export default React.memo(ColumnItem);
