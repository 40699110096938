import React from "react";
import { ScrollView } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../assets/strings";
import { useRootContext } from "../../../context/rootContext/RootContext";
import { theme } from "../../../theme";
import CustomButton from "../../Buttons/CustomButton";
import CustomModal from "../../Modal/CustomModal";

type props = {
  visible: boolean;
  onDismiss: () => void;
};

const SubscriptionInfoModal: React.FC<props> = ({ visible, onDismiss }) => {
  const {
    dimensions: { width: width },
  } = useRootContext();
  return (
    <CustomModal
      modalStyle={[
        { width: 400, height: 280 },
        width < 450 && { width: "95%" },
      ]}
      visible={visible}
      onDismiss={onDismiss}
    >
      <ScrollView>
        <Text
          style={{
            color: theme.colors.btn,
            fontSize: 20,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {Strings.WARNING}
        </Text>
        <Text
          style={{
            marginTop: 10,
            color: theme.colors.btn,
            fontSize: 20,
            textAlign: "center",
          }}
        >
          {Strings.PLAN_MANAGED_BY_ORGANIZATION}
        </Text>
        <CustomButton
          btnStyle={{ marginTop: 20 }}
          onPress={onDismiss}
          name={Strings.CANCEL}
        />
      </ScrollView>
    </CustomModal>
  );
};

export default React.memo(SubscriptionInfoModal);
