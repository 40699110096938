import { useContext, useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  Image,
  Text,
  Pressable,
  Dimensions,
  Platform,
} from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import { theme } from "../../theme";
import menu from "../../data/menu";
import { AuthContext } from "../../context/AuthContext";
import { reset } from "../../navigation/RouteNavigation";
import { Screens } from "../../common/Constant";
import Header from "../Header";
import { useStoreContext } from "../../context/storeContext/StoreProvider";
import organizationMenu from "../../data/organizationMenu";
import { Images } from "../../../assets/images";
import { useOrgAuthContext } from "../../context/OrganizationAuthContext";
import { useOrgStoreContext } from "../../context/orgStoreContext/StoreProvider";
const { height, width } = Dimensions.get("screen");
type Props = {
  onlyLogoutOptionEnabled?: boolean;
  isOrganization?: boolean;
  isHome?: boolean;
  footer?: string;
};

const BgImageContainer: React.FC<Props> = ({
  children,
  onlyLogoutOptionEnabled = false,
  isHome = false,
  isOrganization = false,
  footer = "",
}) => {
  const [menuIndex, setMenuIndex] = useState(null);
  const [subMenuIndex, setSubMenuIndex] = useState(null);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [currentMenu, setCurrentMenu] = useState([]);
  const { loginState, signOut } = useContext(AuthContext);
  const { profileData, setProfileData } = useStoreContext();
  const isSignedIn = loginState?.userToken;
  const navigation = useNavigation();
  const { orgAuthState, orgSignOut } = useOrgAuthContext();
  const { setOrganizationData } = useOrgStoreContext();
  const headerAndFooterHeight = 240;
  const minHeightBackground =
    Dimensions.get("window").height - headerAndFooterHeight;

  useEffect(() => {
    isOrganization ? setCurrentMenu(organizationMenu) : setCurrentMenu(menu);
  }, [isOrganization]);
  
  return (
    <View style={style.container}>
      <View style={style.header}>
        <View style={style.headerContain}>
          {(isSignedIn || orgAuthState?.orgUserToken) && (
            <Pressable
              style={style.menu}
              onPress={() => setMenuVisible(!isMenuVisible)}
            >
              <MaterialIcons
                name={isMenuVisible ? "close" : "menu"}
                size={30}
                color={theme.colors.btn}
              />
            </Pressable>
          )}

          <Header
            name={`${profileData?.first_name} ${profileData?.last_name}`}
            isOrganization={isOrganization}
            isHome={isHome}
          />
        </View>
      </View>
      <View>
        <Image
          source={Images.CLOUD}
          style={style.cloud}
          resizeMode={"contain"}
        />
      </View>
      {isMenuVisible && (
        <View style={style.menuContainer}>
          <LinearGradient
            start={{ x: 0, y: 0 }}
            end={{ x: 0, y: 1 }}
            style={style.menuMain}
            // Button Linear Gradient
            colors={["#fcfcfc", "#fcfcfc", theme.colors.bgColor]}
          >
            <View style={{ alignSelf: "center" }}>
              {currentMenu.map((menu, index) => {
                return (
                  onlyLogoutOptionEnabled ? menu.name === "Log Out" : true
                ) ? (
                  <View key={`menu ${index.toString()}`}>
                    <Pressable
                      onPress={async () => {
                        if (menu?.subMenu) {
                          setMenuIndex(index);
                        } else {
                          if (menu.name === "Log Out") {
                            if (isOrganization) {
                              orgSignOut()
                                .then(() => {
                                  setOrganizationData(null);
                                  reset(Screens.LOGIN, null);
                                });
                            } else {
                              signOut()
                                .then(() => {
                                  setProfileData(null);
                                  reset(Screens.LOGIN, null);
                                });
                            }
                          } else if (menu?.navigateTo) {
                            // @ts-ignore
                            setMenuVisible(!isMenuVisible);
                            navigation.navigate(menu.navigateTo);
                          }
                        }
                      }}
                      style={style.menuItem}
                    >
                      <Image
                        source={menu.image}
                        style={style.menuImage}
                        resizeMode={"contain"}
                      />
                      <Text numberOfLines={2} style={style.menuText}>
                        {menu.name}
                      </Text>
                    </Pressable>
                    {index === menuIndex
                      ? menu?.subMenu.map((subMenu, index) => {
                          return (
                            <Pressable
                              key={`menu ${index}`}
                              onPress={() => {
                                // @ts-ignore
                                navigation.navigate(subMenu.navigateTo);
                                setSubMenuIndex(index);
                              }}
                              style={style.row}
                            >
                              <View style={style.empty} />
                              <View>
                                <Text
                                  style={[
                                    style.subMenuText,
                                    index === subMenuIndex && style.devider,
                                  ]}
                                >
                                  {subMenu.name}
                                </Text>
                              </View>
                            </Pressable>
                          );
                        })
                      : null}
                  </View>
                ) : null;
              })}
            </View>
          </LinearGradient>
        </View>
      )}
      <View
        style={{
          width: "100%",
          flex: 1,
          justifyContent: "flex-end",
          alignItems: "flex-end",
          position: "absolute",
          bottom: 0,
          zIndex: 0,
        }}
      >
        <Image source={Images.STAT} style={style.stat} resizeMode={"contain"} />
        <View style={style.bottom}>
          <View style={style.bottom2}></View>
        </View>
        <Text style={style.footerText}>{footer}</Text>
      </View>
      <View
        style={{
          width: "100%",
          minHeight: minHeightBackground,
          zIndex: 1,
          marginBottom: 80,
        }}
      >
        {children}
      </View>
    </View>
  );
};

const style = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.bgColor,
  },
  stat: {
    height: 200,
    width: 200,
    bottom: -10,
    zIndex: 1,
    marginRight: 30,
  },
  logo: {
    height: 35,
    width: 300,
    position: "absolute",
    right: 0,
    top: 0,
  },
  empty: {
    width: 30,
    height: 1,
  },
  row: {
    flexDirection: "row",
  },
  devider: {
    borderBottomWidth: 2,
    borderBottomColor: theme.colors.btn,
  },
  menuImage: {
    height: 20,
    width: 20,
    tintColor: theme.colors.btn,
  },
  menuItem: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 5,
  },
  menuText: {
    fontFamily: "Brother1816Black",
    color: theme.colors.btn,
    textTransform: "uppercase",
    fontSize: 18,
    marginStart: 10,
  },
  subMenuText: {
    fontFamily: "FilsonSoftBook",
    color: theme.colors.btn,
    textTransform: "uppercase",
    fontSize: 16,
    marginVertical: 7,
  },
  header: {
    width: "100%",
    zIndex: 2,
    paddingVertical: 25,
  },
  cloud: {
    height: 100,
    width: 290,
    marginTop: -25,
  },
  menuContainer: {
    position: "absolute",
    top: 148,
    left: 3,
    minHeight: 200,
    width: 203,
    zIndex: 2,
  },
  bottom: {
    width: "100%",
    height: 80,
    backgroundColor: theme.colors.bottom,
  },
  bottom2: {
    height: 30,
    width: "100%",
    backgroundColor: theme.colors.bottom2,
  },
  headerContain: {
    flex: 1,
    paddingHorizontal: Platform.OS === "web" ? "5%" : 0,
    flexDirection: "row",
  },
  menu: {
    alignSelf: "center",
    minWidth: 70,
  },
  menuMain: {
    height: 350,
    borderBottomEndRadius: 15,
    borderBottomStartRadius: 15,
    borderStyle: "solid",
  },
  btntxt: {
    color: "white",
    fontSize: 14,
    fontFamily: "Brother1816Black",
    textTransform: "uppercase",
  },
  textStyle: {
    fontFamily: "Brother1816Black",
    fontSize: 16,
    color: theme.colors.btn,
    height: height * 0.05,
    width: width * 0.15,
    position: "absolute",
    right: 0,
  },
  footerText: {
    position: "absolute",
    marginBottom: 12,
    color: "white",
    alignSelf: "center",
    textAlign: "center",
    ...theme.fonts.medium,
    fontSize: 15,
  },
});

export default BgImageContainer;
