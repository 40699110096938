import { Strings } from "./../../assets/strings/index";
const {
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_DESCRIPTION,
  UPLOAD_YOUR_DOCUMENTS,
  UPLOAD_YOUR_DOCUMENTS_DESCRIPTION,
  CONFIRM_YOUR_INFO,
  CONFIRM_YOUR_INFO_DESCRIPTION,
  OTP_VERIFICATION,
} = Strings;
export const signUpInfo = [
  {
    id: 0,
    title: CREATE_ACCOUNT,
    description: () => CREATE_ACCOUNT_DESCRIPTION,
  },
  {
    id: 1,
    title: OTP_VERIFICATION,
    description: (email: string = null) =>
      `We've emailed you a one time passcode to this email ${email}, please enter it below`,
  },
  {
    id: 2,
    title: UPLOAD_YOUR_DOCUMENTS,
    description: () => UPLOAD_YOUR_DOCUMENTS_DESCRIPTION,
  },
  {
    id: 3,
    title: CONFIRM_YOUR_INFO,
    description: () => CONFIRM_YOUR_INFO_DESCRIPTION,
  },
];
