import { OrgApis } from "../axiosInstances/Apis";
import { autoVisasOrgPublicApi } from "./../axiosInstances/autoVisasOrgPublicApi";
export const orgUserRegister = async (
  first_name,
  last_name,
  email,
  password
) => {
  try {
    const options = {
      method: "POST",
      url: OrgApis.ORG_USER_REGISTER,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        first_name,
        last_name,
        email,
        password: password,
      },
    };
    const registerResponse = await autoVisasOrgPublicApi.request(options);
    return {
      data: registerResponse.data,
      status: registerResponse.status,
    };
  } catch (error) {
    return {
      data: error.response.data,
      status: error.response.status,
    };
  }
};
