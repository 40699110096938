import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import CustomShadowModal from "../CustomShadowModal";
import { useRootContext } from "../../../context/rootContext/RootContext";
import { Strings } from "../../../../assets/strings";
import { warningsText } from "../../../data/warnings";

type props = {
    visible: boolean;
    onDismiss: () => void;
    tags?: string[] | undefined;
}

const WarningsModal: React.FC<props> = ({
    visible,
    onDismiss,
    tags,
  }) => {
    const { dimensions: { width: width } } = useRootContext();

    return (
        <CustomShadowModal
            visible={visible}
            onDismiss={onDismiss}
            style={{
                width: width > 550 ? 500 : "90%",
                height: 330,
              }}
        >
            <Text style={{ 
                    fontFamily: "Brother1816Black",  
                    fontSize: 24, 
                    color: "#9797FF",
                    textTransform: "uppercase",
                    textAlign: "center", 
                    paddingVertical: 10, 
                  }}>{ Strings.YOUR_WARNINGS }</Text>
            <ScrollView
                showsVerticalScrollIndicator={false}
                style={{ paddingHorizontal: "4%" }}
            >
                <View>
                    {
                        tags
                            && tags.map((tag: string, index: number) => {
                                if (warningsText[tag] !== undefined) {
                                return (
                                    <View
                                    key={index}
                                    style={[
                                        styles.listContainer,
                                        tags.length - 1 === index && styles.lastBorderStyle,
                                    ]}
                                    >
                                    <Text style={styles.listText}>
                                        - {warningsText[tag]}
                                    </Text>
                                    </View>
                                );
                                }
                            })
                    }
                </View>
            </ScrollView>
        </CustomShadowModal>
    );
};

const styles = StyleSheet.create({
    listContainer: {
        paddingVertical: 16,
        borderBottomWidth: 1,
        borderBottomColor: "#9797FF",
    },
    lastBorderStyle: {
        borderBottomWidth: 0,
    },
    listText: {
        fontSize: 16,
        color: "#9797FF",
        textAlign: "left",
    },
})

export default WarningsModal;