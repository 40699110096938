import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Animated, View } from "react-native";
import { HelperText, Text } from "react-native-paper";
import { Strings } from "../../../../../assets/strings";
import { useOrgAuthContext } from "../../../../context/OrganizationAuthContext";
import validationSchema from "../../../../data/validationSchema";
import ClientScreenController from "../../../../screen/Organization/ClientScreen/controller/ClientScreenController";
import { DependentData } from "../../../../screen/StatusProtector/types/Dependentypes";
import { theme } from "../../../../theme";
import { getCountriesList } from "../../../../util/countries";
import CustomButton from "../../../Buttons/CustomButton";
import ConfirmInfoInput from "../../../ConfirmInfo/ConfirmInfoInput";
import { DatePickerInput } from "../../../datePicker/DatePicker";
import DropDown from "../../../dropDown/DropDown";
import ErrorText from "../../../ErrorText";
import ProgressBarTimer from "../../../progessBarTimerButton/ProgressBarTimer";
import { confirmInfoContentStyles } from "./ConfirmInfoContent.Styles";

type props = {
  data: DependentData;
  onCreateClient: () => void;
  email: string;
  clientId: string;
};

const { GIVEN_NAME, FAMILY_NAME, DATE_OF_BIRTH, PASSPORT_ID, EMPLOYER } =
  Strings.ConfirmInfo;

const ConfirmInfoContent: React.FC<props> = ({
  onCreateClient,
  data,
  email,
  clientId,
}) => {
  var date = new Date();
  const formattedDate = data?.date_of_birth
    ? moment(moment(data?.date_of_birth).utc().format("YYYY-MM-DD")).toDate()
    : date.setDate(date.getDate() - 1);
  let progressBarValue = useRef(new Animated.Value(0)).current;
  const [startDate, setStartDate] = useState(formattedDate);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { orgAuthState } = useOrgAuthContext();
  const [errorMessage, setErrorMessage] = useState("");
  const { updateClientDetails, handleCBP } = ClientScreenController();
  useEffect(() => {
    getCountriesList().then((res) => {
      const list = res.data.map((item) => ({
        label: item.name,
        value: item.alpha3Code,
      }));
      setList(list);
    });
  }, []);
  const createClient = async (values) => {
    setErrorMessage("");
    const data = {
      first_name: values.first_name,
      last_name: values.family_name,
      date_of_birth: moment(startDate).format("yyyy-MM-DD"),
      email: values.email,
      passport_number: values.passport_number,
      citizenship: values.passport_country,
      employer_name: values.employer.toUpperCase(),
    };
    const response = await updateClientDetails(
      orgAuthState.orgId,
      orgAuthState.orgUserToken,
      clientId,
      data
    );
    if (!response.hasError) {
      await handleCBP(clientId, orgAuthState.orgId);
    } else {
      setErrorMessage(response.data);
    }
    progressBarValue.setValue(1);
    setLoading(false);
    onCreateClient();
  };
  return (
    <>
      <Text style={confirmInfoContentStyles.heading}>
        {Strings.CONFIRM_INFORMATION}
      </Text>
      <Formik
        initialValues={{
          first_name: data ? data.first_name : "",
          family_name: data ? data.last_name : "",
          birth_date: data ? data.date_of_birth : startDate,
          passport_number: data ? data.passport_number : "",
          passport_country: data ? data.citizenship : "",
          email: email,
          employer: "",
        }}
        validationSchema={validationSchema.confirmClientInfo}
        onSubmit={async (values) => {
          !loading ? setLoading(true) : createClient(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <>
            <View style={{ marginTop: 10, zIndex: 2 }}>
              <View style={confirmInfoContentStyles.inputsContainer}>
                <ConfirmInfoInput
                  label={GIVEN_NAME}
                  placeholder={GIVEN_NAME}
                  value={values.first_name}
                  inputContainer={confirmInfoContentStyles.inputContainer}
                  setValue={handleChange("first_name")}
                  error={touched.first_name && errors.first_name}
                  handleBlur={handleBlur("first_name")}
                  outlineColor={theme.colors.inputActiveOutlineColor}
                />
                <ConfirmInfoInput
                  label={FAMILY_NAME}
                  placeholder={FAMILY_NAME}
                  value={values.family_name}
                  inputContainer={confirmInfoContentStyles.inputContainer}
                  setValue={handleChange("family_name")}
                  error={touched.family_name && errors.family_name}
                  handleBlur={handleBlur("family_name")}
                  outlineColor={theme.colors.inputActiveOutlineColor}
                />
              </View>
              <View
                style={[
                  confirmInfoContentStyles.inputsContainer,
                  { zIndex: 6 },
                ]}
              >
                <ConfirmInfoInput
                  label={PASSPORT_ID}
                  placeholder={PASSPORT_ID}
                  value={values.passport_number}
                  inputContainer={confirmInfoContentStyles.inputContainer}
                  setValue={handleChange("passport_number")}
                  error={touched.passport_number && errors.passport_number}
                  handleBlur={handleBlur("passport_number")}
                  outlineColor={theme.colors.inputActiveOutlineColor}
                />
                <View style={{ flex: 1 }}>
                  <DatePickerInput
                    setSelectedDate={setStartDate}
                    selectedDate={startDate}
                    customInput={
                      <ConfirmInfoInput
                        editable={false}
                        label={DATE_OF_BIRTH}
                        inputContainer={confirmInfoContentStyles.inputContainer}
                        placeholder={DATE_OF_BIRTH}
                        value={
                          startDate
                            ? moment(startDate).format("yyyy-MM-DD")
                            : ""
                        }
                        outlineColor={theme.colors.inputActiveOutlineColor}
                        handleBlur={handleBlur}
                      />
                    }
                  />
                </View>
              </View>
              <View
                style={[
                  confirmInfoContentStyles.inputsContainer,
                  { zIndex: 5 },
                ]}
              >
                <ConfirmInfoInput
                  label={Strings.EMAIL}
                  placeholder={Strings.EMAIL}
                  value={values.email}
                  editable={email ? false : true}
                  inputContainer={confirmInfoContentStyles.inputContainer}
                  setValue={handleChange("email")}
                  error={touched.email && errors.email}
                  handleBlur={handleBlur("email")}
                  outlineColor={theme.colors.inputActiveOutlineColor}
                />
                <View style={confirmInfoContentStyles.inputContainer}>
                  <Text
                    style={[
                      {
                        ...theme.fonts.black,
                        marginBottom: 1,
                        fontSize: 16,
                        color: theme.colors.btn,
                        textTransform: "uppercase",
                      },
                    ]}
                  >
                    {Strings.CITIZEN_SHIP}
                  </Text>
                  <>
                    <View
                      style={[
                        confirmInfoContentStyles.dropdownStyle,
                        // {
                        //   zIndex: 5,
                        // },
                      ]}
                    >
                      <DropDown
                        receiptTypeOptions={list}
                        onSelection={handleChange("passport_country")}
                        selectedOption={values.passport_country}
                      />
                    </View>
                    <HelperText
                      style={{ color: theme.colors.red, fontWeight: "700" }}
                      type="error"
                      visible={
                        !!(touched.passport_country && errors.passport_country)
                      }
                    >
                      {errors.passport_country}
                    </HelperText>
                  </>
                </View>
              </View>
              <View style={confirmInfoContentStyles.inputsContainer}>
                <ConfirmInfoInput
                  label={EMPLOYER}
                  placeholder={EMPLOYER}
                  value={values.employer}
                  inputContainer={confirmInfoContentStyles.inputContainer}
                  setValue={handleChange("employer")}
                  error={touched.employer && errors.employer}
                  handleBlur={handleBlur("employer")}
                  outlineColor={theme.colors.inputActiveOutlineColor}
                />
                <View style={{ flex: 1 }} />
              </View>
            </View>
            {errorMessage ? (
              <ErrorText
                error={errorMessage}
                txtStyle={{ marginVertical: 5 }}
              />
            ) : null}
            <View style={confirmInfoContentStyles.btnContainer}>
              <ProgressBarTimer
                showProgressBar={loading}
                handleFunction={handleSubmit}
                timer={62000}
                progressBarValue={progressBarValue}
              >
                <CustomButton
                  onPress={handleSubmit}
                  name={Strings.CREATE_CLIENT}
                />
              </ProgressBarTimer>
            </View>
          </>
        )}
      </Formik>
    </>
  );
};

export default React.memo(ConfirmInfoContent);
