import React from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../assets/strings";
import { useRootContext } from "../../../context/rootContext/RootContext";
import CustomButton from "../../Buttons/CustomButton";
import ErrorText from "../../ErrorText";
import CustomModal from "../../Modal/CustomModal";
import { deleteDocumentModalStyles } from "./DeleteDocumentModal.Styles";

type props = {
  visible: boolean;
  onDismiss: () => void;
  isLoading: boolean;
  docName: string;
  onDelete: () => void;
  msg?: string;
};

const DeleteDocumentModal: React.FC<props> = ({
  visible,
  onDismiss,
  isLoading,
  docName,
  onDelete,
  msg,
}) => {
  const {
    dimensions: { width },
  } = useRootContext();

  return (
    <CustomModal
      modalStyle={{ width: width > 465 ? 400 : "90%" }}
      visible={visible}
      onDismiss={onDismiss}
    >
      <Text style={deleteDocumentModalStyles.warningTxt}>
        {Strings.WARNING}
      </Text>
      <Text style={deleteDocumentModalStyles.text}>
        {Strings.ARE_YOU_SURE_TO_DELETE}
      </Text>
      <Text style={deleteDocumentModalStyles.fileName}>{docName}</Text>
      <View style={deleteDocumentModalStyles.btnContainer}>
        <CustomButton
          btnStyle={{ margin: 10 }}
          onPress={onDismiss}
          name={Strings.CANCEL}
        />
        <CustomButton
          btnStyle={{ margin: 10 }}
          onPress={onDelete}
          isLoading={isLoading}
          name={Strings.DELETE}
        />
      </View>
      {msg && msg !== Strings.LOADING_STARTED ? (
        <ErrorText txtStyle={{ marginTop: 10 }} error={msg} />
      ) : null}
    </CustomModal>
  );
};

export default React.memo(DeleteDocumentModal);
