import { multiGet } from "../../common/AsyncStorage";
import { AsyncStorageKeys } from "../../common/Constant";

export const getOrgSessionFromStorage = async () => {
  try {
    const values = await multiGet([
      AsyncStorageKeys.ORG_USER_ID,
      AsyncStorageKeys.ORG_USER_EMAIL,
      AsyncStorageKeys.ORG_SESSION_TOKEN,
      AsyncStorageKeys.ORG_EXPIRES_AT,
      AsyncStorageKeys.ORG_SESSION_REFRESH_TOKEN,
    ]);

    return {
      orgUserId: values[0][1],
      orgUserEmail: values[1][1],
      orgAccessToken: values[2][1],
      orgExpiresAt: values[3][1],
      orgRefreshToken: values[4][1],
    };
  } catch (error) {
    console.error(error);
  }
};
