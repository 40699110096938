import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { theme } from "../../theme";
import { HelperText } from "react-native-paper";

type Props = {
  containerStyle?: {};
  receiptTypeOptions: { label: string; value: string }[];
  onSelection: (value: string) => {};
  selectedOption: string;
  disable?: boolean;
  isProfile?: boolean;
  error?: string;
};
const DropDown: React.FC<Props> = (props) => {
  const [defaultValue, setDefaultValue] = useState(null);
  useEffect(() => {
    if (!!props.receiptTypeOptions.length) {
      const value = props.receiptTypeOptions.findIndex(
        (val) =>
          val.label === props.selectedOption ||
          val.value === props.selectedOption
      );
      setDefaultValue(props?.receiptTypeOptions?.[value]);
    }
  }, [props.receiptTypeOptions, props.selectedOption]);
  return (
    <>
      <Select
        className="basic-single"
        classNamePrefix="select"
        isLoading={!props.receiptTypeOptions.length}
        styles={props.isProfile ? profileStyles : signUpStyles}
        options={props.receiptTypeOptions}
        value={defaultValue}
        onChange={(val: any) => {
          props.onSelection(val.value);
        }}
        isDisabled={props.disable}
      />
      {true ? (
        <HelperText
          style={{ color: theme.colors.red, fontWeight: "700" }}
          type="error"
          visible={props.error !== undefined}
        >
          {props.error}
        </HelperText>
      ) : null}
    </>
  );
};
const commonStyle = {
  option: (provided, state) => {
    return {
      ...provided,
      background:
        state.data === state.selectProps.value ? theme.colors.bottom : "white",
    };
  },
};
const profileStyles = {
  control: (base, state) => ({
    ...base,
    ...theme.fonts.regular,
    background: "transparent",
    color: "red",
    borderRadius: 0,
    borderColor: "transparent",
    fontSize: 20,
    "&:hover": {
      borderBottom: "1px solid rgba(0, 0, 0, 1)",
    },
    boxShadow: "0 1px 0 transparent",
    borderBottom: "1px solid rgba(0, 0, 0, 1)",
  }),

  singleValue: (base) => ({
    ...base,
    color: "#9797FF",
    marginLeft: -5,
  }),
  input: (base) => ({
    ...base,
    color: "#9797FF",
    marginLeft: -5,
  }),
  placeholder: (base) => ({
    ...base,
    color: "#9797FF",
    marginLeft: -5,
  }),
  ...commonStyle,
};
const signUpStyles = {
  control: (base) => ({
    ...base,
    ...theme.fonts.regular,
    borderRadius: 3,
    background: "#F0FFFF",
    borderColor: "transparent",
    "&:hover": {
      borderColor: "transparent",
    },
    fontSize: 16,
    boxShadow: "transparent",
    paddingTop: 2,
    maxWidth: 225,
  }),
  input: (base) => ({
    ...base,
    display: "flex",
  }),
  ...commonStyle,
};
export default React.memo(DropDown);
