import React, { useMemo } from "react";
import { Strings } from "../../../../assets/strings";
import { MenuType } from "../../../screen/StatusProtector/types/DeshboardTypes";
import AddDependentButton from "../../Buttons/AddDependentButton";
import ButtonCheckMyStatus from "../../Buttons/CheckMyStatus";
import UploadDocumentsButton from "../../Buttons/UploadDocument";

type props = {
  isStatusSet?: boolean;
  activeMenu: string;
  isActiveMenu: (menu: MenuType) => boolean;
  disabled: boolean;
  setMessage: (type?: string, mess?: string) => void;
  uploadedSuccessFully: () => void;
  addDependent?: () => void;
  reloadNonImmigrantStatusCallback?: () => void;
  clientId?: string;
};

const FooterButtons: React.FC<props> = ({
  isStatusSet,
  activeMenu,
  isActiveMenu,
  disabled,
  setMessage,
  uploadedSuccessFully,
  addDependent,
  reloadNonImmigrantStatusCallback,
  clientId,
}) => {
  const renderButton = useMemo(() => {
    return isActiveMenu(MenuType.STATUS) && isStatusSet ? (
      <ButtonCheckMyStatus
        setMessage={setMessage}
        disabled={disabled}
        reloadNonImmigrantStatusCallback={reloadNonImmigrantStatusCallback}
        clientId={clientId}
      />
    ) : isActiveMenu(MenuType.DOCUMENTS)? (
      <UploadDocumentsButton
        setMessage={(type: string, message: string) => {
          if (type === Strings.TYPE_MESSAGE_SUCCESSFUL) {
            uploadedSuccessFully();
          }
          setMessage(type, message);
        }}
        disabled={disabled}
        clientId={clientId}
      />
      ) : isActiveMenu(MenuType.DEPENDENTS) && !clientId ? (
      <AddDependentButton onPress={addDependent} disabled={disabled} />
    ) : null;
  }, [activeMenu, isStatusSet, clientId]);

  return renderButton;
};

export default React.memo(FooterButtons);
