import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const headerStyles = StyleSheet.create({
    container: {flexDirection: "row", alignItems: "center"},
    textStyle: {
        color: theme.colors.btn,
        fontSize: 20,
        flex: 1,
        textTransform: "uppercase",
        fontWeight: "bold",
      }
})