import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
    container: { 
        alignItems: "center", 
        marginHorizontal: 32 
    },
    minSectionContainer: {
        maxWidth: 1023, 
        width: "100%",
    },  
    sectionOne: {
        maxWidth: 1152, 
        width: "100%", 
        flexDirection: "row", 
        flexWrap: "wrap", 
        justifyContent: "space-around",
        alignItems: "center",
    },
    sectionOne_descriptionContainer: { 
        maxWidth: 624, 
        width: "100%", 
        marginTop: 120 
    },
    sectionOne_image: { 
        maxWidth: 432, 
        width: "100%", 
        height: 338, 
        alignSelf: "flex-end", 
        marginTop: 64 
    },
    sectionTwo: {
        maxWidth: 1152, 
        width: "100%", 
        alignItems: "center",
        marginVertical: 96,
        paddingVertical: 118,
        paddingHorizontal: 32,
        borderWidth: 5, 
        borderColor: "#9797FF", 
        borderRadius: 17, 
    },
    subSectionTwo: {  
        flexDirection: "row-reverse",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-around", 
    },
    sectionTwo_descriptionContainer: { 
        maxWidth: 497,
        width: "100%",  
    },
    sectionTwo_image: { 
        width: 239, 
        height: 345, 
        alignSelf: "center", 
        marginTop: 32 
    },
    sectionThree: { 
        maxWidth: 1023, 
        width: "100%", 
        marginBottom: 96 
    },
    subSectionThree: {
        flexDirection: "row", 
        flexWrap: "wrap", 
        alignItems: "center", 
        justifyContent: "space-around"
    },
    sectionThree_descriptionContainer: { 
        maxWidth: 483, 
        width: "100%",
        alignSelf: "center" 
    },
    point: {
        width: 9, 
        height: 9, 
        borderRadius: 9, 
    },
    sectionThree_button: {
        marginTop: 32,
    },
    sectionThree_image: {
        maxWidth: 420,
        width: "100%", 
        height: 427, 
        marginTop: 64 
    },
    sectionFour: { 
        maxWidth: 1152, 
        width: "100%", 
        marginBottom: 48 
    },
    subSectionFour: { 
        flexDirection: "row", 
        justifyContent: "space-around", 
        flexWrap: "wrap" 
    },
    sectionFour_cardContainer: { 
        maxWidth: 350, 
        width: "100%", 
        height: 234, 
        marginBottom: 48 
    },
    sectionFour_imageContainer: { 
        width: 60, 
        height: 60, 
        borderRadius: 12, 
        padding: 12 
    },
    sectionFour_image: { 
        width: 36, 
        height: 36 
    },
    sectionFour_title: { 
        fontSize: 21, 
        lineHeight: 33.6, 
        fontWeight: "bold", 
        textTransform: "uppercase",
        marginTop: 10, 
        marginBottom: 14, 
    },
    videoSection: {
        width: "100%", 
        marginBottom: 96,
    },
    video: {
        alignSelf: "center",
        maxWidth: 900,
        minWidth: 200,
        width: "100%",
        height: "100%",
        borderRadius: 5,
    },
    sectionFive: { 
        maxWidth: 1023, 
        width: "100%" 
    },
    sectionFive_cardsContainer: { 
        flexDirection: "row", 
        justifyContent: "space-around", 
        flexWrap: "wrap", 
        marginBottom: 85 
    },
    sectionFive_container: { 
        maxWidth: 423, 
        width: "100%", 
        height: 600, 
        borderWidth: 4, 
        borderColor: "#9797FF", 
        borderRadius: 17, 
        padding: 32,
        backgroundColor: "#F0FFFF", 
        marginVertical: 11  
    },
    sectionFive_title: { 
        fontSize: 21, 
        textAlign: "center", 
        fontWeight: "bold", 
        marginBottom: 32 
    },
    sectionFive_divider: { backgroundColor: "#8894A0", },
    sectionFive_subContainer: {
        flex: 1, 
        justifyContent: "space-between" 
    },
    sectionFive_subTitle: { 
        paddingVertical: 16, 
        fontSize: 18, 
        lineHeight: 28.8, 
        fontWeight: "bold" 
    },
    sectionFive_description: { 
        fontSize: 17, 
        lineHeight: 28.8,
        marginBottom: 16, 
    },
    sectionFive_letterBtn: { 
        fontFamily: "FilsonSoftBook", 
        textTransform: "capitalize", 
        fontSize: 18, 
        lineHeight: 28.8 
    },
    title: {
        fontFamily: "Brother1816Black",
        fontSize: 38,
        textAlign: "center",
        color: "#212529",
        marginBottom: 69,
    },
    heroTitle: {
        fontFamily: "Brother1816Black", 
        fontSize: 50, 
        lineHeight: 58, 
        color: "#212529",
    },
    text: {
        fontSize: 18,
        lineHeight: 28.8,
        color: "#212529",
    },
})