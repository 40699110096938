import React, { useState } from "react";
import {
  View,
  StyleSheet,
  Pressable,
  StyleProp,
  PressableProps,
} from "react-native";
import { MaterialIcons } from "@expo/vector-icons";

/* Logic */
import { theme } from "../../theme";

type Props = {
  style?: {};
  color?: string;
  defaultChecked?: boolean;
  onChecked: (value: boolean) => void;
  isEditable?: boolean;
};

const Checkbox: React.FC<Props> = ({
  style,
  color = theme.colors.btn,
  defaultChecked = false,
  onChecked = () => {},
  isEditable = true,
}) => {
  const [isChecked, setChecked] = useState(defaultChecked);

  let noEditStyle = {
    borderRadius: 100,
  };

  let mainStyle = {
    borderColor: color,
    ...style,
    ...(isEditable ? {} : noEditStyle),
  };

  return (
    <Pressable
      style={[styles.container, mainStyle]}
      onPress={() => {
        if (isEditable) {
          const newValue = !isChecked;
          onChecked(newValue);
          setChecked(newValue);
        }
      }}
    >
      <View style={isEditable ? {} : styles.noEditStyleIcon}>
        <MaterialIcons
          name={isChecked ? "done" : null}
          size={12}
          color={isEditable ? theme.colors.btn : theme.colors.secondary}
        />
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 20,
    width: 20,
    borderWidth: 2,
    borderColor: theme.colors.btn,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  noEditStyleIcon: {
    backgroundColor: theme.colors.btn,
    height: 14,
    width: 14,
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default Checkbox;
