import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const dependentInfoContentStyles = StyleSheet.create({
  inputsContainer: {
    flexDirection: "row",
  },
  inputContainer: {
    flex: 1,
    marginRight: 5,
  },

  errorContainer: {
    height: 22.5,
  },
  label: {
    fontSize: 13,
    color: "#9797FF",
  },
  input: { backgroundColor: "#F0FFFF" },
  btnContainer: {
    flex: 1,
    width: "100%",
  },
  dropdownStyle: {
    borderWidth: 1,
    borderRadius: 5,
    marginTop: 5,
    flexDirection: "row",
    alignSelf: "flex-start",
  },
});
