import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../assets/strings";

type Props = {
  dateEntry: string;
  name: string;
  passportNumber: string;
  admisionClass: string;
  citizenship: string;
};

const Status: React.FC<Props> = ({ name, passportNumber, dateEntry, admisionClass, citizenship }) => {
  const { FULL_NAME, PASSPORT_NUMBER, DATE_OF_ENTRY, ADMISSION_CLASS } =
    Strings.TableHeader;

  return (
    <View style={statusStyles.statusContainer}>
      <View style={statusStyles.statusPersonalInfoPart1}>
        <View style={statusStyles.fullNameContainer}>
          <Text style={statusStyles.title}>{FULL_NAME}</Text>
          <Text style={[statusStyles.text, { textTransform: "capitalize" }]}>
            {name ? name : Strings.UNKNOWN}
          </Text>
        </View>
        <View style={statusStyles.passportContainer}>
          <Text style={statusStyles.title}>{PASSPORT_NUMBER}</Text>
          <Text style={statusStyles.text}>{passportNumber ? passportNumber : Strings.UNKNOWN}</Text>
        </View>
      </View>

      <View style={statusStyles.statusPersonalInfoPart2}>
        <View style={statusStyles.dateOfEntryContainer}>
          <Text style={statusStyles.title}>{DATE_OF_ENTRY}</Text>
          <Text style={statusStyles.text}>{dateEntry ? dateEntry : Strings.UNKNOWN}</Text>
        </View>
        <View style={statusStyles.admissionRecordContainer}>
          <Text style={statusStyles.title}>{ADMISSION_CLASS}</Text>
          <Text style={statusStyles.text}>{admisionClass ? admisionClass : Strings.UNKNOWN}</Text>
        </View>
        <View style={statusStyles.countryContainer}>
          <Text style={statusStyles.title}>{Strings.CITIZEN_SHIP}</Text>
          <Text style={[statusStyles.text, { textTransform: "capitalize" }]}>
            {citizenship ? citizenship : Strings.UNKNOWN}
          </Text>
        </View>
      </View>
    </View>
  );
};

const statusStyles = StyleSheet.create({
  containerLoading: {
    height: 204,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F0FFFF",
  },
  statusContainer: {
    flex: 1,
    backgroundColor: "#F0FFFF",
    minWidth: 650,
  },
  documentBody: {
    height: 182,
    backgroundColor: "#F0FFFF",
  },
  notFoundPage: {
    justifyContent: "center",
    alignItems: "center",
  },
  notFoundText: {
    fontFamily: "Roboto_500Medium",
    fontSize: 16,
    color: "#9797FF",
  },
  statusPersonalInfoPart1: {
    flexDirection: "row",
    justifyContent: "space-between",
    minHeight: 86,
    flex: 1,
  },
  fullNameContainer: {
    flex: 1,
    justifyContent: "center",
    paddingLeft: 30,
    paddingRight: 16,
    fontSize: 16,
    color: "#5B456B",
    textTransform: "uppercase",
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderColor: "#9797FF",
  },
  title: {
    fontSize: 18,
    color: "#9797FF",
    marginBottom: 8,
  },
  text: { fontSize: 18, color: "#9797FF" },
  passportContainer: {
    flex: 1,
    justifyContent: "center",
    paddingLeft: 30,
    paddingRight: 16,
    fontSize: 16,
    color: "#5B456B",
    textTransform: "uppercase",
    borderBottomWidth: 1,
    borderColor: "#9797FF",
  },
  statusPersonalInfoPart2: {
    flexDirection: "row",
    justifyContent: "space-between",
    minHeight: 86,
    marginBottom: 32,
  },
  dateOfEntryContainer: {
    flex: 1,
    justifyContent: "center",
    paddingLeft: 30,
    paddingRight: 16,
    textTransform: "uppercase",
    borderRightWidth: 1,
    borderColor: "#9797FF",
  },
  admissionRecordContainer: {
    flex: 1,
    justifyContent: "center",
    paddingLeft: 30,
    paddingRight: 16,
    textTransform: "uppercase",
    borderRightWidth: 1,
    borderColor: "#9797FF",
  },
  countryContainer: {
    flex: 1,
    justifyContent: "center",
    paddingLeft: 30,
    paddingRight: 16,
    textTransform: "uppercase",
  },
});

export default Status;
