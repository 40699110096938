import React from "react";
import { View, ScrollView, ViewStyle } from "react-native";
import { Strings } from "../../../../../assets/strings";
import { DocumentBody } from "../../../../screen/StatusProtector/types/DeshboardTypes";
import ErrorText from "../../../ErrorText";
import DocumentListItem from "../DocumentListItem";
import TableHeader from "../TableHeader";
import { documentsListStyles } from "./DocumentsList.Styles";

type props = {
  style?: ViewStyle;
  documents: DocumentBody[];
  onDocDelete: (document: DocumentBody) => void;
  onDocDownload: (id: string) => void;
};

const DocumentsList: React.FC<props> = ({
  style,
  documents,
  onDocDelete,
  onDocDownload,
}) => {
  return (
    <View style={[documentsListStyles.container, style]}>
      <TableHeader />
      {documents.length ? (
        <ScrollView
          style={{ marginTop: 10 }}
          showsVerticalScrollIndicator={false}
        >
          {documents.map((data) => {
            return data.id ? (
              <DocumentListItem
                key={data.id}
                onDocDelete={() => onDocDelete(data)}
                onDocDownload={() => onDocDownload(data.id)}
                document={data}
              />
            ) : null;
          })}
        </ScrollView>
      ) : (
        <ErrorText
          txtStyle={documentsListStyles.txt}
          error={Strings.NO_DATA_AVAILABLE}
        />
      )}
    </View>
  );
};

export default React.memo(DocumentsList);
