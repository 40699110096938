import { Strings } from "../../../assets/strings";

export const getError = (error) => ({
  data: error?.response?.data || Strings.SOMETHING_WENT_WRONG,
  status: error?.response?.status,
});

export const getSuccess = (response) => ({
  data: response.data,
  status: response.status,
});
