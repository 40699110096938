import { Apis } from "./axiosInstances/Apis";
import { autoVisasPublicApi } from "./axiosInstances/autovisasPublicApi";
import { getError, getSuccess } from "./axiosInstances/handleResponse";

export const getPricingTable = async () => {
    try {
      const options = {
        method: "GET",
        url: `${Apis.BILLING}${Apis.PRICING_TABLE}`,
        headers: {
          "Content-Type": "application/json",
        }
      };
  
      const apiResponse = await autoVisasPublicApi.request(options);
      return getSuccess(apiResponse);
    } catch (error) {
      console.error(error.response);
      return getError(error);
    }
  };