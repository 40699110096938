import * as React from "react";
import { Screens } from "../common/Constant";
import Loading from "../components/Loading";
import { useStoreContext } from "../context/storeContext/StoreProvider";
import { getUserInfo } from "../util/apiUserInfo";
import { checkUploadedDocuments } from "../util/checkUploadedDocuments";
import { replace } from "./RouteNavigation";

const ProtectedRouteScreen = ({ children, ...rest }) => {
  const [isLoading, setLoading] = React.useState(true);
  const { setProfileData, profileData } = useStoreContext();
  const redirectTo = async (profileData) => {
    const paymentCompleted =
      (profileData.stripe_setup_completed && !profileData.organization_id) ||
      (!profileData.stripe_setup_completed && !!profileData.organization_id);
    if (
      !profileData.stripe_setup_completed &&
      !profileData.organization_id &&
      !profileData.organization_id &&
      rest.route.name !== Screens.STRIPE_PAYMENT
    ) {
      replace(Screens.STRIPE_PAYMENT, null);
    } else if (
      paymentCompleted &&
      !profileData.profile_completed &&
      rest.route.name !== Screens.UPLOAD_DOCUMENTS &&
      rest.route.name !== Screens.CONFIRM_INFO
    ) {
      await checkUploadedDocuments(profileData.uid, async (document) => {
        if (document) {
          await setProfileData({
            ...document,
          });
          replace(Screens.CONFIRM_INFO, null);
        } else {
          replace(Screens.UPLOAD_DOCUMENTS, null);
        }
      });
    } else if (
      profileData.profile_completed &&
      paymentCompleted &&
      (rest.route.name === Screens.UPLOAD_DOCUMENTS ||
        rest.route.name === Screens.CONFIRM_INFO ||
        rest.route.name === Screens.STRIPE_PAYMENT ||
        rest.route.name === Screens.PAYMENT_SUCCESS ||
        rest.route.name === Screens.PAYMENT_FAILURE)
    ) {
      replace(Screens.DRAWER, null);
    } else {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    const controller = new AbortController();
    if (!profileData) {
      setLoading(true);
      getUserInfo(controller)
        .then((res) => {
          setProfileData(res.data);
          redirectTo(res.data);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    } else {
      redirectTo(profileData);
    }
    return () => {
      controller.abort();
    };
  }, []);
  if (isLoading) {
    return <Loading />;
  }
  return children;
};

export default ProtectedRouteScreen;
