import { Screens } from "../../src/common/Constant";
import { Images } from "../images";

export const Strings = {
  SEND_OTP: "Send Passcode",
  ENTER_YOUR_EMAIL: "Enter your new email that is to be updated",
  SUBMIT: "Submit",
  RESEND_PASSCODE: "Resend Passcode",
  EMAIL_VERIFICATION_TXT:
    "Verify your email by entering the passcode received on your new email",
  UNAUTHORIZED_EMAIL:
    "Email Address not authorized for Status Protector beta test account. Please use the email address provided in your Status Protector Test Invitation email",
  INVITE_EMAIL_TITLE: "HELLO! AND WELCOME TO",
  INVITE_EMAIL_PARA: "YOU ARE INVITED TO THE (STATUS PROTECTOR) BETA TEST",
  INVITE_EMAIL: "EMAIL ADDRESS",
  LET_GET_STARTED: "GET STARTED",
  HAVE_AN_ACCOUNT: "Have an account?",
  LOGIN: "Login",
  LOADING_STARTED: "Loading Started",
  COMPLETE_YOUR_PROFILE: "Profile Incomplete",
  INVALID: "Invalid",
  CRITICAL: "Critical",
  CREATE_ACCOUNT: "Create your account",
  LOADING_SUCCESS: "Loading Success",
  CREATE_ACCOUNT_DESCRIPTION:
    "Create your account with a secure and unique password.",
  UPLOAD_YOUR_DOCUMENTS: "Upload status documents",
  UPLOAD_YOUR_DOCUMENTS_DESCRIPTION:
    "Please upload one or more of the following documents: \n\n-I-94 \n-I797 \n-Passport Bio Page\n\nStatus Protector will detect the information it needs to begin safeguarding your status.",
  CONFIRM_YOUR_INFO: "Confirm your information",
  CONFIRM_YOUR_INFO_DESCRIPTION:
    "Status Protector has detected the following information from your uploaded documents. Confirm or edit your information.",
  CONFIRM_YOUR_INFO_CHECKBOX_ERROR: "Check this box to continue",
  TERMS_CONDITION_CHECKBOX_WARNING:
    "Please agree with our terms and conditions by checking the above box",
  CBP_SERVER_ERROR:
    "Something went wrong checking your status, please reach out to support@autovisas.com",
  CBP_SUCCESSFUL: "Successfully  checked status ",
  CONFIRM_INFO: "Confirm Info",
  CONFIRM_INFORMATION: "Confirm Information",
  INVALID_EMAIL: "Invalid email",
  ADDED_TO_WAITLIST:
    "Autovisas is not currently accepting new accounts, but you will be notified when more slots are available",
  SOMETHING_WENT_WRONG: "Something went wrong",
  ALREADY_ASSOCIATED: "User already associated with an organization.",
  ALREADY_HAVE_ACCOUNT: "Already have an account?",
  CREATE_YOUR_ACCOUNT: "Create Your Account",
  CREATE_ACCOUNT_DESC: "Create your account with a secure and unique password",
  UNAUTHORIZED: "Please login again with correct credentials",
  BAD_REQUEST: "Bad Request",
  OTP_VERIFICATION: "OTP Verification",
  OTP_VERIFY_DESC: "We have mailed you a one time passcode to this email",
  I_AGREE_WITH: "I agree with ",
  TERMS_AND_CONDITIONS: "Terms and Conditions",
  AND: " and ",
  PRIVACY_POLICY: "Privacy Policy",
  UPLOAD_DOCUMENTS: "Upload Documents",
  UPLOAD_STATUS_DOCS: "Upload Status Documents",
  UPLOAD_DOCUMENTS_LATER: "Upload Documents Later",
  CONTINUE: "Continue",
  UPLOAD_ADDITIONAL_DOCUMENTS: "Upload Additional Documents ",
  DONE: "Done",
  UNSUPPORTED_FILETYPE:
    "Unsupported Filetype. Filetypes supported: .pdf, .jpg, .png, .tiff ",
  UNSUPPORTED_CSV_FILETYPE: "Unsupported Filetype. Only supported: .csv",
  SUPPORTED_FILETYPE: " * Filetypes supported: .pdf, .jpg, .png, .tiff ",
  SUPPORTED_CSV_FILETYPE: " * FileType supported: .csv",

  UPLOAD_ERROR:
    "We've encountered a problem receiving your document. Please try again. If you receive this message repeatedly, please contact us at Support@Autovisas.com",
  DRAG_AND_DROP_FILE: "Drag and drop file here",
  OR: "Or",
  SELECT_FILES: "Select FileS",
  INVALID_CODE: "Invalid Code",
  UNKNOWN_TYPE_FILE: "UNKNOWN",
  EMPTY_TYPE_FILE: "",
  DOCUMENT_ERROR: "Something when wrong, please reload again",
  SERVER_ERROR: "Somenthing when wrong, please try again",
  SEND_OTP_SUCCESS_MESSAGE:
    "The One Time Password has been sent to your email.",
  SEND_OTP_WARNING: "We can only send you a new code every 60 seconds.",
  SEND_OTP_USER_SESSION_EXPIRED:
    "The session has expired, please reload the page.",
  CITIZEN_SHIP: "Citizenship",
  CREATE_CLIENT: "Create Client",
  CLIENT_ADDED_MSG: "Client Added Successfully",
  GO_TO_CLIENT_PAGE: "Go to Client Page",
  STATUS_PROTECTOR_FOOTER:
    "Status Protector is only a tool to assist you and is not an official record for legal purposes.",
  CONFIRM_PAUSE_ACCOUNT:
    "If you pause your account then you won't be able to: \n - Upload new documents \n - Check non-immigration status \n - Get status alerts via mail/text",
  PAUSE_ACCOUNT_ERROR: "Something went wrong while pausing your account",
  RESUME_ACCOUNT_ERROR: "Something went wrong while resuming your account",
  PLAN_EXPIRED:
    "Your plan is expired. Please renew it by purchasing a new plan.",
  EMAIL_ALREADY_EXISTS: "Email already exists",
  ARE_YOU_SURE_TO_DELETE: "Are you sure want to delete document?",
  DELETE_DEPENDENT: "Are you sure want to delete dependent?",
  ConfirmInfo: {
    GIVEN_NAME: "Given Name",
    FAMILY_NAME: "Family Name",
    DATE_OF_BIRTH: "Date Of Birth",
    PASSPORT_ID: "Passport Id",
    RELATIONSHIP: "Relationship",
    CLASS_OF_ADMISSION: "Class of Admission",
    CONFIRM_CHECK_STATUS: "Confirm: Check Status!",
    EMPLOYER: "Employer",
    CONFIRM_YOUR_INFO_CHECKBOX:
      " I authorize AutoVisas to use this information to check my I-94 status now, with additional checks every 30 days.",
  },
  ADMIT_UNTIL: "Admit Until",
  HAS_PENDING_NIV: "Has Pending Niv",
  HAS_PENDING_AOS: "Has Pending Aos",
  TableHeader: {
    NAME: "Name",
    ADMISSION_CLASS: "Admission Class",
    ADMISSION_OR_EFFECTIVE_DATE: "Admission or effective date",
    DOCUMENT: "Document",
    FULL_NAME: "Full name",
    PASSPORT_NUMBER: "Passport number",
    DATE_OF_ENTRY: "Date of entry",
    VALID_UNTIL: "Valid Until",
    ACTIONS: "Actions",
  },
  CsvUserHeader: {
    NAME: "Name",
    UPLOAD_TIME: "Upload Time",
    TOTAL_ROWS: "Total Rows",
    SUCCESS_COUNT: "Success Count",
    ERROR_COUNT: "Error Count",
  },
  Profile: {
    EDIT_YOUR: "Edit Your",
    PROFILE: "Profile",
    EDIT: "Edit",
    SAVE: "Save",
    ENTER_LAST_NAME: "Please enter your last name",
    ENTER_FIRST_NAME: "Please enter your first name",
    ENTER_CITIZEN_SHIP: "Please enter your citizenship",
    ENTER_PASSPORT_ID: "Please enter Passport number",
    ENTER_DOB: "Please enter correct Date of birth",
    DELETE_ACCOUNT: "Delete Account",
    RESUME: "Resume",
    PAUSE: "Pause",
  },
  VISA_TYPE: {
    H_1B: "H-1B",
    L1_A: "L1-A",
    L1_B: "L1-B",
  },
  VISA_TYPE_NAME: "Visa Type",
  NON_IMMIGRANT_STATUS: "Your non-immigrant status is ",
  YOUR_INFORMATION: "Information",
  YOUR_DOCUMENTS: "Documents",
  YOUR_WARNINGS: "Warnings",
  EMPTY_WARNINGS: "No warnings found",
  WARNING: "Warning!",
  DELETE_ACCOUNT_WARNING:
    "Deleting your account is PERMANENT and cannot be undone. You will lose all documents and information you have submitted to Autovisas. If you want to use Status Protector again in the future you will have to re enter your information.",
  CANCEL: "Cancel",
  PLAN_MANAGED_BY_ORGANIZATION:
    "You can't manage your subscription as you don't have any personal plan purchased. \n\nYour plan is managed by your organization",
  PASSCODE_SENT: "Passcode sent successfully",
  INVITATION_SENT: "Invitation sent successfully",
  CONFIRM: "Confirm",
  ORGANIZATION: "Organization",
  USERS: "Users",
  CREATE_ORGANIZATION: "Create an Organization",
  CREATE_ORG: "Create Organization",
  SWITCH_ORG: "Switch Organization",
  NAME_LABEL: "Name*",
  NAME: "Name",
  EMAIL_LABEL: "Email*",
  MANAGE_USER_DETAILS: "Manage your users details",
  EMAIL: "Email",
  CHANGE_PASSWORD_SUCCESS_TEXT:
    "An email has been sent to your entered mail id to reset your password.\n Kindly check your mail to reset it.",
  RESET_PASS_SUCCESS_TXT:
    "Your password has been changed successfully.\n You can now login with new password.",

  ENTER_OTP: "Enter Passcode",
  ENTER_EMAIL: "Enter your email to reset your password",
  ADDRESS_LINE_1_LABEL: "Address Line 1*",
  ENTER_PASSWORD: "Enter New Password",
  ADDRESS_LINE_1: "Address Line 1",
  ADDRESS_LINE_2_LABEL: "Address Line 2",
  ADDRESS_LINE_2: "Address Line 2",
  CITY_LABEL: "City*",
  CITY: "City",
  STATE_LABEL: "State*",
  STATE: "State",
  ZIP_CODE_LABEL: "ZIP Code*",
  ZIP_CODE: "ZIP Code",
  PHONE_LABEL: "Phone*",
  PHONE: "Phone",
  OUTLINED: "outlined",
  CREATE: "Create",
  GO_TO_PAY: "Go To Pay",
  REQUIRED: "Required",
  VALID_MAIL_MESSAGE: "Please enter a valid email address",
  ENTER_MAIL: "Please enter email",
  STATUS: "Status",
  FILTER: "Filter",
  ASSIGNED_TO: "Assigned To",
  VALID_STATUS: "Valid",
  WARNING_STATUS: "Warning",
  SETTING: "Setting",
  LOGOUT: "Log Out",
  OWNER: "OWNER",
  MANAGER: "MANAGER",
  ADMIN: "ADMIN",
  ADMINS: "Admins",
  ADDRESS: "Address",
  CLIENT: "CLIENT",
  PROFILE: "Profile",
  ADD_ADMIN: "ADD ADMIN",
  ROLE_LABEL: "Role*",
  SEARCH: "Search",
  RESET_FILTER: "Reset Filter",
  ROLE: "Role",
  ADMIN_CODE: "AD",
  MANAGER_CODE: "MN",
  SHOW_MORE: "Show More",
  ACTION: "Actions",
  DAYS_SPENT: "Cap Out Days Spent",
  DAYS_REMAINING: "Cap Out Days Remaining",
  ARE_YOU_SURE_TO_DLT: "Are you sure want to delete",
  DELETE: "Delete",
  UPDATE: "Update",
  EDIT_USER: "Edit Admin",
  CLIENT_SMALL: "Client",
  CLIENTS: "Clients",
  ONBOARDING: "ONBOARDING",
  ADD_CLIENT: "Add Client",
  SEND_INVITATION: "Send Invitation",
  RESET_PASSWORD: "Reset Password",
  SAVE_PASSWORD: "Save Password",
  NO_DATA_AVAILABLE: "No data available",
  REJECT: "Reject",
  FIRST_NAME: "First Name",
  EDIT_PROFILE: "Edit your profile",
  ENTER_DETAILS_TO_ACCEPT_INVITE: "Enter details to accept invitation of ",
  ACCEPT_INVITE: "Accept Invite",
  INVITATION_ACCEPTED: "Invitation accepted. Click on ",
  GO_TO_LOGIN_PAGE: " to go to login page",
  LAST_NAME: "Last Name",
  CITIZENSHIP: "Citizenship",
  DOB: "Date of birth",
  PASSPORT_NUMBER: "Passport Number",
  TYPE_MESSAGE_ERROR: "error",
  TYPE_MESSAGE_SUCCESSFUL: "successful",
  TYPE_MESSAGE_CBP: "cbp succesful",
  STATUS_PROTECTOR: "Status Protector",
  MONTH: "month",
  YEAR: "year",
  ONCE: "one-time",
  PER: "per",
  SUBSCRIBE: "Subscribe",
  CREATE_USER: "Create a user",
  ZERO_DATE: "0001-01-01T00:00:00Z",
  ZERO_UTC_DATE: "0001-01-01 00:00:00 +0000 UTC",
  UNKNOWN: "UNKNOWN",
  N_A: "N/A",
  TRAVEL_HISTORY: "History",
  DATE: "Date",
  PASSWORD: "Password",
  CONFIRM_PASSWORD: "Confirm Passowrd",
  TYPE: "Type",
  LOCATION: "Location",
  ARRIVAL: "Arrival",
  DEPARTURE: "Departure",
  NO_DATA_FOUND: "No data found!!",
  PAYMENT: "payment",
  PAY: "Pay",
  DATE_FORMAT: "YYYY-MM-DD",
  SUBSCRIPTION: "subscription",
  SUBSCRIPTION_VALID_TILL: "Your subscription is valid till: ",
  MANAGE_SUBSSCRIPTION: "Manage Subscription",
  CURRENT_PASS: "Current Password",
  NEW_PASS: "New Password",
  PAYMENT_SUCCESS_MSG:
    "Congraturations, your payment is successful.\nYou can now avail the facilities offered by AutoVisas",
  PAYMENT_FAILURE_MSG:
    "Oops!!, your payment is unsuccessful. \nDebited money will be refunded back in your account. ",
  NO_ACTIVE_PLANS:
    "Oops!!, no active plans found.\n Please contact to admin for further assistance.\n\n Thank-You!",
  DEPENDENT: "Dependent",
  PASSWORD_CHANGED: "Password Changed Successfully",
  CHANGE_PASSWORD: "Change Password",
  VISA_CLASS: "Visa Class",
  ADD_DEPENDENT: "Add Dependent",
  ADD_DEPENDENT_DESCRIPTION: "Please enter your dependent's email address",

  LandingPage: {
    MAIN_TITLE: "Passport to Peace of Mind",
    MAIN_DESCRIPTION:
      "Status Protector is a trusted service that monitors your legal status, informs you of unexpected changes in legal status, and sends you reminders about important upcoming dates, such as status and passport expiration.",
    BUTTON_NAME: "Protect your status",
    CONTEXT1:
      "The U.S. immigration system is confusing, even for legal professionals. If you violate the terms of your visa, the consequences can be disastrous. The government might cancel your visa, ban you from the country for 10 years, and deport you at your expense. If you are working, studying, or simply living in the U.S. on a visa, many factors determine how long you can legally stay.",
    CONTEXT2:
      "Fortunately, Status Protector makes things easy by actively monitoring your status and keeping you informed of critical deadlines, unexpected changes, and, most importantly, your current legal status.",
    CONSEQUENCES_TITLE: "Consequences Of Overstaying Your Status",
    CONSEQUENCES_CONTENT: [
      {
        id: 1,
        description: "Up to 10-year ban from entering the United States.",
      },
      {
        id: 2,
        description: "Permanent disqualification from receiving a green card.",
      },
      {
        id: 3,
        description: "Career and family life disruption.",
      },
      {
        id: 4,
        description:
          "Damage to employer’s reputation with government agencies.",
      },
      {
        id: 5,
        description: "Costly legal and administrative fees.",
      },
    ],
    WHAT_STATUS_PROTECTOR_DOES_TITLE: "What Status Protector Does",
    WHAT_STATUS_PROTECTOR_DOES_CONTENT: [
      {
        id: 1,
        title: "Monitor",
        description:
          "Monitors your official authorized nonimmigrant status in the United States with effortless, automated, ongoing verification of your I-94 record.",
        image: Images.MONITOR,
        backgroundImageColor: "#FEF0E6",
      },
      {
        id: 2,
        title: "Detect",
        description: "Detects unexpected changes to your status.",
        image: Images.DETECT,
        backgroundImageColor: "#FCE6F6",
      },
      {
        id: 3,
        title: "Notify",
        description:
          "Notifies you of important deadlines to help you make informed decisions about your future in the United States.",
        image: Images.NOTIFY,
        backgroundImageColor: "#E7DEFC",
      },
      {
        id: 4,
        title: "Protect",
        description:
          "Protect your employees, clients, and business from unintended immigration consequences with Status Protector for Organizations",
        image: Images.PROTECT,
        backgroundImageColor: "#D0F7E9",
      },
      {
        id: 5,
        title: "Organize",
        description:
          "Organizes your I-94s, I-797s, passports, and travel history.",
        image: Images.ORGANIZE,
        backgroundImageColor: "#D3E5FE",
      },
      {
        id: 6,
        title: "Alert",
        description:
          "Alerts to potential issues before they become problems, so you're ahead of the game.",
        image: Images.ALERT,
        backgroundImageColor: "#FDE8E4",
      },
    ],
    PRICING_TITLE: "How Can We Protect You?",
    PRINCING_SUBTITLE: "What's inside",
    PRICING: [
      {
        id: 1,
        title: "Status Protector for Individuals and Families",
        content: [
          {
            id: 1,
            description: "Know your legal status in the United States.",
          },
          {
            id: 2,
            description: "Never worry about missing a critical deadline.",
          },
          {
            id: 3,
            description:
              "Access your essential immigration data on our intuitive dashboard.",
          },
        ],
        navigateTo: Screens.CREATE_ACCOUNT,
        btnName: "Get Status Protector Now",
      },
      {
        id: 2,
        title: "Status Protector for Organizations",
        content: [
          {
            id: 1,
            description:
              "Prevent accidental damage to your organization’s reputation, business operations, and employee relations.",
          },
          {
            id: 2,
            description: "Understand the big picture without all the effort.",
          },
          {
            id: 3,
            description:
              "See every status, expiration date, and alert in one convenient place.",
          },
        ],
        navigateTo: Screens.ORGANIZATION_USER_SIGNUP,
        btnName: "Get Status Protector for Organization",
      },
    ],
    HOME_FOOTER: "Have Questions? Contact AutoVisas: hello@autovisas.com",
  },
  MAP_CSV_COLUMN:
    "Map your uploaded csv file column according to given column name",
  CSV: "CSV",
  SELECT_UNIQUE_COLUMN_NAME: "Please select unique values for column",
  SELECT_ALL_COLUMN: "All fields except 'employer' are required",
  STARTING_AT: "Starting at",
  PER_UNIT: "per user",
  BILLED_BASED_ON_USAGE: "Billed monthly based on usage",
  EFFECTIVE_COST_CAL: "Effective Cost Calculation",
  INCOMPLETE_ACCOUNT:
    "We have not completed setting up your account, please enter your profile details exactly as they appear on your travel documents",

  ERROR_COUNT_MODAL: {
    ROW_NUMBER: "Row Number",
    ERROR_MESSAGE: "Error Message",
    ERROR_COUNT_ON: "Error count on\n",
    FILE: "file",
  },

  VISATYPE: ["H-1B", "L-1A", "L-1B", "LZ"],
  I140: "I-140",
  INTERMITTENT: "intermittent",
  USER_TYPE: {
    ORGANIZATION: "organization",
    INDIVIDUAL: "individual",
  },
  DEPENDENT_HEADERS: {
    ORGANIZATION: ["Dependent", "Visa Class", "Status"],
    INDIVIDUAL: ["Dependent", "Visa Class", "Status", "Action"],
  },
  EMPTY_STATUS: "Checking your non-immigrant status",
  CHECK_STATUS_SUCCESFULLY: "Thank you for checking your the status. Please be aware that the update may take up to 20 minutes. In the meantime, feel free to explore other features of our app. We appreciate your patience.",
};
