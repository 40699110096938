import { StyleSheet } from "react-native";

export const confirmInfoContentStyles = StyleSheet.create({
  inputsContainer: {
    flexDirection: "row",
  },
  heading: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 20,
    marginTop: 10,
    marginBottom: 15,
  },
  inputContainer: {
    flex: 1,
    marginRight: 5,
  },

  errorContainer: {
    height: 22.5,
  },
  label: {
    fontSize: 13,
    color: "#9797FF",
  },
  input: { backgroundColor: "#F0FFFF" },
  btnContainer: {
    width: 224,
    height: 45,
  },
  dropdownStyle: {
    borderWidth: 1,
    borderRadius: 5,
    marginTop: 5,
    flexDirection: "row",
    alignSelf: "flex-start",
  },
});
