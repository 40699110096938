import { StyleSheet } from "react-native";
import { theme } from "../../../../theme";

export const documentsListStyles = StyleSheet.create({
    container: {
        width: "100%",
        height: 400,
        marginTop: 20,
        backgroundColor: "#F0FFFF",
        borderColor: theme.colors.btn,
        borderWidth: 6,
        borderRadius: 20,
        padding: 20,
      },
      txt: {
        textAlign: "center",
        fontSize: 20,
        color: theme.colors.btn,
        marginTop: "15%",
      }
})