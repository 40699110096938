import { StyleSheet } from "react-native";
import { theme } from "../../../../theme";

export const enterEmailModalStyles = StyleSheet.create({
    label: {
        fontSize: 16,
        color: "#9797FF",
        textTransform: "uppercase",
      },
    
      input: { backgroundColor: "#F0FFFF" },
      errorContainer: {
        height: 22.5,
      },
      heading: {
        fontFamily:"Brother1816Black",
        color: theme.colors.btn,
        fontWeight: "bold",
        fontSize: 20,
        textAlign: "center",
        marginTop: 0,
      }
})