import React from "react";
import { Pressable } from "react-native";
import { Text } from "react-native-paper";
import { borderButtonStyles } from "./BorderButton.Styles";

type props = {
  title: string;
  onPress: () => void;
  style: {};
  txtStyle?: {};
};

const BorderButton: React.FC<props> = ({ title, onPress, style, txtStyle }) => {
  return (
    <Pressable onPress={onPress} style={style}>
      <Text style={[borderButtonStyles.btnTextEdit, txtStyle]}>{title}</Text>
    </Pressable>
  );
};

export default React.memo(BorderButton);
