// import
import * as React from "react";
import { Card, Text } from "react-native-paper";
import { FlatList, Pressable, ScrollView, View } from "react-native";

import DropView from "../../../components/DropView";
import { Strings } from "../../../../assets/strings";
import { browseFile as styles } from "./BrowseFile.Styles";
import SupportedFileMessage from "../SupportedFileMessage";
import DocumentFile from "../../DocumentFile";
import CustomShadowModal from "../../Modal/CustomShadowModal";
import { useRootContext } from "../../../context/rootContext/RootContext";
type Props = {
  visible: boolean;
  onDismiss: () => void;
  onPress: () => void;
  onDrop: (files: File[]) => void;
  onUpload: () => void;
  itemList: File[];
  setItemList: (files: File[]) => void;
};
const BrowseFile: React.FC<Props> = (props) => {
  const {
    visible,
    onDismiss,
    itemList,
    onPress,
    onDrop,
    onUpload,
    setItemList,
  } = props;
  const dropViewRef = React.useRef();
  const {
    dimensions: { width: width },
  } = useRootContext();

  const deleteFile = (documents: File[], documentPosition: number) => {
    const newDocuments = documents.filter(
      (document, index) => index !== documentPosition
    );
    setItemList(newDocuments);
  };

  return (
    <DropView ref={dropViewRef} onDrop={onDrop} multiple={true} noClick={true}>
      <CustomShadowModal
        visible={visible}
        onDismiss={onDismiss}
        style={[
          { height: 350 },
          width > 453 ? { width: 400 } : { width: "90%" },
        ]}
      >
        <ScrollView showsVerticalScrollIndicator={false}>
          <FlatList
            key={width > 453 ? "_" : "#"}
            contentContainerStyle={{
              marginStart: 10,
            }}
            data={itemList}
            renderItem={({ item, index }) => (
              <DocumentFile
                key={`${index} files`}
                document={item}
                deleteFile={() => deleteFile(itemList, index)}
              />
            )}
            numColumns={width > 453 ? 3 : 2}
            keyExtractor={(_, index) => index.toString()}
          />
          <Card.Actions style={styles.actionsContainer}>
            <View style={styles.shadow3}>
              <Pressable
                style={styles.button}
                // @ts-ignore
                onPress={() => dropViewRef?.current?.open()}
              >
                <Text
                  style={styles.btntxt}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {Strings.UPLOAD_ADDITIONAL_DOCUMENTS}
                </Text>
              </Pressable>
            </View>
            <View style={styles.shadow3}>
              <Pressable style={styles.button} onPress={onUpload}>
                <Text style={styles.btntxt}>{Strings.DONE}</Text>
              </Pressable>
            </View>
          </Card.Actions>
          <SupportedFileMessage />
        </ScrollView>
      </CustomShadowModal>
    </DropView>
  );
};

export default BrowseFile;
