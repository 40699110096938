import { StyleSheet } from "react-native";
import { theme } from "../../../../theme";

export const adminHeaderStyles = StyleSheet.create({
    container: {
        flexDirection: "row",
        width: "100%",
      },  
      headerText: {
        flex: 1,
        fontSize: 34,
        color: theme.colors.btn,
        fontFamily: "Brother1816Black"
      }
})