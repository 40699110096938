import { StyleSheet } from "react-native";
import { theme } from "../../../../theme";

export const changePasswordModalStyles = StyleSheet.create({
    inputContainer: {
        flex: 1,
        marginEnd: 5,
      },
      label: {
        fontSize: 16,
        color: "#9797FF",
        textTransform: "uppercase",
      },
    
      input: { backgroundColor: "#F0FFFF" },
      errorContainer: {
        height: 22.5,
      },
      text: {
            color: theme.colors.btn,
            fontWeight: "bold",
            fontSize: 20,
            textAlign: "center",
      }
})