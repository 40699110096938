import { StyleSheet } from "react-native";

export const uploadDocContentStyles = StyleSheet.create({
    heading: {
        fontSize: 20,
        marginTop: 10,
        fontWeight: "bold",
        textAlign: "center",
      },
      desc: { flex: 1, marginTop: 20, color: "#737373", fontSize: 17 } 
})