import { autoVisasPublicApi } from "../axiosInstances/autovisasPublicApi";

export const verifyOTP = async (session_id: string, otpCode: string) => {
  try {
    const options = {
      method: "POST",
      url: `/otp/${session_id}/verify`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        otp: otpCode,
      },
    };

    const verifyOTPResponse = await autoVisasPublicApi(options);

    return {
      userId: verifyOTPResponse.data.uid,
      accessToken: verifyOTPResponse.data.access_token.token,
      expiresAt: verifyOTPResponse.data.access_token.expires_at,
      refreshToken: verifyOTPResponse.data.refresh_token.token,
      status: verifyOTPResponse.status,
    };
  } catch (error) {
    console.error(error.response);

    return {
      data: error.response.data,
      status: error.response.status,
    };
  }
};
