import { getError, getSuccess } from "../axiosInstances/handleResponse"
import { autoVisasOrgPrivateApi } from "../axiosInstances/autoVisasOrgPrivateApi";
import { OrgApis } from "../axiosInstances/Apis";

export const getClientFamilyInfo = async (clientId: string, orgId: string, token: string) => {
    try {
        const options = {
            method: "GET",
            url: `${OrgApis.ORGANIZATION}/${orgId}${OrgApis.CLIENTS}/${clientId}${OrgApis.FAMILY}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            }
        };
        const apiResponse = await autoVisasOrgPrivateApi.request(options);
        return getSuccess(apiResponse);
    } catch (error) {
        getError(error);
    }
}