import { autoVisasOrgPublicApi } from "../axiosInstances/autoVisasOrgPublicApi";
import { OrgApis } from "../axiosInstances/Apis";
import { getError, getSuccess } from "../axiosInstances/handleResponse";

export const getOrgPricingTable = async () => {
  try {
    const options = {
      method: "GET",
      url: `${OrgApis.PAYMENT}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const apiResponse = await autoVisasOrgPublicApi.request(options);
    return getSuccess(apiResponse);
  } catch (error) {
    console.error(error.response);
    return getError(error);
  }
};
