import { Apis } from "./axiosInstances/Apis";
import { autoVisasPrivateApi } from "./axiosInstances/autovisasPrivateApi";
import { getError, getSuccess } from "./axiosInstances/handleResponse";
import { getUserSessionFromStorage } from "./getUserSessionFromStorage";

export const deleteDocument = async (userId: string, docId: string) => {
    try {
      const isUserSessionStorage = await getUserSessionFromStorage();
        const options = {
          method: "DELETE",
          url: `${Apis.GET_DOCUMENTS}/${userId}/${docId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${isUserSessionStorage.accessToken}`,
          },
        };
        const getDocumentResponse = await autoVisasPrivateApi.request(options);
        return getSuccess(getDocumentResponse);
      } catch (error) {
        console.error(error.response);
        return getError(error);
      }
  };