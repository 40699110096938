import React, { useEffect } from "react";
import { Image, View } from "react-native";
import { Text } from "react-native-paper";
import { Entypo } from "@expo/vector-icons";
import { Images } from "../../../assets/images";
import { Strings } from "../../../assets/strings";
import { paymentFailureStyles } from "./PaymentFailure.Styles";
import { replace } from "../../navigation/RouteNavigation";
import { Screens } from "../../common/Constant";
import Animation from "react-native-web-lottie";
import { Lottie } from "../../../assets/lottie";
import { useRoute } from "@react-navigation/native";
type props = {};
const Paymentfailure: React.FC<props> = () => {
  const { params } = useRoute();
  const redirectToDashboard = () => {
    const isOrganization = params && params?.type === "organization";
    if (isOrganization) {
      replace(Screens.ORGANIZATION_PAYMENT, null);
    } else {
      replace(Screens.DRAWER, null);
    }
  };
  return (
    <View style={paymentFailureStyles.container}>
      <Image
        source={Images.MAIN_LOGO}
        style={paymentFailureStyles.img}
        resizeMode="contain"
      />

      <Animation
        style={{ height: 300 }}
        source={Lottie.PAYMENT_FAILED}
        autoPlay
        onAnimationFinish={redirectToDashboard}
      />
    </View>
  );
};

export default React.memo(Paymentfailure);
