import { useRef } from "react";
import { Pressable } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Text } from "react-native-paper";
import { submitButtonStyles } from "./Submit.Styles";
import Loading from "../../Loading";

type Props = {
  onPress: () => void;
  name: string;
  style?: {};
  isDisabled?: boolean;
  letterStyle?: {};
  isLoading?: boolean;
};

const SubmitButton: React.FC<Props> = ({
  onPress,
  name,
  style,
  isDisabled,
  letterStyle,
  isLoading,
}) => {
  const ref = useRef(null);
  return (
    <Pressable
      disabled={isDisabled}
      style={[submitButtonStyles.button, style]}
      onPress={onPress}
    >
      <LinearGradient
        ref={ref}
        colors={["#9797FF", "#BE8DFA"]}
        locations={[0.2, 0.7]}
        start={{ x: 0.1, y: 0.5 }}
        end={{ x: 0.5, y: 0.5 }}
        style={[submitButtonStyles.buttonGradient]}
      >
        {isLoading ? (
          <Loading size="small" color="#F0FFFF" />
        ) : (
          <Text style={[submitButtonStyles.buttonText, letterStyle]}>
            {name}
          </Text>
        )}
      </LinearGradient>
    </Pressable>
  );
};

export default SubmitButton;
