import React from "react";
import { View } from "react-native";
import { SubscriptionPlan } from "../../../screen/StripePayment/types";
import StripePlanButton from "../../Buttons/StripePlanButton";
import { planHeaderStyles } from "./PlanHeader.Styles";

type props = {
  activeBtn: string;
  setActiveBtn: (value: string) => void;
  plans: SubscriptionPlan[];
};

const PlanHeader: React.FC<props> = ({ activeBtn, setActiveBtn, plans }) => {
  return (
    <View style={planHeaderStyles.container}>
      {plans.map((plan) => {
        return (
          <StripePlanButton
            key={plan.product_id}
            activeBtn={activeBtn}
            setActiveBtn={setActiveBtn}
            title={plan.name}
          />
        );
      })}
    </View>
  );
};

export default React.memo(PlanHeader);
