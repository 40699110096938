import { initialLoginType } from "../../context/AuthContext";
import { autoVisasPrivateApi } from "../axiosInstances/autovisasPrivateApi";
import { getError, getSuccess } from "../axiosInstances/handleResponse";

export const deleteDependent = async (
  loginState: initialLoginType,
  dId: string
) => {
  try {
    const options = {
      method: "DELETE",
      url: `users/${loginState.userId}/dependents/${dId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginState.userToken}`,
      },
    };
    const getDocumentResponse = await autoVisasPrivateApi.request(options);
    return getSuccess(getDocumentResponse);
  } catch (error) {
    console.error(error.response);
    return getError(error);
  }
};
