import { View, Pressable, Animated } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Text } from "react-native-paper";
import ContinueButton from "../Buttons/Continue";
import { Strings } from "../../../assets/strings";
import documentTextStyles from "./DocumentText.Styles";
import { documentsApiResponse } from "../../screen/UploadDocuments/types";
import { navigate } from "../../navigation/RouteNavigation";
import { Screens } from "../../common/Constant";
import { useContext, useMemo, useRef, useState } from "react";
import UploadDocumentsController from "../../screen/UploadDocuments/controller/UploadDocumentsController";
import ProgressBarTimer from "../progessBarTimerButton/ProgressBarTimer";
import { useStoreContext } from "../../context/storeContext/StoreProvider";
import { useRootContext } from "../../context/rootContext/RootContext";
import { DependentData } from "../../screen/StatusProtector/types/Dependentypes";
import { AuthContext } from "../../context/AuthContext";
import { useOrgAuthContext } from "../../context/OrganizationAuthContext";

type Props = {
  onUploadDoc: () => void;
  files: File[];
  setErrorMessage: (message: string) => void;
  onContinue: (data?: DependentData) => void;
  dId?: string;
  clientId?: string;
};

const DocumentText: React.FC<Props> = (props) => {
  const { onUploadDoc, files, setErrorMessage, onContinue, dId, clientId } =
    props;
  const [isLoading, setIsLoading] = useState(false);
  const { loginState } = useContext(AuthContext);
  const { orgAuthState } = useOrgAuthContext();

  const { uploadDocumentsFile, getSingleResponse } = useMemo(
    () => UploadDocumentsController(),
    []
  );
  const progressBarValue = useRef(new Animated.Value(0)).current;
  const { setProfileData } = useStoreContext();
  const {
    dimensions: { width: width },
  } = useRootContext();

  const isMobileView = useMemo(() => width < 539, [width]);
  const uploadDocumentHandle = async (files: File[]) => {
    setIsLoading(true);
    const documentsApiResponse: documentsApiResponse[] =
      await uploadDocumentsFile(files, dId, loginState, clientId, orgAuthState);
    const response = getSingleResponse(documentsApiResponse);
    progressBarValue.setValue(1);
    setTimeout(async () => {
      if (!response.hasError) {
        const data = response.data;
        const confirmInfoData = {
          dId,
          first_name: data?.first_name,
          last_name: data?.family_name,
          date_of_birth: data?.birth_date,
          passport_number: data?.passport_number,
          citizenship: data?.passport_country,
        };
        !onContinue &&
          (await setProfileData({
            ...confirmInfoData,
            admission_class: data?.admission_class,
            stripe_setup_completed: true,
          }));
        onContinue
          ? onContinue(confirmInfoData)
          : navigate(Screens.CONFIRM_INFO, null);
      } else {
        setErrorMessage(response.message);
      }
      setIsLoading(false);
    }, 500);
  };
  return (
    <>
      <View
        style={[
          documentTextStyles.btnsContainer,
          isMobileView && {
            flexDirection: "column",
            alignItems: "flex-start",
          },
        ]}
      >
        <Pressable onPress={onUploadDoc}>
          <LinearGradient
            colors={["#C0C0FF", "#CFAEFF"]}
            locations={[0.2, 0.7]}
            start={{ x: 0.1, y: 0.5 }}
            end={{ x: 0.5, y: 0.5 }}
            style={[documentTextStyles.button]}
          >
            <Text style={[documentTextStyles.btnTxt]}>
              {Strings.UPLOAD_DOCUMENTS}
            </Text>
          </LinearGradient>
        </Pressable>
        <Pressable
          onPress={() =>
            onContinue ? onContinue() : navigate(Screens.CONFIRM_INFO, null)
          }
        >
          <Text style={[documentTextStyles.link]}>
            {Strings.UPLOAD_DOCUMENTS_LATER}
          </Text>
        </Pressable>
      </View>
      <View style={documentTextStyles.documentTextContainer}>
        {files.map((val, index) => (
          <Text key={index} style={documentTextStyles.documentText}>
            ● {val.name}
          </Text>
        ))}
      </View>
      {files.length > 0 && (
        <View style={documentTextStyles.continueButtonContainer}>
          <ProgressBarTimer
            showProgressBar={isLoading}
            handleFunction={() => uploadDocumentHandle(files)}
            progressBarValue={progressBarValue}
          >
            <ContinueButton
              name={Strings.CONTINUE}
              onPress={() => setIsLoading(true)}
            />
          </ProgressBarTimer>
        </View>
      )}
    </>
  );
};

export default DocumentText;
