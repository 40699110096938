import { useIsFocused, useRoute } from "@react-navigation/native";
import React, { useEffect, useMemo, useState } from "react";
import { View } from "react-native";
import { setLocale } from "yup";
import { Strings } from "../../../../assets/strings";
import { decryptedData } from "../../../common/Utils";
import BgImageContainer from "../../../components/BgImageContainer";
import ErrorText from "../../../components/ErrorText";
import ClientDocumentsHeader from "../../../components/Organization/ClientDocumentsScreen/ClientDocumentsHeader";
import DocumentsList from "../../../components/Organization/ClientDocumentsScreen/DocumentsList";
import UploadClientDocModal from "../../../components/Organization/ClientScreen/UploadClientDocModal";
import { ProgressBar } from "../../../components/progressBar/ProgressBar";
import DeleteDocumentModal from "../../../components/StatusProtector/DeleteDocumentModal";
import { useOrgAuthContext } from "../../../context/OrganizationAuthContext";
import { useRootContext } from "../../../context/rootContext/RootContext";
import { downloadClientDocument } from "../../../util/organization/clients";
import ClientDocumentScreenController from "./controller/ClientDocumentScreenController";
import { OrganizationNavigatorScreenProps } from "../../../navigation/types";

type props = {};

const ClientDocumentsScreen: React.FC<props> = () => {
  const [doc, setDoc] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [getDocsMsg, setGetDocsMsg] = useState("");
  const [visible, setModalVisible] = useState(false);
  const [deleteError, setDeleteError] = useState("");
  const [docDownloadStart, setDocDownloadStart] = useState(false);
  const isFocused = useIsFocused();
  const {
    dimensions: { width: width },
  } = useRootContext();
  const { getAllDocs, deleteDocument } = ClientDocumentScreenController();
  const { orgAuthState } = useOrgAuthContext();

  const { params } = useRoute<OrganizationNavigatorScreenProps<"ClientDocumentsScreen">["route"]>();
  const client_id = useMemo(() => decryptedData(params.id), [params.id]);

  const getDocs = async () => {
    setGetDocsMsg(Strings.LOADING_STARTED);
    await getAllDocs(
      orgAuthState.orgId,
      orgAuthState.orgUserToken,
      client_id
    ).then((res) => {
      if (!res.hasError) {
        setDocuments(res.data);
        setGetDocsMsg("");
      } else {
        setGetDocsMsg(res.data);
      }
    });
  };

  const downloadFile = async (docId: string) => {
    setDocDownloadStart(true);
    const { url, fileName } = await downloadClientDocument(
      orgAuthState.orgId,
      orgAuthState.orgUserToken,
      client_id,
      docId
    );
    const element = document.createElement("a");
    element.href = url;
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    URL.revokeObjectURL(url);
    element.remove();
    setDocDownloadStart(false);
  };

  const deleteDoc = async () => {
    setDeleteError(Strings.LOADING_STARTED);
    await deleteDocument(
      orgAuthState.orgId,
      orgAuthState.orgUserToken,
      client_id,
      doc.id
    ).then((res) => {
      if (!res.hasError) {
        setDoc(null);
        setDeleteError("");
        getDocs();
      } else {
        setDeleteError(res.data);
      }
    });
  };

  useEffect(() => {
    if (isFocused) {
      getDocs();
    }
  }, [isFocused]);

  return (
    <ProgressBar
      loading={getDocsMsg === Strings.LOADING_STARTED || docDownloadStart}
    >
      <BgImageContainer isOrganization={true}>
        {getDocsMsg !== Strings.LOADING_STARTED ? (
          <View
            style={{
              width: width > 950 ? 800 : "98%",
              minWidth: 640,
              alignSelf: "center",
            }}
          >
            <ClientDocumentsHeader onUploadDoc={() => setModalVisible(true)} />
            <DocumentsList
              documents={documents}
              onDocDelete={setDoc}
              onDocDownload={(id) => downloadFile(id)}
            />
            {getDocsMsg !== "" && getDocsMsg !== Strings.LOADING_STARTED && (
              <ErrorText txtStyle={{ marginVertical: 10 }} error={getDocsMsg} />
            )}
            <DeleteDocumentModal
              visible={doc ? true : false}
              onDismiss={() => setDoc(null)}
              isLoading={deleteError === Strings.LOADING_STARTED}
              docName={doc ? doc.file_name : ""}
              onDelete={deleteDoc}
            />
            <UploadClientDocModal
              visible={visible}
              onDismiss={(refresh) => {
                setModalVisible(false);
                refresh && getDocs();
              }}
              style={{ height: 500, width: width > 700 ? 600 : "90%" }}
              client_Id={client_id}
            />
          </View>
        ) : null}
      </BgImageContainer>
    </ProgressBar>
  );
};

export default React.memo(ClientDocumentsScreen);
