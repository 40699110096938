import { StyleSheet } from "react-native";
import { theme } from "../../../theme";

export const customButtonStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  buttonContainer: {
    width: "100%",
  },
  buttonGradient: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
  },
  buttonText: {
    fontFamily: "Brother1816Black",
    fontSize: 15,
    marginVertical: 5,
    color: theme.colors.bgColor,
    textAlign: "center",
    textTransform: "uppercase",
  },
  loadStyle: {
    marginVertical: 6,
  },
});
