import React from "react";
import { Linking, Text, StyleSheet } from "react-native";

type Props = {
  text: string;
  url: string;
};

const TextLink: React.FC<Props> = ({ text, url }) => {
  return (
    <Text style={linkStyles.text} onPress={() => Linking.openURL(url)}>
      {text}
    </Text>
  );
};

const linkStyles = StyleSheet.create({
  text: {
    textDecorationLine: "underline",
  },
});

export default TextLink;
