/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { createContext, useContext } from "react";
import { StoreContextData } from "./data/StoreContextDefaultData";
import {
  ProfileType,
  StoreContextType,
  CountryType,
} from "./type/StoreContextType";

export const StoreContext = createContext<StoreContextType>(StoreContextData);
export const StoreContextProvider: React.FC = ({ children }) => {
  const [profileData, setProfileData] = useState<ProfileType>(
    StoreContextData.profileData
  );
  const [countriesData, setCountriesData] = useState<CountryType[]>(
    StoreContextData.countriesData
  );

  return (
    <StoreContext.Provider
      value={{
        countriesData,
        setCountriesData,
        profileData,
        setProfileData,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
export const useStoreContext = () => useContext(StoreContext);
