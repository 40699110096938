import { StyleSheet } from "react-native";
import {theme} from "../../theme"
const uploadDocumentStyles = StyleSheet.create({
  errorText: {
    fontSize: 16,
    color: theme.colors.red,
  },
  successfulText: {
    fontSize: 16,
    color: theme.colors.green,
  },
  successfulCbpText: {
    maxWidth: 630,
    backgroundColor: "#93D0FF",
    borderRadius: 5,
    padding: 20,
    fontSize: 16,
    color: "#004C86"
  }
});
export default uploadDocumentStyles;
