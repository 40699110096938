export const userInfo = {
  id: {
    position: 0,
    value: 1,
  },
  email: {
    position: 1,
    value: 1,
  },
  token: {
    position: 2,
    value: 1,
  },
  expiresAt: {
    position: 3,
    value: 1,
  },
  refreshToken: {
    position: 4,
    value: 1,
  },
};
