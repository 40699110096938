import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { APP_TITLE, Screens } from "../common/Constant";
import { OrganizationNavigatorScreenProps, OrganizationStackParamList, RootNavigatorScreenProps } from "./types";
import ClientScreen from "../screen/Organization/ClientScreen";
import CreateOrganization from "../screen/Organization/CreateOrganization";
import AdminsScreen from "../screen/Organization/AdminsScreen";
import ProtectedOrgRouteScreen from "./ProtectedOrgRouteScreen";
import EditOrganizationScreen from "../screen/Organization/EditOrganizationScreen";
import EditAdminScreen from "../screen/Organization/EditAdminScreen";
import ProfileEditScreen from "../screen/Organization/ProfileEditScreen";
import EditClientScreen from "../screen/Organization/EditClientScreen";
import ClientDocumentsScreen from "../screen/Organization/ClientDocumentsScreen";
import OrganizationPayment from "../screen/Organization/Payment/OrganizationPayment";
import PaymentSuccess from "../screen/PaymentSuccess";
import PaymentFailure from "../screen/PaymentFailure";
import Onboarding from "../screen/Organization/Onboarding";
import StatusProtectorClient from "../screen/Organization/SPClientScreen";

const OrganizationStackNavigator = () => {
  const Stack = createNativeStackNavigator<OrganizationStackParamList>();

  const protectedOrgRoute = (Component, props) => {
    return (
      <ProtectedOrgRouteScreen {...props}>
        <Component />
      </ProtectedOrgRouteScreen>
    );
  };
  return (
    <Stack.Navigator screenOptions={{ headerShown: false, title: APP_TITLE }}>
      <Stack.Screen name={Screens.ADMIN_SCREEN}>
        {(props) => protectedOrgRoute(AdminsScreen, props)}
      </Stack.Screen>
      <Stack.Screen name={Screens.ORGANIZATION_PAYMENT}>
        {(props) => protectedOrgRoute(OrganizationPayment, props)}
      </Stack.Screen>
      <Stack.Screen name={Screens.CREATE_ORGANIZATION_SCREEN}>
        {(props) => protectedOrgRoute(CreateOrganization, props)}
      </Stack.Screen>
      <Stack.Screen name={Screens.EDIT_ORGANIZATION_SCREEN}>
        {(props) => protectedOrgRoute(EditOrganizationScreen, props)}
      </Stack.Screen>
      <Stack.Screen name={Screens.CLIENT_SCREEN}>
        {(props) => protectedOrgRoute(ClientScreen, props)}
      </Stack.Screen>
      <Stack.Screen name={Screens.EDIT_ADMIN_SCREEN}>
        {(props) => protectedOrgRoute(EditAdminScreen, props)}
      </Stack.Screen>
      <Stack.Screen name={Screens.ORG_PROFILE_EDIT_SCREEN}>
        {(props) => protectedOrgRoute(ProfileEditScreen, props)}
      </Stack.Screen>
      <Stack.Screen name={Screens.EDIT_ORG_CLIENT_SCREEN}>
        {(props) => protectedOrgRoute(EditClientScreen, props)}
      </Stack.Screen>
      <Stack.Screen name={Screens.CLIENT_DOCUMENTS_SCREEN}>
        {(props) => protectedOrgRoute(ClientDocumentsScreen, props)}
      </Stack.Screen>
      <Stack.Screen name={Screens.ORGANIZATION_PAYMENT_SUCCESS}>
        {(props) => protectedOrgRoute(PaymentSuccess, props)}
      </Stack.Screen>
      <Stack.Screen name={Screens.ORGANIZATION_PAYMENT_FAILURE}>
        {(props) => protectedOrgRoute(PaymentFailure, props)}
      </Stack.Screen>
      <Stack.Screen name={Screens.ONBOARDING_USERS_SCREEN}>
        {(props) => protectedOrgRoute(Onboarding, props)}
      </Stack.Screen>
      <Stack.Screen name={Screens.STATUS_PROTECTOR_CLIENT}>
        {(props) => protectedOrgRoute(StatusProtectorClient, props)}
      </Stack.Screen>
    </Stack.Navigator>
  );
};

export default OrganizationStackNavigator;
