import { getStorageValue } from "../common/AsyncStorage";
import { AsyncStorageKeys } from "../common/Constant";
import { Apis } from "./axiosInstances/Apis";
import { autoVisasPrivateApi } from "./axiosInstances/autovisasPrivateApi";
import { getError, getSuccess } from "./axiosInstances/handleResponse"

export const getBillingPortalURL = async (user_id: string) => {
    try {
        const userTokenInStorage = await getStorageValue(
            AsyncStorageKeys.USER_SESSION_TOKEN
          );
        const options = {
            method: "POST",
            url: `${Apis.BILLING}/${user_id}${Apis.PORTAL}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userTokenInStorage}`,
            },
        };
        const response = await autoVisasPrivateApi.request(options);
        return getSuccess(response)
    } catch (error) {
        console.log(error);   
        return getError(error)
    }
}