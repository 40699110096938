import { AsyncStorageKeys } from "../common/Constant";
import { getStorageValue } from "./../common/AsyncStorage";
import { autoVisasPrivateApi } from "./axiosInstances/autovisasPrivateApi";

export const uploadDocument = async (documentData) => {
  try {
    const data = new FormData();
    data.append("file", documentData, documentData.name);
    const userTokenInStorage = await getStorageValue(
      AsyncStorageKeys.USER_SESSION_TOKEN
    );
    const options = {
      method: "POST",
      url: "/documents/parse",
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=---011000010111000001101001",
        Authorization: `Bearer ${userTokenInStorage}`,
      },
      data: data,
    };
    const uploadDocumentResponse = await autoVisasPrivateApi.request(options);
    return {
      userInfoData: uploadDocumentResponse.data[0],
      status: uploadDocumentResponse.status,
    };
  } catch (error) {
    return error;
  }
};
