import { multiGet } from "../common/AsyncStorage";
import { userInfo } from "../data/userInfo";
import { Apis } from "./axiosInstances/Apis";
import { autoVisasPrivateApi } from "./axiosInstances/autovisasPrivateApi";
import { getError, getSuccess } from "./axiosInstances/handleResponse";

export const getTravelHistory = async () => {
    try {
      const userSession = await multiGet(["userId"]);
      const options = {
        method: "GET",
        url: `${Apis.TRAVEL}/${userSession[userInfo.id.position][userInfo.id.value]}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const getUserInfoResponse = await autoVisasPrivateApi.request(options);
      return getSuccess(getUserInfoResponse);
    } catch (error) {
      console.error(error);
      return getError(error)
    }
  };