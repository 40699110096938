import React from "react";
import { ScrollView } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../../assets/strings";
import { DependentData } from "../../../../screen/StatusProtector/types/Dependentypes";
import UploadDocumentsContent from "../../../UploadDocumentsContent";
import { uploadDocContentStyles } from "./UploadDocContent.Styles";

type props = {
  setErrorMessage: () => void;
  onContinue: (data?: DependentData) => void;
  clientId: string;
};

const UploadDocContent: React.FC<props> = ({
  setErrorMessage,
  onContinue,
  clientId,
}) => {
  return (
    <>
      <Text style={uploadDocContentStyles.heading}>
        {Strings.UPLOAD_YOUR_DOCUMENTS}
      </Text>
      <ScrollView style={{ flex: 1 }}>
        <Text style={uploadDocContentStyles.desc}>
          {Strings.UPLOAD_YOUR_DOCUMENTS_DESCRIPTION}
        </Text>
        <UploadDocumentsContent
          setErrorMessage={setErrorMessage}
          onContinue={onContinue}
          clientId={clientId}
        />
      </ScrollView>
    </>
  );
};

export default React.memo(UploadDocContent);
