import { Formik } from "formik";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { View } from "react-native";
import { HelperText, Text } from "react-native-paper";
import { Strings } from "../../../../assets/strings";
import validationSchema from "../../../data/validationSchema";
import SubmitButton from "../../Buttons/Submit";
import FormInput from "../../FormInput";
import { addDependentModalStyles } from "./AddDependentModal.Styles";
import Header from "../Header";
import { createDependentById } from "../../../util/dependent/createDependentId";
import { DependentModalData } from "../../../screen/StatusProtector/types/Dependentypes";
import { AuthContext } from "../../../context/AuthContext";
import { dependentInfoContentStyles } from "../DependentInfoContent/DependentInfoContent.Styles";
import DropDown from "../../dropDown/DropDown";
import {theme} from "../../../theme"

type props = {
  onDismiss: () => void;
  onContinue: (data: DependentModalData) => void;
};

const AddDependentModal: React.FC<props> = ({ onDismiss, onContinue }) => {
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const { loginState } = useContext(AuthContext);
  const relationshipData = useMemo(
    () => [
      { label: "Child", value: "Child" },
      { label: "Spouse", value: "Spouse" },
      { label: "Other", value: "Other" },
    ],
    []
  );

  const addDependent = useCallback(
    async (email: string, relationship: string) => {
      setMessage("");
      setLoading(true);
      const data = await createDependentById(loginState, {
        email,
        relationship,
      });

      if (data.status === 200) {
        onContinue({ pageNo: 1, dId: data?.data?.uid, email });
      } else {
        setMessage(data?.data?.message);
      }
      setLoading(false);
    },
    []
  );
  return (
    <>
      <Header title={Strings.ADD_DEPENDENT} onDismiss={onDismiss} />
      <Text style={addDependentModalStyles.contentStyle}>
        {Strings.ADD_DEPENDENT_DESCRIPTION}
      </Text>
      <View style={addDependentModalStyles.container}>
        <Formik
          initialValues={{
            email: "",
            relationShip: "",
          }}
          validationSchema={validationSchema.addDependent}
          onSubmit={(values) => {
            addDependent(values.email, values.relationShip);
          }}
        >
          {({ values, errors, touched, handleSubmit, handleChange }) => (
            <View
              style={[addDependentModalStyles.formContainer, { zIndex: 2 }]}
            >
              <HelperText
                style={{ color: theme.colors.red, fontWeight: "700" }}
                type="error"
                visible={!!message}
              >
                {message}
              </HelperText>
              <View style={addDependentModalStyles.inputContainer}>
                <FormInput
                  label={Strings.EMAIL}
                  inputPlaceholder=""
                  inputValue={values.email}
                  setInputValue={handleChange("email")}
                  mode="outlined"
                  error={touched.email && errors.email}
                  style={{ backgroundColor: "#F0FFFF", width: "100%" }}
                  labelStyle={{ fontSize: 15, fontWeight: "normal" }}
                  activeOutlineColor="#9797FF"
                  outlineColor={theme.colors.btnshadow}
                  dense
                />
              </View>
              <View style={{ flex: 1, marginTop: 20 }}>
                <Text
                  style={[
                    {
                      ...theme.fonts.black,
                      marginBottom: 1,
                      fontSize: 16,
                      color: theme.colors.btn,
                      textTransform: "uppercase",
                    },
                  ]}
                >
                  {Strings.ConfirmInfo.RELATIONSHIP}
                </Text>

                <View
                  style={[
                    dependentInfoContentStyles.dropdownStyle,
                    {
                      borderColor: theme.colors.inputActiveOutlineColor,
                      zIndex: 5,
                    },
                  ]}
                >
                  <DropDown
                    receiptTypeOptions={relationshipData}
                    onSelection={handleChange("relationShip")}
                    selectedOption={values.relationShip}
                  />
                </View>
                <HelperText
                  style={{ color: theme.colors.red, fontWeight: "700" }}
                  type="error"
                  visible={!!(touched.relationShip && errors.relationShip)}
                >
                  {errors.relationShip}
                </HelperText>
              </View>
              <View style={addDependentModalStyles.btnContainer}>
                <SubmitButton
                  isLoading={isLoading}
                  onPress={handleSubmit}
                  name={Strings.CONTINUE}
                />
              </View>
            </View>
          )}
        </Formik>
      </View>
    </>
  );
};

export default React.memo(AddDependentModal);
