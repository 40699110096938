import React, { useState } from "react";
import { Strings } from "../../../../assets/strings";
import { DependentModalData } from "../../../screen/StatusProtector/types/Dependentypes";
import DependentInfoContent from "../DependentInfoContent";
import Header from "../Header";

type props = {
  onDismiss: () => void;
  onCreate: () => void;
  modalData: DependentModalData;
};

const ConfirmInfoModal: React.FC<props> = ({
  onDismiss,
  onCreate,
  modalData,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <Header title={Strings.CONFIRM_INFO} onDismiss={onDismiss} />
      <DependentInfoContent
        onCreate={onCreate}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        modalData={modalData}
        editable={true}
      />
    </>
  );
};

export default React.memo(ConfirmInfoModal);
