import { autoVisasOrgPrivateApi } from "./../axiosInstances/autoVisasOrgPrivateApi";
import { getError, getSuccess } from "../axiosInstances/handleResponse";
import { OrgApis } from "../axiosInstances/Apis";
export const getOrganizationMeta = async (
  organizationId: string,
  organizationToken: string,
  controller: AbortController
) => {
  try {
    const options = {
      method: "GET",
      url: `${OrgApis.ORGANIZATION}/${organizationId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${organizationToken}`,
      },
      signal: controller.signal,
    };
    const apiResponse = await autoVisasOrgPrivateApi.request(options);
    const { data, status } = getSuccess(apiResponse);
    return {
      status: status,
      data: data,
    };
  } catch (error) {
    return getError(error);
  }
};
