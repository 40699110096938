import { Formik } from "formik";
import React, { useState } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../assets/strings";
import validationSchema from "../../../data/validationSchema";
import { theme } from "../../../theme";
import BgImageContainer from "../../BgImageContainer";
import FormInput from "../../FormInput";
import CreateButton from "../../../components/Buttons/Create";
import { useRootContext } from "../../../context/rootContext/RootContext";
import { organizationDetailsStyles } from "./OrganizationDetails.Styles";
import BorderButton from "../../Buttons/BorderButton";
import { OrganizationData } from "../../../context/orgStoreContext/type/StoreContextType";
import CreateOrganizationController from "../../../screen/Organization/CreateOrganization/controller/CreateOrganizationController";
import ErrorText from "../../ErrorText";
import { ProgressBar } from "../../progressBar/ProgressBar";
import { formatDate } from "../../../common/DateFormat";

type props = {
  isEdit: boolean;
  organizationData?: OrganizationData;
  redirect?: (data: OrganizationData) => void;
};

const OrganizationDetails: React.FC<props> = ({
  isEdit,
  organizationData,
  redirect,
}) => {
  const [isEditEnabled, setIsEditEnabled] = useState(!isEdit);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const {
    dimensions: { width },
  } = useRootContext();
  const { createOrganization } = CreateOrganizationController();
  const onSubmit = (
    name: string,
    email: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    state: string,
    zipCode: string,
    phone: string
  ) => {
    setLoading(true);
    createOrganization({
      name: name,
      address: {
        line_1: addressLine1,
        line_2: addressLine2 ? addressLine2 : "",
        city: city,
        state: state,
        zip: zipCode,
      },
      contact: {
        phone: phone,
        email: email,
      },
    }).then((res) => {
      setLoading(false);
      if (!res.hasError) {
        setHasError(false);
        !isEdit ? redirect(res.data) : setIsEditEnabled(false);
      } else {
        setHasError(true);
        setErrorMessage(res.data);
      }
    });
  };
  return (
    <ProgressBar loading={isLoading}>
      <BgImageContainer isOrganization={true}>
        <View
          style={[
            organizationDetailsStyles.body,
            { width: width > 560 ? 550 : "98%" },
          ]}
        >
          <View style={[organizationDetailsStyles.formContainer]}>
            <Formik
              initialValues={{
                name: organizationData ? organizationData?.name : "",
                email: organizationData ? organizationData?.contact?.email : "",
                addressLine1: organizationData
                  ? organizationData?.address?.line_1
                  : "",
                addressLine2: organizationData
                  ? organizationData?.address?.line_2
                  : "",
                city: organizationData ? organizationData?.address?.city : "",
                state: organizationData ? organizationData?.address?.state : "",
                zipCode: organizationData ? organizationData?.address?.zip : "",
                phone: organizationData ? organizationData?.contact?.phone : "",
              }}
              validationSchema={validationSchema.organizationCreation}
              onSubmit={(values) => {
                onSubmit(
                  values.name,
                  values.email,
                  values.addressLine1,
                  values.addressLine2,
                  values.city,
                  values.state,
                  values.zipCode,
                  values.phone
                );
              }}
            >
              {({ values, errors, touched, handleSubmit, handleChange }) => (
                <>
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      marginBottom: 20,
                    }}
                  >
                    <Text style={organizationDetailsStyles.title}>
                      {isEdit
                        ? Strings.ORGANIZATION
                        : Strings.CREATE_ORGANIZATION}
                    </Text>
                    {isEdit && (
                      <BorderButton
                        title={
                          isEditEnabled
                            ? Strings.Profile.SAVE
                            : Strings.Profile.EDIT
                        }
                        //TODO: Add logic for updating org details and handling validation
                        onPress={() =>
                          !isEditEnabled
                            ? setIsEditEnabled(!isEditEnabled)
                            : handleSubmit()
                        }
                        style={[
                          organizationDetailsStyles.borderBtn,
                          width < 500 && { paddingHorizontal: "10%" },
                          isEditEnabled && {
                            backgroundColor: theme.colors.bottom2,
                          },
                        ]}
                        txtStyle={isEditEnabled && { color: "white" }}
                      />
                    )}
                  </View>
                  <View
                    style={[
                      organizationDetailsStyles.inputsContainer,
                      { flexDirection: width > 480 ? "row" : "column" },
                    ]}
                  >
                    <View style={organizationDetailsStyles.inputContainer}>
                      <FormInput
                        label={Strings.NAME_LABEL}
                        inputPlaceholder={Strings.NAME}
                        inputValue={values.name}
                        setInputValue={handleChange("name")}
                        mode="outlined"
                        inputWidth="100%"
                        editable={isEditEnabled}
                        error={touched.name && errors.name}
                        labelStyle={organizationDetailsStyles.label}
                        style={organizationDetailsStyles.input}
                        activeOutlineColor={
                          theme.colors.inputActiveOutlineColor
                        }
                        outlineColor={theme.colors.inputOutlineColor}
                        dense
                      />
                      {(touched.name && errors.name) === undefined && (
                        <View
                          style={organizationDetailsStyles.errorContainer}
                        ></View>
                      )}
                    </View>
                    <View style={organizationDetailsStyles.inputContainer}>
                      <FormInput
                        label={Strings.EMAIL_LABEL}
                        inputPlaceholder={Strings.EMAIL}
                        inputValue={values.email}
                        setInputValue={handleChange("email")}
                        mode="outlined"
                        inputWidth="100%"
                        editable={isEditEnabled}
                        error={touched.email && errors.email}
                        labelStyle={organizationDetailsStyles.label}
                        style={organizationDetailsStyles.input}
                        activeOutlineColor={
                          theme.colors.inputActiveOutlineColor
                        }
                        outlineColor={theme.colors.inputOutlineColor}
                        dense
                      />
                      {(touched.email && errors.email) === undefined && (
                        <View
                          style={organizationDetailsStyles.errorContainer}
                        ></View>
                      )}
                    </View>
                  </View>
                  <View
                    style={organizationDetailsStyles.inputContainerFullLength}
                  >
                    <FormInput
                      label={Strings.ADDRESS_LINE_1_LABEL}
                      inputPlaceholder={Strings.ADDRESS_LINE_1}
                      inputValue={values.addressLine1}
                      setInputValue={handleChange("addressLine1")}
                      mode="outlined"
                      inputWidth="100%"
                      editable={isEditEnabled}
                      error={touched.addressLine1 && errors.addressLine1}
                      labelStyle={organizationDetailsStyles.label}
                      style={organizationDetailsStyles.input}
                      activeOutlineColor={theme.colors.inputActiveOutlineColor}
                      outlineColor={theme.colors.inputOutlineColor}
                      dense
                    />
                    {(touched.addressLine1 && errors.addressLine1) ===
                      undefined && (
                      <View
                        style={organizationDetailsStyles.errorContainer}
                      ></View>
                    )}
                  </View>
                  <View
                    style={organizationDetailsStyles.inputContainerFullLength}
                  >
                    <FormInput
                      label={Strings.ADDRESS_LINE_2_LABEL}
                      inputPlaceholder={Strings.ADDRESS_LINE_2}
                      inputValue={values.addressLine2}
                      setInputValue={handleChange("addressLine2")}
                      mode="outlined"
                      inputWidth="100%"
                      editable={isEditEnabled}
                      error={touched.addressLine2 && errors.addressLine2}
                      labelStyle={organizationDetailsStyles.label}
                      style={organizationDetailsStyles.input}
                      activeOutlineColor={theme.colors.inputActiveOutlineColor}
                      outlineColor={theme.colors.inputOutlineColor}
                      dense
                    />
                    {(touched.addressLine2 && errors.addressLine2) ===
                      undefined && (
                      <View
                        style={organizationDetailsStyles.errorContainer}
                      ></View>
                    )}
                  </View>
                  <View style={organizationDetailsStyles.inputsContainer}>
                    <View style={organizationDetailsStyles.inputContainer}>
                      <FormInput
                        label={Strings.CITY_LABEL}
                        inputPlaceholder={Strings.CITY}
                        inputValue={values.city}
                        setInputValue={handleChange("city")}
                        mode="outlined"
                        inputWidth="100%"
                        editable={isEditEnabled}
                        error={touched.city && errors.city}
                        labelStyle={organizationDetailsStyles.label}
                        style={organizationDetailsStyles.input}
                        activeOutlineColor={
                          theme.colors.inputActiveOutlineColor
                        }
                        outlineColor={theme.colors.inputOutlineColor}
                        dense
                      />
                      {(touched.city && errors.city) === undefined && (
                        <View
                          style={organizationDetailsStyles.errorContainer}
                        ></View>
                      )}
                    </View>
                    <View style={organizationDetailsStyles.inputContainer}>
                      <FormInput
                        label={Strings.STATE_LABEL}
                        inputPlaceholder={Strings.STATE}
                        inputValue={values.state}
                        setInputValue={handleChange("state")}
                        mode="outlined"
                        inputWidth="100%"
                        editable={isEditEnabled}
                        error={touched.state && errors.state}
                        labelStyle={organizationDetailsStyles.label}
                        style={organizationDetailsStyles.input}
                        activeOutlineColor={
                          theme.colors.inputActiveOutlineColor
                        }
                        outlineColor={theme.colors.inputOutlineColor}
                        dense
                      />
                      {(touched.state && errors.state) === undefined && (
                        <View
                          style={organizationDetailsStyles.errorContainer}
                        ></View>
                      )}
                    </View>
                  </View>
                  <View style={organizationDetailsStyles.inputsContainer}>
                    <View style={organizationDetailsStyles.inputContainer}>
                      <FormInput
                        label={Strings.ZIP_CODE_LABEL}
                        inputPlaceholder={Strings.ZIP_CODE}
                        inputValue={values.zipCode}
                        setInputValue={handleChange("zipCode")}
                        mode="outlined"
                        inputWidth="100%"
                        editable={isEditEnabled}
                        error={touched.zipCode && errors.zipCode}
                        labelStyle={organizationDetailsStyles.label}
                        style={organizationDetailsStyles.input}
                        activeOutlineColor={
                          theme.colors.inputActiveOutlineColor
                        }
                        outlineColor={theme.colors.inputOutlineColor}
                        dense
                      />
                      {(touched.zipCode && errors.zipCode) === undefined && (
                        <View
                          style={organizationDetailsStyles.errorContainer}
                        ></View>
                      )}
                    </View>
                    <View style={organizationDetailsStyles.inputContainer}>
                      <FormInput
                        label={Strings.PHONE_LABEL}
                        inputPlaceholder={Strings.PHONE}
                        inputValue={values.phone}
                        setInputValue={handleChange("phone")}
                        mode="outlined"
                        inputWidth="100%"
                        editable={isEditEnabled}
                        error={touched.phone && errors.phone}
                        labelStyle={organizationDetailsStyles.label}
                        style={organizationDetailsStyles.input}
                        activeOutlineColor={
                          theme.colors.inputActiveOutlineColor
                        }
                        outlineColor={theme.colors.inputOutlineColor}
                        dense
                      />
                      {(touched.phone && errors.phone) === undefined && (
                        <View
                          style={organizationDetailsStyles.errorContainer}
                        ></View>
                      )}
                    </View>
                  </View>
                  <View
                    style={[
                      organizationDetailsStyles.btnContainer,
                      isEdit && { marginVertical: 5 },
                    ]}
                  >
                    {!isEdit ? (
                      <CreateButton
                        name={Strings.GO_TO_PAY}
                        onPress={handleSubmit}
                        style={organizationDetailsStyles.btnStyle}
                      />
                    ) : (
                      <View style={{ alignSelf: "center" }}>
                        <Text style={organizationDetailsStyles.subscription}>
                          {`${Strings.SUBSCRIPTION_VALID_TILL} ${
                            organizationData.subscription_active
                              ? formatDate(
                                  organizationData.subscription_vaild_till,
                                  Strings.DATE_FORMAT
                                )
                              : Strings.N_A
                          }`}
                        </Text>
                      </View>
                    )}
                    {hasError ? <ErrorText error={errorMessage} /> : null}
                  </View>
                </>
              )}
            </Formik>
          </View>
        </View>
      </BgImageContainer>
    </ProgressBar>
  );
};

export default React.memo(OrganizationDetails);
