import { createContext, useContext, useMemo, useReducer } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AsyncStorageKeys } from "../../common/Constant";
import { multiSet } from "../../common/AsyncStorage";
import { addMinutes } from "../../util/addMinutes";
import Cookies from "js-cookie";
import { UserType } from "../AuthContext";

export type AuthContextType = {
  orgSignIn?: (email: string, user: any, userType: UserType) => Promise<void>;
  orgSignOut?: () => Promise<void>;
  orgSignInCompleted?: (email: string, session: string, userType: UserType) => Promise<void>;
  orgAuthState?: initialOrganizationType;
  orgDispatch?: React.Dispatch<OrganizationAction>;
};
const defaultValues: AuthContextType = {
  orgSignOut: async () => {},
  orgSignIn: async (email: string, user: any) => {},
  orgSignInCompleted: async () => {},
};
const OrgAuthContext = createContext<AuthContextType>(defaultValues);
export enum OrganizationActionType {
  OrgRestoreData = "ORG_RESTORE_DATA",
  OrgSignIn = "ORG_SIGN_IN",
  OrgSignOut = "ORG_SIGN_OUT",
  OrgSignInCompleted = "ORG_SIGN_IN_COMPLETED",
}
export interface initialOrganizationType {
  orgUserEmail?: string;
  orgUserToken?: string;
  orgUserId?: string;
  orgId?: string;
  isOrgSignInCompleted?: boolean;
  userType?: UserType;
}
export interface OrganizationAction {
  type: OrganizationActionType;
  payload?: initialOrganizationType;
}

const OrgAuthProvider = (props) => {
  const initialOrganizationState: initialOrganizationType = {
    orgUserEmail: "",
    orgUserToken: null,
    orgUserId: "",
    orgId: "",
    isOrgSignInCompleted: false,
    userType: "",
  };

  const OrgReducer = (
    prevState: initialOrganizationType,
    action: OrganizationAction
  ) => {
    const { type, payload } = action;
    switch (type) {
      case OrganizationActionType.OrgRestoreData:
        return {
          orgUserEmail: payload?.orgUserEmail,
          orgUserToken: payload?.orgUserToken,
          orgId: payload?.orgId,
          orgUserId: payload?.orgUserId,
          isOrgSignInCompleted: payload?.isOrgSignInCompleted,
          userType: payload?.userType,
        };

      case OrganizationActionType.OrgSignIn:
        return {
          ...prevState,
          orgUserEmail: payload.orgUserEmail,
          orgUserToken: payload.orgUserToken,
          orgUserId: payload?.orgUserId,
          orgId: payload?.orgId,
        };
      case OrganizationActionType.OrgSignOut:
          return {
            ...prevState,
            orgUserEmail: null,
            orgUserToken: null,
            orgId: null,
            orgUserId: null,
            orgRole: null,
            userType: "",
          }
      case OrganizationActionType.OrgSignInCompleted:
        return {
          ...prevState,
          orgUserEmail: payload.orgUserEmail,
          userType: payload.userType,
          isOrgSignInCompleted: payload.isOrgSignInCompleted,
        };
      default:
        return initialOrganizationState;
    }
  };

  const [orgState, dispatch] = useReducer(OrgReducer, initialOrganizationState);

  const orgAuthContext = useMemo(
    () => ({
      orgSignIn: async (orgUserEmail, user, userType: UserType) => {
        try {
          const orgSession = [
            [AsyncStorageKeys.ORG_USER_ID, user.user_id],
            [AsyncStorageKeys.ORG_SESSION_TOKEN, user.access_token.token],
            [AsyncStorageKeys.ORG_EXPIRES_AT, user.access_token.expires_at],
            [
              AsyncStorageKeys.ORG_SESSION_REFRESH_TOKEN,
              user.refresh_token.token,
            ],
            [AsyncStorageKeys.ORG_ID, user.organization_id],
            [AsyncStorageKeys.ORG_ROLE, user.organization_role],
          ];
          await AsyncStorage.multiSet(orgSession as [string, string][]);
        } catch (e) {
          console.log(e);
        }
        dispatch({
          type: OrganizationActionType.OrgSignIn,
          payload: {
            orgUserId: user.user_id,
            orgId: user.organization_id,
            orgUserEmail,
            orgUserToken: user.access_token.token,
            userType
          },
        });
      },
      signOut: async () => {
        try {
          const keys = [
            AsyncStorageKeys.ORG_USER_EMAIL,
            AsyncStorageKeys.ORG_USER_ID,
            AsyncStorageKeys.ORG_SESSION_TOKEN,
            AsyncStorageKeys.ORG_SESSION_REFRESH_TOKEN,
            AsyncStorageKeys.ORG_EXPIRES_AT,
            AsyncStorageKeys.ORG_ID,
            AsyncStorageKeys.ORG_ROLE,
            AsyncStorageKeys.USER_TYPE,
          ];
          await AsyncStorage.multiRemove(keys);
        } catch (e) {
          console.log(e);
        }

        dispatch({ type: OrganizationActionType.OrgSignOut });
      },
      orgSignInCompleted: async (email: string, session: string, userType: UserType) => {
        try {
          await multiSet([
            [AsyncStorageKeys.ORG_USER_EMAIL, email],
            [AsyncStorageKeys.ORG_SIGN_IN_COMPLETED, "true"],
            [AsyncStorageKeys.USER_TYPE, userType],
          ]);
          const sessionDateExpired = addMinutes(5);
          Cookies.set(AsyncStorageKeys.ORG_SESSION, session, {
            expires: sessionDateExpired,
            sameSite: "none",
            secure: true,
          });
          dispatch({
            type: OrganizationActionType.OrgSignInCompleted,
            payload: { isOrgSignInCompleted: true, orgUserEmail: email, userType: userType },
          });
        } catch (error) {
          console.error(error);
        }
      },
    }),
    []
  );

  return (
    <OrgAuthContext.Provider
      value={{
        orgSignIn: orgAuthContext.orgSignIn,
        orgSignOut: orgAuthContext.signOut,
        orgSignInCompleted: orgAuthContext.orgSignInCompleted,
        orgAuthState: orgState,
        orgDispatch: dispatch,
      }}
    >
      {props.children}
    </OrgAuthContext.Provider>
  );
};
const useOrgAuthContext = () => useContext(OrgAuthContext);

export { useOrgAuthContext, OrgAuthProvider };
