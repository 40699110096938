import { Strings } from "../../../../../assets/strings";
import { OrgApis } from "../../../../util/axiosInstances/Apis";
import { autoVisasOrgPrivateApi } from "../../../../util/axiosInstances/autoVisasOrgPrivateApi";
import { getCBP } from "../../../../util/getCBP";
import {
  checkEmailAvailabilityForClient,
  deleteClient,
  getClientsList,
  updateClient,
} from "../../../../util/organization/clients";
import { Client, ClientUpdateRequestBody } from "../../types";

const ClientScreenController = () => {
  const controller = new AbortController();
  const handleCBP = async (client_id: string, org_id: string) => {
    const api = `${OrgApis.ORGANIZATION}/${org_id}/clients/${client_id}/status-protector/run`;
    const cbpResponse = await getCBP(
      client_id,
      controller,
      autoVisasOrgPrivateApi,
      api
    );
    if (cbpResponse.status === 200) {
      return { hasError: false, message: "" };
    } else {
      return { hasError: true, message: Strings.CBP_SERVER_ERROR };
    }
  };
  const cancelCBPRequest = () => {
    controller.abort();
  };
  const getAllClientsOfOrganization = async (
    organizationId: string,
    token: string
  ) => {
    try {
      const response = await getClientsList(organizationId, token);
      if (response.status === 200) {
        return {
          hasError: false,
          data: response.data,
        };
      } else {
        return {
          hasError: true,
          data: response.data.message,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        hasError: true,
        data: Strings.SOMETHING_WENT_WRONG,
      };
    }
  };

  const checkEmailAvailability = async (
    orgId: string,
    token: string,
    email: string
  ) => {
    try {
      const response = await checkEmailAvailabilityForClient(
        orgId,
        token,
        email
      );
      if (response.status === 200) {
        return {
          hasError: false,
          data: response.data,
        };
      } else {
        return {
          hasError: true,
          data: response.data.message,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        hasError: true,
        data: Strings.SOMETHING_WENT_WRONG,
      };
    }
  };

  const updateClientDetails = async (
    organizationId: string,
    token: string,
    clientId: string,
    body: ClientUpdateRequestBody
  ) => {
    try {
      const response = await updateClient(
        organizationId,
        token,
        clientId,
        body
      );
      if (response.status === 200) {
        return {
          hasError: false,
          data: response.data,
        };
      } else {
        return {
          hasError: true,
          data: response.data.message,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        hasError: true,
        data: Strings.SOMETHING_WENT_WRONG,
      };
    }
  };

  const removeClient = async (
    organizationId: string,
    token: string,
    clientId: string
  ) => {
    try {
      const response = await deleteClient(organizationId, token, clientId);
      if (response.status === 200) {
        return {
          hasError: false,
          data: response.data,
        };
      } else {
        return {
          hasError: true,
          data: response.data.message,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        hasError: true,
        data: Strings.SOMETHING_WENT_WRONG,
      };
    }
  };

  const hide0Values = (rawVal) => {
    if (!rawVal || rawVal.toString()=== "" || rawVal.toString() === Strings.ZERO_DATE || rawVal.toString() === Strings.ZERO_UTC_DATE) {
      return Strings.N_A
    }
    return rawVal
  }

  const hideDaysToTargetCapOutDate = (targetCapOut, value) => {
    if (!targetCapOut || targetCapOut.toString() === Strings.ZERO_DATE || targetCapOut.toString() === Strings.ZERO_UTC_DATE) {
      return Strings.N_A
    }
    return value
  }

  const hideInvalidVisa = (visaType, value) => {
    if (Strings.VISATYPE.includes(visaType)) {
      return value
    }
    return Strings.N_A

  }

  const exportToCsv = (clientList) => {
    let headers = ["first_name,last_name,employer_name,email,status,tags,expiration_date,cap_out_days_remaining,total_cap_out_days_in_country,has_i140,visa_type,cap_out_date,post_sixth_year_extension_date,target_cap_out_date,days_to_target_cap_out_date"];
    let usersCsv = clientList.reduce((acc, client) => {
      acc.push(
        [client.first_name, client.last_name, client.employer_name,
        client.email, client.non_immigrant_status?.status,
        client.non_immigrant_status?.tags?.join("|"),
        client.non_immigrant_status?.expiration_date,
        hideInvalidVisa(client.non_immigrant_status?.visa_type, client.non_immigrant_status?.cap_out_days_remaining),
        hideInvalidVisa(client.non_immigrant_status?.visa_type, client.non_immigrant_status?.total_cap_out_days_in_country),
        client.non_immigrant_status?.has_i140, client.non_immigrant_status?.visa_type,
        hideInvalidVisa(client.non_immigrant_status?.visa_type, client.non_immigrant_status?.cap_out_date),
        hide0Values(client.non_immigrant_status?.post_sixth_year_extension_date),
        hide0Values(client.non_immigrant_status?.target_cap_out_date),
        hideDaysToTargetCapOutDate(client.non_immigrant_status?.target_cap_out_date, client.non_immigrant_status?.days_to_target_cap_out_date)].join(",")
      );
      return acc;
    }, []);
    return [...headers, ...usersCsv].join("\n");
  };

  return {
    getAllClientsOfOrganization,
    checkEmailAvailability,
    updateClientDetails,
    removeClient,
    handleCBP,
    cancelCBPRequest,
    exportToCsv,
  };
};

export default ClientScreenController;
