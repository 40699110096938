import { autoVisasOrgPrivateApi } from "./../axiosInstances/autoVisasOrgPrivateApi";

export const uploadClientDoc = async (
  clientId: string,
  orgId: string,
  token: string,
  documentData
) => {
  try {
    const data = new FormData();
    data.append("file", documentData, documentData.name);
    const options = {
      method: "POST",
      url: `organization/${orgId}/clients/${clientId}/documents/parse`,
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=---011000010111000001101001",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    const uploadDocumentResponse = await autoVisasOrgPrivateApi.request(
      options
    );
    return {
      userInfoData: uploadDocumentResponse.data[0],
      status: uploadDocumentResponse.status,
    };
  } catch (error) {
    console.log(error);

    return error;
  }
};
