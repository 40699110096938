import React from "react";
import { ViewStyle } from "react-native";
import { Text } from "react-native-paper";
import { errorTextStyles } from "./ErrorText.Styles";

type props = {
  error: string;
  txtStyle?: {};
};

const ErrorText: React.FC<props> = ({ error, txtStyle }) => {
  return <Text style={[errorTextStyles.errorText, txtStyle]}>{error}</Text>;
};

export default React.memo(ErrorText);
