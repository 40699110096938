import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { View, Text, Animated } from "react-native";
import moment from "moment";
import { updateUserInfo } from "../../util/updateUserInfo";
import { getUserSessionFromStorage } from "../../util/getUserSessionFromStorage";
import ContinueButton from "../../components/Buttons/Continue";
import validationSchema from "../../data/validationSchema";
import { AsyncStorageKeys, Screens } from "../../common/Constant";
import { Strings } from "../../../assets/strings";
import { removeStorageKey } from "../../common/AsyncStorage";
import Checkbox from "../../components/Checkbox";
import confirmInfoStyles from "./ConfirmInfo.Styles";
import { useStoreContext } from "../../context/storeContext/StoreProvider";
import { reset } from "../../navigation/RouteNavigation";
import SignUpHOC from "../../hoc/SignUpHOC";
import { DatePickerInput } from "../../components/datePicker/DatePicker";
import ConfirmInfoInput from "../../components/ConfirmInfo/ConfirmInfoInput";
import DropDown from "../../components/dropDown/DropDown";
import { theme } from "../../theme";
import ConfirmInfoController from "./controller/ConfirmStatusController";
import ProgressBarTimer from "../../components/progessBarTimerButton/ProgressBarTimer";
import { getCountriesList } from "../../util/countries";
const {
  GIVEN_NAME,
  FAMILY_NAME,
  DATE_OF_BIRTH,
  PASSPORT_ID,
  CONFIRM_YOUR_INFO_CHECKBOX,
  CONFIRM_CHECK_STATUS,
} = Strings.ConfirmInfo;
const ConfirmInfoForm: React.FC = () => {
  const { profileData, setProfileData, setCountriesData, countriesData } =
    useStoreContext();
  const formattedDate = profileData?.date_of_birth
    ? moment(
        moment(profileData?.date_of_birth).utc().format("YYYY-MM-DD")
      ).toDate()
    : new Date();
  const [isChecked, setChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [startDate, setStartDate] = useState(formattedDate);
  const [showProgressBar, setShowProgressBar] = useState(false);

  useEffect(() => {
    getCountriesList().then((res) => {
      const list = res.data.map((item) => ({
        label: item.name,
        value: item.alpha3Code,
      }));
      setCountriesData(list);
    });
  }, []);
  const { handleCBP, cancelCBPRequest } = ConfirmInfoController();
  let progressBarValue = useRef(new Animated.Value(0)).current;
  let savedCallback = useRef(null);
  useEffect(() => {
    return () => {
      clearTimeout(savedCallback.current);
    };
  }, []);
  const resetAnimation = () => {
    savedCallback.current = setTimeout(() => {
      reset(Screens.DRAWER, null);
      removeStorageKey(AsyncStorageKeys.INVITE_EMAIL);
      setShowProgressBar(false);
    }, 500);
  };
  const signUpHandle = (values) => {
    setErrorMessage("");
    updateUserInfo(
      values.first_name,
      values.family_name,
      moment(startDate).format("yyyy-MM-DD"),
      values.passport_number,
      values.passport_country
    ).then((updateUserResponse) => {
      if (updateUserResponse?.status === 200) {
        setProfileData({
          ...profileData,
          first_name: values.first_name,
          last_name: values.family_name,
          date_of_birth: moment(startDate).format("yyyy-MM-DD"),
          passport_number: values.passport_number,
          citizenship: values.passport_country,
          profile_completed: true,
        });
        getUserSessionFromStorage().then((userInfo) => {
          if (userInfo !== null) {
            handleCBP(userInfo.userId).then(({ hasError, message }) => {
              progressBarValue.setValue(1);
              resetAnimation();
            });
          }
        });
      } else {
        setErrorMessage(Strings.SOMETHING_WENT_WRONG);
        setShowProgressBar(false);
      }
    });
  };

  return (
    <SignUpHOC pageNumber={3} onlyLogoutOptionEnabled={true}>
      <Text style={confirmInfoStyles.infoText}>
        {Strings.INCOMPLETE_ACCOUNT}
      </Text>
      <Formik
        initialValues={{
          first_name: profileData?.first_name || "",
          family_name: profileData?.last_name || "",
          birth_date: startDate,
          passport_number: profileData?.passport_number || "",
          passport_country: profileData?.citizenship || "",
        }}
        validationSchema={validationSchema.confirmInfo}
        onSubmit={(values) => {
          isChecked
            ? !showProgressBar
              ? setShowProgressBar(true)
              : signUpHandle(values)
            : setErrorMessage(Strings.CONFIRM_YOUR_INFO_CHECKBOX_ERROR);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <View>
            <View style={confirmInfoStyles.inputsContainer}>
              <ConfirmInfoInput
                label={GIVEN_NAME}
                placeholder={GIVEN_NAME}
                value={values.first_name}
                inputContainer={confirmInfoStyles.inputContainer}
                setValue={handleChange("first_name")}
                error={touched.first_name && errors.first_name}
                handleBlur={handleBlur("first_name")}
              />
              <ConfirmInfoInput
                label={FAMILY_NAME}
                placeholder={FAMILY_NAME}
                value={values.family_name}
                inputContainer={confirmInfoStyles.inputContainer}
                setValue={handleChange("family_name")}
                error={touched.family_name && errors.family_name}
                handleBlur={handleBlur("family_name")}
              />
              <DatePickerInput
                setSelectedDate={setStartDate}
                selectedDate={startDate}
                customInput={
                  <ConfirmInfoInput
                    editable={false}
                    label={DATE_OF_BIRTH}
                    inputContainer={confirmInfoStyles.inputContainer}
                    placeholder={DATE_OF_BIRTH}
                    value={
                      startDate ? moment(startDate).format("yyyy-MM-DD") : ""
                    }
                    handleBlur={handleBlur}
                  />
                }
              />
              <ConfirmInfoInput
                label={PASSPORT_ID + " #"}
                placeholder={PASSPORT_ID}
                value={values.passport_number}
                inputContainer={confirmInfoStyles.inputContainer}
                setValue={handleChange("passport_number")}
                error={touched.passport_number && errors.passport_number}
                handleBlur={handleBlur("passport_number")}
              />
              <View
                style={[confirmInfoStyles.inputContainer, { minWidth: 225 }]}
              >
                <Text
                  style={[
                    {
                      ...theme.fonts.black,
                      marginBottom: 7,
                      fontSize: 16,
                      color: theme.colors.btn,
                      textTransform: "uppercase",
                    },
                  ]}
                >
                  {Strings.CITIZEN_SHIP}
                </Text>

                <DropDown
                  receiptTypeOptions={countriesData}
                  onSelection={handleChange("passport_country")}
                  error={touched.passport_country && errors.passport_country}
                  selectedOption={
                    values.passport_country ? values.passport_country : ""
                  }
                />
              </View>
            </View>
            <View style={confirmInfoStyles.checkboxContainer}>
              <Checkbox onChecked={(val) => setChecked(val)} />
              <Text style={confirmInfoStyles.text}>
                {CONFIRM_YOUR_INFO_CHECKBOX}
              </Text>
            </View>
            {errorMessage !== "" && (
              <Text style={confirmInfoStyles.errorText}>{errorMessage}</Text>
            )}
            <View style={confirmInfoStyles.buttonContainer}>
              <ProgressBarTimer
                showProgressBar={showProgressBar}
                cancelRequest={cancelCBPRequest}
                handleFunction={handleSubmit}
                progressBarValue={progressBarValue}
                timer={62000}
              >
                <ContinueButton
                  name={CONFIRM_CHECK_STATUS}
                  onPress={handleSubmit}
                  containerStyle={confirmInfoStyles.continueButtonContainer}
                />
              </ProgressBarTimer>
            </View>
          </View>
        )}
      </Formik>
    </SignUpHOC>
  );
};

export default ConfirmInfoForm;
