import { sendAdminInvitation } from "./../../../../util/organization/sendAdminInvitation";
import { useOrgStoreContext } from "./../../../../context/orgStoreContext/StoreProvider";
import { Strings } from "../../../../../assets/strings";
import { getUsersListOfOrganization } from "../../../../util/organization/getUsersListOfOrganization";
import { useOrgAuthContext } from "../../../../context/OrganizationAuthContext";

const AdminScreenController = () => {
  const { organizationData } = useOrgStoreContext();
  const { orgAuthState } = useOrgAuthContext();

  const getAllAdminsList = async () => {
    try {
      const response = await getUsersListOfOrganization(
        orgAuthState.orgUserToken,
        organizationData.organization_id
      );

      if (response.status === 200) {
        return {
          hasError: false,
          data: response.data,
        };
      } else {
        return {
          hasError: true,
          message: response.data.message,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        hasError: true,
        message: Strings.SOMETHING_WENT_WRONG,
      };
    }
  };

  const sendInvitationToAdmin = async (email: string) => {
    try {
      const response = await sendAdminInvitation(
        orgAuthState.orgUserToken,
        organizationData.organization_id,
        email
      );

      if (response.status === 200) {
        return {
          hasError: false,
          data: response.data,
        };
      } else {
        return {
          hasError: true,
          message: response.data.message,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        hasError: true,
        message: Strings.SOMETHING_WENT_WRONG,
      };
    }
  };

  return { getAllAdminsList, sendInvitationToAdmin };
};

export default AdminScreenController;
